import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MysqlService } from '../../../../services/mysql.service';
import { AuthorizationService } from '../../../../services/authorization.service';
import { IPerizia } from '../../../../models/perizia.interface';

@Component({
    selector: 'question-edit-dialog',
    templateUrl: './question-edit-dialog.component.html',
    styleUrls: ['./question-edit-dialog.component.scss'],
    standalone: false
})
export class VerycarQuestionEditDialogComponent implements OnInit {

  q
  questions

  sections

  constructor(
    public dialogRef: MatDialogRef<VerycarQuestionEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.q = data.q
    this.questions = data.questions

    this.loadSections()


  }

  loadSections() {
    let tmp = this.questions.map(e => { return { sectionCode: e.sectionCode, sectionDescription: e.sectionDescription } })
    tmp.push({ sectionCode: this.q.sectionCode, sectionDescription: this.q.sectionDescription })
    tmp = tmp.filter(e => e.sectionCode != '')
    
    this.sections = [...new Map(tmp.map(e => [e.sectionCode, e])).values()];
    this.sections.sort((a, b) => a.sectionCode - b.sectionCode)

  }

  onSelectChange(event) {
    console.log(event)

    if (event.value == '(Nuovo)') {

      let sectionCode = prompt('Inserisci sectionCode')
      let sectionDescription = prompt('Inserisci sectionDescription')

      if (!sectionCode || sectionCode == '' || !sectionDescription || sectionDescription == '') return

      this.q.sectionCode = sectionCode
      this.q.sectionDescription = sectionDescription

      this.loadSections()

      return

    }

    this.q.sectionDescription = this.sections.find(e => e.sectionCode == event.value).sectionDescription

    this.loadSections()

  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {


  }

  save() {



    this.dialogRef.close(this.q)

  }


}

