import { MysqlService } from './../../services/mysql.service';
import { UserService } from './../../services/user.service';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Location, PopStateEvent } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { VERSION } from '@angular/core';


import PerfectScrollbar from 'perfect-scrollbar';
import { environment } from 'environments/environment';
import { SharedService } from 'app/services/shared.service';
import moment from 'moment';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss'],
    standalone: false
})
export class AdminLayoutComponent implements OnInit {

    easteregg = 'default'

    private _router: Subscription;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    version = environment.appVersion
    versionAg = VERSION.major

    user;
    innerWidth;

    toggleSubject: Subject<void> = new Subject<void>();
    toggleButton: any;

    isToggle: boolean = false;

    isInitial: boolean = true;

    constructor(public location: Location, private router: Router, private userService: UserService, public db: MysqlService,
        private sharedService: SharedService, private element: ElementRef
    ) {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
    }

    ngOnInit() {

        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        this.userService.onUserState()
            .subscribe((user: any) => {

                this.easteregg = getEasterEgg(this.userService.getRole(), this.userService.getEmail(), this.db.isTest())

                this.db.get_current_user_profile().then(res => {

                    console.log(res)

                    ///// clear auth if token invalid
                    if (localStorage.getItem('authority'))
                        if (res.status == 'ERROR' && res.error.errorInfo[2] == "Authentication failed!") {
                            localStorage.clear()
                            window.location.reload()
                            return
                        }


                    this.user = res[0]


                    console.log('auth obs')
                });

            });

        const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

        if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {
            // if we are on windows OS we activate the perfectScrollbar function

            document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
        }
        const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
        const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                if (event.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (event instanceof NavigationEnd) {
                if (event.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });


        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

            try {

                elemMainPanel.scrollTop = 0;
                elemSidebar.scrollTop = 0;
            } catch (error) {

            }

        });
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            try {

                let ps = new PerfectScrollbar(elemMainPanel);
                ps = new PerfectScrollbar(elemSidebar);
            } catch (error) {

            }
        }
    }
    ngAfterViewInit() {
        this.runOnRouteChange();
    }
    isMaps(path) {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice(1);
        if (path == titlee) {
            return false;
        }
        else {
            return true;
        }
    }
    runOnRouteChange(): void {
        try {
            if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
                const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
                const ps = new PerfectScrollbar(elemMainPanel);
                ps.update();
            }
        } catch (error) {

        }

    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    sidebarToggle() {
        this.isInitial = false;
        this.sharedService.toggleSidebar();

        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        setTimeout(function () {
            $toggle.classList.remove('toggled');
        }, 400);

        this.isToggle = !this.isToggle;
    }

}



function getEasterEgg(role: string, email: string, isTest: boolean) {

    const active = false// disattivato

    // se attivo, gg e ruolo interno
    if (
        active &&
        email?.endsWith('@general-group.it') &&
        (role == 'ADMIN' || role == 'GESTORE' || role == 'ISPETTORE')
    ) {

        const eastereggs = [
            { code: 'piday', from: '03-14', to: '03-14' },
            { code: 'stpatrick', from: '03-17', to: '03-17' },
            { code: 'pirate', from: '09-19', to: '09-19' },
            { code: 'may4th', from: '05-04', to: '05-04' },
            { code: 'halloween', from: '10-31', to: '10-31' },
            { code: 'xmass', from: '12-07', to: '12-27' }, 
        ]

        const mmdd = moment().format('MM-DD')

        for (const e of eastereggs)
            if (mmdd >= e.from && mmdd <= e.to)
                return e.code
    }

    if (isTest) return 'default-test';
    return 'deafult';
}