import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MysqlService } from 'app/services/mysql.service';
import { MD5 } from 'app/shared';

@Component({
    selector: 'app-search-perizie',
    templateUrl: './search-perizie.component.html',
    styleUrls: ['./search-perizie.component.scss'],
    standalone: false
})
export class SearchPerizieComponent implements OnInit {

  @Input() type = 'all'
  
  @Output() onRouted = new EventEmitter();

  searchVal = ''

  constructor(
    private db: MysqlService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }


  async searchByVal() {

    let ids = await this.db.unipolrental_get_ids_by_val(this.searchVal.trim(), this.type)
    console.log('ids: ', ids);

    if (ids && ids.ids && ids.ids != '')
      this.goto(ids.ids)
    else
      alert('Nessun risultato per questa ricerca')

  }

  goto(ids: string) {

    if (!ids.includes(',')) {
      this.router.navigate([`automotive/${this.type}/dettaglio/${ids}`], {  replaceUrl: true })
      return
    }

    let hash = MD5(ids)
    sessionStorage.setItem(hash, ids)
    this.router.navigate([`automotive/${this.type}`], { queryParams: { hash: hash }, replaceUrl: true })

    this.onRouted.emit()

  }

}
