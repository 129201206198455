import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IForm } from 'app/models/form.interface';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MysqlService } from 'app/services/mysql.service';
import { IFile } from 'app/models/incarico.interface';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    standalone: false
})
export class FormComponent implements OnInit {

  form;

  @Input() disabled: boolean = false;
  @Input() result: any;
  @Output() resultChange = new EventEmitter<any>();



  @Input('id') set setId(id) {

    this.db.forms.get(id).then(res => {
      console.log(res)
      this.setFormx(res.data)
    })

  }

  @Input('code') set setcode(code) {

    this.db.forms.get_by_code(code).then(res => {
      console.log(res)
      this.setFormx(res.data)
    })

  }

  @Input('form') set setForm(form) {

    console.log('init form', form)

    this.setFormx(form)


  };

  @Output() isValid = new EventEmitter<boolean>();



  setFormx(form) {

    setTimeout(() => {


      console.log('setFormx', form, this.result)

      var xformBuildier = {};

      form.rows.forEach(row => {
        row.cols.forEach(col => {
          if (!col) return;
          if (col.id) {
            xformBuildier[col.id] = new UntypedFormControl({ disabled: this.disabled }, col.mandatory ? Validators.required : undefined)



            if (this.result && typeof (this.result) == 'string') this.result = JSON.parse(this.result)

            if (!this.result || this.result == '"null"' || this.result == 'null' || this.result == '{}') this.result = {} as any;
            //console.log('setFormx',form, this.result)

            if (!this.result[col.id])
              if (col.type == 'input-address')
                this.result[col.id] = {};
              else
                this.result[col.id] = undefined;
          }
        })

      });

      console.log('setFormx', form, this.result)

      console.log('xformBuildier', xformBuildier)


      this.xform = this.formBuilder.group(xformBuildier)
      this.form = form;

    }, 100);

    setInterval(() => { this.resultChange.emit(this.result) }, 100)
  }

  xform: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private db: MysqlService) {


    /*
        this.xform = formBuilder.group({
          email: ['', Validators.required],
          password: ['', Validators.required],
        });
    */


    setInterval(() => {

      try {
        this.isValid.emit(this.xform.valid)
      } catch (error) {

      }

    }, 100)

  }

  ngOnInit() {
    //console.clear()
    console.log('xform_result', this.result)

  }

  colId
  loadImageClick(id) {
    this.colId = id
    document.getElementById('file_' + id).click();
  }

  uploadFile(event) {
    console.log(event)

    for (let i = 0; i < event.target.files.length; i++) {
      const file: File = event.target.files[i];

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = res => {

        let tmp: IFile = {
          fileName: file.name,
          size: file.size,
          fileContent: '' + reader.result,
          creationDate: new Date(),

        }

        if (!this.result[this.colId]) this.result[this.colId] = {} as any
        this.result[this.colId].img = tmp

        console.log(this.result)


      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };

    }

  }



  dropFile(event, id) {

    if (!event.content.match('image')) {
      alert("Immagine non valida")
      return;
    }

    console.log(event)

    this.result[id] = event.content;

  }




  onLocationSelected(id, event) {


    this.result[id].cap = event.CAP
    this.result[id].citta = event.Citta
    this.result[id].indirizzo = event.Indirizzo
    this.result[id].provincia = event.Prov
    this.result[id].location_lat = event.location_lat
    this.result[id].location_lon = event.location_lon


  }




  showTooltip(string) {
    alert(string)
  }


  getMediumColor(x: string) {

    if (!x) return 255

    try {

      //console.log('color ')
      let x1 = x.substr(1, 2)
      let n1 = parseInt('0x' + x1)
      //console.log('color ' + x1 + ' ' + n1)
      let x2 = x.substr(3, 2)
      let n2 = parseInt('0x' + x2)
      //console.log('color ' + x2 + ' ' + n2)
      let x3 = x.substr(5, 2)
      let n3 = parseInt('0x' + x3)
      //console.log('color ' + x3 + ' ' + n3)
      let n = (n1 + n2 + n3) / 3
      return n

    } catch (error) {
      console.error(error)
      return 255
    }

  }


}
