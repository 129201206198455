import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Observable, Subscriber } from 'rxjs';

@Pipe({
    name: 'mycountdown',
    standalone: false
})
export class CountdownPipe implements PipeTransform {

  outVal: string = '';

  d;

  transform(d): any {

    this.d = d;

    this.refresh(d)

    return 'this.out'
  }

  refresh(d) {
    let formatIn = "YYYY-MM-DD HH:mm:ss";
    let m1 = moment(d, formatIn);
    let now = moment();
    let diff = now.diff(m1);

    this.outVal = '' + diff;

    setTimeout(() => {
      this.refresh(d);
    }, 1000);
  }


}
