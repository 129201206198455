"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SDPCandidateType = void 0;
var SDPCandidateType;
(function (SDPCandidateType) {
  SDPCandidateType["Host"] = "host";
  SDPCandidateType["ServerReflexive"] = "srflx";
  SDPCandidateType["PeerReflexive"] = "prflx";
  SDPCandidateType["Relay"] = "relay";
})(SDPCandidateType = exports.SDPCandidateType || (exports.SDPCandidateType = {}));
exports.default = SDPCandidateType;
