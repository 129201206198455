"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * [[RealtimeVolumeIndicator]] stores the current volume, mute, and
 * signal strength for an attendee.
 */
class RealtimeVolumeIndicator {
  constructor() {
    this.volume = null;
    this.muted = null;
    this.signalStrength = null;
  }
}
exports.default = RealtimeVolumeIndicator;
