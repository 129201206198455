"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckNetworkUDPConnectivityFeedback = void 0;
var CheckNetworkUDPConnectivityFeedback;
(function (CheckNetworkUDPConnectivityFeedback) {
  CheckNetworkUDPConnectivityFeedback[CheckNetworkUDPConnectivityFeedback["Succeeded"] = 0] = "Succeeded";
  CheckNetworkUDPConnectivityFeedback[CheckNetworkUDPConnectivityFeedback["MeetingSessionURLsNotInitialized"] = 1] = "MeetingSessionURLsNotInitialized";
  CheckNetworkUDPConnectivityFeedback[CheckNetworkUDPConnectivityFeedback["ConnectionFailed"] = 2] = "ConnectionFailed";
  CheckNetworkUDPConnectivityFeedback[CheckNetworkUDPConnectivityFeedback["ICENegotiationFailed"] = 3] = "ICENegotiationFailed";
})(CheckNetworkUDPConnectivityFeedback = exports.CheckNetworkUDPConnectivityFeedback || (exports.CheckNetworkUDPConnectivityFeedback = {}));
exports.default = CheckNetworkUDPConnectivityFeedback;
