import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.css'],
    standalone: false
})
export class RatingComponent implements OnInit {
 
  text = ['xxxxxx', 'xxxxxx', 'xxxxxx',]

  @Input('text') _text = ['xxxxxx', 'xxxxxx', 'xxxxxx',];
  @Input() value: any;

  @Input() itemId: string;
  @Input() disabled: boolean=false;

  //@Output() onChange: EventEmitter<any> = new EventEmitter();

  inputName: string;
  ngOnInit() {

    this.text = this._text.reverse();

  }
  setValue(v: number) {
    if(this.disabled) return;
    this.value[this.itemId] = v
    console.log(this.value)
  }

  getSelectedValue(){
    return  this.value[this.itemId]
  }
}
