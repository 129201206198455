"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckAudioOutputFeedback = void 0;
var CheckAudioOutputFeedback;
(function (CheckAudioOutputFeedback) {
  CheckAudioOutputFeedback[CheckAudioOutputFeedback["Succeeded"] = 0] = "Succeeded";
  CheckAudioOutputFeedback[CheckAudioOutputFeedback["Failed"] = 1] = "Failed";
})(CheckAudioOutputFeedback = exports.CheckAudioOutputFeedback || (exports.CheckAudioOutputFeedback = {}));
exports.default = CheckAudioOutputFeedback;
