import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'my-dialog',
    templateUrl: './my-dialog.component.html',
    styleUrls: ['./my-dialog.component.scss'],
    standalone: false
})
export class MyDialogComponent implements OnInit {
  result = {} as any

  constructor(
    public dialogRef: MatDialogRef<MyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { myDialogData: IMyDialogData },
  ) { }

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close(this.result)
  }

  close() {
    this.dialogRef.close()
  }


}


export interface IMyDialogData {
  title: string
  type: string //prompt, alert, info, warning, confirm
  fields: [
    {
      name: string
      type: string
      label: string
      value?: any
    }
  ]
}

export const openMyDialog = (dialog, myDialogData: IMyDialogData) => {


  return new Promise<any>(resolve => {

    const dialogRef = dialog.open(MyDialogComponent, {
      data: {
        myDialogData: myDialogData
      }
    });

    dialogRef.afterClosed().subscribe(res => {

      resolve(res)

    })

  })


}