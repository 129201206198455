import { Component, ElementRef, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import * as OT from '@opentok/client';

@Component({
    selector: 'app-subscriber',
    templateUrl: './subscriber.component.html',
    styleUrls: ['./subscriber.component.scss'],
    standalone: false
})

export class SubscriberComponent implements AfterViewInit {
  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  @Input() session: OT.Session;
  @Input() stream: OT.Stream;

  @Input() captureEnabled: boolean = true;

  @Input() recordable: boolean;


  @Input() isRecording: boolean;


  @Output() onCapture: EventEmitter<string> = new EventEmitter();
  @Output() onSendCommand: EventEmitter<string> = new EventEmitter();
  @Output() onToggleRecord: EventEmitter<boolean> = new EventEmitter();
  @Output() onCurrentResolutionChange: EventEmitter<any> = new EventEmitter();



  constructor() { }

  subscriber;

  ngAfterViewInit() {
    this.subscriber = this.session.subscribe(this.stream, this.subscriberDiv.nativeElement, { preferredResolution: { width: 1920, height: 1080 }, width: '100%', height: '100%' }, (err) => {
      if (err) {
        alert(err.message);
      } else {
        this.resolutionChange()
      }
    });
  }

  capture() {
    console.log('capture - subscriber')

    let x = '' + this.subscriber.getImgData();
    this.onCapture.emit(x)

  }

  sendCommand(cmd: string) {
    console.log('sendCommand - subscriber')
    this.onSendCommand.emit(cmd)
  }

  toggleRecord() {
    //this.isRecording = !this.isRecording

    console.log('toggleRecord', this.isRecording)
    this.onToggleRecord.emit(this.isRecording)


  }

  currentResolution = { w: 0, h: 0 }

  resolutionChange() {

    setTimeout(() => {

      let h = this.subscriber.videoHeight()
      let w = this.subscriber.videoWidth()

      //console.log('currentResolution ' + w + 'x' + h)

      if (this.currentResolution.w != w || this.currentResolution.h != h) {
        this.currentResolution = { w: w, h: h }

        console.log('resolution changed ' + w + 'x' + h)

        this.onCurrentResolutionChange.emit(this.currentResolution)
      }


      this.resolutionChange()
    }, 100);

  }

  max(a,b){
    return Math.max(a,b)
  } 

}
