<div class="cnt">

    <div [ngClass]="{'logo':true}">
        <a href="./" class="simple-text">
            <div class="logo-img">
                <img [src]="logo" />
            </div>
        </a>
    </div>


    <div [ngClass]="{'sidebar-wrapper':false}">

        <app-navbar [hidden]="!user"></app-navbar><br><br>
        <hr>


        <app-search-perizie style="display:block; width:200px; margin-left:30px;">
        </app-search-perizie>


        <app-upload-vdr
            *ngIf="user?.Livello == 'ADMIN' || user?.Livello == 'GESTORE' || user?.Livello == 'ISPETTORE' || user?.Livello == 'SALACONTROLLO'"></app-upload-vdr>


        <ul class="nav">

            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20"
                    (click)="open(node)">
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <button mat-icon-button disabled></button>
                    <i class="material-icons">{{node.icon}}</i>
                    {{node.name}}
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                    matTreeNodePaddingIndent="20" (click)="open(node)">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <i class="material-icons">{{node.icon}}</i>
                    {{node.name}}
                </mat-tree-node>
            </mat-tree>

            <div>
                <br><br>
                <app-language-selector></app-language-selector>
            </div>

            <div *ngIf="user?.Livello == 'ADMIN'">
                <br>
                <app-sysmon></app-sysmon>
            </div>





        </ul>
    </div>
</div>