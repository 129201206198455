import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'myNumber',
    standalone: false
})
export class MyNumberPipe implements PipeTransform {

  constructor(private p: DecimalPipe) { }

  transform(value: any, args?: any): any {

    let s: string = this.p.transform(value, args);

    try {
      s = s == '0,00' ? '' : s
    } catch (error) {

    }

    return s;
  }

}
