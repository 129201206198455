"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckAudioInputFeedback = void 0;
var CheckAudioInputFeedback;
(function (CheckAudioInputFeedback) {
  CheckAudioInputFeedback[CheckAudioInputFeedback["Succeeded"] = 0] = "Succeeded";
  CheckAudioInputFeedback[CheckAudioInputFeedback["Failed"] = 1] = "Failed";
  CheckAudioInputFeedback[CheckAudioInputFeedback["PermissionDenied"] = 2] = "PermissionDenied";
})(CheckAudioInputFeedback = exports.CheckAudioInputFeedback || (exports.CheckAudioInputFeedback = {}));
exports.default = CheckAudioInputFeedback;
