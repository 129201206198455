"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
class VideoQualitySettings {
  constructor(videoWidth, videoHeight, videoFrameRate, videoMaxBandwidthKbps) {
    this.videoWidth = videoWidth;
    this.videoHeight = videoHeight;
    this.videoFrameRate = videoFrameRate;
    this.videoMaxBandwidthKbps = videoMaxBandwidthKbps;
  }
}
exports.default = VideoQualitySettings;
