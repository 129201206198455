import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITiresData, ITire } from 'app/models/tire.interface';
;

@Component({
    selector: 'app-tires',
    templateUrl: './tires.component.html',
    styleUrls: ['./tires.component.css'],
    standalone: false
})
export class TiresComponent implements OnInit {

  @Input() value: ITiresData;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  spare;

  constructor() { }

  @Output() onOpenViewer: EventEmitter<any> = new EventEmitter();
  openViewer(event) {
    console.log('openViewer tires')
    this.onOpenViewer.emit(event)
  }

  ngOnInit() {
    ////console.log(this.value)
  }

  copy() {

    for (let i = 1; i < this.value.tires.length; i++) {
      this.value.tires[i].brand = this.value.tires[0].brand;
      this.value.tires[i].d = this.value.tires[0].d;
      this.value.tires[i].h = this.value.tires[0].h;
      this.value.tires[i].l = this.value.tires[0].l;
      this.value.tires[i].runflat = this.value.tires[0].runflat;
      this.value.tires[i].type = this.value.tires[0].type;
    }

    this.changed(null)
  }


  changed(e) {

    ////console.log(e)

    this.onChange.emit();
  }

}



export const getEmptyTiresData = function (): ITiresData {
  let t: ITiresData = {
    tires: [getEmptyTire(), getEmptyTire(), getEmptyTire(), getEmptyTire(), getEmptyTire(),],
    spareTireNotPreset: false
  }
  return t;
}


export const getEmptyTire = function (): ITire {
  let t: ITire = {
    l: 0,
    h: 0,
    d: 0,
    brand: '',
    runflat: false,
    type: '',
    mm: 0,
  }

  return t;

}