import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, ElementRef, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MysqlService } from 'app/services/mysql.service';
import { ILiveXpertTime, IPerizia, IResult } from 'app/models/perizia.interface';
import { IFile } from 'app/models/incarico.interface';
import { MD5, copyText } from 'app/shared';
import { HttpClient, HttpEventType } from '@angular/common/http';
import JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import * as FileSaver from 'file-saver'
import { UserService } from 'app/services/user.service';
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  AudioVideoObserver,
  VideoTile,
  DefaultVideoTile,
  VideoTileState,
  DefaultActiveSpeakerPolicy,
} from 'amazon-chime-sdk-js';
import { Platform } from '@angular/cdk/platform';


/*
import { asBlob } from 'html-docx-js-typescript'

import { saveAs } from "file-saver/src/FileSaver";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

import moment from 'moment-timezone';;
*/
import { ChimeSettingsDialog } from 'app/menu/extra/chimes/chimes.component';



@Component({
  selector: 'app-dialog-telexpert',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})

export class TelexpertChimeDialogComponent implements OnInit, OnDestroy {

  msg = '';
  n = 0;

  loading = false

  id: string;
  item;

  sessionId: string;
  token: string;
  filtered_source: IFile[] = [];
  _source: IFile[] = [];
  intervalHandler;
  usertType
  locked = false



  @ViewChild('meetingAudio') public meetingAudio: ElementRef<HTMLAudioElement>;
  @ViewChild('meetingVideo') public meetingVideo: ElementRef<HTMLVideoElement>;

  @ViewChild('video1') public video1: ElementRef<HTMLVideoElement>;

  meeting
  meetingId
  attendee
  attendeeId
  audioInputDevs
  audioOutputDevs
  videoDevs
  videoDev: any

  logger: ConsoleLogger
  deviceController: DefaultDeviceController
  configuration: MeetingSessionConfiguration
  meetingResponse
  attendeeResponse
  meetingSession: DefaultMeetingSession


  showMeetingVideo = false
  audio
  videos = []

  constructor(
    private route: ActivatedRoute,
    private db: MysqlService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private platform: Platform
  ) {

    this.audio = new Audio();
    this.audio.src = "../../../../../assets/sounds/ping.mp3";
    this.audio.load();

    console.log('constructor')

    for (let i = 1; i <= 100; i++)
      this.videos.push({ id: i, visible: false, isMuted: true })



    this.route.params.subscribe(params => {

      console.log(params)

      if (params['meetingId'])
        this.meetingId = params['meetingId'];


      if (params['attendeeId'])
        this.attendeeId = params['attendeeId'];

      setTimeout(() => {
        this.start()
      }, 1000);

    })

    this.db.impostazioni.get('minuti_videoperizia').then(reso => {
      console.log(reso)
      this.db.telexpert.get_all_times().then((res: any) => {
        console.log(reso[0].valore - (Math.round(res.TOTAL / 60 * 100) / 100))
        if ((reso[0].valore - (Math.round(res.TOTAL / 60 * 100) / 100)) <= 0) {
          alert("i minuti disponibili all'utenza aziendale per le videoperizie sono terminati. Contattare un amministratore")
          this.locked = true
        }
        // if(true)

      })
    })

    for (let i = 1; i <= 2; i++)
      this.videos.push({ id: i, visible: false, isMuted: true })
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadData();
  }

  ngOnDestroy() {
    if (!this.stoppedTimer && !this.item.closed) {
      let item: ILiveXpertTime = {
        id: 0,
        time: this.ts,
        inspector: this.userService.getEmail(),
        date: new Date(),
        idRequest: this.item.id
      }
      console.log('set')
      this.db.telexpert.set_time(item).then(res => {
        console.log(res)
        this.clearTimer()
      })
    } else
      this.clearTimer()
  }









  downloadAll() {


    let zip = new JSZip();
    let count = 0
    let arr = this.item.attachments
    arr.forEach(element => {
      console.log(element.url)


      JSZipUtils.getBinaryContent(element.url, (err, data) => {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(element.fileName, data, { binary: true });
        count++;
        if (count == arr.length) {

          zip.generateAsync({ type: "blob" })
            .then((zipFile) => {

              console.log('async gen')

              FileSaver.saveAs(zipFile, 'allegati.zip');


            }).catch(err => console.error(err));


        }
      });

    });



  }


  alreadyStarted = false

  async start() {

    if (this.alreadyStarted) return


    this.alreadyStarted = true

    console.log('start')

    this.meetingId = this.item.meetingId


    if (this.meetingId) {

      console.log('get meeting id from param')
      this.meeting = await this.db.aws_chime.get_meeting(this.meetingId)

      if (this.meeting.status == 'ERROR') {
        alert('Meeting chiuso o scaduto')
        window.close()
        return
      }

    } else {

      console.log('get meeting id from api')
      this.meeting = await this.db.aws_chime.create_meeting()
      //this.router.navigate(['/chime/' + this.meeting.Meeting.MeetingId])


      //return

    }

    console.log(this.meeting)

    this.meetingId = this.meeting.Meeting.MeetingId


    this.setSession(this.meetingId)

    if (this.attendeeId) {

      console.log('get attendee id from param')
      this.attendee = await this.db.aws_chime.get_attendee(this.meetingId, this.attendeeId)

    } else {

      console.log('get attendee id from api')
      this.attendee = await this.db.aws_chime.create_attendee(this.meetingId)
      //this.router.navigate(['/chime/' + this.meeting.Meeting.MeetingId + '/' + this.attendee.Attendee.AttendeeId])
      //return

    }

    //console.clear()
    console.log('meeting', this.meeting)
    console.log('attendee', this.attendee)

    // You need responses from server-side Chime API. See below for details.
    this.meetingResponse = this.meeting;
    this.attendeeResponse = this.attendee;

    await this.initMeeting()
    this.meetingSession.audioVideo.start() /// importantissimo, senza questo non funziona un cazzo
    this.setEventListeners()





  }

  async initMeeting() {

    this.logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);
    this.deviceController = new DefaultDeviceController(this.logger, { enableWebAudio: true });

    console.log(this.attendee)
    this.configuration = new MeetingSessionConfiguration(this.meeting, this.attendee);

    // In the usage examples below, you will use this meetingSession object.
    this.meetingSession = new DefaultMeetingSession(
      this.configuration,
      this.logger,
      this.deviceController
    );



    //this.meetingSession.audioVideo.enableWebAudio(true)


    ///set audio IN
    //this.sendMessage(undefined, 'chat', '/set audio IN')
    let xx = await navigator.mediaDevices.enumerateDevices()
    //this.sendMessage(undefined, 'chat', JSON.stringify(xx))
    xx = await navigator.mediaDevices.enumerateDevices()
    //this.sendMessage(undefined, 'chat', JSON.stringify(xx))

    try {
      const audioInputDevice = await this.meetingSession.audioVideo.listAudioInputDevices()

      this.audioInputDevs = audioInputDevice

      //this.sendMessage(undefined, 'chat', JSON.stringify(audioInputDevice))

      //   alert('audioInputDevice ' + JSON.stringify(audioInputDevice[0].deviceId))





      console.log('audioInputDevice', audioInputDevice)
      if (!audioInputDevice || audioInputDevice.length <= 0 || audioInputDevice[0].deviceId == '') alert('audioInputDevice non disponibile')
      //this.sendMessage(undefined, 'chat', JSON.stringify(audioInputDevice[0] ? audioInputDevice[0] : null))

      let dev = localStorage.getItem('awsc_audioIn')
      if (dev) dev = JSON.parse(dev)

      //alert(JSON.stringify(dev ? dev : audioInputDevice[0] ? audioInputDevice[0] : null))

      let x = await this.meetingSession.audioVideo.chooseAudioInputDevice(dev ? dev : audioInputDevice[0].deviceId ? audioInputDevice[0].deviceId : null)
      //this.sendMessage(undefined, 'chat', JSON.stringify(x))
      //  alert(x)
      //   .then(res => alert('then ' + JSON.stringify(res)))
      //  .catch(res => alert('catch ' + JSON.stringify(res)))


    } catch (error) {
      alert('set audio IN ' + JSON.stringify(error))
    }


    /// set audio OUT

    try {
      let audioOutputDevice
      await this.meetingSession.audioVideo.listAudioOutputDevices()
        .then(res => audioOutputDevice = res)
        .catch(err => {
          alert(JSON.stringify(err))
          console.error(err)
        })
      console.log('audioOutputDevice', audioOutputDevice)


      this.audioOutputDevs = audioOutputDevice

      // alert('audioOutputDevice ' + JSON.stringify(audioOutputDevice))

      let audioOutDeviceId = audioOutputDevice[0] ? audioOutputDevice[0].deviceId : null

      if (!audioOutDeviceId) {
        let tmp
        await navigator.mediaDevices.getUserMedia({ audio: true }).then(res => tmp = res).catch(err => alert(JSON.stringify(err)))
        audioOutDeviceId = tmp.id
        console.warn('Im getting the output device id as walkaround' + audioOutDeviceId)

        //alert('Im getting the output device id as walkaround' + audioOutDeviceId)

      }
      if (!audioOutDeviceId) alert('audioOutputDevice non disponibile')

      let dev: any = localStorage.getItem('awsc_audioOut')
      if (dev) dev = JSON.parse(dev)

      //alert(JSON.stringify(dev ? dev : audioOutDeviceId))

      await this.meetingSession.audioVideo.chooseAudioOutputDevice(dev ? dev.deviceId : audioOutDeviceId).then(res => { }).catch(err => alert('catch ' + JSON.stringify(err)))

      /*
            setInterval(() => {
              let anal: AnalyserNode = this.meetingSession.audioVideo.createAnalyserNodeForAudioInput()
              console.log('maxdb:' + anal.maxDecibels)
            }, 1000)
      */
    } catch (error) {
      alert('set video ' + JSON.stringify(error))
    }




    await this.setVideo()

    //this.meetingSession.audioVideo.createAnalyserNodeForAudioInput()


    //alert('audio el' + JSON.stringify(this.meetingAudio))




    let b
    this.meetingSession.audioVideo.bindAudioElement(this.meetingAudio.nativeElement).then(res => {
      b = res
    })

    if (this.platform.SAFARI)
      alert("Vidochiamata pronta, premere ok per continuare")
  }


  async setVideo() {
    /// set video

    try {
      const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices()

      this.videoDevs = videoInputDevices

      console.log('videoInputDevices', videoInputDevices)
      if (!videoInputDevices || videoInputDevices.length <= 0) alert('videoInputDevices non disponibile')
      await this.meetingSession.audioVideo.chooseVideoInputQuality(720, 480, 12, 300);

      this.videoDev = localStorage.getItem('awsc_videoDev')
      if (this.videoDev) this.videoDev = JSON.parse(this.videoDev)

      if (this.videoDev && this.videoDev.deviceId == 'none') {
        this.videoDev = null

      } else if (!this.videoDev)
        this.videoDev = videoInputDevices[0]

      await this.meetingSession.audioVideo.chooseVideoInputDevice(this.videoDev)
    } catch (error) {
      //alert('set video ' + JSON.stringify(error))
    }
  }


  setEventListeners() {

    const observer = {


      videoTileDidUpdate: tileState => {

        //console.log(this.meetingSession.audioVideo.getAllVideoTiles())

        //console.log('videoTileDidUpdate', tileState)

        // Ignore a tile without attendee ID and other attendee's tile.

        if (!tileState.boundAttendeeId) {
          return;
        }


        //console.log('test', tileState)

        let n = this.videos.findIndex(e => e.attendeeId == tileState.boundAttendeeId)
        //console.log('test', n)

        if (n > 16) {

          this.videos[n] = { id: n + 1, visible: false, isMuted: true }

        }



        this.bindVideo(tileState)

        //console.warn(this.videos)

      },
      videoTileWasRemoved: tileId => {
        //console.clear()
        console.log('videoTileWasRemoved')
        console.log('videoTileWasRemoved ', tileId)

        let e = this.videos.find(e => e.tileState != undefined && e.tileState.tileId == tileId)


        console.log('videoTileWasRemoved ', e)


        this.unbindVideo(tileId)
      },




      connectionDidBecomePoor: () => console.warn('connectionDidBecomePoor'),
      connectionDidSuggestStopVideo: () => console.warn('connectionDidSuggestStopVideo'),
      // connectionHealthDidChange: (res) => console.warn('connectionHealthDidChange', res),
      estimatedDownlinkBandwidthLessThanRequired: (n1, n2) => {
        console.warn('estimatedDownlinkBandwidthLessThanRequired', n1, n2)
        this.setTriangle(this.getAttendeeId())
      },
      //metricsDidReceive: clientMetricReport => console.warn('clientMetricReport', clientMetricReport),
      videoNotReceivingEnoughData: res => {
        console.warn('videoNotReceivingEnoughData', res)
        this.setTriangle(res[0].attendeeId)
      },
      //videoReceiveBandwidthDidChange: (bold, bnew) => { this.bw_down = bnew },
      //videoSendBandwidthDidChange: (bold, bnew) => { this.bw_up = bnew },
      //videoSendHealthDidChange: (n1, n2) => console.warn('videoSendHealthDidChange', n1, n2),


      contentShareDidStart: () => {
        console.log('Screen share started');
      },
      contentShareDidStop: () => {
        // Chime SDK allows 2 simultaneous content shares per meeting.
        // This method will be invoked if two attendees are already sharing content
        // when you call startContentShareFromScreenCapture or startContentShare.
        console.log('Screen share stopped');
      }


    };

    this.meetingSession.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(muted => {
      console.log('realtimeSubscribeToMuteAndUnmuteLocalAudio', muted)

      this.sendMessage({ attendeeId: 'chatroom' }, 'isMuted', muted)

    })


    /// chatroom
    this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('chatroom', dataMessage => {
      this.manageMessages(dataMessage)
    })

    /// privatechat
    this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(this.getAttendeeId(), dataMessage => {
      this.manageMessages(dataMessage)
    })

    /// chatroom
    this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('reservations', dataMessage => {
      console.log(dataMessage)

      let b = dataMessage.text() === 'true'

      console.log('reserved', dataMessage)

      try {
        let v = this.videos.find(e => e.attendeeId == dataMessage.senderAttendeeId)
        v.reserved = b
        if (b) v.reservedAt = dataMessage.timestampMs
        else v.reservedAt = undefined




      } catch (error) { }


      dataMessage.senderAttendeeId


    })


    //alert('subscribeToActiveSpeakerDetector')
    this.meetingSession.audioVideo.subscribeToActiveSpeakerDetector(
      new DefaultActiveSpeakerPolicy(),
      (activeSpeakers) => {
        //console.log(activeSpeakers)

        //alert('subscribeToActiveSpeakerDetector -> callback')


      }
    );

    this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present) => {

      console.log('realtimeSubscribeToAttendeeIdPresence', presentAttendeeId)

      if (present) {


        this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume) => {
          console.log('realtimeSubscribeToVolumeIndicator', volume)
          try {

            let el = this.videos.find(e => e.attendeeId == attendeeId)

            console.log('presence el ', el)

            if (!el) {

              let n = 0
              for (let i = 17; i < this.videos.length; i++) {
                if (!this.videos[i].visible) {
                  n = i
                  break
                }
              }


              //console.error('HERE', el, n)

              //let nvideo = this.meetingSession.audioVideo.getAllVideoTiles().findIndex((e: any) => e.tileState.boundAttendeeId == attendeeId)

              //console.log(this.meetingSession.audioVideo.getAllVideoTiles())

              // console.log('presence n ', n)
              console.log(volume)

              this.videos[n] = { id: n + 1, visible: false, isMuted: true }
              this.videos[n].visible = true
              this.videos[n].attendeeId = attendeeId
              this.videos[n].tileState = { tileId: n }
              this.videos[n].volume = volume

            }




          } catch (error) {

            console.error(error)

          }
        })
      } else {

        try {


          console.log('disconnected: ' + presentAttendeeId)
          this.meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(presentAttendeeId)

          let n = this.videos.findIndex(e => e.attendeeId == presentAttendeeId)

          if (n == -1) {
            console.log(this.videos, n, presentAttendeeId)
            return
          }


          console.log('disconnected: ' + n)

          this.videos[n] = { id: n + 1, visible: false, isMuted: true }



        } catch (error) {

          console.error(error)



        }


      }


    });


    this.meetingSession.audioVideo.addContentShareObserver(observer);
    this.meetingSession.audioVideo.addObserver(observer)
    this.meetingSession.audioVideo.startLocalVideoTile()

  }


  openSettings() {
    console.log('openSettings')

    console.log(this.videos)


    let dialogRef = this.dialog.open(ChimeSettingsDialog, {
      height: '400px',
      width: '600px',
      data: {
        audioInputDevs: this.audioInputDevs,
        audioOutputDevs: this.audioOutputDevs,
        videoDevs: this.videoDevs,
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log(`Dialog result: ${result}`);
      if (result) {


        let dev1 = localStorage.getItem('awsc_audioIn')
        if (dev1) {
          dev1 = JSON.parse(dev1)
          await this.meetingSession.audioVideo.chooseAudioInputDevice(dev1)
        }

        let dev2 = localStorage.getItem('awsc_audioOut')
        if (dev2) {
          dev2 = JSON.parse(dev2)
          await this.meetingSession.audioVideo.chooseAudioOutputDevice(dev2)
        }

        let dev3 = localStorage.getItem('awsc_videoDev')
        if (dev3) {
          dev3 = JSON.parse(dev3)
          await this.meetingSession.audioVideo.chooseVideoInputDevice(dev3)
        }


        /*
                this.meetingSession.audioVideo.stop()
        
                setTimeout(() => {
                  this.meetingSession.audioVideo.start()
                }, 100);
        */
      }
    });
  }

  async manageMessages(dataMessage) {
    //console.log('manageMessages')

    let msg = JSON.parse(dataMessage.text())
    //console.log(msg)



    //console.log("I'm receiving a message - ", msg)

    switch (msg.type) {



    }
  }

  switchCamera() {
    this.sendMessage({ attendeeId: 'chatroom' }, 'switchCamera', {})
  }


  sendMessage(x, type: string, msg = undefined) {
    //console.log('sendMessage')

    if (!this.meetingSession) return

    let useMsg = false;

    if (type == 'chat' && !msg) {
      msg = this.msg
      if (!msg || msg == '') return
      useMsg = true;
    }


    //console.log("I'm sending a message - ", { type: type, msg: msg })

    this.meetingSession.audioVideo.realtimeSendDataMessage(x ? x.attendeeId : 'chatroom', { type: type, msg: msg })

    if (useMsg) this.msg = ''
  }



  getAttendeeId() {
    //console.log('getAttendeeId')
    try {
      return this.attendeeResponse.Attendee.AttendeeId
    } catch (error) {

    }
  }


  setTriangle(attendeeId) {
    console.log(this.videos)
    this.videos.filter(e => e.attendeeId == attendeeId)[0].warning = true
    setTimeout(() => {
      this.videos.filter(e => e.attendeeId == attendeeId)[0].warning = false
    }, 10000);
  }

  bindVideo(tileState: VideoTileState, mainVideo = false) {
    //console.log('bindVideo')


    let el;
    if (!mainVideo) {

      let nvideo = this.videos.findIndex(e => e && e.tileState && tileState && e.tileState.tileId == tileState.tileId)

      if (nvideo < 0) {
        nvideo = this.videos.findIndex(e => e.visible == false)
      }

      nvideo++

      this.videos[nvideo - 1].visible = true
      this.videos[nvideo - 1].attendeeId = tileState.boundAttendeeId
      this.videos[nvideo - 1].tileState = tileState

      el = document.getElementById('video-' + nvideo)
      if (!el) return
      this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, el as HTMLVideoElement)
    }
    else {

      //alert('binding main video')

      try {

        this.showMeetingVideo = true;
        el = document.getElementById('content-share-video')
        if (!el) return
        this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, el as HTMLVideoElement)

      } catch (error) {
        console.error(error)
        //alert(JSON.stringify(error))

      }


    }

  }



  unbindVideo(tileId) {
    console.log('unbindVideo')
    let nvideo
    try {
      console.log('unbinding video -> tileId:' + tileId)

      nvideo = this.videos.findIndex(e => e.tileState != undefined && e.tileState.tileId == tileId)

      console.log(this.videos, nvideo)

      this.meetingSession.audioVideo.unbindVideoElement(tileId)

      this.videos[nvideo] = { id: nvideo + 1, visible: false, isMuted: true }

    } catch (error) {
      console.error(error, nvideo)
    }

  }

  afFirestore = {} as any

  loadData() {


    this.startTimer()

    this.afFirestore.collection('requests').doc(this.id).valueChanges().subscribe((res: any) => {
      console.log(res)


      if (!res.attachments) res.attachments = [];
      for (let i = 0; i < res.attachments.length; i++) {
        res.attachments[i].url = this.db.telexpert.get_attachment_url(this.id, res.attachments[i])
      }

      this.item = res;

      if (!this.ts) this.ts = this.item.ts ? this.item.ts : 0

      this.start()

    })

  }

  ts;
  stoppedTimer = false
  async startTimer() {
    if (this.intervalHandler != undefined)
      await this.clearTimer()

    this.intervalHandler = setInterval(() => {
      if (!this.stoppedTimer)
        this.ts++
    }, 1000);
  }

  clearTimer() {
    clearInterval(this.intervalHandler)
    return this.afFirestore.doc('requests/' + this.id).set({ ts: this.ts }, { merge: true })
  }


  setSession(meetingId = null) {
    this.afFirestore.doc('requests/' + this.id).set({ meetingId: meetingId }, { merge: true })
  }

  openImg(url: string) {
    window.open(url, '_blank');
  }

  async sendCommand(cmd) {

    console.log('sendCommand - dialog')

    this.afFirestore.collection('requests').doc(this.id).set({ cmd: cmd }, { merge: true })
      .then(res => { console.log('cmd sent', res) })
      .then(err => { console.error('cmd error', err) })
  }

  async save() {

    if (!this.usertType || this.usertType == '') {
      alert("Selezionare il tipo utente")
      return
    }

    this.loading = true

    this.db.get_perizia(this.item.idPerizia, '')
      .then(async res => {
        console.log(res)

        if (res.status != 'OK') {
          console.error(event)
          return;
        }


        let per = res.item

        //per.noteCarico = this.db.getIcon() == 'gg-demo' || this.db.getIcon() == 'gg' ? 'DirectXpert' : 'LiveXpert'


        for (let i = 0; i < this.item.attachments.length; i++) {


          this.msg = ` - Conversione immagine ${i + 1}/${this.item.attachments.length}`

          let c = '';
          await this.getImage(this.item.attachments[i].url).then(async (res: any) => {

            await this.createImageFromBlob(res).then(res => {
              c = res;
              //   console.log(c)
            }).catch(err => { console.error(err); return ''; });

          }).catch(err => { console.error(err); return ''; });


          per.allegati.push(
            {
              fileName: this.item.attachments[i].fileName,
              fileContent: c,
              size: this.item.attachments[i].size,
              creationDate: this.item.attachments[i].creationDate,
            }
          )


        }

        this.msg = ` - Conversione immagine aggiornameto perizia 1/2`

        this.db.get_perizia_result(this.item.idPerizia).then(res => {

          console.log(res)

          let presult: IResult;
          if (res.data) presult = res.data;
          else presult = {
            attachments: undefined,
            altriElementi: undefined,
            tiresData: undefined,
            fotoVetrina: undefined,
            damages: undefined,
            dataCreazione: new Date(),
            geo: undefined,
            vehType: undefined,
            documenti: undefined,
            privacy: undefined,
            usertType: this.usertType
          }

          presult.geo = this.item.geo

          presult.privacy = [
            { source: 'DIRECT', type: 'Policy', ok: this.item.okPrivacy, date: this.item.okPrivacyDate },
            { source: 'DIRECT', type: 'Geo', ok: this.item.okPrivacyGeo, date: this.item.okPrivacyGeoDate },
            { source: 'DIRECT', type: 'Camera', ok: this.item.okPrivacyCam, date: this.item.okPrivacyCamDate },
          ]



          this.msg = ` - Conversione immagine aggiornameto perizia 2/2`
          this.db.perizia_upsert_result(this.item.idPerizia, presult)


        }).catch(err => { console.error(err); return ''; });


        this.msg = ` - Salvataggio... `
        this.db.upsert_perizia(per).subscribe(event => {

          // progress
          if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
            const percentage = 100 / event.total * event.loaded;

            if (percentage < 100)
              this.msg = ` - Salvataggio... ${Math.round(percentage)}`
            else

              setInterval(() => {

                this.msg = ` - Ancora un attimo di pazienza... ${Math.round(this.n)}`

                this.n++

              }, 1000)


            //this.loading_percentage = percentage;


            console.log(percentage);
          }



          // finished
          if (event.type === HttpEventType.Response) {
            //this.loading = false;
            if (event.body.status != 'OK') {
              console.error(event)
              return;
            }

            this.afFirestore.doc('requests/' + this.id).set({ idPerizia: event.body.item.id, closed: true }, { merge: true });
            clearInterval(this.intervalHandler)
            this.router.navigate([`automotive/perizie/dettaglio/${event.body.item.id}`])

            this.loading = false

          }


        })

      })
      .catch(err => {
        alert(JSON.stringify(err))
      })




  }


  endCall() {
    this.stoppedTimer = true
    console.log('set')
    this.afFirestore.doc('requests/' + this.id).set({ closed: true }, { merge: true });
    let item: ILiveXpertTime = {
      id: 0,
      time: this.ts,
      inspector: this.userService.getEmail(),
      date: new Date(),
      idRequest: this.item.id

    }
    this.db.telexpert.set_time(item).then(res => {
      console.log(res)
      this.clearTimer()
    })
  }

  async sendToPerizia() {

    if (!this.usertType || this.usertType == '') {
      alert("Selezionare il tipo utente")
      return
    }


    let tmp: IPerizia = this.getEmptyPerizia();

    tmp.targa = '' + this.item.plate;
    tmp.sinistro_numero = this.item.claimNr;
    tmp.noteCarico = (this.db.getIcon() == 'gg' || this.db.getIcon() == 'gg-demo' ? 'DirectXpert' : 'LiveXpert');
    tmp.cliente = this.item.client;

    console.log('tmp', tmp)

    for (let i = 0; i < this.item.attachments.length; i++) {

      let c = '';
      await this.getImage(this.item.attachments[i].url).then(async (res: any) => {
        await this.createImageFromBlob(res).then(res => {
          console.log(c)
          c = res;
        }).catch(err => { console.error(err); return ''; });
      }).catch(err => { console.error(err); return ''; });


      tmp.allegatiIncarico.push(
        {
          fileName: this.item.attachments[i].fileName,
          fileContent: c,
          size: this.item.attachments[i].size,
          creationDate: this.item.attachments[i].creationDate,
        }
      )

    }

    this.db.upsert_perizia(tmp).subscribe(event => {

      // progress
      if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
        const percentage = 100 / event.total * event.loaded;

        //this.loading_percentage = percentage;


        console.log(percentage);
      }

      // finished
      if (event.type === HttpEventType.Response) {
        //this.loading = false;
        if (event.body.status != 'OK') {
          console.error(event)
          return;
        }



        console.log(event.body.item.id)
        this.afFirestore.doc('requests/' + this.id).set({ idPerizia: event.body.item.id }, { merge: true });
        this.router.navigate([`automotive/perizie/dettaglio/${event.body.item.id}`])

      }


    })

  }

  getImage(imageUrl: string): Promise<any> {
    console.log(imageUrl)
    return this.http.get(imageUrl, { headers: {}, responseType: 'blob' }).toPromise()
  }

  createImageFromBlob(image: Blob): Promise<string> {

    return new Promise(resolve => {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        resolve('' + reader.result);
      }, false);
      if (image) {
        reader.readAsDataURL(image);
      }

    })


  }

  getEmptyPerizia(type = '') {
    let tmp: IPerizia = {
      targa: '',
      telaio: '',
      stato: this.db.getStatiPeriziaAll()[0],
      marca: '',
      annoImmatricolazione: '',
      modello: '',
      versione: '',
      deposito: '',
      tipo: type == 'ssu' ? 'Stato d\'uso' : '',
      nPerizia: '',
      km: 0,
      provincia: '',
      citta: '',
      cap: '',
      indirizzo: '',
      noteCarico: '',

      timeRequired: 0,

      sinistro_numero: '',
      partita_danno: 0,
      sinistro_mandante: '',
      sinistro_data: null,
      sinistro_targaAssicurato: '',
      sinistro_nomeAssicurato: '',

      sinistro_provincia: '',
      sinistro_citta: '',
      sinistro_cap: '',
      sinistro_indirizzo: '',



      prodotto_polizza: '',
      numero_polizza: '',
      danneggiato_cf: '',

      danneggiato_nome: '',
      danneggiato_contatto: '',
      danneggiato_provincia: '',
      danneggiato_citta: '',
      danneggiato_cap: '',
      danneggiato_indirizzo: '',
      danneggiato_telefono: '',
      danneggiato_fax: '',
      danneggiato_email: '',
      danneggiato_pec: '',



      patrocinatore_nome: '',
      patrocinatore_contatto: '',
      patrocinatore_telefono: '',
      patrocinatore_provincia: '',
      patrocinatore_citta: '',
      patrocinatore_cap: '',
      patrocinatore_indirizzo: '',
      patrocinatore_fax: '',
      patrocinatore_email: '',
      patrocinatore_pec: '',

      allegatiIncarico: [],

      allegati: [],

      quotazioneIntero: 0,
      quotazioneVendita: 0,
      dataImmatricolazione: null,

      messaggio_cliente: '',
      messaggio_perito: '',

      creatoDa: '', assegnatoA1: '', assegnatoA2: '',

      dtaAppuntamento: null,
      cliente: null,

      result: null,

      adz_data: null,
      riparatore_nome: '',
      riparatore_email: '',
      riparatore_tel: ''


    };

    return tmp

  }

  sendPushNotify() {

    console.log('sendPushNotify')

    this.afFirestore.collection('devices', ref => ref.where('user', '==', this.item.user)).valueChanges()
      .subscribe((res: any) => {

        console.log(res)

        let devices = [];
        for (let i = 0; i < res.length; i++) {
          devices.push(res[i].deviceId)
        }

        let data = {
          title: (this.db.getIcon() == 'gg' || this.db.getIcon() == 'gg-demo' ? 'DirectXpert' : 'LiveXpert') + ' - ' + this.item.plate,
          body: 'Richiesta di teleperizia',
          deviceToken: devices,
          requestId: this.id
        }

        this.db.telexpert.send_cloud_message(data).then(res => {

          let snackBarRef = this.snackBar.open('Notifica inviata!', 'Ok!', {
            direction: "ltr",
            duration: 2000,
          });


          console.log(res)
        }).catch(err => {
          console.error(err)
        })

      })



  }


  capture(e) {


    const canvas = document.createElement("canvas");
    // scale the canvas accordingly
    canvas.width = this.video1.nativeElement.videoWidth;
    canvas.height = this.video1.nativeElement.videoHeight;
    // draw the video at that frame
    canvas.getContext('2d')
      .drawImage(this.video1.nativeElement, 0, 0, canvas.width, canvas.height);
    // convert it to a usable data URL
    const dataURL = canvas.toDataURL();




    console.log('capture - start', e)

    let tmp: IFile = {
      fileName: MD5(dataURL) + '.png',
      fileContent: dataURL,
      size: dataURL.length,
      creationDate: new Date(),
    }

    console.log('capture tmp', tmp)


    console.log('capture upsert id', this.id)
    this.db.telexpert.upsert_allegato(tmp, this.id, this.item.geo)
      .then(res => {
        console.log('capture telexpert_upsert_allegato', res)

        tmp.fileContent = '';
        if (!this.item.attachments) this.item.attachments = [];
        this.item.attachments.push(res.item)
        console.log(this.item)
        this.afFirestore.doc('requests/' + this.id).set(this.item, { merge: true }).then(res =>
          console.log('capture afFirestore.doc().set()', res))
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err))




  }

  copyUrl() {
    copyText(this.getUrl())
    alert("Indirizzo copiato!")
  }

  sendSms() {

    let dest = prompt("Inserisci il numero di telefono: ", "+39");

    if (dest != null) {
      let string: string = 'Clicca per avviare ' + (this.db.getIcon() == 'gg' || this.db.getIcon() == 'gg-demo' ? 'DirectXpert' : 'LiveXpert') + ': ' + this.getUrl()
      console.log(string)
      this.db.sms.send(dest, string, 'X-pert', 1).then(res => {
        console.log(res)

        try {

          let id = res.resp.split(' ')[1]
          this.getStatus(id)


        } catch (error) {

        }


      })

      /*
      this.db.tinyurl_get_tiny_url(this.getUrl()).then(res => {
        console.log(res)
      }).catch(err => console.error(err))
      */
    }

  }


  getStatus(id) {
    setTimeout(() => {
      console.log('get status for: ' + id)
      this.db.sms.get_status(id).then(res => {

        console.log(res)

        if ((res.resp as string).includes('Delivered')) {
          //alert("SMS ricevuto dal destinatario")
        } else
          this.getStatus(id)


      }).catch(err => console.error(err))
    }, 1000);
  }


  getUrl() {
    console.log('copyUrl')
    let url = window.location.href.split('/').slice(0, 3).join('/') + '/#/videochat-ext-chime/' + this.id
    return url
  }


}