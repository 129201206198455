<div style="width:100%; text-align: center;">
    <button *ngIf="item?.status && item?.status=='OK'"  type="button" mat-raised-button class="btn btn-success" (click)="open();">CLUSTER OK</button>
    <button *ngIf="item?.status && item?.status!='OK'"  type="button" mat-raised-button class="btn btn-danger" (click)="open();">CLUSTER ERROR</button>
    <button *ngIf="item?.status == undefined"  type="button" mat-raised-button class="btn btn-info" >
        CLUSTER
        <mat-spinner style="margin-left:10px; float:right" [diameter]="15" color="light"></mat-spinner>
    </button>
</div>



