"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "hash": "373d1f1",
  "raw": "v2.31.0",
  "semverString": "2.31.0"
};
