import { UserService } from './../../services/user.service';
import { MysqlService } from './../../services/mysql.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
//import { ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { Router, ROUTES } from '@angular/router';
import { MD5 } from 'app/shared';
import { SharedService } from 'app/services/shared.service';
import { AdminLayoutComponent } from 'app/layouts/admin-layout/admin-layout.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    standalone: false
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;

    notifies = 0;
    notifiesMenu = [{ link: null, title: 'Non hai nuovi messaggi' }];

    user: any;

    @ViewChild(AdminLayoutComponent) adminLayoutComponent!: AdminLayoutComponent;


    constructor(location: Location, private element: ElementRef, private router: Router, public db: MysqlService, private userService: UserService,
        private sharedService: SharedService
    ) {
        this.location = location;
        this.sidebarVisible = false;

        this.router.onSameUrlNavigation = 'reload'

    }

    ngOnInit() {
        this.listTitles = ROUTES[0]?.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.router.events.subscribe((event) => {
            this.sidebarClose();
        });

        this.sharedService.toggle$.subscribe((event) => { if (event) this.sidebarToggle() });


        this.userService.onUserState()
            .subscribe((user: any) => {
                this.user = user;

                //this.refreshNotifies();

                this.db.get_current_user_profile().then(res => {
                    this.user = res[0]
                });

            });



    }

    openUrl(url) {
        this.router.navigate([url], { queryParams: { ts: (new Date()).getTime() } });
    }

    logout() {
        this.userService.logout();
        this.router.navigate(['/login']);
    }

    changePwd() {

        this.db.user_change_pwd_request().then(res => {
            console.log(res);
            alert("Richiesta di modifica password inviata per email")
        }).catch(err => {
            console.error(err);
            alert(JSON.stringify(err))
        });


    }

    sidebarOpen() {
        //const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            //toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        //this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        //var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                //$layer.remove();
            }
            setTimeout(function () {
                //$toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function () {
                //$toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            //$layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                //document.getElementsByClassName('main-panel')[0].appendChild($layer);
            } else if (body.classList.contains('off-canvas-sidebar')) {
                //document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function () {
                //$layer.classList.add('visible');
            }, 100);

            $layer.onclick = function () { //asign a function
                body.classList.remove('nav-open');
                this.mobile_menu_visible = 0;
                //$layer.classList.remove('visible');
                setTimeout(function () {
                    //$layer.remove();
                    //$toggle.classList.remove('toggled');
                }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getHeightToggle() {
        if (this.db.isTest()) {
            return '-530px';
        } else {
            return '-280px';
        }
    }

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(2);
        }
        titlee = titlee.split('/').pop();

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }


    lastNotifyHash = ''
    refreshNotifies() {

        this.db.getListNotifies().then(res => {

            let hash = MD5(JSON.stringify(res))

            if (this.lastNotifyHash == hash) return;

            this.lastNotifyHash = hash

            //console.log('getListNotifies', res)

            let tmp = [];
            let n = 0;

            for (let i = 0; i < res.length; i++) {

                n += +res[i].n;

                switch (res[i].type) {

                    case 'chat':

                        switch (res[i].code.split('-')[0]) {
                            case 'perizie':
                                var type = 'perizia';
                                var id = res[i].code.split('-')[1];
                                var link = `/automotive/all/dettaglio/${id}/6`;
                                var t = `Hai ${res[i].n} messaggi/o da ${res[i].sender} per la ${type} ${id}`;
                                var msg = res[i].msg
                                //var tipo = 'chat';

                                break;

                            case 'irepair':
                                var type = 'irepair';
                                var id = res[i].code.split('-')[1];
                                var link = `/automotive/irepair/dettaglio/${id}/6`;
                                var t = `Hai ${res[i].n} messaggi/o da ${res[i].sender} per ${type} ${id}`;
                                var msg = res[i].msg
                                //var tipo = 'chat';

                                break;
                        }

                        break;

                    case 'eshop-sold':

                        var link = `/ecommerce/detail/${res[i].id}`;
                        var t = `Vendita conclusa: ${res[i].sender}`;
                        //var tipo = 'chat';

                        break;

                    case 'eshop-buoght':

                        var link = `/ecommerce/detail/${res[i].id}`;
                        var t = `Acquisto concluso: ${res[i].sender}`;
                        //var tipo = 'chat';

                        break;

                    case 'eshop-auction_losing':

                        var link = `/ecommerce/detail/${res[i].id}`;
                        var t = `Stai perdendo l'asta: ${res[i].sender}`;
                        //var tipo = 'chat';

                        break;
                    case 'new-perizia':
                        var type = 'perizia';
                        var id = res[i].code.split('-')[1];
                        var link = `/automotive/perizie/dettaglio/${id}/6`;
                        var t = `Hai ${res[i].n} messaggi/o da ${res[i].sender} per la ${type} ${id}`;
                }



                tmp.push({ title: t, link: link, msg: msg });
            }

            if (tmp.length == 0) {
                tmp = [{ title: 'Non hai nuovi messaggi' }];
            }

            this.notifies = n;

            this.notifiesMenu = tmp;

            setTimeout(() => { this.refreshNotifies() }, 5000);
        }).catch(err => {
            console.error(err);
            setTimeout(() => { this.refreshNotifies() }, 5000);
        })

    }
}
