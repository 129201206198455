"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckVideoInputFeedback = void 0;
var CheckVideoInputFeedback;
(function (CheckVideoInputFeedback) {
  CheckVideoInputFeedback[CheckVideoInputFeedback["Succeeded"] = 0] = "Succeeded";
  CheckVideoInputFeedback[CheckVideoInputFeedback["Failed"] = 1] = "Failed";
  CheckVideoInputFeedback[CheckVideoInputFeedback["PermissionDenied"] = 2] = "PermissionDenied";
})(CheckVideoInputFeedback = exports.CheckVideoInputFeedback || (exports.CheckVideoInputFeedback = {}));
exports.default = CheckVideoInputFeedback;
