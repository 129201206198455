<div class="card">

    <div class="card-header card-header-info">
        <h4 class="card-title">
            {{ data?.title }}
            <a style="float:right" (click)="close()"><i class="material-icons">close</i></a>
        </h4>
    </div>

    <div class="card-body">

        <p class="mb-0">Sei sicuro di voler applicare i permessi importati da {{ data?.value?.Nome }} {{ data?.value?.Cognome}}?</p>

        <button style="float:right" type="button" mat-raised-button class="btn btn-success" (click)="ok()">Conferma</button>

    </div>
</div>