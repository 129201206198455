import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-commesse-dialog',
    templateUrl: './commesse-dialog-manuali.component.html',
    styleUrls: ['./commesse-dialog-manuali.component.scss'],
    standalone: false
})
export class CommesseDialogDialogManualiComponent implements OnInit {
  form : UntypedFormGroup

  descr
  ricavo
  costo
  type='new'
  constructor(  public dialogRef: MatDialogRef<CommesseDialogDialogManualiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder : UntypedFormBuilder) {
      if(this.data){
        
        this.ricavo = data.ricavo
        this.costo = data.costo
        this.descr = data.descr
        this.type='edit'
      }
      this.form = this.formBuilder.group({

        descr: ['', Validators.required],
        ricavo: ['', Validators.required],
        costo: ['', Validators.required],
      
      });
  
     }

  ngOnInit(): void {
  }
   close(){
     this.dialogRef.close()
   }

   save(){
     let type = this.type
     let item = {  descr : this.descr,
      ricavo : this.ricavo,
      costo: this.costo
    }
  this.dialogRef.close({item : item, type : type})

   }
}
