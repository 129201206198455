"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogLevel = void 0;
var LogLevel;
(function (LogLevel) {
  LogLevel[LogLevel["DEBUG"] = 0] = "DEBUG";
  LogLevel[LogLevel["INFO"] = 1] = "INFO";
  LogLevel[LogLevel["WARN"] = 2] = "WARN";
  LogLevel[LogLevel["ERROR"] = 3] = "ERROR";
  LogLevel[LogLevel["OFF"] = 4] = "OFF";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
exports.default = LogLevel;
