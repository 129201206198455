<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute " [hidden]="!user">
    <div class="container-fluid">

        <div class="navbar-toggler" (click)="sidebarToggle()" [ngStyle]="{ 'margin-top': getHeightToggle() }">
            <mat-icon>menu</mat-icon>
        </div>

        <div class="collapse navbar-collapse justify-content-end" id="navigation">

            <ul class="navbar-nav">

                <!-- USER -->
                <li class="nav-item">
                    <button mat-button [matMenuTriggerFor]="menu" class="nav-button">
                        <i class="material-icons icon-black">person</i>
                        <p class="nav-text truncated-text">{{ user?.Nome }} {{ user?.Cognome }}</p>
                    </button>
                    <!-- <button mat-button class="nav-button">
                        <i class="material-icons icon-black">notifications</i>
                    </button> -->
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="changePwd()" class="custom-hover">
                            <mat-icon class="menu-icon">lock</mat-icon>
                            <span class="menu-text">{{ 'SIDEBAR.CHANGE_PASSWORD' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="logout()" class="custom-hover">
                            <mat-icon class="menu-icon">exit_to_app</mat-icon>
                            <span class="menu-text">{{ 'SIDEBAR.LOGOUT' | translate }}</span>
                        </button>
                    </mat-menu>
                </li>

                <!-- NOTIFIES -->
                <li class="nav-item dropdown">
                    <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                        (click)="openUrl('/extra/messages')" aria-expanded="false">
                        <i class="material-icons icon-black alert-button" style="margin-top: 1rem;">notifications</i>
                        <span class="notification" *ngIf="notifies>0">{{notifies}}</span>
                        <p>
                            <!-- <span class="d-lg-none d-md-block">Some Actions</span> -->
                        </p>
                    </a>
                    <!--
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink"
                        style="width:220px!important;">
                        <div *ngFor="let i of notifiesMenu">
                            <div class="dropdown-item" (click)="openUrl(i.link)" *ngIf="i.link"
                                style=" white-space: normal!important; padding:0; margin:5px;">
                                <br *ngIf="i.msg">
                                <div style="width:100%!important;">
                                    {{i.title}}
                                    <br *ngIf="i.msg">
                                    <div
                                        style="margin:5px 3px 3px 5px; width:100%!important; border: 1px solid #cccccccc; border-radius:15px; padding:3px;">{{i.msg}}
                                    </div>
                                </div>
                            </div>
                            <span class="dropdown-item" *ngIf="!i.link">{{i.title}}</span>
                            <div></div>
                        </div>

                    </div>
-->
                </li>
            </ul>
        </div>
    </div>
</nav>