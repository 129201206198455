"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * [[SignalingClientVideoSubscriptionConfiguration]] contains a mapping of MID to parameters for customize what remote video is received and how,
 * on an individual stream level. This can be used to update remote video sessions set up through `subscribe`
 */
class SignalingClientVideoSubscriptionConfiguration {}
exports.default = SignalingClientVideoSubscriptionConfiguration;
