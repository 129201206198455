"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
class CSPMonitor {
  /* istanbul ignore next */
  static register() {
    if (!('document' in global) || !document.addEventListener) {
      return;
    }
    if (CSPMonitor.shouldRegisterCSPMonitor) {
      if (!CSPMonitor.added) {
        document.addEventListener('securitypolicyviolation', CSPMonitor.listener);
        CSPMonitor.added = true;
      }
    }
  }
  /* istanbul ignore next */
  static unregister() {
    if (!('document' in global) || !document.removeEventListener) {
      return;
    }
    document.removeEventListener('securitypolicyviolation', CSPMonitor.listener);
    CSPMonitor.loggers = new Set();
    CSPMonitor.added = false;
  }
  static disable() {
    CSPMonitor.shouldRegisterCSPMonitor = false;
    CSPMonitor.unregister();
  }
  static addLogger(logger) {
    if (logger) {
      CSPMonitor.loggers.add(logger);
    }
  }
  static removeLogger(logger) {
    if (logger) {
      CSPMonitor.loggers.delete(logger);
    }
  }
}
exports.default = CSPMonitor;
CSPMonitor.loggers = new Set();
CSPMonitor.shouldRegisterCSPMonitor = true;
CSPMonitor.added = false;
/* istanbul ignore next */
CSPMonitor.listener = event => {
  const message = 'Security Policy Violation\n' + `Blocked URI: ${event.blockedURI}\n` + `Violated Directive: ${event.violatedDirective}\n` + `Original Policy: ${event.originalPolicy}\n` + `Document URI: ${event.documentURI}\n` + `Source File: ${event.sourceFile}\n` + `Line No.: ${event.lineNumber}\n`;
  for (const logger of CSPMonitor.loggers) {
    logger.error(message);
  }
  if (CSPMonitor.loggers.size === 0) {
    console.error(message);
  }
};
