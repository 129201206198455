import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { getFiliale } from 'app/menu/automotive/perizie/dialog/dialog.component';
import { IFile } from 'app/models/incarico.interface';
import { IContattoPerizia, IPerizia } from 'app/models/perizia.interface';
import { MysqlService } from 'app/services/mysql.service';
import * as pdfjsLib from 'pdfjs-dist';


@Component({
  selector: 'app-upload-vdr-dialog',
  templateUrl: './upload-vdr-dialog.component.html',
  styleUrls: ['./upload-vdr-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})

export class UploadVdrDialogComponent implements OnInit {

  loading = false

  img: string
  reader

  id: number
  targa: string
  error: string

  targaManuale = ''

  showManualSearch = false

  p: IPerizia

  constructor(
    private db: MysqlService,
    private router: Router,
    //public dialogRef: MatDialogRef<UploadVdrDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data,

  ) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.min.mjs';
  }

  ngOnInit() {


  }


  setManual() {
    this.reset()
    this.showManualSearch = true
  }

  reset() {
    this.id = undefined
    this.targa = undefined
    this.error = undefined
    this.showManualSearch = false
    this.p = undefined

  }


  close() {
    this.router.navigate(['/unipolrental/dashboard/appuntamenti'])
    //this.dialogRef.close();
  }

  file: File
  uploadVDR(event) {
    this.reset()
    this.loading = true
    console.log(event)
    this.file = event.target.files[0];
    this.getBase64(this.file);
  }


  @ViewChild('fileVDR') fileVDR: ElementRef;

  getBase64(file: File) {
    console.log('file: ', file);

    this.loading = true

    this.reader = new FileReader();
    this.reader.readAsDataURL(file);
    this.reader.onload = async res => {


      let base64 = this.reader.result

      if (file.type == 'application/pdf') {


        base64 = await this.convertPdfToImage('' + base64)


        console.log(base64)

      }


      this.img = '' + base64

      let res1: any = await this.db.qrcode.scan(('' + base64).split(',')[1])
      console.log('res1: ', res1);


      this.loading = false

      console.log(res1)

      if (res1.data == '') {
        this.error = 'Impossibile leggere il QRCode'
        this.showManualSearch = true
        this.loading = false
      } else

        try {

          this.id = res1.data.split('-')[0]
          this.targa = res1.data.split('-')[1]

          this.caricaPerizia()

        } catch (error) {
          this.error = JSON.stringify(error)
          this.loading = false

        }


      try {
        this.fileVDR.nativeElement.value = "";
      } catch (error) { }




    };
    this.reader.onerror = function (error) {
      console.log('Error: ', error);
      this.loading = false
    };
  }


  convertPdfToImage(base64Pdf: string): Promise<string> {

    base64Pdf = ('' + base64Pdf).split(',')[1]

    console.log('Converting PDF to JPG')
    console.log(base64Pdf)

    return new Promise((resolve, reject) => {
      // Decodifica il PDF base64 in un buffer
      const pdfData = base64ToArrayBuffer(base64Pdf);

      // Carica il PDF con pdf.js
      pdfjsLib.getDocument({ data: pdfData }).promise.then((pdf) => {
        // Prendi la prima pagina del PDF
        pdf.getPage(1).then((page) => {
          const scale = 2; // Imposta la scala per l'immagine
          const viewport = page.getViewport({ scale: scale });

          // Crea un elemento canvas per disegnare la pagina del PDF
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Rendi la pagina del PDF sul canvas
          const renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          page.render(renderContext).promise.then(() => {
            // Ottieni l'immagine come base64 (formato JPG)
            const imageBase64 = canvas.toDataURL('image/jpeg');
            resolve(imageBase64); // Risolvi la promise con il base64 dell'immagine
          }).catch((err) => {
            reject(err); // In caso di errore nel renderizzare la pagina
          });
        }).catch((err) => {
          reject(err); // In caso di errore nel caricare la pagina
        });
      }).catch((err) => {
        reject(err); // In caso di errore nel caricare il PDF
      });
    });

    function base64ToArrayBuffer(base64: string): ArrayBuffer {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }
  }

  async caricaPerizia() {

    this.loading = true

    this.p = (await this.db.get_perizia(this.id, 'unipolrental')).item

    this.loading = false

    if (!this.p) {
      this.error = "Targa non trovata"
      return
    }

    if (this.p?.targa != this.targa) {
      this.error = "Targa errata"
      return
    }


    if (this.p.allegati.find(e => e.type == 'Verbale di riconsegna')) {
      this.error = "Verbale già inserito"
      return
    }

    console.log(this.p)

  }

  loadingManualSearch = false
  async cercaPeriziaManuale() {


    this.loading = true

    this.error = undefined

    this.loadingManualSearch = true

    let list = await this.db.list_perizie('unipolrental', undefined, undefined, undefined, undefined, { targa: this.targaManuale })

    console.log(list)

    list = list.filter(e => e.stato != 'ANNULLATO')

    this.loadingManualSearch = false

    if (list.length == 0) {
      this.error = "Pratica non trovata"
      this.loading = false
      return
    }

    if (list.length > 1) {
      this.error = "Pratica non univoca"
      this.loading = false
      return
    }

    this.id = list[0].id
    this.targa = list[0].targa

    this.showManualSearch = false

    this.caricaPerizia()

    console.log(list)



  }

  saving = false
  saveFile() {
    this.saving = true
    let f: IFile = {
      fileName: this.file.name,
      size: this.file.size,
      fileContent: '' + this.reader.result,
      creationDate: new Date(),
      mime: this.file.type,
      type: 'Verbale di riconsegna',
      sent: new Date()
    }

    console.log(f)
    this.attachmentSave(f)






  }




  loading_percentage
  loading_loaded
  loading_total


  attachmentSave(vdr: IFile) {

    this.p.allegati.push(vdr)

    this.db.upsert_allegati_perizia(this.p, true).subscribe(async (event: any) => {

      console.log('upsert_allegati_perizia')
      console.log(event)

      // progress
      if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
        const percentage = 100 / event.total * event.loaded;

        this.loading_percentage = percentage;
        this.loading_loaded = event.loaded;
        this.loading_total = event.total;

        console.log(percentage);
      }

      // finished
      if (event.type === HttpEventType.Response) {

        if (event.body.status != 'OK') {
          console.error(event.body)
          //console.log(JSON.parse(this.attachment_backup));
          //this.item.allegati = JSON.parse(this.attachment_backup);
          this.loading = false;

          alert(JSON.stringify(event.body.error));

          this.saving = false
          return;
        } else {

          //await this.save(false)

          this.sendVDR(vdr)



        }
      }

    })


  }




  async sendVDR(vdr: IFile) {

    const contatti_source = (await this.db.perizie_contatti_list(this.p.id))
    console.log(contatti_source)

    let recipients = []

    let drivers: IContattoPerizia[] = contatti_source.filter(e => e.ruolo == 'DRIVER')
    let fleetmanagers: IContattoPerizia[] = contatti_source.filter(e => e.ruolo == 'FLEET MNGR')
    let centriMAD: IContattoPerizia[] = contatti_source.filter(e => e.ruolo == 'CENTRO-MAD') // || e.ruolo == 'FILIALE' modifica per gestione alternativa email filiale

    for (const driver of drivers) {
      if (driver && driver.email && driver.email != '') recipients.push(driver.email)
    }

    for (const fleetmanager of fleetmanagers) {
      if (fleetmanager && fleetmanager.email && fleetmanager.email != '') recipients.push(fleetmanager.email)
    }


    for (const centroMAD of centriMAD) {
      if (centroMAD && centroMAD.email && centroMAD.email != '') recipients.push(centroMAD.email)
    }

    let centro
    await this.db.unipolrental_get_centri(this.p.codiceCentro).then(res => {
      console.log(res)
      centro = res.item
    }).catch(err => {
      console.error(err)
    })

    if (centro?.INDIRIZZO_EMAIL) recipients.push(centro.INDIRIZZO_EMAIL)



    let fil = getFiliale(this.p.provincia)
    if (!fil) {
      alert("Attenzione, verificare la provincia e riprovare")
      this.saving = false
      location.reload()
      return
    }

    let msg = {
      recipientEmail: recipients.join('; '),
      senderName: 'RICONSEGNE UNIPOLRENTAL',
      subject: 'Verbale di riconsegna per ' + this.p.targa + ' - ' + fil.provFiliale + ':',
      text: 'In allegato il verbale di riconsegna del veicolo',
      html: '',
      attachments: [vdr],
      pec: 2,
      bcc: 'secondolivello@unipolrental.it', cc: ['operations.unipolrental@general-group.it;chiusuracontratto@unipolrental.it'].join(';'),
      tab: 'perizie',
      idTable: this.p.id
    }
    console.log('msg: ', msg);


    await this.db.send_mail(msg).then(res => {
      this.saving = false
      if (res.status != 'OK') {
        alert(JSON.stringify(res))
      }

      alert("Operazione completata")

      this.reset()

    }).catch(err => console.error(err))





  }

}

