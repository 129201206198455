import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-weather',
    templateUrl: './weather.component.html',
    styleUrls: ['./weather.component.scss'],
    standalone: false
})
export class WeatherComponent implements OnInit {

  @Input() data;

  constructor() {
    
   }

  ngOnInit() {
  }

}
