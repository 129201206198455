
import { MysqlService } from './../../../../services/mysql.service';
import { IAnagrafica, ILuogo } from './../../../../models/anagrafica.interface';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'app/services/user.service';
//import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    standalone: false
})
export class VerycarLeedsDialogComponent implements OnInit {

  formData
  formQuestions
  gridApi = []
  gridColumnApi
  getRowStyle
  tableReady = false
  rowClass = 'row-color'
  rowData = []
  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,

    cellStyle: { padding: '0', 'text-align': 'center' }

  };
  columnDefs: any[] = [

    {
      field: 'recipient', headerName: 'Utente'
    },

    { field: 'value', headerName: 'Risposta' },
    { field: 'date', headerName: 'Data' },
  ];

  constructor(
    public dialogRef: MatDialogRef<VerycarLeedsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public depotDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private db: MysqlService
    , private userService: UserService) {
    // const options = { weekday: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric' };
    this.db.forms.get_by_code(data).then(res => {
      console.log(res)
      this.formData = res
      this.formQuestions = this.formData.data.rows.filter(e => e.cols[0].isInput)


      this.db.verycar.get_leed_data(res.id).then(resx => {



        console.log('verycar_get_leed_data', resx)

        for (const iterator of this.formQuestions) {
          this.rowData[iterator.cols[0].id] = []

          for (const element of resx.data.filter(e => e.state == 'completed')) {

            this.rowData[iterator.cols[0].id].push({
              recipient: element.recipient,
              date: new Date(element.timestamp * 1000).toLocaleDateString('it-IT'),
              value: (element.value ? element.value[iterator.cols[0].id] : '')
            })

          }

        }
        console.log(this.rowData)
        this.getRowStyle = params => {
          if (params.node.rowIndex % 2 === 0) {
            return { background: '#F0F0FF' };
          }
        };
        this.tableReady = true
      }).catch(error => console.log(error))
    })
  }

  ngOnInit() {

  }

  setArrayOfNum(num) {
    let arr = []
    for (let index = 0; index <= num; index++) {
      arr.push(index)
    }
    return arr
  }

  leedExport(ind) {
    let workbook = {} as any // new Workbook();
    let worksheet
    console.log(this.rowData)
    for (const iterator of this.formQuestions) {
      worksheet = workbook.addWorksheet(iterator.cols[0].id.replace('?', '').replace(':', ''));
      worksheet.addRow(['date', 'recipient', 'value']);
      for (const question of this.rowData[iterator.cols[0].id]) {
        worksheet.addRow([question.date, question.recipient, question.value]);
      }
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Stats.xlsx');
    });
  }

  getYesNo(data, yn) {
    if (data) {
      if (yn)
        return data.filter(e => e.value).length
      else
        return data.filter(e => !e.value).length
    }

  }

  spliceSelect(string) {
    let array = []
    array = string.split("|")
    return array
  }
  getCombo(result, string) {
    if (result)
      return result.filter(e => e.value == string).length
  }
  getTotalSlider(result, num) {
    if (result)
      return result.filter(e => e.value == num).length
  }



  close() {
    this.dialogRef.close();
  }

  onGridReady(params) {
    console.log('ready')
    this.gridApi.push(params.api);
    this.gridColumnApi = params.columnApi;

  }



}





