import { AuthorizationService } from './services/authorization.service';
import { OsmService } from './services/osm.service';
import { MysqlService } from './services/mysql.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, HostListener } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HttpClient, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoginRouteGuard, AdminRouteGuard, LoginFirRouteGuard, NotLoginRouteGuard } from './route-guard';
import { UserService } from './services/user.service';
import { DecimalPipe, registerLocaleData, DatePipe } from '@angular/common'

import { BrowserModule } from '@angular/platform-browser';

import localeIt from '@angular/common/locales/it';

import { ReportComponent } from './report/report.component';

import { PublicDetailsComponent } from './public-details//public-details.component'

import { OpentokService } from './services/opentok.service';
import { VideochatExtComponent } from './videochat-ext/videochat-ext.component';
import { ServerityPipe } from './pipes/serverity.pipe';
import { environment } from 'environments/environment';
import { OpenWeatherService } from './services/open-weather.service';

import { PipesModule } from './pipes/pipes.module';
import { XlsButtonComponent } from './components/xls-button/xls-button.component';

import { PropostaComponent } from './proposta/proposta.component';

import { ChimesComponent, ChimeSettingsDialog } from './menu/extra/chimes/chimes.component';
import { QuestionDialogComponent } from './menu/extra/chimes/dialog/dialog.component';
//import { ProgramComponent } from './menu/demo/program/program.component';
//import { VerycarPaymentComponent } from './verycar-payment/verycar-payment.component';
//import { NgxStripeModule } from 'ngx-stripe';
import { ChimeUsersComponent } from './menu/extra/chime-users/chime-users.component';


import { TelexpertChimeDialogComponent } from './menu/automotive/telexpert/dialog-chime/dialog.component';
import { VideochatExtChimeComponent } from './videochat-ext-chime/videochat-ext-chime.component';

import { CommesseDialogDialogComponent } from './menu/options/commesse/dialog/commesse-dialog/commesse-dialog.component';
import { CommesseDialogDialogManualiComponent } from './menu/options/commesse/dialog/commesse-dialog-manuali/commesse-dialog-manuali.component';
import { DialogCheckComponent } from './menu/automotive/perizie/dialog/dialog-check/dialog-check.component';
import { VerycarLeedsComponent } from './menu/options/verycar-leeds/verycar-leeds.component';
import { VerycarLeedsDialogComponent } from './menu/options/verycar-leeds/dialog/dialog.component';
import { VerycarDefsComponent } from './menu/automotive/verycar-defs/verycar-defs.component';
import { VerycarUsersComponent } from './menu/automotive/verycar-users/verycar-users.component';
import { VerycarSegnalazioniComponent } from './menu/automotive/verycar-segnalazioni/verycar-segnalazioni.component';
import { MyNumberPipe } from './pipes/my-number.pipe';


registerLocaleData(localeIt, 'it');

import { PerizieOrderPhotoComponent } from './menu/automotive/perizie/dialog/order-photo/order-photo.component';
//import { CdkDrag } from '@angular/cdk/drag-drop';
//
import config from 'devextreme/core/config';
config({ licenseKey: 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjkzMTM1MTg0LTc5NWEtNGQzOC1iMWY3LWEzZDBiYjc2YmQ3YSIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.ay/KVWHaxCb0RQrY6s5XRHwXgvlGXnbz/dB2nSIsn0Q9zoBW8TQnI5GHV16Z8R6oi6rN0tO+eJCU7WdweDkaQcxdG+b8xUGxtX9XqnAiPlwkNW5dzZea0TmM1KhDHbynRJgpwA==' });


import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({

    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ComponentsModule,
        //EshopComponentsModule,
        RouterModule,
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        // AngularFireModule.initializeApp(environment.config),
        // AngularFirestoreModule,

        // provideFirebaseApp(() => initializeApp(environment.config)),
        // provideFirestore(() => getFirestore()),

        //NgxStripeModule.forRoot('pk_test_O712DLrtAIxFGDhORm2ysJgx00u4Q5s5mH'),
        PipesModule
    ],

    declarations: [
        AppComponent,
        AdminLayoutComponent,
        //EShopLayoutComponent,
        ReportComponent,
        PublicDetailsComponent,
        VideochatExtComponent,
        VideochatExtChimeComponent,
        ChimesComponent,
        ChimeSettingsDialog,
        PropostaComponent,
        ServerityPipe,
        QuestionDialogComponent,
        //VerycarPaymentComponent,
        ChimeUsersComponent,
        TelexpertChimeDialogComponent,
        TelexpertChimeDialogComponent,
        CommesseDialogDialogManualiComponent,
        CommesseDialogDialogComponent,
        DialogCheckComponent,
        PerizieOrderPhotoComponent,
        VerycarLeedsComponent,
        VerycarLeedsDialogComponent,
        VerycarDefsComponent,
        VerycarUsersComponent,
        VerycarSegnalazioniComponent,
    ],
    exports: [
        ServerityPipe,
        XlsButtonComponent
    ],
    bootstrap: [AppComponent],
    providers: [

        provideFirebaseApp(() => initializeApp(environment.config)),
        provideFirestore(() => getFirestore()),

        MysqlService,
        DatePipe,
        LoginRouteGuard,
        AdminRouteGuard,
        LoginFirRouteGuard,
        NotLoginRouteGuard,
        UserService,
        DecimalPipe,
        OsmService,
        AuthorizationService,
        { provide: LOCALE_ID, useValue: 'it' },
        QuestionDialogComponent,
        OpentokService,
        ServerityPipe,
        OpenWeatherService,
        MyNumberPipe,
        PipesModule,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})



export class AppModule {



}


