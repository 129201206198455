import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-sliedeshow',
    templateUrl: './sliedeshow.component.html',
    styleUrls: ['./sliedeshow.component.scss'],
    standalone: false
})
export class SliedeshowComponent implements OnInit {

  fadeIn = false;
  fadeOut = false;

  i_img = 0;

  @Input() images = [];

  timer = null;

  constructor() { }

  ngOnInit() {

    this.start()
  }

  next() {

    this.fadeOut = true;

    setTimeout(() => {

      this.fadeOut = false;
      this.fadeIn = true;

      //console.log('next: ' + (this.i_img + 1))

      if (this.i_img + 1 >= this.images.length) {

        //console.log('return to 0')

        this.i_img = 0;
        return;
      }

      this.i_img++

    }, 100);


  }

  start() {
    this.timer = setTimeout(() => {
      this.next();
      this.start();
    }, 2000);
  }

}
