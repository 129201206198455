import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { MysqlService } from 'app/services/mysql.service';
import { ActivatedRoute } from '@angular/router';
import {
	ConsoleLogger,
	DefaultDeviceController,
	DefaultMeetingSession,
	LogLevel,
	MeetingSessionConfiguration,
	AudioVideoObserver,
	VideoTile,
	DefaultVideoTile,
	VideoTileState,
	DefaultActiveSpeakerPolicy,
	PermissionDeniedError,
} from 'amazon-chime-sdk-js';
import { Platform } from '@angular/cdk/platform';
import { ChimeSettingsDialog } from 'app/menu/extra/chimes/chimes.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { doc, Firestore, onSnapshot, setDoc } from '@angular/fire/firestore';
@Component({
	selector: 'app-videochat-ext-chime',
	templateUrl: './videochat-ext-chime.component.html',
	styleUrls: ['./videochat-ext-chime.component.scss'],
	standalone: false
})


export class VideochatExtChimeComponent implements OnInit, OnDestroy {

	token: string;
	cmd: string;


	@HostListener('window:unload', ['$event'])
	async handleClose($event) {
		await this.setOnlineStatus(false).then(res => console.log(res)).catch(err => console.log(err));
		//$event.returnValue = true;

		return true;
	}
	currentCamera = 0
	id: string;
	item;
	agent
	hideThumb: boolean;

	meeting
	meetingId
	attendee
	attendeeId
	audioInputDevs
	audioOutputDevs
	videoDevs
	videoDev: any

	logger: ConsoleLogger
	deviceController: DefaultDeviceController
	configuration: MeetingSessionConfiguration
	meetingResponse
	attendeeResponse
	meetingSession: DefaultMeetingSession

	videos = []

	msg = '';
	showMeetingVideo = false


	@ViewChild('meetingAudio') public meetingAudio: ElementRef<HTMLAudioElement>;
	@ViewChild('meetingVideo') public meetingVideo: ElementRef<HTMLVideoElement>;

	constructor(private route: ActivatedRoute,
		private firestore: Firestore,
		private db: MysqlService,
		private platform: Platform,

		private dialog: MatDialog,
	) {
		console.log('constructor')

		this.agent = window.navigator.userAgent

		for (let i = 1; i <= 100; i++)
			this.videos.push({ id: i, visible: false, isMuted: true })

		//this.hideThumb = db.getIcon() == 'gg'

	}

	ngOnInit() {

		//alert('init')

		this.route.params.subscribe(res => {
			console.log('id:' + res.id)
			this.id = res.id;

			//alert('id: ' + this.id)

			this.loadData()
		})


	}



	alreadyStarted = false
	loadData() {

		console.log('loaddata', this.alreadyStarted)

		const unsub = onSnapshot(doc(this.firestore, "requests", this.id), async (docRef) => {

			let res = docRef.data()

			this.item = res;

			//alert('record found')
			console.log('record found', res)

			if (this.item.closed && this.item.okPrivacy) {
				alert('Teleperizia conclusa, la ringraziamo per la collaborazione!')
				return;
			}
			if (this.item.closed && this.item.okPrivacy === false) {
				alert('Teleperizia non possibile!')
				return;
			}

			//alert('not closed')
			console.log('not closed')

			if (this.item.cmd && this.item.cmd != '') {
				//alert('cmd ' + this.item.cmd)
				console.log('cmd ' + this.item.cmd)
				console.log('cmd received - ext: ', this.item.cmd);
				this.cmd = this.item.cmd;
				setTimeout(() => { this.cmd = ''; this.update({ cmd: '' }) }, 100)
			}


			//alert('sessionId ' + this.item.sessionId)
			if (this.item.meetingId && !this.alreadyStarted && this.item.okPrivacy) {

				this.alreadyStarted = true

				this.meetingId = this.item.meetingId

				console.log('get attendee id from api')

				this.meeting = await this.db.aws_chime.get_meeting(this.meetingId)
				this.attendee = await this.db.aws_chime.create_attendee(this.meetingId)

				// You need responses from server-side Chime API. See below for details.
				this.meetingResponse = this.meeting;
				this.attendeeResponse = this.attendee;


				setTimeout(async () => {

					await this.initMeeting()
					this.meetingSession.audioVideo.start() /// importantissimo, senza questo non funziona un cazzo

					setTimeout(() => {

						this.setOptions()

						this.switchCamera()

					}, 3000)

					this.setEventListeners()
					this.setOnlineStatus(true, this.meetingId);

				}, 1500);

				if (this.platform.SAFARI)
					alert("Vidochiamata pronta, premere ok per continuare")

			}

			// set agent //
			if (!this.item.agent) this.update({ agent: this.agent })

			if (this.item.okPrivacy) this.getGeo()

		})

	}



	update(data) {
		const docRef = doc(this.firestore, 'requests', this.id);
		return setDoc(docRef, data, { merge: true });
	}



	async initMeeting() {

		this.logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);

		this.deviceController = new DefaultDeviceController(this.logger, { enableWebAudio: true });


		this.configuration = new MeetingSessionConfiguration(this.meeting, this.attendee);
		console.log('config', this.configuration, this.logger, this.deviceController)
		// In the usage examples below, you will use this meetingSession object.
		this.meetingSession = new DefaultMeetingSession(
			this.configuration,
			this.logger,
			this.deviceController
		);



		//this.meetingSession.audioVideo.enableWebAudio(true)


		///set audio IN
		//this.sendMessage(undefined, 'chat', '/set audio IN')
		let xx = await navigator.mediaDevices.enumerateDevices()
		//this.sendMessage(undefined, 'chat', JSON.stringify(xx))
		xx = await navigator.mediaDevices.enumerateDevices()
		//this.sendMessage(undefined, 'chat', JSON.stringify(xx))

		try {
			const audioInputDevice = await this.meetingSession.audioVideo.listAudioInputDevices()

			this.audioInputDevs = audioInputDevice

			//this.sendMessage(undefined, 'chat', JSON.stringify(audioInputDevice))

			//   alert('audioInputDevice ' + JSON.stringify(audioInputDevice[0].deviceId))
			let audioInputDeviceId = audioInputDevice[0] ? audioInputDevice[0].deviceId : null
			if (!audioInputDeviceId) {
				let tmp
				await navigator.mediaDevices.getUserMedia({ audio: true }).then(res => tmp = res).catch(err => alert(JSON.stringify(err)))
				audioInputDeviceId = tmp.id
				console.warn('Im getting the output device id as walkaround' + audioInputDeviceId)

				alert('Im getting the output device id as walkaround' + audioInputDeviceId)

			}
			if (!audioInputDeviceId) alert('audioInputDevice non disponibile')


			alert('audio in: ' + JSON.stringify(audioInputDeviceId))


			console.log('audioInputDevice', audioInputDevice)
			if (!audioInputDevice || audioInputDevice.length <= 0 || audioInputDevice[0].deviceId == '') alert('audioInputDevice non disponibile')
			//this.sendMessage(undefined, 'chat', JSON.stringify(audioInputDevice[0] ? audioInputDevice[0] : null))

			let dev: any = localStorage.getItem('awsc_audioIn')
			if (dev) dev = JSON.parse(dev)


			alert('dev: ' + JSON.stringify(dev))

			//alert(JSON.stringify(dev ? dev : audioInputDevice[0] ? audioInputDevice[0] : null))

			//let x = await this.meetingSession.audioVideo.chooseAudioInputDevice(dev ? dev : audioInputDevice[0].deviceId ? audioInputDevice[0].deviceId : null)

			try {
				let x = await this.meetingSession.audioVideo.chooseAudioInputDevice(dev ? dev.deviceId : audioInputDevice[0].deviceId ? audioInputDevice[0].deviceId : null);
				alert("x: " + x)
			} catch (e) {
				if (e instanceof PermissionDeniedError) {
					console.error('Permission denied', e);
					alert('Permission denied ' + JSON.stringify(e));
				}
			}

			alert('dev: ' + JSON.stringify(dev))

			//this.sendMessage(undefined, 'chat', JSON.stringify(x))
			//  alert(x)
			//   .then(res => alert('then ' + JSON.stringify(res)))
			//  .catch(res => alert('catch ' + JSON.stringify(res)))


		} catch (error) {
			alert('set audio IN ' + JSON.stringify(error))
		}


		/// set audio OUT

		try {
			let audioOutputDevice
			await this.meetingSession.audioVideo.listAudioOutputDevices()
				.then(res => audioOutputDevice = res)
				.catch(err => {
					alert(JSON.stringify(err))
					console.error(err)
				})
			console.log('audioOutputDevice', audioOutputDevice)


			this.audioOutputDevs = audioOutputDevice

			// alert('audioOutputDevice ' + JSON.stringify(audioOutputDevice))

			let audioOutDeviceId = audioOutputDevice[0] ? audioOutputDevice[0].deviceId : null

			if (!audioOutDeviceId) {
				let tmp
				await navigator.mediaDevices.getUserMedia({ audio: true }).then(res => tmp = res).catch(err => alert(JSON.stringify(err)))
				audioOutDeviceId = tmp.id
				console.warn('Im getting the output device id as walkaround' + audioOutDeviceId)

				//alert('Im getting the output device id as walkaround' + audioOutDeviceId)

			}
			if (!audioOutDeviceId) alert('audioOutputDevice non disponibile')


			if (localStorage != undefined)
				var dev: any = localStorage.getItem('awsc_audioOut')
			if (dev) dev = JSON.parse(dev)

			//alert(JSON.stringify(dev ? dev : audioOutDeviceId))

			await this.meetingSession.audioVideo.chooseAudioOutputDevice(dev ? dev.deviceId : audioOutDeviceId).then(res => { }).catch(err => alert('catch ' + JSON.stringify(err)))

			/*
						setInterval(() => {
							let anal: AnalyserNode = this.meetingSession.audioVideo.createAnalyserNodeForAudioInput()
							console.log('maxdb:' + anal.maxDecibels)
						}, 1000)
			*/
		} catch (error) {
			alert('set video ' + JSON.stringify(error))
		}




		await this.setVideo()

		//this.meetingSession.audioVideo.createAnalyserNodeForAudioInput()


		//alert('audio el' + JSON.stringify(this.meetingAudio))


		let b
		await this.meetingSession.audioVideo.bindAudioElement(this.meetingAudio.nativeElement).then(res => {
			b = res
		})

	}

	novideo = false
	async setVideo(index = 0) {
		/// set video

		try {
			const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices()

			//alert(JSON.stringify(videoInputDevices))

			this.videoDevs = videoInputDevices

			console.log('videoInputDevices', videoInputDevices)
			if (!videoInputDevices || videoInputDevices.length <= 0) alert('videoInputDevices non disponibile')
			await this.meetingSession.audioVideo.chooseVideoInputQuality(720, 480, 18, 800);


			this.videoDev = videoInputDevices[index]

			await this.meetingSession.audioVideo.chooseVideoInputDevice(this.videoDev)

			if (!this.videoDev) this.novideo = true

		} catch (error) {

			this.novideo = true


			//alert('set video ' + JSON.stringify(error))
		}
	}


	setEventListeners() {

		const observer = {


			videoTileDidUpdate: tileState => {

				console.log('videoTileDidUpdate', tileState)

				//console.log(this.meetingSession.audioVideo.getAllVideoTiles())

				//console.log('videoTileDidUpdate', tileState)

				// Ignore a tile without attendee ID and other attendee's tile.

				if (!tileState.boundAttendeeId) {
					return;
				}


				//console.log('test', tileState)

				let n = this.videos.findIndex(e => e.attendeeId == tileState.boundAttendeeId)
				//console.log('test', n)

				if (n > 16) {

					this.videos[n] = { id: n + 1, visible: false, isMuted: true }

				}



				this.bindVideo(tileState)

				//console.warn(this.videos)

			},
			videoTileWasRemoved: tileId => {
				//console.clear()
				console.log('videoTileWasRemoved')
				console.log('videoTileWasRemoved ', tileId)

				let e = this.videos.find(e => e.tileState != undefined && e.tileState.tileId == tileId)


				console.log('videoTileWasRemoved ', e)


				this.unbindVideo(tileId)
			},




			connectionDidBecomePoor: () => console.warn('connectionDidBecomePoor'),
			connectionDidSuggestStopVideo: () => console.warn('connectionDidSuggestStopVideo'),
			// connectionHealthDidChange: (res) => console.warn('connectionHealthDidChange', res),
			estimatedDownlinkBandwidthLessThanRequired: (n1, n2) => {
				console.warn('estimatedDownlinkBandwidthLessThanRequired', n1, n2)
				this.setTriangle(this.getAttendeeId())
			},
			//metricsDidReceive: clientMetricReport => console.warn('clientMetricReport', clientMetricReport),
			videoNotReceivingEnoughData: res => {
				console.warn('videoNotReceivingEnoughData', res)
				this.setTriangle(res[0].attendeeId)
			},
			//videoReceiveBandwidthDidChange: (bold, bnew) => { this.bw_down = bnew },
			//videoSendBandwidthDidChange: (bold, bnew) => { this.bw_up = bnew },
			//videoSendHealthDidChange: (n1, n2) => console.warn('videoSendHealthDidChange', n1, n2),


			contentShareDidStart: () => {
				console.log('Screen share started');
			},
			contentShareDidStop: () => {
				// Chime SDK allows 2 simultaneous content shares per meeting.
				// This method will be invoked if two attendees are already sharing content
				// when you call startContentShareFromScreenCapture or startContentShare.
				console.log('Screen share stopped');
			}


		};

		this.meetingSession.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(muted => {
			console.log('realtimeSubscribeToMuteAndUnmuteLocalAudio', muted)

			this.sendMessage({ attendeeId: 'chatroom' }, 'isMuted', muted)

		})


		/// chatroom
		this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('chatroom', dataMessage => {
			this.manageMessages(dataMessage)
		})


		/// privatechat
		this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(this.getAttendeeId(), dataMessage => {
			this.manageMessages(dataMessage)
		})

		/// chatroom
		this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('reservations', dataMessage => {
			console.log(dataMessage)

			let b = dataMessage.text() === 'true'

			console.log('reserved', dataMessage)

			try {
				let v = this.videos.find(e => e.attendeeId == dataMessage.senderAttendeeId)
				v.reserved = b
				if (b) v.reservedAt = dataMessage.timestampMs
				else v.reservedAt = undefined




			} catch (error) { }


			dataMessage.senderAttendeeId


		})


		//alert('subscribeToActiveSpeakerDetector')
		this.meetingSession.audioVideo.subscribeToActiveSpeakerDetector(
			new DefaultActiveSpeakerPolicy(),
			(activeSpeakers) => {
				//console.log(activeSpeakers)

				//alert('subscribeToActiveSpeakerDetector -> callback')


			}
		);

		this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present) => {

			console.log('realtimeSubscribeToAttendeeIdPresence', presentAttendeeId)

			if (present) {


				this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume) => {
					try {

						let el = this.videos.find(e => e.attendeeId == attendeeId)

						//console.log('presence el ', el)

						if (!el) {

							let n = 0
							for (let i = 17; i < this.videos.length; i++) {
								if (!this.videos[i].visible) {
									n = i
									break
								}
							}


							//console.error('HERE', el, n)

							//let nvideo = this.meetingSession.audioVideo.getAllVideoTiles().findIndex((e: any) => e.tileState.boundAttendeeId == attendeeId)

							//console.log(this.meetingSession.audioVideo.getAllVideoTiles())

							// console.log('presence n ', n)


							this.videos[n] = { id: n + 1, visible: false, isMuted: true }
							this.videos[n].visible = true
							this.videos[n].attendeeId = attendeeId
							this.videos[n].tileState = { tileId: n }
							this.videos[n].volume = volume
						}




					} catch (error) {

						console.error(error)

					}
				})
			} else {

				try {


					console.log('disconnected: ' + presentAttendeeId)
					this.meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(presentAttendeeId)

					let n = this.videos.findIndex(e => e.attendeeId == presentAttendeeId)

					if (n == -1) {
						console.log(this.videos, n, presentAttendeeId)
						return
					}


					console.log('disconnected: ' + n)

					this.videos[n] = { id: n + 1, visible: false, isMuted: true }



				} catch (error) {

					console.error(error)



				}


			}


		});


		this.meetingSession.audioVideo.addContentShareObserver(observer);
		this.meetingSession.audioVideo.addObserver(observer)
		if (!this.novideo) this.meetingSession.audioVideo.startLocalVideoTile()

	}


	setMuted(b) {

		alert('setMuted ' + b)

		console.log('setMuted', b)
		!b ?
			this.meetingSession.audioVideo.realtimeMuteLocalAudio()
			:
			this.meetingSession.audioVideo.realtimeUnmuteLocalAudio()

	}


	async manageMessages(dataMessage) {
		//console.log('manageMessages')

		let msg = JSON.parse(dataMessage.text())
		//console.log(msg)



		//console.log("I'm receiving a message - ", msg)

		switch (msg.type) {

			case 'switchCamera':
				this.switchCamera()
				break;

		}
	}

	switchCamera() {

		/*
		this.videoDevs.forEach(element => {
			alert(element.label)
		});
		this.audioInputDevs.forEach(element => {
			alert(element.label)
		});
		this.audioOutputDevs.forEach(element => {
			alert(element.label)
		});
		*/
		if (this.currentCamera == 0) {
			this.currentCamera = 1
			this.setVideo(1)
		} else {
			this.currentCamera = 0
			this.setVideo(0)
		}

	}

	openSettings() {
		console.log('openSettings')

		//console.clear()


		let dialogRef = this.dialog.open(ChimeSettingsDialog, {
			height: '400px',
			width: '600px',
			data: {
				audioInputDevs: this.audioInputDevs,
				audioOutputDevs: this.audioOutputDevs,
				videoDevs: this.videoDevs,
			}
		});
		dialogRef.afterClosed().subscribe(async result => {
			console.log(`Dialog result: ${result}`);
			if (result) {


				this.setOptions()


				/*
								this.meetingSession.audioVideo.stop()
			  
								setTimeout(() => {
									this.meetingSession.audioVideo.start()
								}, 100);
				*/
			}
		});
	}


	async setOptions() {
		var dev1 = localStorage.getItem('awsc_audioIn')
		if (dev1) {
			dev1 = JSON.parse(dev1)
			await this.meetingSession.audioVideo.chooseAudioInputDevice(dev1)
		}

		var dev2 = localStorage.getItem('awsc_audioOut')
		if (dev2) {
			dev2 = JSON.parse(dev2)
			await this.meetingSession.audioVideo.chooseAudioOutputDevice(dev2)
		}

		var dev3 = localStorage.getItem('awsc_videoDev')
		if (dev3) {
			dev3 = JSON.parse(dev3)
			await this.meetingSession.audioVideo.chooseVideoInputDevice(dev3)
		}
	}

	sendMessage(x, type: string, msg = undefined) {
		//console.log('sendMessage')

		if (!this.meetingSession) return

		let useMsg = false;

		if (type == 'chat' && !msg) {
			msg = this.msg
			if (!msg || msg == '') return
			useMsg = true;
		}


		//console.log("I'm sending a message - ", { type: type, msg: msg })

		this.meetingSession.audioVideo.realtimeSendDataMessage(x ? x.attendeeId : 'chatroom', { type: type, msg: msg })

		if (useMsg) this.msg = ''
	}



	getAttendeeId() {
		//console.log('getAttendeeId')
		try {
			return this.attendeeResponse.Attendee.AttendeeId
		} catch (error) {

		}
	}


	setTriangle(attendeeId) {
		console.log(this.videos)
		this.videos.filter(e => e.attendeeId == attendeeId)[0].warning = true
		setTimeout(() => {
			this.videos.filter(e => e.attendeeId == attendeeId)[0].warning = false
		}, 10000);
	}

	bindVideo(tileState: VideoTileState, mainVideo = false) {
		//console.log('bindVideo')


		let el;
		if (!mainVideo) {

			let nvideo = this.videos.findIndex(e => e && e.tileState && tileState && e.tileState.tileId == tileState.tileId)

			if (nvideo < 0) {
				nvideo = this.videos.findIndex(e => e.visible == false)
			}

			nvideo++

			this.videos[nvideo - 1].visible = true
			this.videos[nvideo - 1].attendeeId = tileState.boundAttendeeId
			this.videos[nvideo - 1].tileState = tileState

			el = document.getElementById('video-' + nvideo)
			if (!el) return
			this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, el as HTMLVideoElement)
		}
		else {

			//alert('binding main video')

			try {

				this.showMeetingVideo = true;
				el = document.getElementById('content-share-video')
				if (!el) return
				this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, el as HTMLVideoElement)

			} catch (error) {
				console.error(error)
				//alert(JSON.stringify(error))

			}


		}

	}



	unbindVideo(tileId) {
		console.log('unbindVideo')
		let nvideo
		try {
			console.log('unbinding video -> tileId:' + tileId)

			nvideo = this.videos.findIndex(e => e.tileState != undefined && e.tileState.tileId == tileId)

			console.log(this.videos, nvideo)

			this.meetingSession.audioVideo.unbindVideoElement(tileId)

			this.videos[nvideo] = { id: nvideo + 1, visible: false, isMuted: true }

		} catch (error) {
			console.error(error, nvideo)
		}

	}



	async setOnlineStatus(online: boolean, sessionId = null) {
		console.log('setting online:' + online)

		if (sessionId == null)
			await this.update({ online: online, })
		else
			await this.update({ online: online, sessionId: sessionId })
		console.log('online:' + online)
	}

	ngOnDestroy() {
		console.log('ngOnDestroy')
		alert('ngOnDestroy')

		this.setOnlineStatus(false);
		alert('destroy')
		console.log('destroy')

		this.meetingSession.audioVideo.stop()

	}


	async setOkPrivacy(b) {

		await this.update({ okPrivacy: b, okPrivacyDate: new Date(), closed: !b })

		if (b) this.getGeo()

	}

	getGeo() {
		if (!this.item.geo && this.item.okPrivacyGeo == undefined) {

			if (window.navigator.geolocation) {
				window.navigator.geolocation.getCurrentPosition(res => {




					//  alert('geo ok ')

					console.log(res.coords)

					let geo = {
						accuracy: res.coords.accuracy,
						latitude: res.coords.latitude,
						longitude: res.coords.longitude,
					}

					this.update({ geo: geo, okPrivacyGeo: true, okPrivacyGeoDate: new Date() })


				},
					err => {
						console.error(err)
						this.update({ geoErr: err.message, okPrivacyGeo: false, okPrivacyGeoDate: new Date() })
					}
				);
			};


		}

	}

	okPrivacyCam(b) {

		console.log('okPrivacyCam ' + b)
		this.update({ okPrivacyCam: b, okPrivacyCamDate: new Date(), closed: !b })

	}

	setStream(e) {

		console.log('setStream ', e)
		this.update({ stream: e })

	}
}
