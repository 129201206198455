<button *ngIf="asButton" type="button" class="btn btn-danger" style="margin-right:10px" (click)="show()">{{backoffice ?
  'CHAT' :
  'Contatta un operatore'}}</button>

<div class="p-4 " [ngClass]="{'dialogx': group}" [hidden]='hidden && asButton'
  [ngClass]="{'chatroom': group, 'single':!group}">

  <button *ngIf="asButton" type="button" class="btn btn-danger" (click)="close()"
    style="margin-right:20px; position:absolute; top:10px; right:0">X</button>

  <div #scrollMe class="scroll">
    <div *ngFor="let msg of msgs" [ngClass]="{'left': me!=msg.sender , 'right': me==msg.sender }">
      <div class="mess">{{msg.message}}<br>
        <span class="date" *ngIf="group">{{normalizeEmail(msg.sender)}} - </span>
        <span class="date">{{msg.date}}</span>
      </div>
    </div>
  </div>

  <hr>

  <div class="row" *ngIf="!readonly">
    <div class="col">
      <div class="form-group">

        <input style="width: calc(100% - 50px)" type="text" class="form-control" placeholder="Message" [(ngModel)]="msg"
          (keydown.enter)="send()">

        <button type="button" mat-mini-fab (click)="send()"
          style="background-color:rgb(121, 211, 247)!important; position:absolute; right:0; top:0">
          <i class="material-icons" style="vertical-align:middle!important;color: white;">
            send
          </i></button>
      </div>
    </div>

  </div>

  <div class="row" *ngIf="!readonly">
    <div class="col-12 col-md-4" *ngFor="let e of shorts">
      <button style="width:100%" (click)="sendShort(e)" class="btn btn-medium" type="button" mat-raised-button>{{e}}
      </button>
    </div>

  </div>


  <div class="row" *ngIf="!readonly && AI && msgs?.length >0">
    <div class="col-12 col-md-4">
      <br>
      <button (click)="generateAI()" style="width:100%" class="btn btn-primary" type="button" mat-raised-button>
        <span *ngIf="!isGeneratingIa">Genera con IA</span>
        <mat-spinner *ngIf="isGeneratingIa" [diameter]="25" color="light"></mat-spinner>
      </button>
    </div>
  </div>
</div>