import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GridActiveComponent, GridEditComponent, GridIconComponent, GridStatusComponent } from 'app/components/grid-edit/grid-edit.component';
import { MysqlService } from 'app/services/mysql.service';
//import { Workbook } from 'exceljs';
import { VerycarLeedsDialogComponent } from './dialog/dialog.component';

import * as fs from 'file-saver';

@Component({
    selector: 'app-verycar-leeds',
    templateUrl: './verycar-leeds.component.html',
    styleUrls: ['./verycar-leeds.component.scss'],
    standalone: false
})
export class VerycarLeedsComponent implements OnInit {

  constructor(private db: MysqlService, private router: Router, public dialog: MatDialog) { }
  chartLoading = false
  utenti
  tab = 0;
  showDialog = false
  getRowStyle
  getRowStyle2
  gridApi;
  gridColumnApi;
  selectedMails
  selectedLeed
  tableReady = false
  rowClass = 'row-color'
  rowClass2 = 'row-color'
  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    headerCheckboxSelection: this.isFirstColumn,
    checkboxSelection: this.isFirstColumn,
    cellStyle: { padding: '0', 'text-align': 'center' }

  };
  defaultColDef2 = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,

    cellStyle: { padding: '0', 'text-align': 'center' }

  };

  columnDefs: any[] = [

    {
      field: 'selected', headerName: '', checkboxSelection: true, width: '10px', resizable: false,filter: false
    },

    { field: 'firstname', headerName: 'Nome' },
    { field: 'lastname', headerName: 'Cognome' },
    { field: 'email', headerName: 'Mail' },
    { field: 'type', headerName: 'Tipo' },


  ];


  columnDefs2: any[] = [


    {
      field: 'id', headerName: '',
      sortable: false,
      width: '60px', resizable: false,
      cellRenderer: GridEditComponent,
      cellRendererParams: {
        clicked: (field: any) => {
          this.onEditDialog({ data: { id: field } })
        }
      },
    },
    {
      field: 'id', headerName: '',
      sortable: false,
      width: '60px', resizable: false,
      cellRenderer: GridIconComponent,
      cellRendererParams: {
        value: 'delete',
        clicked: (field: any) => {
          this.onDeleteDialog({ data: { id: field } })
        }
      },
    },


    { field: 'id', headerName: 'ID' },
    { field: 'code', headerName: 'CODICE' },
    { field: 'description', headerName: 'DESCRIZIONE' },
    { field: 'displayName', headerName: 'Creatore' },
    { field: 'nquestions', headerName: 'N° Domande' },
    { field: 'inviati', headerName: 'N° Invii' },
    { field: 'completati', headerName: 'N° Completati' },
    {
      field: 'code', headerName: 'Risultati',
      sortable: false,
      width: '60px', resizable: false,
      cellRenderer: GridStatusComponent,
      cellRendererParams: {
        clicked: (field: any) => {
          this.openStatsDialog({ data: { code: field } })
        }
      },
    },
    {
      field: 'id', headerName: '',
      sortable: false,
      width: '60px', resizable: false,
      cellRenderer: GridIconComponent,
      cellRendererParams: {
        value: 'file_download',
        clicked: (field: any) => {
          this.downloadData({ data: { id: field } })
        }
      },
    },



  ];
  rowData2
  rowData
  ngOnInit(): void {
    this.loadUtenti()
    this.loadSondaggi()
  }

  loadUtenti() {
    this.db.verycar.list_utenti_fb().then(res => {
      console.log(res)
      this.utenti = res.list
      this.rowData = res.list
      this.getRowStyle = params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: '#F0F0FF' };
        }
      };
    })
  }



  async loadSondaggi() {
    this.db.verycar.get_leeds().then(async res => {
      //console.clear()
      console.log('get_leeds', res)

      this.rowData2 = res
      for (const iterator of this.rowData2) {
        iterator.nquestions = iterator.data.rows.filter(e => e.cols[0].isInput).length
        // console.log(iterator.id)
        await this.db.verycar.get_leed_data('' + iterator.id).then(res => {
          console.log('verycar_get_leed_data ' + iterator.id, res)
          if (res) {
            iterator.inviati = res.data.filter(e => e).length
            iterator.completati = res.data.filter(e => e.state == 'completed').length
          }
        }).catch(error => { console.log(error) })
      }
      this.getRowStyle2 = params => {
        if (params.node.rowIndex % 2 === 0) {
          return { background: '#F0F0FF' };
        }
      };
      this.tableReady = true
      console.log(this.rowData2)


    })
  }


  showDialogSend() {
    let selectedNodes = this.gridApi.getSelectedNodes();
    this.selectedMails = selectedNodes.map(node => node.data);

    this.showDialog = true;

  }
  onBtExport() {
    this.gridApi.exportDataAsCsv({ columnSeparator: ';' });
  }
  openDialog(data): void {
    console.log(data)
    const dialogRef = this.dialog.open(VerycarLeedsDialogComponent, {
      width: '90%',
      data: data
    });

  }
  onDeleteDialog(data) {
    console.log(data)
    if (confirm("Sei sicuro di voler cancellare il sondaggio n° " + data.data.id.data.id)) {
      this.db.forms.delete(data.data.id.data.id).then(res => {
        console.log(res)
        this.loadSondaggi()
      })
    }
  }

  downloadData(id) {

    
    console.log(id)
    let code = id.data.id.data.code
    this.db.forms.get_by_code(code).then(res => {
      console.log(res)
      let formData = res
      let formQuestions = formData.data.rows.filter(e => e.cols[0].isInput)
      this.db.verycar.get_leed_data(res.id).then(res => {
        console.log(res)
        for (const iterator of formQuestions) {
          this.rowData[iterator.cols[0].id] = []
          for (const element of res.filter(e => e.state == 'completed')) {

            this.rowData[iterator.cols[0].id].push({
              recipient: element.recipient,
              date: new Date(element.timestamp * 1000).toLocaleDateString('it-IT'),
              value: (element.value ? element.value[iterator.cols[0].id] : '')
            })

          }

        }
        let workbook = {} as any //new Workbook();
        let worksheet
        console.log(this.rowData)
        for (const iterator of formQuestions) {
          worksheet = workbook.addWorksheet(iterator.cols[0].id.replace('?', '').replace(':', ''));
          worksheet.addRow(['date', 'recipient', 'value']);
          for (const question of this.rowData[iterator.cols[0].id]) {
            worksheet.addRow([question.date, question.recipient, question.value]);
          }
        }

        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, formData.code.trim() + '.xlsx');
        });
        console.log(this.rowData)
      }).catch(error => console.log(error))
    })
  }

  openStatsDialog(evt) {
    console.log(evt)
    this.openDialog(evt.data.code)
  }

  sendLeed() {
    var recipients = []
    for (const element of this.selectedMails) {
      recipients.push(element.email)
    }
    setTimeout(() => {
      console.log(this.selectedLeed)
      if (this.selectedLeed) {
        this.db.verycar.send_leed(recipients, this.selectedLeed).then(res => {
          console.log(res)
          alert("Sondaggio Inviato")
          this.showDialog = false
        }).catch(error => {
          console.log(error)
          alert("Sondaggio Inviato")
          this.showDialog = false
        })


      }

      else {
        alert('Seleziona un sondaggio')
      }

    }, 1000);


  }

  isFirstColumn(params) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  close() {
    this.showDialog = false;
  }
  tabChange(e) {
    this.tab = e.index;

    //console.log(e);
    //this.loadData(e.index);

  }
  onEditDialog(data) {
    console.log(data)
    this.router.navigate(['options/form-composer/' + data.data.id])
  }
  onCreateDialog() {
    this.router.navigate(['options/form-composer/0'])
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll(false);
  }
  autoSizeAll(skipHeader = false) {
    var allColumnIds = [];
    this.gridApi?.getColumns().filter(e => e.colDef.resizable).forEach((column) => {
      allColumnIds.push(column.colId);
      //    console.log(column)
    });

    this.gridApi?.autoSizeColumns(allColumnIds, skipHeader);
  }





  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //console.log(event)
    this.autoSizeAll(false);
  }

}
