import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDeleteComponent, GridDuplicateComponent, GridEditComponent, GridUpDownComponent } from 'app/components/grid-edit/grid-edit.component';
import { MysqlService } from 'app/services/mysql.service';
import { VerycarQuestionEditDialogComponent } from '../verycar-defs/question-edit-dialog/question-edit-dialog.component';

@Component({
    selector: 'app-verycar-users',
    templateUrl: './verycar-users.component.html',
    styleUrls: ['./verycar-users.component.scss'],
    standalone: false
})
export class VerycarUsersComponent implements OnInit {
  item

  gridApi_defs;
  gridColumnApi_defs;
  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
  };

  rowData: any[]





  gridApi_def;
  gridColumnApi_def;
  defaultColDef_def = {
    resizable: true,
    sortable: true,
    filter: true,
    editable: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
  };


  columnDefs: any[] = [


    //{ field: 'Order', headerName: 'order', },
    //{ field: 'completed', headerName: '', width: '50px', resizable: false },

    { field: 'email', headerName: 'Email', },
    { field: 'firstname', headerName: 'Nome', },


    { field: 'lastname', headerName: 'Cognome', },
    { field: 'nickname', headerName: 'Nick', },
    { field: 'nVeh', headerName: 'Numero Veicoli', },
    { field: 'abbonamento', headerName: 'Tipo Abbonamento', },
    { field: 'type', headerName: 'Tipo', },

    { field: 'trattamento', headerName: 'Trattamento Dati', },
    { field: 'trattamentoDate', headerName: 'Data Trattamento', },
    { field: 'privacy', headerName: 'Privacy', },
    { field: 'privacyDate', headerName: 'Data Privacy', },
    { field: 'offerte', headerName: 'Offerte', },
    { field: 'offerteDate', headerName: 'Data offerte', },
    { field: 'profilazione', headerName: 'Profilazione', },
    { field: 'profilazioneDate', headerName: 'Data profilazione', },
    { field: 'comunicazioni', headerName: 'Comunicazioni', },
    { field: 'comunicazioniDate', headerName: 'Data Comunicazioni', },
    { field: 'business', headerName: 'Condizioni Business', },
    { field: 'businessDate', headerName: 'Data Business', },



    /*
  
      note_inspect
      hint
      Cristalli
      Kasko
      Visiva
      Guasti12mesi
      Guasti24mesi
  
    */


  ];

  rowData_def: any[]


  users: any[];

  type: string;

  constructor(

    public dialog: MatDialog,

    private router: Router,
    private route: ActivatedRoute,
    private db: MysqlService,
  ) {


  }


  tab = 0
  onTabChanged(event) {
    //console.clear()
    console.log(event)
    this.tab = event.index

    switch (this.tab) {
      case 1:
        break;

      default:
        break;
    }
  }

  addDef() {
    this.item = {
      id: '',
      questions: [],
      ver: 0,
    } as any
  }

  loadUsers() {
    this.db.verycar.users_list().then((res: any) => {
      console.log(res)
      res.list.forEach(element => {
        console.log(element)
        if (element.nVeh > 100) {
          element.nVeh = 'Illimitato'
        }



        if (element.trattamento) {
          element.trattamento = 'Si'
        } else
          element.trattamento = 'No'
        //  element.trattamento = moment()


        if (element.privacy) {
          element.privacy = 'Si'
        } else
          element.privacy = 'No'


        /////SISTEMO DATE/////
        console.log(element.email)
        if (element.offerte) {
          element.offerte = 'Si'
        } else
          element.offerte = 'No'
        element.offerteDate = element.offerteDate[element.offerteDate.length - 1]

        element.profilazione = element.profilazione[element.profilazione.length - 1]
        if (element.profilazione) {
          element.profilazione = 'Si'
        } else
          element.profilazione = 'No'
        element.profilazioneDate = element.profilazioneDate[element.profilazioneDate.length - 1]

        element.comunicazioni = element.comunicazioni[element.comunicazioni.length - 1]
        if (element.comunicazioni) {
          element.comunicazioni = 'Si'
        } else
          element.comunicazioni = 'No'
        element.comunicazioniDate = element.comunicazioniDate[element.comunicazioniDate.length - 1]
        if (element.business) {
          element.business = element.business[element.business.length - 1]
          if (element.business) {
            element.business = 'Si'
          } else
            element.business = 'No'
          element.businessDate = element.businessDate[element.businessDate.length - 1]
        }



      });
      this.rowData = res.list
    }).catch(err => {
      console.error(err)
    })
  }

  saveDef() {
    /**
    let rowData = []
    this.gridApi_def.forEachNode(node => rowData.push(node.data));

    this.item.questions = rowData

    if (this.item.id == '') this.item.id = MD5(`${new Date()}_${this.item.type}_${this.item.vehType}_${this.item.ver}`)

    this.db.verycar.defs_upsert(this.item)
      .then((res: any) => {
        if (res.status != 'OK') {
          alert(JSON.stringify(res))
          return
        }

        this.cancelDef()
        this.loadDefs()

      })
      .catch(err => alert(JSON.stringify(err)))
 */
  }

  cancelDef() {
    this.item = undefined
  }

  getUserNameByEmail(email) {
    let r;
    try {
      r = this.users.find(v => { return v.email == email });
      return r.firstname + ' ' + r.lastname;
    } catch (error) {

      return email;

    }

  }




  addQuestion() {


    let qid = Math.max(...this.item.questions.map(e => e.questionId)) + 1

    console.log(this.item.questions.map(e => e.questionId), qid)

    let max = 1
    try {
      max = Math.max(...this.item.questions.map(e => e.Order)) + 1
    } catch (error) { }


    qid = qid == -Infinity ? 1 : qid
    max = max == -Infinity ? 1 : max

    let q = {
      sectionCode: '',
      questionId: qid,
      questionName: '' + qid,

      Order: max,

    }

    //console.clear()
    console.log(q)
    this.item.questions.push(q)



    let i = this.item.questions.findIndex(e => e.questionId == qid)

    const dialogRef = this.dialog.open(VerycarQuestionEditDialogComponent, {
      data: { q: this.item.questions[i], questions: this.item.questions }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result)

      if (!result) return

      this.item.questions[i] = result

      let tmp = JSON.parse(JSON.stringify(this.item.questions))
      this.item.questions = []
      this.item.questions = tmp
    });



  }

  ngOnInit() {

    this.loadUsers()

  }


  ngOnDestroy() {
    console.log('destroy')



  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //console.log(event)
    this.autoSizeAll_defs();
    this.autoSizeAll_def();
  }

  onGridReady_defs(params) {
    this.gridApi_defs = params.api;
    this.gridColumnApi_defs = params.columnApi;
    this.autoSizeAll_defs();
  }

  sizeColumnsToFit_defs(gridApi) {
    this.gridApi_defs.sizeColumnsToFit()
  }


  autoSizeAll_defs(skipHeader = false) {
    try {
      var allColumnIds = [];
      this.gridColumnApi_defs.getAllColumns().filter(e => e.colDef.resizable).forEach((column) => {
        allColumnIds.push(column.colId);
        console.log(column)
      });

      this.gridColumnApi_defs.autoSizeColumns(allColumnIds, skipHeader);
    } catch (error) {

    }
  }



  onGridReady_def(params) {
    this.gridApi_def = params.api;
    this.gridColumnApi_def = params.columnApi;
    this.autoSizeAll_defs();
  }

  sizeColumnsToFit_def(gridApi) {
    this.gridApi_def.sizeColumnsToFit()
  }


  autoSizeAll_def(skipHeader = false) {

    try {
      var allColumnIds = [];
      this.gridColumnApi_def.getAllColumns().filter(e => e.colDef.resizable).forEach((column) => {
        allColumnIds.push(column.colId);
        console.log(column)
      });

      this.gridColumnApi_def.autoSizeColumns(allColumnIds, skipHeader);

    } catch (error) {

    }
  }

}
