<div>
    <div class="flag-cnt">
        <span>
            <img [ngClass]="e.id == currentLang ? 'selected' : '' " class="flag" [src]="e.flag"
                *ngFor="let e of languages" (click)="changeLanguage(e.id)">
        </span>
    </div>
    <!--
    <mat-form-field>
        <mat-label>Lingua</mat-label>
        <mat-select [(ngModel)]="currentLang" (ngModelChange)="changeLanguage($event)">
            <mat-option *ngFor="let e of languages" [value]="e.id">
                {{e.descr}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    -->
</div>