
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: false
})
export class ImageComponent implements OnInit {

  @Input() src;
  @Input() file_type;
  @Input() index;

  @ViewChild('glass') public glass: ElementRef;

  lastTarget
  lastEvent

  zoomLevel = 2;

  constructor() { }

  ngOnInit() {
    this.zoomImage();
  }

  onClick() {
    this.zoomLevel *= 2
    if (this.zoomLevel > 10) this.zoomLevel = 2

    this.showGlass(this.lastTarget, this.lastEvent);

  }

  zoomImage() {
    const images = document.getElementsByClassName("magniflier");

    // Nasconde la lente
    const hideGlass = () => {
      this.glass.nativeElement.style.display = "none";
    };

    document.addEventListener("mouseover", (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && target.classList.contains("magniflier")) {
        const image = target as HTMLImageElement;
        this.glass.nativeElement.style.display = "block";
        this.showGlass(image, event);

        target.addEventListener("mousemove", (e: MouseEvent) => this.showGlass(image, e));
        target.addEventListener("mouseout", hideGlass);
      }
    });
  }

  showGlass(image: HTMLImageElement, event: MouseEvent) {
    if (!this.glass.nativeElement) {
      console.error("Lente non trovata nel DOM");
      return;
    }

    this.lastEvent = event;
    this.lastTarget = image;

    const rect = image.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const lensSize = this.glass.nativeElement.offsetWidth / 2;

    // Calcola le coordinate della lente
    let lensX = x - lensSize;
    let lensY = y - lensSize;

    lensX = Math.max(-lensSize, Math.min(lensX, rect.width - lensSize));
    lensY = Math.max(-lensSize, Math.min(lensY, rect.height - lensSize));

    // Posiziona la lente
    this.glass.nativeElement.style.left = `${lensX + rect.left}px`;
    this.glass.nativeElement.style.top = `${lensY + rect.top}px`;

    const bgPosX = Math.max(0, Math.min(x * this.zoomLevel - lensSize * this.zoomLevel, (image.width * this.zoomLevel) - this.glass.nativeElement.offsetWidth));
    const bgPosY = Math.max(0, Math.min(y * this.zoomLevel - lensSize * this.zoomLevel, (image.height * this.zoomLevel) - this.glass.nativeElement.offsetHeight));

    this.glass.nativeElement.style.backgroundImage = `url(${image.src})`;
    this.glass.nativeElement.style.backgroundSize = `${image.width * this.zoomLevel}px ${image.height * this.zoomLevel}px`;
    this.glass.nativeElement.style.backgroundPosition = `-${bgPosX}px -${bgPosY}px`;
  }




}
