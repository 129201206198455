
<div class="container-fluid">
  <!-- Drag and Drop Zone -->
  <div class="row" *ngIf="!disabled">
    <div class="col">
      <ngx-file-drop class="w-100 h-100 rounded p-3" dropZoneLabel="Trascina un file qui"
        (onFileDrop)="dropFiles($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
      </ngx-file-drop>
    </div>
  </div>

  <!-- Upload Buttons -->
  <div class="row mb-3" *ngIf="!disabled">
    <div class="col">
      <div class="d-flex flex-wrap align-items-center justify-content-start gap-3">

        <ng-container *ngFor="let t of customDocTypes">
          <button class="btn btn-info" (click)="startUpload(t); file.click()" type="button">
            CARICA {{ t }}
          </button>
        </ng-container>

        <button class="btn btn-info" (click)="startUpload(''); file.click()" type="button">
          CARICA ALLEGATO...
        </button>

        <button *ngIf="!uploader && categorized" class="btn" type="button"
          [ngClass]="{'btn-success': filter.length === 1, 'btn-outline-secondary': filter.length > 1}"
          (click)="setFilter(['img'])">
          Immagini
        </button>
        <button *ngIf="!uploader && categorized" class="btn" type="button"
          [ngClass]="{'btn-success': filter.length > 1, 'btn-outline-secondary': filter.length === 1}"
          (click)="setFilter(['oth', 'pdf'])">
          Documenti
        </button>

        <button class="btn btn-success" [disabled]="downloading" (click)="downloadAll()" type="button" [ngClass]="{ 'm-btn': componentType == 'fermi-tecnici'}">
          Scarica tutto
        </button>
      </div>
    </div>
  </div>


  <!-- Gallery or Table View -->
  <div class="card">
    <div *ngIf="viewGallery && !isShowAddAdz" class="gallery">
      <!-- Gallery View -->
      <div class="row g-3">
        <div class="col-6 col-sm-4 col-md-3 col-lg-2" *ngFor="let a of filtered_source; index as i"
          [ngClass]="{ 'concordato': isConcordato(a) }">
          <div class="position-relative">
            <button type="button" color="warn" *ngIf="!disabled" class="btn-sm position-absolute top-0 end-0"
              (click)="delete(a)" style="background-color: white;" mat-icon-button>
              <i class="material-icons" style="font-size: 16px;">delete_forever</i>
            </button>
            <a (click)="openFile(i)">
              <img [src]="isImage(a)" class="img-fluid rounded shadow-sm">
            </a>
          </div>
          <div *ngIf="showLabel || showTag" class="mt-2 text-truncate">
            {{ a.fileName }} - {{ humanize(a.size) }}
            <span *ngIf="showTag">({{ a.tag }})</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!viewGallery && !isShowAddAdz" class="gallery">
      <!-- Table View -->
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Anteprima</th>
            <th>Nome file</th>
            <th>Data</th>
            <th>Dimensione</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let a of filtered_source; index as i" (click)="openFile(i)">
            <td><img [src]="isImage(a)" alt="Preview" class="img-thumbnail" style="width: 32px;"></td>
            <td>{{ a.fileName }}</td>
            <td>{{ a.creationDate | date }}</td>
            <td>{{ (a.size / 1024 / 1024) | number: '0.2-2' }} MB</td>
            <td>
              <button *ngIf="!disabled" type="button" (click)="delete(a)" color="warn" style="background-color: white;"
                mat-icon-button>
                <i class="material-icons" style="font-size: 16px">delete_forever</i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<input type="file" #file (change)="uploadFile($event)" class="d-none" [accept]="accepted" [multiple]="isMultiple"
  [(ngModel)]="ffile">