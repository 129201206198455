"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckNetworkTCPConnectivityFeedback = void 0;
var CheckNetworkTCPConnectivityFeedback;
(function (CheckNetworkTCPConnectivityFeedback) {
  CheckNetworkTCPConnectivityFeedback[CheckNetworkTCPConnectivityFeedback["Succeeded"] = 0] = "Succeeded";
  CheckNetworkTCPConnectivityFeedback[CheckNetworkTCPConnectivityFeedback["MeetingSessionURLsNotInitialized"] = 1] = "MeetingSessionURLsNotInitialized";
  CheckNetworkTCPConnectivityFeedback[CheckNetworkTCPConnectivityFeedback["ConnectionFailed"] = 2] = "ConnectionFailed";
  CheckNetworkTCPConnectivityFeedback[CheckNetworkTCPConnectivityFeedback["ICENegotiationFailed"] = 3] = "ICENegotiationFailed";
})(CheckNetworkTCPConnectivityFeedback = exports.CheckNetworkTCPConnectivityFeedback || (exports.CheckNetworkTCPConnectivityFeedback = {}));
exports.default = CheckNetworkTCPConnectivityFeedback;
