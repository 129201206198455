<div class="strength">

  <ul class="strengthBar mt-2">
    <li class="point" [style.background-color]="bar0"></li>
    <li class="point" [style.background-color]="bar1"></li>
    <li class="point" [style.background-color]="bar2"></li>
    <li class="point" [style.background-color]="bar3"></li>
    <li class="point" [style.background-color]="bar4"></li>
  </ul>

  <p class="text-center mb-0" [style.color]="messageColor" *ngIf="passwordToCheck?.length">{{ message }}</p>




</div>
<div>
  <ul *ngIf="passwordToCheck?.length>0">
    <li [ngClass]="{ 'ok' : flags?.length === true, 'ko': flags?.length !== true }">Deve essere lunga almeno 10 caratteri</li>
    <li [ngClass]="{ 'ok' : flags?.lowerLetters === true, 'ko': flags?.lowerLetters !== true }">Deve contenere almeno
      un carattere minuscolo</li>
    <li [ngClass]="{ 'ok' : flags?.upperLetters === true, 'ko': flags?.upperLetters !== true }">Deve contenere almeno
      un carattere maiuscolo</li>
    <li [ngClass]="{ 'ok' : flags?.numbers === true, 'ko': flags?.numbers !== true }">Deve contenere almeno un numero
    </li>
    <li [ngClass]="{ 'ok' : flags?.symbols === true, 'ko': flags?.symbols !== true }">Deve contenere almeno un simbolo
    </li>
  </ul>
</div>