"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * [[MeetingSessionTURNCredentials]] contains TURN credentials from the TURN server.
 */
class MeetingSessionTURNCredentials {
  constructor() {
    this.username = null;
    this.password = null;
    this.ttl = null;
    this.uris = null;
  }
}
exports.default = MeetingSessionTURNCredentials;
