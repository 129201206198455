<div class="card" style="width: 60%; margin: auto;">

    <div class="card-header card-header-info">
        <h4 class="card-title">
            <a style="float:right" (click)="close()"><i class="material-icons">close</i></a>
        </h4>
    </div>

    <div class="card-body" style="display: flex;">
        <p class="mb-0">La targa è associata al lotto:</p>
        <p class="ml-2" style="font-weight: bold;">{{ data?.noteCarico }}</p>
    </div>
</div>