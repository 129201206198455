"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Represents parsed attributes of a media section (i.e. associated with a single m-line)
 */
class SDPMediaSection {}
exports.default = SDPMediaSection;
