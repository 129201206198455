
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { MysqlService } from 'app/services/mysql.service';
import Chart from 'chart.js';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { getAgGridLocale } from 'app/components/components.module';

@Component({
    selector: 'app-stats3cx',
    templateUrl: './stats3cx.component.html',
    styleUrls: ['./stats3cx.component.scss'],
    standalone: false
})
export class Stats3cxComponent implements OnInit {

  chart_user;
  tableList = new Array();

  @Input() isDashboard3CX: boolean;
  users = [];


  private eventsSubscription: Subscription;
  @Input() events: Observable<void>;

  nDays = 7;
  columnDefs;

  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
  };
  gridApi;
  gridColumnApi;

  gridOptions: GridOptions = {
    localeText: getAgGridLocale(),
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      //autoSizeWidth: true,
    },
    pagination: false,
    //rowSelection: { enableClickSelection: false },


  }

  isNotData: boolean = false;
  dataList = [];

  constructor(private db: MysqlService, private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe(() => this.loadData());
  }

  loadData() {

    let d1 = moment().subtract(this.nDays + 1, 'day').format('YYYY-MM-DD');
    let d2 = moment().subtract(1, 'day').format('YYYY-MM-DD');

    //console.clear()
    console.log(d1, d2)

    this.db.f3cx.stats_list('day', d1, d2, 'in').then((res: any) => {
      console.log('loadData 1', res);
      let data = res.list;
      this.dataList = [...data];

      this.setListForTable(data);

      /* Set Charts */
      this.isDashboard3CX ? this.setChart(data) : '';

    }).catch(err => {
      alert(JSON.stringify(err));
    });
  }

  onGridReady(params) {
    this.gridOptions = params
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll();
  }

  autoSizeAll(skipHeader = false) {
    var allColumnIds = [];
    this.gridApi.getColumns().filter(e => e.colDef.resizable).forEach((column) => {
      allColumnIds.push(column.colId);
    });
    this.gridApi.autoSizeColumns(allColumnIds, skipHeader);

  }

  getGridHeight() {
    if (!this.isDashboard3CX) {
      return '25vh';
    }
    return '35vh';
  }

  setChart(data) {

    /* labels */
    let labels = data.map(e => e.p);
    labels = labels.filter((item, pos, arr) => {
      return arr.indexOf(item) == pos;
    });
    labels = labels.sort();

    /* users */

    let users = [];

    users = data.map(e => e);
    users = users.filter((item, pos, arr) => {
      return arr.indexOf(item) == pos;
    });

    /* datasets */
    let datasets = [];

    const newArray = [...new Set(data.map(e => e.p))];

    let x = [];

    users.forEach((u, i) => {

      let userData = data.filter(e => e);

      labels.forEach(l => {
        let v;
        v = userData.find(e => (e.p == l));

        if (!v) {
          x.push(0)
        } else {
          x.push(v.chiamanteUR - v.answered)
        }
      });
    });

    datasets.push({
      label: 'Utenti persi',
      fill: false,
      backgroundColor: '#FF6633',
      borderColor: '#FF6633',
      data: x,
    });

    setTimeout(() => {
      this.chart_user = new Chart('chart_user', {
        type: 'bar',

        data: {
          datasets: datasets,
          labels: labels,
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      });
    }, 100)

  }

  setListForTable(data: any) {

    //console.clear()

    this.tableList = [];

    const dateList = [...new Set(data.map(e => e.p))].sort((a, b) => a > b ? 1 : -1);

    const usersList = [...new Set(data.map(e => e.email))];



    this.columnDefs = [{ field: 'dest', headerName: 'Utente', width: '250px', hide: false, valueFormatter: e => this.setUserValue(e.value), }];

    for (const date of dateList) {
      this.columnDefs.push({ field: date, headerName: date, width: '130px', hide: false })
    }



    let sumObj = { dest: 'Totale complessivo risposte' } as any;
    let singleCallersObj = { dest: 'Chiamanti singoli' } as any;
    let losedUsersObj = { dest: 'Utenti persi' } as any;

    for (const user of usersList) {
      let row = { dest: user } as any;


      //console.log(row)

      for (const date of dateList) {

        try {


          let obj = data.find(e => e.p == date && e.email == user);
          console.log('obj: ', obj);

          if (!obj) obj = { n: 0, chiamanteUR: 0 }

          console.log(obj)
          row['' + date] = obj.n;

          /* Somma chiamate ricevute per giorno */
          if (!sumObj['' + date]) sumObj['' + date] = 0;
          sumObj['' + date] += obj.n;

          /* Chiamanti singoli */
          if (!singleCallersObj['' + date]) singleCallersObj['' + date] = 0;
          singleCallersObj['' + date] = obj.chiamanteUR;
          console.log('singleCallersObj: ', singleCallersObj);

          /* Utenti persi */
          if (!losedUsersObj['' + date]) losedUsersObj['' + date] = 0;
          losedUsersObj['' + date] = obj.chiamanteUR - sumObj['' + date];

        } catch (error) {
          console.error(error)
        }

      }

      this.tableList.push(row);
    }


    //console.log('here4')

    this.tableList.push({});
    this.tableList.push(sumObj);
    this.tableList.push(singleCallersObj);
    this.tableList.push(losedUsersObj);

    setTimeout(() => {
      this.autoSizeAll();
    }, 10);
  }

  setUserValue(value) {
    for (const element of this.dataList) {
      if (element.email == value) {
        return element.dest;
      }
    }

  }

  ngOnDestroy() {
    if (this.eventsSubscription) { this.eventsSubscription.unsubscribe(); }
  }


}
