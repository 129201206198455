"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * [[VideoSource]] contains the information of a video source.
 */
class VideoSource {}
exports.default = VideoSource;
