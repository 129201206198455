import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, Inject, ElementRef, ViewChild, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UploadVdrDialogComponent } from 'app/components/upload-vdr/detail/upload-vdr-dialogcomponent';
import { MysqlService } from 'app/services/mysql.service';


@Component({
  selector: 'app-sysmon-dialog',
  templateUrl: './sysmon-dialog.component.html',
  styleUrls: ['./sysmon-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})

export class SysMonDialogComponent implements OnInit {

  item

  constructor(
    public dialogRef: MatDialogRef<UploadVdrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private db: MysqlService
  ) {

    this.item = data
    console.log('this.item: ', this.item);

  }

  ngOnInit() {


  }

  loading = false
  async refresh() {
    this.loading = true
    let r = await this.db.sysmon.get_info()
    console.log('r: ', r);
    this.item = r
    this.loading = false
  }



  close() {
    this.dialogRef.close();
  }

}

