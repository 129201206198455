"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientMetricReportDirection = void 0;
var ClientMetricReportDirection;
(function (ClientMetricReportDirection) {
  ClientMetricReportDirection[ClientMetricReportDirection["UPSTREAM"] = 0] = "UPSTREAM";
  ClientMetricReportDirection[ClientMetricReportDirection["DOWNSTREAM"] = 1] = "DOWNSTREAM";
})(ClientMetricReportDirection = exports.ClientMetricReportDirection || (exports.ClientMetricReportDirection = {}));
exports.default = ClientMetricReportDirection;
