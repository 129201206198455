<div class="row">
    <div class="col">
        <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [rowData]="tableList"
            [ngStyle]="{ 'height': getGridHeight() }" *ngIf="gridOptions && tableList && columnDefs"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
            [gridOptions]="gridOptions" [pagination]="false">
        </ag-grid-angular>
    </div>
</div>

<div class="row" *ngIf="isDashboard3CX">
    <div class="col">
        <h3>Grafico utenti persi</h3>
        <div style="height: 30vh;">
            <canvas id="chart_user">{{ chart_user }}</canvas>
        </div>
    </div>
</div>