import { Component, ElementRef, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { OpentokService } from 'app/services/opentok.service';


const publish = () => {

};

@Component({
    selector: 'app-publisher',
    templateUrl: './publisher.component.html',
    styleUrls: ['./publisher.component.css'],
    standalone: false
})

export class PublisherComponent implements AfterViewInit {

  cmd = '';

  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @Input() session: OT.Session;

  @Input() //value: IResult
  set receiveCommand(cmd: string) {
    if (cmd == '') return;
    //this.getContext();
    console.log('cmd received - publisher: ', cmd);
    this.cmd = cmd;
    cmd = '';
    setTimeout(() => { this.cmd = ''; }, 100)

    switch (this.cmd) {
      case 'switch_camera': this.cycleVideo(); break;
    }
  }


  @Output() onOkPrivacy: EventEmitter<boolean> = new EventEmitter();
  @Output() onSetStream: EventEmitter<any> = new EventEmitter();

  publisher: OT.Publisher;
  publishing: Boolean;

  constructor(private opentokService: OpentokService) {
    this.publishing = false;
  }

  ngAfterViewInit() {
    const OT = this.opentokService.getOT();
    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, { width: '100%', height: '100%', resolution: '1280x720', insertMode: 'append', fitMode: 'contain', mirror: false });


    this.publisher.on('streamCreated',  (event) => {

      //console.clear()

      console.log('Stream resolution: ' +
        event.stream.videoDimensions.width +
        'x' + event.stream.videoDimensions.height);

        this.onSetStream.emit(event.stream.videoDimensions)

    });


    if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
    }
  }

  publish() {
    this.session.publish(this.publisher, (err) => {
      if (err) {
        alert(err.message);
        this.onOkPrivacy.emit(false)

      } else {
        this.publishing = true;
        this.onOkPrivacy.emit(true)
      }
    });
  }

  cycleVideo() {
    console.log('cycleVideo')
    this.publisher.cycleVideo()
  }

}
