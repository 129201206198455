//import { LocalDataSource, } from 'ng2-smart-table';
import { AuthorizationService, IPermission, READONLY } from './../../../../services/authorization.service';
import { HttpEventType } from '@angular/common/http';
import { MysqlService, Appointment } from './../../../../services/mysql.service';
import { IPerizia, IInterlocutoria, IPeriziaReminder, IContattoPerizia } from './../../../../models/perizia.interface';
//import { ReadMode } from 'ngx-file-helpers';
import { Component, OnInit, EventEmitter, ViewEncapsulation, Inject, ElementRef, ViewChild, Input, Output, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { OsmService } from '../../../../services/osm.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { IChat } from '../../../../components/chat/models/chat.interface';
import { UserService } from 'app/services/user.service';
import { Location, DatePipe } from '@angular/common';
import moment from 'moment-timezone';;

import { saveAs } from "file-saver"

import { Subscription } from 'rxjs';
import { MD5 } from 'app/shared';
import { TableConfirmButton } from 'app/components/table/table-confirm/table-confirm.component';
import { IFile } from 'app/models/incarico.interface';
import { PerizieDetailComponent } from './detail/detail.component';
import { ICommessa } from 'app/models/commessa.interface';
import { DialogCheckComponent } from './dialog-check/dialog-check.component';
import { GridDeleteComponent, GridEditComponent, GridIconComponent } from 'app/components/grid-edit/grid-edit.component';
import { ILuogo } from 'app/models/anagrafica.interface';
import { MapSelectorComponent } from 'app/components/map-selector/map-selector.component';
import { emptyAdzRow } from 'app/components/adz-viewer/adz-viewer.component';



import JSZip from 'jszip';
import { PerizieOrderPhotoComponent } from './order-photo/order-photo.component';
//import * as JSZipUtils from 'jszip-utils';


declare let L;
declare let google: any;

@Component({
  selector: 'app-dialog-perizie',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})


export class PerizieDialogComponent implements OnInit {


  isShowAddAdz: boolean = true;

  elenco_perizie_stessa_targa = []

  commesse_order_manager = [29, 15, 19, 21, 23]

  legend = false
  showReclamo = false

  reclamo

  showLocationSearch = true

  ria: any = {}

  tab_mod = 1;

  s: Subscription;
  campo = ''
  nomeCampo = ''
  appointmentsData: Appointment[];
  currentDate: Date = new Date();
  newDate: Date;
  vehType = '';
  attachment_backup;


  ids_list = [];
  currentIdInList;

  commesse = [];
  commessa: ICommessa | any
  //timeRequired: number;

  public READONLY = READONLY;
  public startTime = '07:00';
  public endTime = '20:00';

  storico = [];
  logs = [];
  email = [];
  //result;//:IResult;


  alertno: boolean = true
  id: number;

  selectedTab_name = '';
  tab = 0;

  pdf_perizia;
  img_360;
  pdf_parcel;

  blur: boolean = false;

  permission: IPermission = this.authorizationService.isAuthorized('insurance', '');
  userCompetence: boolean = false;

  onLoadingUpdate: EventEmitter<number> = new EventEmitter();

  ///////////////////////////////////
  clienti = [];
  salacontrollo = [];
  ispettori = [];
  ///////////////////////////////////
  province = [];

  comuniIspezione = [];
  capIspezione = [];

  comuniDanneggiato = [];
  capDanneggiato = [];

  comuniPatrocinatore = [];
  capPatrocinatore = [];

  comuniSinistro = [];
  capSinistro = [];
  //////////////////////////////////

  marche = [];
  anni = [];
  modelli = [];
  versioni = [];

  docviewer = false;
  docviewer_i = 0;
  docviewer_source = [];
  docviewer_position = 'center';
  docviewer_filter = '';
  docviewer_double = false
  docviewer_descr


  loading = false;
  public loading_percentage: number = 0;
  public loading_loaded: number = 0;
  public loading_total: number = 0;

  public readMode = 2;

  item: IPerizia;
  form: UntypedFormGroup;

  stati = [];
  stati1 = [
    '',
    'VIDEOPERIZIA EFFETTUATA',
    'DOCUMENTAZIONE DISPONIBILE',
    'RICHIESTA DOC. INTEGRATIVA',
    'INVIATO ACCORDO',
    'RICEVUTO ACCORDO',
  ];
  stato;

  data: IPerizia;

  editMode: boolean;
  live;
  type: string;
  reminders;

  ninterloc

  visualizzaStorico = false


  gridApi;
  gridColumnApi;

  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
  };


  contatti_source = []

  async allegaVDRTest() {

    let vdr = await this.db.unipolrental_get_vdr(this.item.id, false)
    console.log(vdr)

    saveAs(vdr, 'vdr.pdf')


  }

  onVisualizzaStoricoChange(event) {


    this.visualizzaStorico = event.checked
    console.log(this.visualizzaStorico, event)

    this.loadEvents()

  }



  contatti_columnDefs: any[] = [
    {
      field: 'id', headerName: '',
      sortable: false,
      width: '60px', resizable: false, filter: false, suppressHeaderMenuButton: true,
      cellRenderer: GridEditComponent,
      cellRendererParams: {
        clicked: (field: any) => {
          this.onContactEditDialog(field.id)
        }
      },
    },


    {
      field: 'id', headerName: '',
      name: 'delete',
      sortable: false,
      width: '60px', resizable: false, filter: false, suppressHeaderMenuButton: true,
      cellRenderer: GridDeleteComponent,
      cellRendererParams: {
        clicked: (field: any) => {
          this.onContactDelete(field)
        }
      },
    },

    { field: 'ruolo', headerName: 'Ruolo' },
    { field: 'nome', headerName: 'Nome' },
    { field: 'cognome', headerName: 'Cognome' },
    {
      field: 'emailCorrect', headerName: '',
      sortable: false, filter: false, suppressHeaderMenuButton: true,
      width: '45px', resizable: false,
      //cellRenderer: GridIconComponent,
      cellRenderer: params => !params.value ? '<i class="material-icons" style="margin-left:0px; margin-top:7px;color: red">error</i>' : '',

    },
    { field: 'email', headerName: 'Email' },
    { field: 'telefono', headerName: 'Telefono' },
    //{ field: 'inviaMail', headerName: 'Invia Mail' },

  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private osm: OsmService,
    public authorizationService: AuthorizationService,
    private router: Router,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public matDialog: MatDialog,
    public db: MysqlService,
    public userService: UserService,
    private location: Location,
    //private ow: OpenWeatherService,
  ) {



    //this.appointmentsData = db.getAppointments();

    this.form = this.formBuilder.group({

      targa: ['', [Validators.required]],
      telaio: ['',],
      stato: ['', Validators.required],
      stato1: ['',],
      marca: ['',],
      annoImmatricolazione: ['',],
      tipoAuto: ['',],
      modello: ['',],
      deposito: ['',],
      tipo: ['',],
      nPerizia: ['',],
      km: ['',],
      madTarga: ['',],
      provincia: ['',],
      citta: ['',],
      cap: ['',],
      indirizzo: ['',],
      location_lat: ['',],
      location_lon: ['',],
      noteCarico: ['',],

      danneggiato_nome: ['',],
      danneggiato_contatto: ['',],
      danneggiato_provincia: ['',],
      danneggiato_citta: ['',],
      danneggiato_cap: ['',],
      danneggiato_indirizzo: ['',],
      danneggiato_telefono: ['',],
      danneggiato_fax: ['',],
      danneggiato_email: ['',],
      danneggiato_pec: ['',],

      patrocinatore_nome: ['',],
      patrocinatore_contatto: ['',],
      patrocinatore_provincia: ['',],
      patrocinatore_citta: ['',],
      patrocinatore_cap: ['',],
      patrocinatore_indirizzo: ['',],
      patrocinatore_telefono: ['',],
      patrocinatore_fax: ['',],
      patrocinatore_email: ['',],
      patrocinatore_pec: ['',],

      sinistro_numero: ['',],
      partita_danno: ['',],
      sinistro_mandante: ['',],
      sinistro_data: ['',],
      sinistro_targaAssicurato: ['',],
      sinistro_nomeAssicurato: ['',],

      sinistro_provincia: ['',],
      sinistro_citta: ['',],
      sinistro_cap: ['',],
      sinistro_indirizzo: ['',],

      sinistro_location_lat: ['',],
      sinistro_location_lon: ['',],


      versione: ['',],
      quotazioneIntero: ['',],
      quotazioneVendita: ['',],
      dataImmatricolazione: ['',],


      messaggio_cliente: ['',],
      messaggio_perito: ['',],

      assegnatoA1: ['',],
      assegnatoA2: ['',],


      commessa: ['',],
      cliente: ['',],

      importo_proposta: ['',],
      importo_concordato: ['',],

      iban_proposta: ['',],




      riparatore_nome: ['',],
      riparatore_tel: ['',],
      riparatore_email: ['',],

      fotoComplete: ['',],


      noteInterne: ['',],

      documentale: ['',],

      ricavo_manuale: ['',],
      costo_manuale: ['',],
      descrizione_cambio_tariffa: ['',],

      sel_tariffa: ['',],//dummy

      Doppie_chiavi: ['',],
      Carta_di_circolazione: ['',],
      Ruota_scorta: ['',],

      filterIsp: ['',],

    });


    this.s = route.params.subscribe(val => {

      let id = +val.id

      //console.log('id ' + id)
      //alert('init subscribe')
      this.init(id)
    });


  }

  getType() {
    let tmp = window.location.href.split('/')
    this.type = tmp.pop()
    for (let i = 0; i < 5; i++)
      if (this.type != 'all' && this.type != 'ssu' && this.type != 'perizie' && this.type != 'buyback' && this.type != 'tradein' && this.type != 'uxpert' && this.type != 'unipolvp' && this.type != 'unipolrental') this.type = tmp.pop()


  }

  isBlurring = false
  async setBlur() {
    this.isBlurring = true
    console.clear()
    this.blur = true

    if (this.item?.result?.fotoVetrina?.length > 0) {

      for (const e of this.item.result.fotoVetrina) {
        let r = await this.db.photoroom_remove_background(this.item.id, e, 'foto_vetrina',/*'gg-bg1.jpg'*/)
        console.log('r: ', r);
        e.url += '&blur=1&ts=' + (new Date()).getMilliseconds()

      }

      this.isBlurring = false
      return
    }

    for (let i = 0; i < 4; i++) {
      let r = await this.db.photoroom_remove_background(this.item.id, this.item.allegati[i], '')
      console.log(r)
      this.item.allegati[i].url += '&ts=' + (new Date()).getMilliseconds()
    }


    this.isBlurring = false
  }

  onContactEditDialog(id = undefined) {

    if (!this.permission.permitContatsEdit) return

    //console.clear()
    console.log(id)

    let item: IContattoPerizia = this.contatti_source.find(e => e.id == id)
    if (!item) item = {
      idPerizia: this.item.id,
      ruolo: '',
      nome: '',
      cognome: '',
      email: '',
      telefono: '',
      inviaMail: false


    }

    const dialogRef = this.dialog.open(DialogContattoComponent, {
      width: '40%',
      data: { item: item }
    });

    dialogRef.afterClosed().subscribe(async (res: IContattoPerizia) => {

      if (res) {
        await this.db.perizie_contatti_upsert(res)
          .catch(res => console.log(res))
          .catch(err => console.error(err))
        this.loadContatti()
      }

    })
  }


  calendarFilterData = {
    ispettore: '',
    centro: ''
  }

  filterCalendar() {
    console.log('loadEvents -> filterCalendar')
    this.loadEvents()
  }

  async onContactDelete(id) {
    //console.clear()
    console.log(id)

    if (!confirm('Sei sicuro di voler eliminare questo contatto?')) return

    await this.db.perizie_contatti_delete(id)
      .catch(res => console.log(res))
      .catch(err => console.error(err))

    this.loadContatti()

  }

  showSinistri = false
  sinistri_unipolrental = []
  async setShowSinistri() {
    this.showSinistri = !this.showSinistri;
    this.showTariffe = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (this.showSinistri) {
      //console.clear()
      this.sinistri_unipolrental = await this.db.unipolrental_list_sinistri(0, 100, this.item.targa, false)

      this.sinistri_unipolrental = this.sinistri_unipolrental.sort((a, b) => {

        let d1 = moment(a.DataSinistro, 'DDMMYYYY').format('YYYY-MM-DD')
        let d2 = moment(b.DataSinistro, 'DDMMYYYY').format('YYYY-MM-DD')

        console.log(d1, d2)

        if (d1 < d2) return 1
        if (d1 > d2) return -1

        return 0



      }).map(e => {
        e.DataSinistro = moment(e.DataSinistro, 'DDMMYYYY').format('DD/MM/YYYY')
        e.DataInserimentoSinistro = moment(e.DataInserimentoSinistro, 'DDMMYYYY').format('DD/MM/YYYY')

        return e
      })
    }

  }

  // Show Tariffario

  showTariffe = false;
  itemTariffe;
  setShowTariffe() {
    this.showTariffe = !this.showTariffe;
    this.showSinistri = false;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    console.log(+this.item.ContrattoQuadroSifa)
    this.itemTariffe = TARIFFARIO_UR_SIFA[+this.item.ContrattoQuadroSifa]
  }

  onCboxClicked() {
    console.log(this.item.adz_data)

    this.saveAdzRia()

  }

  getPlaceholder(field, label) {
    let s = field && field != '' ? label + ': ' + field : label
    //console.log('getPlaceholder => ' + s)
    return s
  }

  convert(event) {
    const file: File = event.target.files[0];
    this.getBase64(file);
  }

  openCheckDialog() {
    const dialogRef = this.dialog.open(DialogCheckComponent, {
      width: '40%',
      data: { item: this.item, commessa: this.commessa }
    });

    dialogRef.afterClosed().subscribe(async res => {

      if (res) {
        //alert('init checkdialog')
        this.init(this.id)
      }

    })
  }

  dati_fatt_expanded = false

  @ViewChild('cfile') myInputVariable: ElementRef;
  getBase64(file: File) {


    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = res => {

      let f: IFile = {
        fileName: file.name,
        size: file.size,
        fileContent: '' + reader.result,
        creationDate: new Date()
      }


      this.item.allegatiIncarico.push(f)

      this.myInputVariable.nativeElement.value = "";



      this.db.perizia_convert_input_file(f).then(res => {
        console.log(res)
        if (res.status == 'OK') {
          console.log(res)

          switch (res.type) {
            case "perizia_convert_input_file_linear":

              this.item.cliente = 12297

              this.item.danneggiato_cap = res.data.dati_danneggiato.cap
              this.item.danneggiato_cf = res.data.dati_danneggiato.codice_fiscale
              this.item.danneggiato_citta = res.data.dati_danneggiato.località
              this.item.danneggiato_contatto = res.data.dati_danneggiato.cap
              this.item.danneggiato_email = res.data.dati_danneggiato.e_mail
              this.item.danneggiato_telefono = res.data.dati_danneggiato.cellulare
              this.item.danneggiato_indirizzo = res.data.dati_danneggiato.indirizzo
              this.item.danneggiato_nome = res.data.dati_danneggiato.nominativo
              this.item.danneggiato_provincia = res.data.dati_danneggiato.e_mail

              this.item.sinistro_indirizzo = res.data.dati_incarico.luogo_perizia
              this.item.sinistro_nomeAssicurato = res.data.dati_assicurato.nominativo
              this.item.sinistro_data = new Date(res.data.dati_sinistro.data_sinistro)
              this.item.sinistro_numero = res.data.dati_sinistro.n__sinistro

              this.item.targa = res.data.veicolo_assicurato.targa

              this.item.marca = res.data.veicolo_assicurato.marca_modello_allestiment

              this.item.tipo = res.data.dati_incarico.tipo_sinistro

              break;
          }



        } else {
          console.error(res)
          alert(res.errorInfo)
        }
      }).catch(err => console.error(err))




    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  /*
    async autoAssign() {
   
      let group = 'automotive/'
   
      switch (this.type) {
   
        case 'ssu': group += 'ssu'; break;
        case 'perizie': group += 'insurance'; break;
        case 'Buyback': group += 'buyback'; break;
   
      }
   
   
      await this.db.bingmaps.get_nearest_inspector(group, this.item.location_lat, this.item.location_lon)
        .then(res => {
          //console.log(res)
   
          this.item.assegnatoA1 = res.assegnatoA1
          this.item.assegnatoA2 = res.assegnatoA2
   
          if (this.item.assegnatoA2 && this.item.assegnatoA2 != '' && this.item.stato == 'NUOVO INCARICO') this.item.stato = 'ASSEGNATO AD ISPETTORE'
   
        })
        .catch(err => console.error(err))
   
    }
  */


  fixDuplicatedAttachments(allegatiOrig) {
    return
    //console.clear()
    //console.log("*********")

    //console.log(allegatiOrig)

    let allegati = [...allegatiOrig]

    let vdr = allegati.filter(e => e.type == 'Verbale di riconsegna')

    //const unique = [...new Set(allegati.map(item => item.hash))];
    let unique = [...new Map(allegati.map(item => [item['hash'], item])).values()];

    //console.log(allegati)
    //console.log(unique)

    if (allegati.length > unique.length) {
      let b = confirm("Attenzione, sono presenti allegati doppi, desideri rimuoverli?")

      if (b) {

        unique = unique.concat(vdr)// fix per non rimuovere i vdr

        return unique
      }
    }

    return false

  }



  init(id) {

    this.getType()


    //if (!this.id) 
    this.id = id;


    this.ids_list = JSON.parse(localStorage.getItem('ids_list'));
    if (!this.ids_list) this.ids_list = []

    this.currentIdInList = this.ids_list.findIndex(v => {
      let b = +v == +this.id;

      //console.log('' + +v + ' == ' + + this.id + ' => ' + b)

      return b
    })

    //console.log('currentIdInList ' + this.currentIdInList)

    let tab = +this.route.snapshot.paramMap.get('tab');



    this.loadEmpty();

    if (this.id > 0) {


      this.db.get_perizia(this.id, this.type == 'all' ? '' : this.type).then(res => {


        //console.log('data', res)


        if ((!res || res.item == undefined) && this.alertno) {
          alert("pratica non trovata o non di competenza")
          this.alertno = false
        }

        res.item.fotoComplete = res.item.fotoComplete > 0
        this.data = res.item;

        if (this.type == 'all') {

          switch (res.item.tipo.toLowerCase()) {

            case "stato d'uso":
              this.type = 'ssu'
              break;

            case "buyback":
              this.type = 'buyback'
              break;

            default:
              this.type = res.item.tipo

          }

        }

        if (!this.item.tipoVeicolo || this.item.tipoVeicolo == '') this.item.tipoVeicolo = res.item.tipo_veicolo




        /*   RIMOSSO PER PROBLEMI CON VDR
        
        if (this.userService.getRole() == 'ADMIN' || this.userService.getRole() == 'GESTORE') {
  
          
          let tmp = this.fixDuplicatedAttachments(res.item.allegati)
          if (tmp !== false) {
            res.item.allegati = tmp
  
            this.db.upsert_perizia(res.item).subscribe(res => {
  
            })
  
          }
  
        }
        */

        this.loadAll();
      }).catch(err => console.error(err));



      this.db.unipolrental_get_riaddebito(this.id)
        .then(res => {
          // //console.clear()
          console.log(res)
          this.ria = res.item

        })
        .catch(err => console.error(err));

    }


    else {
      this.loadAll();
    }

  }

  isAdzResultError(): boolean {
    return this.adzResult?.filter(e => e.status == 'ERROR').length > 0
  }

  ngOnInit() {

    let id = +this.route.snapshot.paramMap.get('id')


    this.db.impostazioni.get('moduli').then(result => {
      //console.log(JSON.parse(result[0].valore))
      this.live = result[0].valore.automotive_telexpert
      //alert('init ngOnInit')
      this.init(id)
    })




  }

  onOptionChanged(event) {
    if (event.name === "currentDate") {
      let selectedDate = event.value;
      this.newDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - 1);
      console.log('newDate => ', this.newDate);
    }
  }

  async loadContatti() {
    this.contatti_source = (await this.db.perizie_contatti_list(this.item.id))
      .map(e => { e.inviaMail = +e.inviaMail > 0; return e })
      .sort((a, b) => a.id - b.id) // importante per richiesta ragazze centralino
    this.areEmailContactsInvalid()


    for (const e of this.contatti_source) e.emailCorrect = true

    for (const e of this.contatti_source) {
      //e.emailCorrect = true
      let b = await ValidateEmail(e.email, this.db)

      e.emailCorrect = b


    }
  }

  areEmailInvalid = false
  async areEmailContactsInvalid() {
    //console.clear()
    for (const e of this.contatti_source) {

      let b = await ValidateEmail(e.email, this.db)

      // console.log(e.email + ' -> ' + b)

      if (!b) {
        this.areEmailInvalid = true
        return true
      }
    }

    this.areEmailInvalid = false
    return false

  }

  gridApi_contatti
  gridColumnApi_contatti
  onGridReady_contatti(params) {
    this.gridApi_contatti = params.api;
    this.gridColumnApi_contatti = params.columnApi;
    this.autoSizeAll();
  }


  autoSizeAll(skipHeader = false) {
    var allColumnIds = [];
    this.gridApi?.getColumns().filter(e => e.colDef.resizable).forEach((column) => {
      allColumnIds.push(column.colId);
      //  console.log(column)
    });


    this.gridApi?.autoSizeColumns(allColumnIds, skipHeader);
  }

  loadReminder() {
    //console.log(this.id)
    this.db.perizie_reminder_list(undefined, this.id)
      .then((res: IPeriziaReminder[]) => {
        //console.log(res)
        res.forEach(e => {
          e.dta = moment(e.dta + 'Z').tz("Europe/Rome").toDate()
          if (e.dtaReminder) e.dtaReminder = moment(e.dtaReminder + 'Z').tz("Europe/Rome").toDate()
        });

        this.reminders = res
      })
      .catch(err => console.error(err))
  }

  async deleteReminder(id) {
    if (!confirm("Confermi la rimozione di questo elemento?")) return

    await this.db.perizie_reminder_delete(id)
    this.loadReminder()
  }

  addDett() {

    const dialogRef = this.dialog.open(PerizieDetailComponent, {


    });
    dialogRef.afterClosed().subscribe(res => {
      console.log(res)
      if (res == '')
        return
      else {
        //console.log(res)

        let result: IPeriziaReminder

        result = {
          idPerizia: this.id,
          descrizione: res.descrizione,
          dtaReminder: res.dtaReminder,
          categoria: res.categoria,
          categoria1: res.categoria1
        }
        console.log(result)


        this.db.perizie_reminder_upsert(result).then(res => {
          console.log(res)

          this.loadReminder()


        }).catch(err => console.error(err))


      }

    });

  }


  openOtherTab(id) {
    let base = window.location.href
    if (base.includes('#')) base = base.split('#')[0]
    let url = `${base}#/automotive/all/dettaglio/${id}`
    window.open(url, "_blank");
  }

  openOtherPdf(e) {
    let pdf_perizia_clean = this.db.get_print_adz_perizie(+e.id, false, '', this.db.getIcon());
    let url = pdf_perizia_clean + `&images&fout=${e.id}-${e.targa.replace(' ', '')}-perizia.pdf${e.type == 'unipolrental' ? '&onlyselected=1' : ''}`;
    window.open(url);
  }


  nextInList() {
    //console.log('nextInList');
    //console.log(this.ids_list);
    //console.log(this.currentIdInList);
    let id = this.ids_list[this.currentIdInList + 1];
    //console.log(id);
    this.router.navigate(['/automotive/' + this.type + '/dettaglio/' + id])


    // this.ngOnInit();
  }

  prevInList() {
    //console.log('prevInList');
    //console.log(this.ids_list);
    //console.log(this.currentIdInList);
    let id = this.ids_list[this.currentIdInList - 1];
    //console.log(id);
    this.router.navigate(['/automotive/' + this.type + '/dettaglio/' + id]);
    //this.ngOnInit();
  }


  getIconUrlFromStatus(sts: string): string {
    return './assets/img/icons/perizie/stati/' + sts.split(' ').join('_').split('/').join('_').toLowerCase() + '.png'
  }


  isEcoFabopened = false;
  ecoFabOpen() {
    this.isEcoFabopened = true;
  }
  ecoFabClose() {
    this.isEcoFabopened = false;
  }

  searchItineraryItem() {

    let query = this.item.citta.trim() + ' ' + this.item.cap.trim() + ' ' + this.item.indirizzo.trim();

    if (!query) return;

    this.db.bingmaps.search(query).then((res: any) => {
      //console.log(res);

      try {
        let x = res.data.resourceSets[0].resources[0].geocodePoints[0].coordinates;

        this.form.controls['location_lat'].setValue(x[0]);
        this.form.controls['location_lon'].setValue(x[1]);

      } catch (error) {
        alert("Impossibile geolocalizzare l'indirizzo");
        console.error(error);

      }



    }).catch(err => {
      console.error(err)
    })
  }


  sendParcel() {

    if (!this.pdf_parcel || this.pdf_parcel == '') {
      alert('Parcella non trovata');
      return;
    }

    if (!this.pdf_perizia_clean || this.pdf_perizia_clean == '') {
      alert('ADZ non trovato');
      return;
    }


    let perizia = {
      filename: this.item.targa + ".PDF",
      url: this.pdf_perizia_clean + '&images&fout=' + this.item.targa.replace(' ', '') + ".PDF"
    }

    let parcella = {
      filename: "PParcella.PDF",
      url: this.pdf_parcel
    }


    this.db.perizie_send_parcel(this.item, [perizia, parcella]).then(res => {

      if (res.status != 'OK') {
        alert(JSON.stringify(res))
        return
      }

      alert('Email inviata')


    }).catch(err => alert(JSON.stringify(err)))

  }

  downloadZip(type: string) {

    let url;

    if (type == 'pdf') {
      url = this.pdf_perizia_clean + `&fout=${this.item.id}-${this.item.targa.replace(' ', '')}-perizia.pdf${this.type == 'unipolrental' ? '&onlyselected=1' : ''}`;
    } else if (type == 'pdf-img') {
      url = this.pdf_perizia_clean + `&images&fout=${this.item.id}-${this.item.targa.replace(' ', '')}-perizia.pdf${this.type == 'unipolrental' ? '&onlyselected=1' : ''}`;
    } else if (type == 'noval') {
      url = this.pdf_perizia_clean + `&noval&fout=${this.item.id}-${this.item.targa.replace(' ', '')}-perizia.pdf${this.type == 'unipolrental' ? '&onlyselected=1' : ''}`;
    } else {
      url = this.db.get_perizia_zip_url(this.id, type, this.db.getIcon(), this.type == 'unipolrental')
    }

    //console.log(url)
    window.open(url, '_blank');
  }

  //////////////////////////////////////////

  manageStatus(source, value) {

    if (source == 'assegnatoA1' && this.item.assegnatoA1 == '') this.item.assegnatoA2 = ''

    //console.log('loadEvents -> manageStatus')
    this.loadEvents()// fix per selezione appuntamenti del perito selezionato


    let i = this.ispettori.find(e => e.email == this.item.assegnatoA2)

    if (this.item.assegnatoA1 == '' && i && i.genitore != '') this.item.assegnatoA1 = i.genitore

    //alert('manageStatus')

    //console.log('here3')
    ////console.log(e)

    ////console.clear()

    let e = { source: { id: source } }

    // //console.log(`x: ${this.item.stato} y: ${this.db.getStatiPeriziaAll()[3]}`)
    // //console.log(`x: ${this.item.stato} y: ${this.db.getStatiPeriziaAll()[4]}`)

    if ((this.item.stato == this.db.getStatiPeriziaAll()[3] || this.item.stato == this.db.getStatiPeriziaAll()[4]) && (e.source.id == '' || e.source.id == 'allegati')) {

      console.log('type: 1')

      // //console.log('looking for adz')

      for (let i = 0; i < this.item.allegati.length; i++) {

        // //console.log(this.item.allegati[i].fileName + ' - ' + this.item.allegati[i].fileName.substr(-3).toLocaleLowerCase())


        if (this.item.allegati[i].fileName.substr(-3).toLocaleLowerCase() == 'adz') {

          if (!this.item.assegnatoA1 || this.item.assegnatoA1 == '')
            this.item.stato = this.db.getStatiPeriziaAll()[9];
          else
            this.item.stato = this.db.getStatiPeriziaAll()[6];
          ////console.log('new sts = ' + this.item.stato)

          break;
        }
      }
    }
    else if (this.item.stato == this.db.getStatiPeriziaAll()[0] && e.source.id == 'assegnatoA1' && this.item.assegnatoA1 != '') {
      console.log('type: 2')
      this.item.stato = this.db.getStatiPeriziaAll()[1]
    }
    else if (this.item.stato == this.db.getStatiPeriziaAll()[1] && (e.source.id == '' || e.source.id == 'allegati')) {
      console.log('type: 3')
      //alert('if')
      for (let i = 0; i < this.item.allegati.length; i++) {

        //alert('for')
        if (this.item.allegati[i].fileName.substr(-3).toLocaleLowerCase() == 'adz') {

          //alert('if2')
          this.item.stato = this.db.getStatiPeriziaAll()[9];
        }
      }


    }
    else if ((this.item.stato == this.db.getStatiPeriziaAll()[0] || this.item.stato == this.db.getStatiPeriziaAll()[1]) && e.source.id == 'assegnatoA2' && this.item.assegnatoA2 && this.item.assegnatoA2 != '') {
      console.log('type: 4')
      console.log(this.item.stato, e, this.item.assegnatoA2)

      this.item.stato = this.db.getStatiPeriziaAll()[3]
    }


    this.sortIspettori()


  }

  loadAll() {

    if (this.id <= 0) {
      this.editMode = false;
      this.loadEmpty();
      this.permission = this.authorizationService.isAuthorized('insurance', 'new');
      this.userCompetence = this.authorizationService.isUserCompetence(this.data.stato);

    } else {
      this.editMode = true;
      this.permission = this.authorizationService.isAuthorized('insurance', 'edit');

      if (this.userService.getCurrentUser().role == 'CLIENTE' && this.data.creatoDa != this.userService.getCurrentUser().email)
        this.permission.permitted = true;

      this.userCompetence = this.authorizationService.isUserCompetence(this.data.stato);



    }



    if (!this.data) this.loadEmpty();

    this.upsertUrlAllegati();

    for (let i = 0; i < this.data.allegatiIncarico.length; i++) {

      if (this.data.allegatiIncarico[i].type == 'da commessa')
        this.data.allegatiIncarico[i].url = this.db.commesse.get_attachment_url(this.item.commessa, this.data.allegatiIncarico[i], 'allegati')
      else
        this.data.allegatiIncarico[i].url = this.db.get_perizia_attachment_url(this.data.id, this.data.allegatiIncarico[i], 'allegatiIncarico');

    }

    if (this.data.dtaAppuntamento) this.data.dtaAppuntamento = removeTZ(new Date(this.data.dtaAppuntamento));

    this.data.documentale = +this.data.documentale > 0

    this.item = this.data;
    //console.clear()
    console.log(this.item)
    try {
      this.ninterloc = this.item.interlocutorie.filter(e => e.tipo == 'interlocutoria').length
    } catch (error) {

    }

    this.loadElencoTarghe()


    //console.log('loadCommesse onLoadAll')
    this.loadCommesse();

    this.loadClienti();

    //console.log(this.item)

    let s = this.db.getStato(this.item.stato, this.item.assegnatoA1 == '');
    this.stato = s

    //alert(JSON.stringify(s))

    if (this.stato.reject == 'RICHIESTA MODIFICA/INTEGRAZIONE A SALA CONTROLLO' && (!this.item.assegnatoA1 || this.item.assegnatoA1 == ''))
      this.stato.reject = 'RICHIESTA MODIFICA/INTEGRAZIONE AD ISPETTORE'

    if (this.stato.accept == 'CONSEGNATO A SALA CONTROLLO' && (!this.item.assegnatoA1 || this.item.assegnatoA1 == ''))
      this.stato.accept = 'CONSEGNATO'

    this.loadReminder();
    this.loadResult();

    this.loadIspettori();
    this.loadSalaControllo();

    this.loadMarche();
    this.loadAnni();
    this.loadModelli();
    this.loadVersioni();

    this.loadProvince();
    this.loadComuniIspezione();
    this.loadComuniDanneggiato();
    this.loadComuniPatrocinatore();
    this.loadComuniSinistro();

    this.loadProposte();
    this.loadConcordati();

    this.loadReclamo()



    if (this.item.id > 0) this.loadContatti()

    if (this.item.id > 0) this.loadCentro()

    if (this.item.dtaAppuntamento) {
      this.currentDate = this.item.dtaAppuntamento
    } else {
      this.currentDate = calcStartDate(new Date(), this.item.madTarga && this.item.madTarga != '')
    }


    //if (this.permission.tabs[4]) this.loadEvents();
    if (this.permission.tabs[7]) this.loadStorico();
    if (this.permission.tabs[8]) this.loadLogs();
    if (this.permission.tabs[11]) this.loadEmail();
    if (this.permission.tabs[9]) this.loadInterlocutorie();

    if (this.item.tipo == 'unipolrental') this.loadCentri()

    this.stati = this.db.getStatiPeriziaAll() //this.db.getStatiPerizia();


    this.attachment_backup = JSON.stringify(this.item.allegati);

    if (!this.item.timeRequired || this.item.timeRequired <= 0)
      this.db.time_matrix.get_time_required('automotive', this.type, this.item.cliente, '' + this.item.commessa)
        .then(res => {

          if (res.status != 'OK') {
            console.error(res);
            return
          }

          this.item.timeRequired = res.timeRequired


        })
        .catch(err => console.error(err))



    if (this.id <= 0) {


      //console.clear()
      this.db.get_current_user_profile().then(res => {

        console.log()


        let filter = res[0].clients.split(',')
        filter = filter.filter(filter => filter != '')
        console.log('filter', filter)
        this.clienti = this.clienti.filter(e => filter.findIndex(x => e.id == x) >= 0 || filter.length == 0)

        this.clienti.sort((a, b) => a.ragioneSociale.toLowerCase() < b.ragioneSociale.toLowerCase() ? -1 : 1)

        if (this.clienti.length == 1) this.item.cliente = this.clienti[0].id

      })


      if (this.userService.getRole() == 'ISPETTORE') {
        ////console.log('here2')
        this.item.assegnatoA2 = this.userService.getEmail()
        this.item.stato = 'INCARICO ACCETTATO'
        ////console.log('new', this.item)


        //console.log('new isp', this.clienti)


        this.manageStatus('assegnatoA2', '')
        this.userCompetence = this.authorizationService.isUserCompetence(this.data.stato);
      }
    }
  }

  customerFilter


  clienteChangeFirstRun = true
  clienteChange() {

    return // fix per cambio commessa anomalo

    console.log('loadCommesse onClienteChange')

    this.commessa = undefined
    this.item.commessa = 0

    this.loadCommesse()

  }

  async loadElencoTarghe() {
    let tmp = await this.db.perizie_list_stessa_targa(this.item.id, this.item.targa)
    this.elenco_perizie_stessa_targa = tmp.list
  }


  async loadReclamo() {
    this.reclamo = (await this.db.unipolrental_get_reclamo(this.item.id)).item
  }

  toggleReclamo() {
    this.showReclamo = !this.showReclamo
  }

  showElencoStessaTarga = false
  toggleElencoStessaTarga() {
    this.showElencoStessaTarga = !this.showElencoStessaTarga
  }

  async loadCommesse() {
    //console.log('loadCommesse')

    if (!this.item.cliente || this.item.cliente == 0) return


    if (!this.item.cliente) this.item.cliente = this.userService.getCurrentUser().client;

    this.commessa = undefined
    //console.log('loadCommessa', this.commessa)

    let type
    switch (this.type) {

      case 'ssu': type = 'AUTOMOTIVE-SSU'; break;
      case 'tradein': type = 'AUTOMOTIVE-TRADEIN'; break;
      case 'buyback': type = 'AUTOMOTIVE-BUYBACK'; break;
      case 'unipolrental': type = 'AUTOMOTIVE-UNIPOLRENTAL'; break;

      default: type = 'AUTOMOTIVE-INSURANCE'

    }


    await this.db.commesse.list_current(type, this.item.cliente).then(res => {
      //console.log('commesse', res)
      this.commesse = res;

      try {
        if (!this.item.commessa || this.item.commessa <= 0) this.item.commessa = res[0].id
      } catch (error) {

      }


    }).catch(err => console.error(err))


    if (!this.commesse || this.commesse.length == 0) {


      try {


        let cli = await this.db.anagrafica.get(this.item.cliente)
        //console.log('cli', cli)

        cli = cli[0]

        //console.log('cli', cli)


        await this.db.commesse.list_current(this.type == 'ssu' ? 'AUTOMOTIVE-SSU' :
          this.type == 'unipolrental' ? 'AUTOMOTIVE-UNIPOLRENTAL' :
            this.type == 'tradein' ? 'AUTOMOTIVE-TRADEIN' :

              'AUTOMOTIVE-INSURANCE', cli.clienteAssociato).then(res => {
                //console.log('commesse', res)
                this.commesse = res;

                try {
                  if (!this.item.commessa || this.item.commessa <= 0) this.item.commessa = res[0].id
                } catch (error) {

                }


              }).catch(err => console.error(err))


      } catch (error) {

      }




    }





    if (this.item.commessa)
      try {
        let c = (await this.db.commesse.get(this.item.commessa))[0]
        c.etax = +c.etax > 0
        this.commessa = { ...c }
        //console.log('loadCommessa', this.commessa)
        this.loadIspettori()
        //console.log('ccc', this.commessa)
      } catch (error) {
        console.error(error)
        this.commessa = undefined
        console.log('loadCommessa', this.commessa)
      }

  }
  sel_tariffa
  async allegaParcella(ricavo) {

    //await this.save(false)


    let item = {
      creationDate: new Date(),
      fileContent: '',
      fileName: 'Parcella_' + this.item.id + '.pdf',
      hash: '',
      size: 0,
      type: '',

      url: this.db.perizie_get_parcella(this.item.id, ricavo)

    }
    this.item.allegati.push(item)
    //g(this.item)
    this.attachmentSave('parcella').then(res => {
      //console.clear()
      console.log(res)
    }).then(err => {
      //console.clear()
      console.error(err)
    })
  }

  selTariffa(event) {

    console.log(event)

    if (event?.value) {
      this.item.costo_manuale = event.value.costo
      this.item.ricavo_manuale = event.value.ricavo
      this.item.descrizione_cambio_tariffa = event.value.descr

    } else {

      if (event?.costo)
        this.item.costo_manuale = event.costo
      if (event?.ricavo)
        this.item.ricavo_manuale = event.ricavo
      if (event?.descr)
        this.item.descrizione_cambio_tariffa = event.descr

    }



  }

  loadClienti() {

    this.db.anagrafica.list('CLIENTE').then(res => {
      //console.log('clienti', res)
      this.clienti = res;

      this.clienti.sort((a, b) => a.ragioneSociale.toLowerCase() < b.ragioneSociale.toLowerCase() ? -1 : 1)
    }).catch(err => console.error(err))

  }


  delAttachments() {
    if (!confirm('ATTENZIONE, sei davvero sicuro di voler eliminare tutti gli allegati? L\'operazione non è reversibile!')) return

    this.item.allegati = this.item.allegati.filter(e => e.type == "Verbale di riconsegna")

    this.save(true)
  }

  openReport() {

    let url
    url = this.db.perizie_list_adz(this.id)
    window.open(url, '_blank');
    /**
     * 
    let url;
  
    //   if (this.type == 'ssu')
    //     url = `/#/report/${this.type}/${this.id}?blur=${this.blur}`
    //   else {
    url = this.db.get_perizia_to_adz_url(this.id)
    //  }
    window.open(url, '_blank');
     * 
     */



  }


  saveResult() {
    this.db.perizia_upsert_result(this.item.id, this.item.result).then(res => {
      //console.log(res);
      this.loadResult()

    }).catch(err => { console.error(err) })
  }

  loadResultImageUrls(type: string = 'all') {

    switch (type) {
      case 'all':
        this.loadResultImageUrls('foto_vetrina');
        this.loadResultImageUrls('altri_elementi');
        this.loadResultImageUrls('danni');
        this.loadResultImageUrls('documenti');
        this.loadResultImageUrls('attachments');
        this.loadResultImageUrls('glasses');
        this.loadResultImageUrls('tires');
        this.loadResultImageUrls('danniInt');
        break;

      case 'foto_vetrina':
        if (this.item.result.fotoVetrina) for (let i = 0; i < this.item.result.fotoVetrina.length; i++) {
          this.item.result.fotoVetrina[i].url = this.db.get_perizia_result_image_url(this.item.result.fotoVetrina[i].img)
        }
        break;

      case 'altri_elementi':
        if (this.item.result.altriElementi) for (let i = 0; i < this.item.result.altriElementi.length; i++) {
          this.item.result.altriElementi[i].url = this.db.get_perizia_result_image_url(this.item.result.altriElementi[i].img)
        }
        break;

      case 'danni':
        if (this.item.result.damages) for (let i = 0; i < this.item.result.damages.length; i++) {

          try {
            this.item.result.damages[i].img1.url = this.db.get_perizia_result_image_url(this.item.result.damages[i].img1.img)
          } catch (error) { }

          try {
            this.item.result.damages[i].img2.url = this.db.get_perizia_result_image_url(this.item.result.damages[i].img2.img)
          } catch (error) { }

          for (let j = 0; this.item.result.damages[i].extra && j < this.item.result.damages[i].extra.length; j++) {

            try {
              this.item.result.damages[i].extra[j].img1.url = this.db.get_perizia_result_image_url(this.item.result.damages[i].extra[j].img1.img)
            } catch (error) { }

            try {
              this.item.result.damages[i].extra[j].img2.url = this.db.get_perizia_result_image_url(this.item.result.damages[i].extra[j].img2.img)
            } catch (error) { }


          }

        }
        break;
      case 'danniInt':
        if (this.item.result.damagesInt) for (let i = 0; i < this.item.result.damagesInt.length; i++) {
          if (this.item.result.damagesInt[i]?.img1) this.item.result.damagesInt[i].img1.url = this.db.get_perizia_result_image_url(this.item.result.damagesInt[i].img1.img)
          if (this.item.result.damagesInt[i]?.img2) this.item.result.damagesInt[i].img2.url = this.db.get_perizia_result_image_url(this.item.result.damagesInt[i].img2.img)


        }
        break;

      case 'glasses':
        if (this.item.result.glasses) for (let i = 0; i < this.item.result.glasses.length; i++) {
          this.item.result.glasses[i].img1.url = this.db.get_perizia_result_image_url(this.item.result.glasses[i].img1.img)
          this.item.result.glasses[i].img2.url = this.db.get_perizia_result_image_url(this.item.result.glasses[i].img2.img)



        }
        break;

      case 'documenti':
        if (this.item.result.documenti) for (let i = 0; i < this.item.result.documenti.length; i++) {
          this.item.result.documenti[i].url = this.db.get_perizia_result_image_url(this.item.result.documenti[i].img)
        }
        break;

      case 'attachments':
        if (this.item.result.attachments) for (let i = 0; i < this.item.result.attachments.length; i++) {
          this.item.result.attachments[i].url = this.db.get_perizia_result_image_url(this.item.result.attachments[i].img)
        }
        break;

      case 'tires':
        if (this.item.result.tiresData?.tires)
          for (let i = 0; i < this.item.result.tiresData.tires.length; i++) {

            for (let j = 0; j < this.item.result.tiresData.tires[i].img.length; j++) {

              this.item.result.tiresData.tires[i].img[j].url = this.db.get_perizia_result_image_url(this.item.result.tiresData.tires[i].img[j].img)

            }

          }
        break;
    }



    //console.log(this.item.result)

  }

  async deleteAttivita(id: number) {

    if (!confirm('Sei sicuro di voler eliminare questo record?')) return

    let res = await this.db.delete_storico(id)
    console.log(res)

    this.loadStorico()
  }


  loadResult() {
    this.db.get_perizia_result(this.id).then((res: any) => {
      //console.log(res)

      if (!res.data) return;

      this.item.result = res.data.result;

      this.vehType = this.item.result.vehType;

      this.loadPrivacy()


      this.loadResultImageUrls();
    }).catch(err => {
      console.error(err);
    });
  }

  pdf_perizia_clean;
  upsertUrlAllegati() {
    if (this.data && this.data.allegati)
      for (let i = 0; i < this.data.allegati.length; i++) {

        if (this.data.allegati[i].fileName.toLowerCase() == 'pparcella.pdf') {
          this.data.allegati[i].url = this.db.get_perizia_attachment_url(this.data.id, this.data.allegati[i], 'allegati');
          this.pdf_parcel = this.data.allegati[i].url;

        } else if (this.data.allegati[i].fileName.startsWith('SAM_100_') || this.data.allegati[i].fileName.startsWith('360')) {
          this.data.allegati[i].url = this.db.get_perizia_attachment_url(this.data.id, this.data.allegati[i], 'allegati');
          this.img_360 = this.data.allegati[i].url;

        } else if (this.data.allegati[i].fileName.substr(-3).toLocaleLowerCase() == 'adz') {
          this.data.allegati[i].url = this.db.get_print_adz_perizie(+this.data.id, false, this.data.allegati[i].hash, this.db.getIcon() == 'tradeone' && this.item.cliente == 45 ? 'ie' : undefined);
          // tarocco header necessario per richiesta cliente BCC lease

          this.pdf_perizia = this.sanitize(this.data.allegati[i].url);
          this.pdf_perizia_clean = this.data.allegati[i].url
        } else if (this.data.allegati[i].fileName.startsWith('Parcella_')) {
          this.data.allegati[i].url = this.db.perizie_get_parcella(this.data.id, this.item.ricavo_manuale ? this.item.ricavo_manuale : this.item.ricavo);
        } else {
          this.data.allegati[i].url = this.db.get_perizia_attachment_url(this.data.id, this.data.allegati[i], 'allegati');

        }

      }

    if (this.data.adz_data) {

      this.pdf_perizia_clean = this.db.get_print_adz_perizie(+this.data.id, false, '', this.db.getIcon() == 'tradeone' && this.item.cliente == 45 ? 'ie' : undefined);

      this.pdf_perizia = this.sanitize(this.pdf_perizia);


    }

    //console.log('360: ' + this.img_360)

    //console.log('BACKUP ALLEGATI')
    //console.log(this.attachment_backup)
  }

  manageCommessaFirstRun = true
  manageCommessa($event) {

    //console.log('manageCommessa start')

    if (this.manageCommessaFirstRun && (this.item.id == 0 || !this.item.id)) {

      //console.log('manageCommessa if ok ', this.item)


      this.commessa = undefined
      console.log('loadCommessa', this.commessa)

      // //console.clear()
      console.log('commessa: ' + this.item.commessa)
      console.log(this.item)
      this.db.commesse.get(this.item.commessa).then(res => {

        //console.log('manageCommessa if ok ', res)
        try {
          let c = res[0]

          c.etax = +c.etax > 0

          this.commessa = { ...c }
          //console.log('loadCommessa', this.commessa)

          this.loadIspettori()


          //console.log('ccc', c)
          //console.log('manageCommessa allegati ', c)
          if (c.allegati)
            c.allegati.forEach(e => {

              e.type = "da commessa"
              e.url = this.db.commesse.get_attachment_url(c.id, e, 'allegati')
              this.item.allegatiIncarico.push(e)
              //console.log('manageCommessa allegati ', this.item.allegatiIncarico)

            });

          this.manageCommessaFirstRun = false

        } catch (error) {
          this.commessa = undefined
          console.error(error)
          console.log('loadCommessa', this.commessa)
        }

      })
    }


  }

  downloadInter(event) {

    let url = this.db.perizie_download_inter(event.data.user, event.data.dta, event.data.description)


    window.open(url, '_blank');

    //console.log(event)
  }

  loadStorico() {
    this.db.list_storico_perizia(this.item.id).then(res => {
      this.storico = res.map(e => {

        e.dta = moment(e.dta).utc(true)

        return e
      });
    })
      .catch(err => {
        console.error(err);
      });
  }

  getAttivita() {
    return this.storico.filter(e => e.sts != 'AGGIORNAMENTO DATI')
  }



  loadEmpty() {

    //console.log('loadEmpty')

    this.data = {
      targa: '',
      telaio: '',
      stato: this.db.getStatiPeriziaAll()[0],
      tipoVeicolo: '',
      marca: '',
      annoImmatricolazione: '',
      modello: '',
      versione: '',
      deposito: '',
      tipo: this.type == 'ssu' ? 'Stato d\'uso' : this.type == 'tradein' ? 'tradein' : this.type == 'uxpert' ? 'uxpert' : this.type == 'unipolvp' ? 'unipolvp' : '',
      nPerizia: '',
      km: 0,
      provincia: '',
      citta: '',
      cap: '',
      indirizzo: '',
      noteCarico: '',

      sinistro_numero: '',
      partita_danno: 0,
      sinistro_mandante: '',
      sinistro_data: null,
      sinistro_targaAssicurato: '',
      sinistro_nomeAssicurato: '',

      sinistro_provincia: '',
      sinistro_citta: '',
      sinistro_cap: '',
      sinistro_indirizzo: '',

      prodotto_polizza: '',
      numero_polizza: '',
      danneggiato_cf: '',

      danneggiato_nome: '',
      danneggiato_contatto: '',
      danneggiato_provincia: '',
      danneggiato_citta: '',
      danneggiato_cap: '',
      danneggiato_indirizzo: '',
      danneggiato_telefono: '',
      danneggiato_fax: '',
      danneggiato_email: '',
      danneggiato_pec: '',

      patrocinatore_nome: '',
      patrocinatore_contatto: '',
      patrocinatore_telefono: '',
      patrocinatore_provincia: '',
      patrocinatore_citta: '',
      patrocinatore_cap: '',
      patrocinatore_indirizzo: '',
      patrocinatore_fax: '',
      patrocinatore_email: '',
      patrocinatore_pec: '',

      allegatiIncarico: [],

      allegati: [],

      quotazioneIntero: 0,
      quotazioneVendita: 0,
      dataImmatricolazione: null,

      messaggio_cliente: '',
      messaggio_perito: '',

      creatoDa: '', assegnatoA1: '', assegnatoA2: '',

      dtaAppuntamento: null,
      cliente: null,

      result: null,

      adz_data: null,

      timeRequired: 0,

      riparatore_nome: '',
      riparatore_tel: '',
      riparatore_email: '',

    };


    this.item = this.data;

  }


  onLinkClick(e) {

    console.log(e)
    console.log(this.tab)

    this.selectedTab_name = e.tab.textLabel;


    //if (this.selectedTab_name == 'Luogo sinistro') this.loadMap();
    if (this.selectedTab_name == 'Documenti incarico') this.docviewer_position = 'center';
    if (this.selectedTab_name == 'Doc Perizia') this.docviewer_position = 'center';

    this.loadEvents()
  }

  loadIspettori() {

    //console.log('load isp', 'list_user_by_type')
    //console.log('loadIspettori', this.commessa)
    this.db.list_user_by_type('ISPETTORE', undefined, this.commessa?.gruppoIspettore)
      .then(res => {
        //console.clear()
        //console.log('isp', res)
        this.ispettori = res;
        this.sortIspettori()
      })
      .catch(err => {
        console.error('isp', err);
      })
  }

  sortIspettori() {

    let p = this.item.provincia.replace('MICM', 'MI')
    //console.clear()
    //console.log('Sort ispettori ' + p)


    this.ispettori = this.ispettori.sort((a, b) => {

      if (a.province.includes(p) && !b.province.includes(p)) return -1
      if (b.province.includes(p) && !a.province.includes(p)) return 1

      return `${a.nome} ${a.cognome}` > `${b.nome} ${b.cognome}` ? 1 : -1

    })
  }

  loadSalaControllo() {
    this.db.list_user_by_type('SALACONTROLLO')
      .then(res => {
        this.salacontrollo = res;
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadProvince() {
    this.db.list_province()
      .then(res => {
        this.province = res;
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadComuniIspezione() {
    this.db.list_comuni(this.item.provincia)
      .then(res => {
        this.comuniIspezione = res;
        this.selectComuneIspezioneChange(null);
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadComuniDanneggiato() {
    this.db.list_comuni(this.item.danneggiato_provincia)
      .then(res => {
        this.comuniDanneggiato = res;
        this.selectComuneDanneggiatoChange(null);
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadComuniPatrocinatore() {
    this.db.list_comuni(this.item.patrocinatore_provincia)
      .then(res => {
        this.comuniPatrocinatore = res;
        this.selectComunePatrocinatoreChange(null);
      })
      .catch(err => {
        console.error(err);
      })
  }


  loadComuniSinistro() {
    this.db.list_comuni(this.item.sinistro_provincia)
      .then(res => {
        this.comuniSinistro = res;
        this.selectComuneSinistroChange(null);
      })
      .catch(err => {
        console.error(err);
      })
  }




  selectProvinciaIspezioneChange(e) {
    this.loadComuniIspezione();
  }

  selectComuneIspezioneChange(e) {

    this.capIspezione = [];

    for (let i = 0; i < this.comuniIspezione.length; i++) {
      if (this.comuniIspezione[i].nome == this.item.citta) {
        this.capIspezione = this.comuniIspezione[i].cap;
        break;
      }
    }

    if (this.capIspezione.length == 1) {
      this.item.cap = this.capIspezione[0];
    }
  }


  selectProvinciaDanneggiatoChange(e) {
    this.loadComuniDanneggiato();
  }

  selectComuneDanneggiatoChange(e) {

    this.capDanneggiato = [];

    for (let i = 0; i < this.comuniDanneggiato.length; i++) {
      if (this.comuniDanneggiato[i].nome == this.item.danneggiato_citta) {
        this.capDanneggiato = this.comuniDanneggiato[i].cap;
        break;
      }
    }

    if (this.capDanneggiato.length == 1) {
      this.item.danneggiato_cap = this.capDanneggiato[0];
    }
  }

  selectProvinciaPatrocinatoreChange(e) {
    this.loadComuniPatrocinatore();
  }

  selectComunePatrocinatoreChange(e) {

    this.capPatrocinatore = [];

    for (let i = 0; i < this.comuniPatrocinatore.length; i++) {
      if (this.comuniPatrocinatore[i].nome == this.item.patrocinatore_citta) {
        this.capPatrocinatore = this.comuniPatrocinatore[i].cap;
        break;
      }
    }

    if (this.capPatrocinatore.length == 1) {
      this.item.patrocinatore_cap = this.capPatrocinatore[0];
    }
  }


  selectProvinciaSinistroChange(e) {

    //console.log('selectProvinciaSinistroChange', e)


    this.item.sinistro_citta = '';
    this.item.sinistro_cap = '';
    this.item.sinistro_indirizzo = '';

    this.loadComuniSinistro();
    //this.loadMap();
  }

  selectComuneSinistroChange(e) {

    //console.log('selectComuneSinistroChange', e)


    this.capSinistro = [];

    for (let i = 0; i < this.comuniSinistro.length; i++) {
      if (this.comuniSinistro[i].nome == this.item.sinistro_citta) {
        this.capSinistro = this.comuniSinistro[i].cap;
        break;
      }
    }

    if (this.capSinistro.length == 1) {
      this.item.sinistro_cap = this.capSinistro[0];
    }

    //this.loadMap();
  }


  etax_search_targa() {

    if (!confirm("Attenzione, questa operazione ha un costo di 0,20€\n\n\n *** TARGA: " + this.item.targa + " *** \n\n\nSi desidera procedere?")) return

    this.db.etax.search_plate(this.item.targa)
      .then((res: any) => {
        // console.log(res)

        this.item.targa = this.item.targa.trim().toUpperCase()
        this.item.tipoVeicolo = res.tipo_veicolo
        this.item.marca = res.marca[0].acronimo
        this.item.modello = res.modelli[0].cod_gamma_mod
        this.item.telaio = res.telaio

        this.item.annoImmatricolazione = (res.data_imm as string).substr(-4)

      })
      .catch(err => alert(JSON.stringify(err)))
  }

  etax_calcola() {

    //console.log(this.item)

    if (!this.item.versione || this.item.versione == '') {
      alert("Attenzione, è necessario selezionare una versione per calcoalre la valutazione")
      return
    }

    if (!this.item.km || this.item.km <= 0) {
      alert("Attenzione, è necessario inserire i km per calcolare la valutazione chilometrica")
      return
    }

    if (!confirm("Attenzione, questa operazione ha un costo di 1,00€, si desidera procedere?")) return


    this.db.etax.get_valutazione(this.item.versione, this.item.tipoVeicolo, this.item.annoImmatricolazione, 1, this.item.km).then(res => {
      console.log(res)
      this.item.valutazione_etax = res
    })
      .catch(err => alert(JSON.stringify(err)))

  }


  loadMarche() {

    if (!this.item.tipoVeicolo) this.item.tipoVeicolo = ''
    this.db.etax.list_marche(this.item.tipoVeicolo)
      .then(res => {
        this.marche = res;


      })
      .catch(err => {
        console.error(err);
      })
  }

  loadAnni() {
    this.db.etax.list_anni(this.item.marca)
      .then(res => {
        this.anni = res;
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadModelli() {
    this.db.etax.list_modelli(this.item.marca, this.item.annoImmatricolazione, this.item.tipoVeicolo)
      .then(res => {
        //console.log(res)
        this.modelli = res;
      })
      .catch(err => {
        console.error(err);
      })
  }
  spinner = false
  loadVersioni() {
    this.spinner = true
    this.db.etax.list_versioni(this.item.marca, this.item.annoImmatricolazione, this.item.modello, this.item.tipoVeicolo)
      .then(res => {
        this.versioni = res;
        this.spinner = false
      })
      .catch(err => {
        console.error(err);
      })

  }
  selectTipoChange(e) {
    this.loadMarche();

    this.marche = []
    this.anni = []
    this.modelli = []
    this.versioni = []

  }


  selectMarcaChange(e) {
    this.loadAnni();
    this.modelli = []
    this.versioni = []
  }

  selectAnnoChange(e) {
    this.loadModelli();
    this.versioni = []
  }

  selectModelloChange(e) {
    this.loadVersioni();
  }

  isMSIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      let r = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      //console.log(r);
      return r;
    }
  }

  setAsWreck() {
    this.item.cliente = '7';
    this.save(false, null, true);
    this.db.notifyWreck(this.item.targa, this.item.id).then(res => console.log(res)
    ).catch(err => console.error(err))
  }

  close() {

    this.s.unsubscribe();
    this.location.back();

    console.log('getState', this.location.getState())
    console.log('path', this.location.path())
    //this.router.navigate(['/automotive/' + this.type])
    //this.dialogRef.close();
  }

  openInvitoDialog() {

    let dest = []
    if (this.item.danneggiato_email) dest.push({
      name: this.item.danneggiato_nome,
      dest_type: 'danneggiato',
      ref: this.item.danneggiato_email
    })
    if (this.item.danneggiato_telefono) dest.push({
      name: this.item.danneggiato_nome,
      dest_type: 'danneggiato',
      ref: this.item.danneggiato_telefono
    })
    if (this.item.patrocinatore_email) dest.push({
      name: this.item.patrocinatore_nome,
      dest_type: 'patrocinatore',
      ref: this.item.patrocinatore_email
    })
    if (this.item.patrocinatore_telefono) dest.push({
      name: this.item.patrocinatore_nome,
      dest_type: 'patrocinatore',
      ref: this.item.patrocinatore_telefono
    })


    const dialogRef = this.matDialog.open(ListInvitoDialogComponent, {
      width: '40%',
      data: dest
    });

    dialogRef.afterClosed().subscribe(async res => {

      console.log('item', this.item)

      if (!res || res == '') return;

      if (!this.item.interlocutorie) this.item.interlocutorie = []

      let society_config
      await this.db.impostazioni.get('society_config').then(res => {
        console.log(res)

        society_config = res[0].valore
      })

      let user
      await this.db.get_current_user_profile().then(res => {
        console.log('user', res)
        user = res[0]
      })

      let client
      await this.db.anagrafica.get(this.item.cliente).then(res => {
        console.log('client', res)
        client = res[0]
      })


      if (!society_config) society_config = ({} as any)
      if (!user) user = ({} as any)
      if (!client) client = ({} as any)

      let tmp: IInterlocutoria = {
        dta: new Date(),
        user: this.userService.getEmail(),
        tipo: 'invito',
        rcpt_name: res.name,
        rcpt_ref: res.ref,
        rcpt_type: res.type,
        rcpt_dest_type: res.dest_type
      }

      let tel = client.rif_int_tel && client.rif_int_tel != '' ? client.rif_int_tel : society_config.tel
      let email = client.rif_int_tel && client.rif_int_email != '' ? client.rif_int_email : society_config.email


      switch (tmp.rcpt_type) {

        case 'SMS':

          let msg = `Abbiamo ricevuto incarico di periziare il veicolo ${this.item.targa} riguardo il sinistro ${this.item.sinistro_numero}. La contatteremo dal numero ${tel} per l’ispezione
        `


          this.db.sms.send('+39' + tmp.rcpt_ref.replace('+39', ''), msg, 'X-pert', 1)
            .then(res => {
              console.log(res)

              this.item.interlocutorie.push(tmp)
              //this.loadInterlocutorie()

              this.save(false)

            })
          break;

        case 'PEC':
        case 'EMAIL':

          let text = `Buongiorno,
          Siamo ${society_config.ragioneSociale} società incaricata da ${client.ragioneSociale} di ispezionare il veicolo targato ${this.item.targa} relativamente al sinistro ${this.item.sinistro_numero}

          La contatteremo dal numero ${society_config.tel} per organizzare l’ispezione.
          Cordiali saluti
          
          ${society_config.ragioneSociale}

          `.replace("\t", '')
          let html = text.replace("\n", "<br>\n")

          let msg1 = {
            recipientEmail: tmp.rcpt_ref,
            senderName: 'Xpert',
            subject: 'Invito a perizia - ' + this.item.targa,
            text: text,
            html: html,
            attachments: [],
            pec: tmp.rcpt_type == 'PEC' ? 1 : 0,
            bcc: '', cc: ''
          }


          this.db.send_mail(msg1).then(res => {
            if (res.status != 'OK') {
              alert(JSON.stringify(res))
              return
            }
          })


          this.item.interlocutorie.push(tmp)
          //this.loadInterlocutorie()

          this.save(false)
          break;

      }





    })

  }

  openInterDialog() {

    const dialogRef = this.matDialog.open(ListInterDialogComponent, {
      width: '40%',
      data: [
        { msg: 'Il periziando/patrocinatore non risponde ai tentativi di contatto' },
        { msg: 'I contatti forniti non permettono di comunicare con il periziando/patrocinatore' },
        { msg: 'Abbiamo appuntamento in data $DATA$ per le ore $ORARIO$', dtaAppuntamentoRequired: true },
        { msg: 'L’appuntamento fissato in data (se presente proporre appuntamento in calendario) $DATA$ alle ore $ORARIO$ è stato disatteso' },
        { msg: 'Il periziando/patrocinatore non ha disponibilità nel breve termine per procedere ad ispezione.' },
        { msg: 'Siamo in attesa di disponibilità del periziando/patrocinatore.' },
        { msg: 'Altro...', textRequired: true }
      ]
    });

    dialogRef.afterClosed().subscribe(res => {

      if (!res || res == '') return;

      let tmp: IInterlocutoria = {
        dta: new Date(),
        user: this.userService.getEmail(),
        tipo: 'interlocutoria',
        description: res.msg
      }


      if (res.dtaAppuntamento) this.item.dtaAppuntamento = res.dtaAppuntamento

      if (!this.item.interlocutorie) this.item.interlocutorie = []
      this.item.interlocutorie.push(tmp)

      this.item.stato = 'RICHIESTA INTERLOCUTORIA'
      this.save(false)

      this.loadInterlocutorie()

    })

  }

  async loadCentro() {

    let c = await this.db.unipolrental_get_centri(this.item.codiceCentro)
    //console.log('loadCentro', c)

    this.centro = c.item
    //console.log('loadCentro', this.centro)
  }



  loadCentri() {
    this.db.unipolrental_list_centri().then(async res => {

      res = res.map(e => {
        e.address = `${e.COMUNE}, ${e.CAP}, ${e.INDIRIZZO_RESIDENZA}`
        e.contact = `Tel.: ${e.TELEFONO} -  Email: ${e.INDIRIZZO_EMAIL}`
        e.title = e.RAGIONE_SOCIALE
        e.icon = e.Tipologia == 'UR' ? '/assets/img/markers/marker_green.png' : '/assets/img/markers/marker_blue.png'
        let note = "<table>"

        if (e.orari) for (const ex of e.orari) {

          let x = `<tr>
                      <td style="padding-right:15px!important">${getWeekDay(ex.weekDay)}</td>
                      <td style="padding-right:15px!important">
                        ${ex.hhStart.substring(0, 2)}:${ex.hhStart.substring(2, 4)} - 
                        ${ex.hhEnd.substring(0, 2)}:${ex.hhEnd.substring(2, 4)}
                      </td> 
                      <td>(${getType(ex.type)})</td>
                   </tr>`
          note += x
        }

        note += "</table>"

        e.note = note + "<br><br>" + e.note

        return e
      })


      let geocoder = new google.maps.Geocoder();

      for (const e of res) {

        /////////////////////// FIX MISSING GEO ///////////////////////
        if (!e.lat || !e.lng) {

          try {

            await geocoder.geocode({ 'address': e.address }, async (results, status) => {
              if (status == 'OK') {
                let location = results[0].geometry.location
                await this.db.unipolrental_set_coords_centri(e.CODICE_AZIENDALE_UNITA, location.lat(), location.lng())
              } else {
                console.warn(e)
              }
            });

          } catch (error) {
            console.error(error)
          }
        }
      }
      //////////////////////////////////////////////
      this.centri = res.sort((a, b) => a.RAGIONE_SOCIALE > b.RAGIONE_SOCIALE ? 1 : -1)
    })
  }

  centri = []
  openMapSelector() {


    if (this.item.dtaAppuntamento) {
      alert("Attenzione, non è possibile modificare il centro per un appuntamento già fissato")
      return
    }

    if ((this.item as any).ServizioRiconsegna.toLocaleLowerCase() == 'presso sede cliente') {
      if (!confirm('ATTENZIONE, per questo contratto è attivo il servizio di riconsegna presso la sede del cliente, sei sicuro di voler continuare?')) return
    }


    this.db.unipolrental_list_centri().then(async res => {

      res = res.map(e => {
        e.address = `${e.COMUNE}, ${e.CAP}, ${e.INDIRIZZO_RESIDENZA}`
        e.contact = `Tel.: ${e.TELEFONO} -  Email: ${e.INDIRIZZO_EMAIL}`
        e.title = e.RAGIONE_SOCIALE
        e.icon = e.Tipologia == 'UR' ? '/assets/img/markers/marker_green.png' : '/assets/img/markers/marker_blue.png'
        let note = "<table>"

        console.log(e)
        if (e.orari) for (const ex of e.orari) {
          let x
          try {
            x = `<tr>
                        <td style="padding-right:15px!important">${getWeekDay(ex.weekDay)}</td>
                        <td style="padding-right:15px!important">
                          ${ex.hhStart.substring(0, 2)}:${ex.hhStart.substring(2, 4)} - 
                          ${ex.hhEnd.substring(0, 2)}:${ex.hhEnd.substring(2, 4)}
                        </td> 
                        <td>(${getType(ex.type)})</td>
                     </tr>`

          } catch (error) {
            console.log(error, ex)
          }
          note += x
        }

        note += "</table>"

        e.note = note + "<br><br>" + e.note

        return e
      })


      let geocoder = new google.maps.Geocoder();

      for (const e of res) {

        /////////////////////// FIX MISSING GEO ///////////////////////
        if (!e.lat || !e.lng) {

          try {

            await geocoder.geocode({ 'address': e.address }, async (results, status) => {
              if (status == 'OK') {
                let location = results[0].geometry.location
                await this.db.unipolrental_set_coords_centri(e.CODICE_AZIENDALE_UNITA, location.lat(), location.lng())
              } else {
                console.warn(e)
              }
            });

          } catch (error) {
            console.error(error)
          }
        }
      }
      //////////////////////////////////////////////
      this.centri = res.sort((a, b) => a.RAGIONE_SOCIALE > b.RAGIONE_SOCIALE ? 1 : -1)
      const dialogRef = this.matDialog.open(MapSelectorComponent, {
        width: '800px',
        data: { list: this.centri, search: this.item.citta }
      });

      dialogRef.afterClosed().subscribe(async res => {
        //console.clear()
        console.log(res)

        this.item.codiceCentro = res.CODICE_AZIENDALE_UNITA
        this.item.provincia = res.PROVINCIA_RESIDENZA
        this.item.citta = res.COMUNE
        this.item.cap = res.CAP
        this.item.indirizzo = res.INDIRIZZO_RESIDENZA
        this.item.noteCarico = res.RAGIONE_SOCIALE
        this.item.location_lat = +res.lat
        this.item.location_lon = +res.lng


        this.sortIspettori()


        this.resetFiliale()




        for (const c of this.contatti_source.filter(e => e.ruolo == 'CENTRO'))
          await this.db.perizie_contatti_delete(c.id).catch(err => console.error(err))

        let c_cliente: IContattoPerizia = {
          idPerizia: this.item.id,
          ruolo: 'CENTRO',
          nome: res.RAGIONE_SOCIALE,
          cognome: '',
          email: res.INDIRIZZO_EMAIL,
          telefono: res.TELEFONO,
          inviaMail: true
        }
        await this.db.perizie_contatti_upsert(c_cliente).catch(err => console.error(err))

        this.loadContatti()
        this.loadCentro()

        this.sortIspettori()

        this.showLocationSearch = false
        setTimeout(() => {
          this.showLocationSearch = true
        }, 10);


      });


    })

  }

  async resetFiliale() {


    if (this.item.tipo != 'unipolrental') return

    for (const e of this.contatti_source.filter(e => e.ruolo == 'FILIALE')) {
      this.db.perizie_contatti_delete(e.id)
    }
    let c_filiale: IContattoPerizia = {
      idPerizia: this.item.id,
      ruolo: 'FILIALE',
      nome: getFiliale(this.item.provincia)?.provFiliale,
      cognome: '',
      email: getFiliale(this.item.provincia)?.email,
      telefono: '',
      inviaMail: true
    }

    if (c_filiale.email != '')
      await this.db.perizie_contatti_upsert(c_filiale).catch(err => console.error(err))
    this.loadContatti()
  }

  openDepotDialog() {

    this.db.anagrafica.get(this.item.cliente).then(res => {

      //console.log('mycust: ', res)

      //console.log(res[0].depositi)

      const dialogRef = this.matDialog.open(ListDepotDialogComponent, {
        width: '800px',
        data: res[0].depositi
      });

      dialogRef.afterClosed().subscribe(res => {
        //console.log('update', res)

        this.item.provincia = res.provincia;
        this.item.citta = res.citta;
        this.item.cap = res.cap;
        this.item.indirizzo = res.indirizzo;
        this.item.noteCarico = res.note;

        this.item.location_lat = res.location_lat;
        this.item.location_lon = res.location_lon;

        this.item.assegnatoA1 = ''
        this.item.assegnatoA2 = res.ispettoreAssociato

        this.selectProvinciaIspezioneChange(null);
        this.selectComuneIspezioneChange(null);

        this.manageStatus('assegnatoA2', '')
      });


    })

  }


  setCostoRicavo() {
    try {


      let cr = this.commessa.costi_ricavi.find(e => e.vehType == this.item.tipoVeicolo && (e.documentale == 'true' == (this.item.documentale == 1 || this.item.documentale == '1' || this.item.documentale == 'true')))
      if (cr) {
        console.log('setCostoRicavo specific')
        this.item.costo = cr.costo
        this.item.ricavo = cr.ricavo
        console.log('setCostoRicavo', cr)
        return
      }

    } catch (error) {

    }

    try {

      let cr = this.commessa.costi_ricavi.find(e => e.vehType == 'tutti' && (e.documentale == 'true' == (this.item.documentale == 1 || this.item.documentale == '1' || this.item.documentale == 'true')))
      if (cr) {
        console.log('setCostoRicavo all')
        this.item.costo = cr.costo
        this.item.ricavo = cr.ricavo
        console.log('setCostoRicavo', cr)
        return
      }

    } catch (error) {

    }

  }

  documentaleChange(event) {
    //console.log(event, this.item.documentale)
  }


  saveModule() {
    //console.clear()

    for (let i = 1; i <= 5; i++) {

      if (this.item['modulo' + i] && this.item['formVal' + i]) {

        for (const row of this.item['modulo' + i].rows) {
          for (const col of row.cols) {
            console.log(col)

            switch (col.type) {
              case 'input-img':

                console.log(this.item['formVal' + i][col.id])

                if (this.item['formVal' + i][col.id] &&
                  this.item['formVal' + i][col.id].img &&
                  this.item['formVal' + i][col.id].img.fileContent &&
                  this.item['formVal' + i][col.id].img.fileContent != '') {

                  /// TODO - salvare fileContent come allegato


                }

                break

              default: ;
            }
          }
        }

        console.log(this.item['modulo' + i])
        console.log(this.item['formVal' + i])

      }
    }


    //this.save(false)
  }

  async save(closeWin: boolean = true, page: string = null, skipPermissionCheck = false, confirmAppointMentDate = undefined) {

    if (this.loading) {
      console.log('loading')
      return;
    }



    console.log('skipPermissionCheck: ' + skipPermissionCheck)
    console.log('permission.permitStatusChange: ' + this.permission.permitStatusChange)
    console.log('permission.permitted: ' + this.permission.permitted)
    console.log('userCompetence: ' + this.userCompetence)

    if (!skipPermissionCheck) if (!this.permission.permitStatusChange && (!this.permission.permitted || !this.userCompetence)) {

      if (page == 'prev') this.prevInList();
      if (page == 'next') this.nextInList();

      console.warn('not permitted')
      return
    };

    if (this.form.invalid) {
      console.warn('form invalid')
      return;
    }


    if ((this.item.costo_manuale || this.item.ricavo_manuale) && (!this.item.descrizione_cambio_tariffa || this.item.descrizione_cambio_tariffa == '')) {
      alert("Attenzione, il campo 'Descrizione Cambio Tariffa' è obbligatorio")
      return;
    }




    if (this.errorClaimNr) {
      alert('Attenzione, numero di sinistro già presente');
      return;
    }


    this.setCostoRicavo()

    console.log('save', this.item)

    this.loading = true;

    /*
        if ((!this.item.assegnatoA2 || this.item.assegnatoA2 == '') && (!this.item.assegnatoA1 || this.item.assegnatoA1 == '')) {
          await this.autoAssign();
        }
    */

    /* ////// sostituito da procedura dedicata
        /////////////////// SEND VERBALE UNIPOLRENTAL ////////////////////////////////
        if (this.type == 'unipolrental') {
    
          let vdrs = this.item.allegati.filter((e: any) => e.type == "Verbale di riconsegna" && !e.sent)
    
          if (vdrs.length > 0) {
    
            //if (confirm('Sei sicuro di voler inviare i VDR allegati (' + vdrs.length + ')? Si prega di verificare con attenzione')) {
    
    
            let recipients = []
    
            let drivers: IContattoPerizia[] = this.contatti_source.filter(e => e.ruolo == 'DRIVER')
            let fleetmanagers: IContattoPerizia[] = this.contatti_source.filter(e => e.ruolo == 'FLEET MNGR')
            let centriMAD: IContattoPerizia[] = this.contatti_source.filter(e => e.ruolo == 'CENTRO-MAD') // || e.ruolo == 'FILIALE' modifica per gestione alternativa email filiale
    
            for (const driver of drivers) {
              if (driver && driver.email && driver.email != '') recipients.push(driver.email)
            }
    
            for (const fleetmanager of fleetmanagers) {
              if (fleetmanager && fleetmanager.email && fleetmanager.email != '') recipients.push(fleetmanager.email)
            }
    
    
            for (const centroMAD of centriMAD) {
              if (centroMAD && centroMAD.email && centroMAD.email != '') recipients.push(centroMAD.email)
            }
    
            let centro
            await this.db.unipolrental_get_centri(this.item.codiceCentro).then(res => {
              console.log(res)
              centro = res.item
            }).catch(err => {
              console.error(err)
            })
    
            if (centro?.INDIRIZZO_EMAIL) recipients.push(centro.INDIRIZZO_EMAIL)
    
    
            for (const el of vdrs) {
    
              let elx: any = el
              elx.mime = 'image/jpg'
    
              let fil = getFiliale(this.item.provincia)
              if (!fil) {
                alert("Attenzione, verificare la provincia e riprovare")
                location.reload()
                return
              }
    
              let msg = {
                recipientEmail: recipients.join('; '),
                senderName: 'RICONSEGNE UNIPOLRENTAL',
                subject: 'Verbale di riconsegna per ' + this.item.targa + ' - ' + fil.provFiliale + ':',
                text: 'In allegato il verbale di riconsegna del veicolo',
                html: '',
                attachments: [elx],
                pec: 2,
                bcc: 'secondolivello@unipolrental.it', cc: ['operations.unipolrental@general-group.it;chiusuracontratto@unipolrental.it'].join(';')
              }
    
              await this.db.send_mail(msg).then(res => {
                if (res.status != 'OK') {
                  alert(JSON.stringify(res))
                }
                el.sent = new Date()
              }).catch(err => console.error(err))
    
    
            }
    
            //}
          }
    
        }
        //////////////////////////////////////////////////////////////////////////////
    */



    console.log('upsert_perizia')
    this.db.upsert_perizia(this.item).subscribe((event: any) => {


      //console.log(event)
      // progress
      if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
        const percentage = 100 / event.total * event.loaded;

        //this.loading_percentage = percentage;


        //console.log(percentage);
      }

      // finished
      if (event.type === HttpEventType.Response) {
        this.loading = false;
        if (event.body.status != 'OK') {
          console.error(event.body)
          return;
        }


        if (confirmAppointMentDate) {
          this.db.unipolrental_confirm_appointment(this.item.id, confirmAppointMentDate, getFiliale(this.item.provincia)?.provFiliale).then(async (res: any) => {
            //console.clear()
            console.log(res)

            if (res.status != 'OK') {
              alert(JSON.stringify(res))
              return
            }

            this.item.dtaAppuntamento = confirmAppointMentDate
            this.item.stato = 'INCARICO ACCETTATO'
            /*
                        let vdr = await this.db.unipolrental_get_vdr(this.item.id, true)
                        this.item.allegati.push({
                          creationDate: new Date(),
                          fileContent: vdr,
                          fileName: 'vdr-prestampato.pdf',
                          size: vdr.length,
                          type: 'application/pdf',
                          tag: 'vdr-prestampato'
                        })
            */

            /*
                        let vdr = await this.db.unipolrental_get_vdr(this.item.id, true)
                        let fvdr = {
                          creationDate: new Date(),
                          fileContent: 'data:application/pdf;base64,' + vdr.base64,
                          fileName: 'vdr-prestampato.pdf',
                          size: vdr.length,
                          mime: 'application/pdf',
                          type: 'vdr-prestampato',
                          ext: 'pdf',
            
                        }
                        console.log(fvdr)
                        this.item.allegati.push(fvdr)
            
                        await this.attachmentSave(undefined)
            */
            this.loadEvents()

          })
            .catch(err => {
              console.error(err)
              alert(JSON.stringify(err))
            })
        }

        if (this.type == 'unipolrental' && this.item.adz_data && this.item.adz_data.adz) {
          this.ria.Km = this.item.adz_data.adz.km.val

          let diffAccessori = +this.ria.Doppie_chiavi
            + +this.ria.Carta_di_circolazione
            + +this.ria.Ruota_scorta

          this.ria.Valore_Remarketing = +this.valRiaddebitoAdz - diffAccessori

          this.ria.Valore_perizia_riaddebito = +this.valRiaddebitoAdz

          this.db.unipolrental_set_riaddebito(this.ria)
            .then(res => console.log(res))
            .catch(err => console.error(err))
        }


        if (this.item.stato == 'CONCLUSO' && this.item.tipo == 'unipolrental') {
          this.sendMailRiaddebito()
        }


        console.log(event.body)

        if (closeWin) {
          this.close();
          return;
        }

        if (page == 'prev') {
          this.prevInList();
          return;
        }

        if (page == 'next') {
          this.nextInList();
          return;
        }

        //alert('finished onFinished')
        this.init(this.item.id)

        this.editMode = true;

        console.log(this.item)

      }




    })



    //.catch(err => { this.loading = true; console.error(err) })



  }

  valRiaddebitoAdz
  setValoreRiaddebito(event) {
    //console.log(event)
    this.valRiaddebitoAdz = event
  }

  closeDocviewer() {
    this.docviewer = false;
  }

  openViewer(e, source) {
    //console.log(e)
    this.docviewer_i = 0;
    this.docviewer_source = source

    this.docviewer_double = e.double
    this.docviewer_descr = e.descr

    this.docviewer_i = e.i;
    this.docviewer_filter = e.filter;
    this.docviewer = true;

  }

  openViewerResult(e) {
    console.log(e)

    this.docviewer_i = 0;
    this.docviewer_source = e.source

    this.docviewer_double = e.double
    this.docviewer_descr = e.descr

    this.docviewer_i = e.i;
    this.docviewer = true;

  }

  map: any
  layers = L.layerGroup();








  errorClaimNr = false
  claimNrChange($event) {

    this.db.perizie_claim_nr_exists($event, this.id, this.item.cliente)
      .then((res: any) => {
        //console.log('res', res)
        this.errorClaimNr = res.found > 0
      })
      .catch(err => {
        console.error(err)
      })

  }

  concordatix_settings = {

    actions: {
      add: false,
      edit: false,
      delete: false,
    },

    pager: { perPage: 1000 },

    columns: {

      importo: {
        title: 'Importo',
      },
      dataInvio: {
        title: 'Data Invio',
        valuePrepareFunction: (value) => {
          try {
            return moment(value).format('YYYY/MM/DD HH:MM')
          } catch (error) {

          }
        }
      },

      destinatario: {
        title: 'Destinatario'
      },
      /*
            proposta_accettata: {
              title: 'Accettata',
            },
      */

      /*
            dataRisposta: {
              title: 'Data risp.',
              valuePrepareFunction: (value) => {
                try {
                  return moment(value).format('YYYY/MM/DD HH:MM')
                } catch (error) {
      
                }
              }
            },
      */
    }
  };


  concordatix_source: any; // add a property to the component


  /*
  ////////////////////////////////// PRIVACY ////////////////////////
  concordati_settings = {
  
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
  
    pager: { perPage: 1000 },
  
    columns: {
  
      importo: {
        title: 'Importo',
      },
      dataInvio: {
        title: 'Data Invio',
        valuePrepareFunction: (value) => {
          try {
            return moment(value).format('YYYY/MM/DD HH:MM')
          } catch (error) {
  
          }
        }
      },
  
      destinatario: {
        title: 'Destinatario'
      },
  
      proposta_accettata: {
        title: 'Accettata',
      },
  
      dataRisposta: {
        title: 'Data risp.',
        valuePrepareFunction: (value) => {
          try {
            return moment(value).format('YYYY/MM/DD HH:MM')
          } catch (error) {
  
          }
        }
      },
  
    }
  };
  
  concordati_source: any; // add a property to the component
  
  ////////////////////////////////// PRIVACY /////////////////////// 
  /*
  proposte_settings = {
  
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
  
    pager: { perPage: 1000 },
  
    columns: {
  
      importo: {
        title: 'Importo',
      },
      dataInvio: {
        title: 'Data Invio',
        valuePrepareFunction: (value) => {
          try {
            return moment(value).format('YYYY/MM/DD HH:MM')
          } catch (error) {
  
          }
        }
      },
  
  
      statoSms: {
        title: 'Stato SMS',
      },
  
      dataConsegnaSMS: {
        title: 'Data. cons. sms'
      },
  
      testoSms: {
        title: 'Testo SMS',
        type: 'custom',
        renderComponent: TableInfoComponent,
      },
  
      proposta_accettata: {
        title: 'Accettata',
      },
  
      dataRisposta: {
        title: 'Data risp.',
        valuePrepareFunction: (value) => {
          try {
            return moment(value).format('YYYY/MM/DD HH:MM')
          } catch (error) {
  
          }
        }
      },
  
    }
  };
  
  proposte_source: any; // add a property to the component*/


  ////////////////////////////////// LOGS ////////////////////////


  ////////////////////////////////// PRIVACY ////////////////////////
  privacy_settings = {

    actions: {
      add: false,
      edit: false,
      delete: false,
    },

    pager: { perPage: 1000 },

    columns: {

      source: {
        title: 'Sorgente',
      },
      type: {
        title: 'Tipo'
      },
      date: {
        title: 'Data',
        valuePrepareFunction: (value) => {
          try {
            return moment(new Date(value.seconds * 1000)).format('YYYY/MM/DD HH:MM')
          } catch (error) {

          }
        }
      },
      ok: {
        title: 'Consenso',
        type: 'html',
        valuePrepareFunction: (value) => { return +value > 0 ? '<span class="material-icons green"> check_circle </span>' : '<span class="material-icons red"> error </span>' }
      },

    }
  };

  privacy_source: any; // add a property to the component


  ////////////////////////////////// LOGS ////////////////////////
  // logs_settings = {

  //   actions: {
  //     add: false,
  //     edit: false,
  //     delete: false,
  //   },

  //   pager: { perPage: 1000 },

  //   columns: {

  //     id: {
  //       title: 'ID',
  //     },
  //     user: {
  //       title: 'Utente'
  //     },
  //     dta: {
  //       title: 'Data'
  //     },
  //     field: {
  //       title: 'Campo',
  //     },
  //     before: {
  //       title: 'Prima',
  //       valuePrepareFunction: (value: string) => { return value ? ('' + value).substr(0, 50) : '' }
  //     },
  //     after: {
  //       title: 'Dopo',
  //       valuePrepareFunction: (value: string) => { return value ? ('' + value).substr(0, 50) : '' }
  //     },
  //   }
  // };

  logs_settings = {
    domLayout: 'autoHeight', // Adjust based on your preference

    defaultColDef: {
      resizable: true,
    },

    suppressPaginationPanel: true, // Hides pagination panel

    columnDefs: [
      { headerName: 'ID', field: 'id' },
      { headerName: 'Utente', field: 'user' },
      { headerName: 'Data', field: 'dta' },
      { headerName: 'Campo', field: 'field' },
      {
        headerName: 'Prima',
        field: 'before',
        valueFormatter: function (params) {
          return params.value ? ('' + params.value).substr(0, 50) : '';
        },
      },
      {
        headerName: 'Dopo',
        field: 'after',
        valueFormatter: function (params) {
          return params.value ? ('' + params.value).substr(0, 50) : '';
        },
      },
    ],

    paginationPageSize: 1000,

  };


  email_settings = {
    domLayout: 'autoHeight', // Adjust based on your preference

    defaultColDef: {
      resizable: true,
    },

    suppressPaginationPanel: true, // Hides pagination panel

    columnDefs: [
      //{ headerName: 'ID', field: 'id' },
      { headerName: 'Data', field: 'creationDate' },
      { headerName: 'Destinatari', field: 'recipientEmail' },
      { headerName: 'CC', field: 'cc' },
      { headerName: 'Oggetto', field: 'subject' },
      { headerName: 'Inviata Il', field: 'sentDate' },
      { headerName: 'N° Errori', field: 'errorCount' },
      { headerName: 'Descr. Errori', field: 'errorMessage' },
    ],

    paginationPageSize: 1000,

  };

  // Example usage in Angular component:
  // this.gridOptions.api.setRowData(yourDataArray);


  logs_source: any; // add a property to the component
  email_source: any

  ////////////////////////////////// INVITI ////////////////////////
  inviti_settings = {

    actions: {
      add: true,
      edit: false,
      delete: false,
    },

    mode: 'external',

    add: {
      addButtonContent: '<i class="material-icons" style="display:inline">add</i>',
      createButtonContent: '<i class="checkmark" style="display:inline"></i>',
      cancelButtonContent: '<i class="close" style="display:inline"></i>',
    },

    edit: {
      editButtonContent: '<i class="material-icons" style="display:inline">picture_as_pdf</i>',
      saveButtonContent: '<i class="checkmark style="display:inline""></i>',
      cancelButtonContent: '<i class="close" style="display:inline"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="material-icons" style="display:inline">delete</i>',
      confirmDelete: true,
    },

    pager: { perPage: 100 },

    columns: {

      dta: {
        title: 'Data', type: 'date',
        valuePrepareFunction: (date) => {
          if (date) {
            return new DatePipe('it_IT').transform(date, 'dd/MM/yyyy hh:mm');
          }
          return null;
        },
      },

      rcpt_name: {
        title: 'Nominativo',
      },
      rcpt_type: {
        title: 'Tipo',
      },

      rcpt_dest_type: {
        title: 'Tipo dest.',
      },

      rcpt_ref: {
        title: 'Ref.',
      },

    }
  };

  inviti_source: any; // add a property to the component


  ////////////////////////////////// INTER ////////////////////////
  inter_settings = {

    actions: {
      add: this.userService.getRole() != 'CLIENTE',
      edit: true,
      delete: false,
    },

    mode: 'external',

    add: {
      addButtonContent: '<i class="material-icons" style="display:inline">add</i>',
      createButtonContent: '<i class="checkmark" style="display:inline"></i>',
      cancelButtonContent: '<i class="close" style="display:inline"></i>',
    },

    edit: {
      editButtonContent: '<i class="material-icons" style="display:inline">picture_as_pdf</i>',
      saveButtonContent: '<i class="checkmark style="display:inline""></i>',
      cancelButtonContent: '<i class="close" style="display:inline"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="material-icons" style="display:inline">delete</i>',
      confirmDelete: true,
    },

    pager: { perPage: 100 },

    columns: {

      dta: {
        title: 'Data', type: 'date',
        valuePrepareFunction: (date) => {
          if (date) {
            return new DatePipe('it_IT').transform(date, 'dd/MM/yyyy hh:mm');
          }
          return null;
        },
      },
      description: {
        title: 'Motivazione',

        valuePrepareFunction: (value: string) => { return value.substr(0, 50) },
      },


      confirmed: {
        title: 'Confermato',
        type: 'custom',
        renderComponent: TableConfirmButton,
        onComponentInitFunction: (instance) => {
          instance.onClick.subscribe(data => {
            this.confirm(data)
          })
        },

      },

    }
  };

  inter_source: any; // add a property to the component

  confirm(data) {

    let rowData = data.rowData;





    if (rowData.value) {
      if (!confirm("Confermare l'invio dell'interlocutoria al cliente?")) return
      rowData.confirmed = new Date()
      this.item.stato = 'INTERLOCUTORIA'
    } else {
      if (!confirm("Declinare l'invio dell'interlocutoria al cliente?")) return
      rowData.confirmed = 'rejected'
      this.item.stato = 'INCARICO ACCETTATO'
    }
    console.log(this.item.interlocutorie)

    //this.loadInterlocutorie()


    this.save(false)

  }

  loadLogs() {
    this.db.list_logs_perizia(this.item.id).then(res => {
      this.logs_source = res;; // create the source
      //this.logs_source = res
    }).catch(err => console.error(err))

  }


  loadEmail() {
    this.db.perizie_email_list(this.item.id).then(res => {
      console.log('res: ', res);
      this.email_source = res?.list?.filter(e => e.recipientEmail || e.cc)
        .filter(e => !e.subject?.includes(' => ')).map(e => { e.send = 1; return e }); // create the source


      this.email_source.push(...res.listReturns)
      this.email_source = this.email_source.sort((a, b) => a.creationDateTz > b.creationDateTz ? 1 : -1)


      //this.logs_source = res
    }).catch(err => console.error(err))

  }

  loadPrivacy() {
    this.privacy_source = (this.item.result.privacy); // create the source
  }


  loadInterlocutorie() {
    if (!this.item.interlocutorie) this.item.interlocutorie = []

    this.inter_source = (this.item.interlocutorie.filter(e => e.tipo == 'interlocutoria' && (this.userService.getRole() != 'CLIENTE' || ((e.confirmed != undefined && e.confirmed != 'rejected'))))); // create the source
    this.inviti_source = (this.item.interlocutorie.filter(e => e.tipo == 'invito' && this.userService.getRole() != 'CLIENTE')); // create the source
  }


  async loadProposte() {
    if (!this.item) return
    if (!this.item.proposte) this.item.proposte = []

    /*
    
     this.proposte_source = (this.item.proposte);
    
        let status_changed = false
        let something_waiting = false
        for (let i = 0; i < this.item.proposte.length; i++) {
    
          if (this.item.proposte[i].statoSms == 'Delivered') continue
    
          something_waiting = true
    
          await this.db.sms.get_status(this.item.proposte[i].idSms)
            .then(res => {
    
    
              try {
    
                let r = res.resp
    
                r = r.split(String.fromCharCode(10))
                let fields = r[0].split(',')
                let values = r[1].split(',')
    
                let rx: any = {}
                for (let j = 0; j < fields.length; j++) {
    
                  rx[fields[j]] = values[j]
    
                }
    
                if (this.item.proposte[i].statoSms == rx.status_text) return
    
                this.item.proposte[i].statoSms = rx.status_text
                this.item.proposte[i].dataConsegnaSMS = rx.timestamp
    
                //this.proposte_source = (this.item.proposte); 
                status_changed = true
    
                console.log(rx)
              } catch (error) {
    
              }
    
            })
            .catch(err => console.error(err))
    
    
    
        }
    
        if (status_changed) this.proposte_source = (this.item.proposte); // create the source
    
        if (something_waiting) setTimeout(() => {
    
          console.log('something_waiting')
    
          this.loadProposte()
        }, 1000);
    
    
    */
  }

  concordato_sel;
  concordato_dest;
  sendConcordato(sel: string) {


    this.concordato_sel = sel
    this.concordato_dest = sel == 'd' ? this.item.danneggiato_email : this.item.riparatore_email


    if (!confirm("Desideri inviare il concordato a: " + this.concordato_dest + " ?")) return

    console.log(`${this.concordato_sel}, ${this.concordato_dest}, ${this.item.importo_concordato}`)

    this.db.perizia_convert_concordato_file(this.concordato_sel, this.concordato_dest, this.item.importo_concordato, this.item.id).then(res => {

      console.log(res)

      if (res.status == 'OK') {
        let tmp = {
          importo: this.item.importo_concordato,
          dataInvio: new Date(),
          destinatario: this.concordato_dest,
          sel_dest: this.concordato_sel,
          //pdf_uscita: res.pdf_data
        }

        this.item.concordati.push(tmp)
        this.save(false)

      } else {

        alert(JSON.stringify(res))

      }



    }).catch(err => {
      console.error(err)
      alert(JSON.stringify(err))
    })


  }

  async loadConcordati() {
    if (!this.item) return
    if (!this.item.concordati) this.item.concordati = []

    this.concordatix_source = (this.item.concordati); // create the source

  }

  sendSMSProposta() {

    let msg = `Visualizza la proposta di quantificazione del danno del veicolo ${this.item.targa} ${this.getPropostaUrl()}`;

    this.db.sms.send('+39' + this.item.danneggiato_telefono.replace('+39', ''), msg, 'X-pert', 1)
      .then(res => {
        console.log(res)
        this.item.proposte.push({

          importo: this.item.importo_proposta,
          dataInvio: new Date(),
          idSms: res.resp.split(' ')[1],
          testoSms: msg

        })

        //this.loadProposte()

        this.save(false)

      })
      .catch(err => {
        console.error(err)
        alert(JSON.stringify(err))
      })
  }

  getPropostaUrl() {
    let url = this.db.URL_BASE.split('/')
    url.pop()
    url.pop()
    url.push(`/#/proposta/${this.item.id}/${MD5(this.item.targa)}`)

    let r = url.join('/');
    return r;
  }

  dxCellTimeout
  slotClick(e: any) {

    const dlClick = async () => {


      if (this.areEmailInvalid) {
        alert('Attenzione, verificare gli indirizzi email')
        return
      }


      if (this.item.indirizzo == '') {
        alert('Attenzione, selezionare prima il luogo dell\'ispezione')
        return
      }

      if (this.item.madTarga != '' && this.contatti_source.filter(e => e.ruolo == 'CENTRO-MAD' && e.email && e.email != '').length == 0) {
        alert('Attenzione, nel caso di MAD è obbligatorio inserire il CENTRO-MAD tra i contatti')
        return
      }

      /*
            // && e.email && e.email != '' --- fix per modifica procedura -> invio email a filiale
            if (this.contatti_source.filter(e => e.ruolo == 'FILIALE').length == 0) {
              alert('Attenzione, è obbligatorio inserire la FILIALE di riferimento tra i contatti')
              return
            }
      */

      let d: Date;
      e.start ? d = e.start : d = e.cellData.startDate

      console.log(d, e.start, e.cellData.startDate)

      ////// verifica se è precedente ai giorni minimi di preavviso centro
      if (this.item.tipo == 'unipolrental' && this.isBeforeStartDate(d)) {
        if (!confirm('Attenzione, questa data è precedente ai termini contrattuali, si desidera procedere comunque?'))
          return
      }


      if (this.isHoliday(d)) {
        alert('Attenzione, questa data è un giorno festivo')
        return
      }

      if (this.item.codiceCentro > 0) {

        let dEnd = moment(d).add(this.item.timeRequired, 'minutes').subtract(1, 'seconds').toDate()
        //console.clear()


        let b1 = this.isDisableDate(d)
        let b2 = this.isDisableDate(dEnd, true)


        if (b1 == 'closed' || b2 == 'closed') {
          if (true || this.userService.getRole() == 'ADMIN') {

            if (!confirm("Attenzione, selezione orario non valida per il centro, desideri continuare comunque?"))
              return

          } else {
            alert("Attenzione, selezione orario non valida per il centro")
            return
          }
        }


        if (b1 == 'call' || b2 == 'call') {
          if (!confirm("Attenzione, il centro richiede la prenotazione per questo orario, desideri procedere?")) return
        }


      }


      if (this.isBeforeContractDate(d) && this.item.stato != 'RICHIESTA AUTORIZZAZIONE' && this.reminders.filter(e => e.categoria == 'AUTH_OK' || e.categoria == 'AUTH_DENIED').length == 0) {

        let isAuth = await this.db.unipolrental_is_already_auth(this.item.targa)

        //alert('isAuth: ' + isAuth)

        console.log(isAuth)

        if (!(+isAuth.isAuthorized > 0)) {

          if (!confirm("Attenzione, il contratto non sarà ancora scaduto in questa data, verrà richiesta autorizazione a UnipolRental, desideri procedere?")) return
          this.richiediConfermaUnipol()
        }

      }

      d = removeTZ(d);



      if (this.type == 'unipolrental') {

        /*
        if (!this.item.assegnatoA2 || this.item.assegnatoA2 == '') {
          alert('Attenzione, è necessario selezionare un ispettore per procedere')
          return
        }
        */

        this.db.unipolrental_add_temporary_appointment(this.item.id, this.item.assegnatoA2, d).then(res => {
          console.log(res)
          this.loadEvents();
        })

      } else {

        this.item.dtaAppuntamento = d

      }

    }


    if (!this.dxCellTimeout) {
      this.dxCellTimeout = setTimeout(() => {
        this.dxCellTimeout = null;
      }, 300);
    }
    else {

      dlClick()

      return;
    }
    e.cancel = true;

  }
  onAppointmentClick(e) {
    e.cancel = true
  }


  async confirmTemporaryAppointment_rollback() {


    if (!this.contatti_source.filter(e => (e.ruolo == 'DRIVER' || e.ruolo == 'FLEET MNGR') && e.email && e.email != '').length) {
      alert('Attenzione, verificare gli indirizzi email di driver e/o fleet manager')
      return
    }

    if (this.item.allegati.filter(e => e.type == 'Verbale di riconsegna').length > 0) {
      alert('Attenzione, non è possibile annullare appuntamenti verbalizzati')
      return
    }

    //console.clear()

    let now = moment().utc(true)
    let dta = moment(this.item.dtaAppuntamento).utc(false)
    let diff = dta.diff(now) / 3600000 // in ore

    console.log(diff)

    if (!confirm("Sei sicuro di voler CANCELLARE un appuntamento già confermato? verrà inviata una mail a tutti gli interessati")) return;


    let datePipe = new DatePipe("it-it")
    let d = datePipe.transform(this.item.dtaAppuntamento, 'dd/MM/yyyy', '+0000')
    let h = datePipe.transform(this.item.dtaAppuntamento, 'HH:mm', '+0000')




    let driver: IContattoPerizia = this.contatti_source.find(e => e.ruolo == 'DRIVER')
    let fleetmanager: IContattoPerizia = this.contatti_source.find(e => e.ruolo == 'FLEET MNGR')



    let recipients = []

    if (isCodCliUnipolRental(this.item.urCodiceCliente)) {
      recipients.push('parcoautoazienda@unipolsai.it') // prima nazarenaveronica riolo, successivamente richiesta modifica da rocco 13/09/2024
    } else {
      if (driver && driver.email && driver.email != '') recipients.push(driver.email)
      if (fleetmanager && fleetmanager.email && fleetmanager.email != '') recipients.push(fleetmanager.email)

    }


    if (recipients.length <= 0) {
      alert("Attenzione, nessun contatto email presente per il ruolo DRIVER o FLEET MANAGER")
      return
    }

    let centro
    await this.db.unipolrental_get_centri(this.item.codiceCentro).then(res => {
      console.log(res)
      centro = res.item
    }).catch(err => {
      console.error(err)
    })

    console.log(centro)
    let ispettore = this.ispettori.find(e => e.email == this.item.assegnatoA2)
    let salacontrollo = this.salacontrollo.find(e => e.email == this.item.assegnatoA1)
    console.log(ispettore)



    let text = `
    Buongiorno,

    si comunica che l'appuntamento previsto il giorno ${d} alle ore ${h} è stato ANNULLATO.
    
    Verrete ricontattati il piu' presto possibile.
    
    Grazie.
    Restituzioni UnipolRental
    Tel. 800 - 936.603  Tasto 3
    servizio.riconsegna@unipolrental.it




    La presente e-mail e' stata generata automaticamente da un indirizzo di posta elettronica di solo invio; 
    si chiede pertanto di non rispondere al messaggio ma scrivere a servizio.riconsegna@unipolrental.it
    `

    let cc = ['servizio.riconsegna@unipolrental.it']
    if (centro?.INDIRIZZO_EMAIL) cc.push(centro.INDIRIZZO_EMAIL)
    if (ispettore?.email2) cc.push(ispettore.email2)
    if (ispettore?.Email2) cc.push(ispettore.Email2)
    if (salacontrollo?.email2) cc.push(salacontrollo.email2)
    if (salacontrollo?.Email2) cc.push(salacontrollo.Email2)




    let msg1 = {
      recipientEmail: recipients.join('; '),
      senderName: 'RICONSEGNE UNIPOLRENTAL',
      subject: 'Incarico di restituzione veicolo UnipolRental targato ' + this.item.targa + ' - ' + getFiliale(this.item.provincia).provFiliale + ':',
      text: text,
      html: '',
      attachments: [],
      pec: 2,
      bcc: 'secondolivello@unipolrental.it', cc: cc.join(';'),
      tab: 'perizie',
      idTable: this.item.id
    }

    console.log(msg1)

    if (confirm("L'appuntamento è stato annullato a causa del cliente (driver o fleet manager)?")) {



      this.item.ricavo_manuale = diff < 24 ? 50 : (diff < 24 * 5 ? 20 : 0)
      this.item.descrizione_cambio_tariffa = diff < 24 ? 'Mancato Appuntamento Oltre 24h' : (diff < 24 * 5 ? 'Cambio Data Prima 24h' : 'Cambio data prima 5g')
      this.item.stato = 'ANNULLATO'

      alert(`Attenzione, pratica annullata dal cliente, ricominciare il procedimento dalla flotta.
      Descrizione tariffa: ${this.item.descrizione_cambio_tariffa}`)

      await this.db.perizie_reminder_upsert({ idPerizia: this.item.id, categoria: 'ANNULLAMENTO', descrizione: 'Annullato da cliente' })
      this.saveAdzRia()

    } else {
      this.item.dtaAppuntamento = null
      this.item.stato = 'NUOVO INCARICO'
    }

    await this.save(false).then((res: any) => {

      console.log(res)

      this.db.send_mail(msg1).then(res => {
        if (res.status != 'OK') {
          alert(JSON.stringify(res))
          return
        }
      }).catch(err => console.error(err))

    })

    this.loadEvents()

  }


  richiediConfermaUnipol() {


    this.item.stato = 'RICHIESTA AUTORIZZAZIONE'

    //let url = this.db.URL_BASE + "unipolrental_auth_confirm&id=" + this.item.id + "&ok="

    let html = `
      <div>
        Buongiorno,
        con la presente richiediamo autorizzazione per la riconsegna del veicolo targato ${this.item.targa} nonostante il contratto non sia ancora scaduto.
        

        <br>
        <br>

        Per autorizzare o rifiutare la pratica utilizzare il seguente link:
        <br>
        <br>
        
        https://xpert.ggroup.cloud/#/automotive/unipolrental/dettaglio/${this.item.id}

      </div>
      `

    let txt = `
      Buongiorno,
      con la presente richiediamo autorizzazione per la riconsegna del veicolo targato ${this.item.targa} nonostante il contratto non sia ancora scaduto.

      -------------------------------------------------------

      Per autorizzare o rifiutare la pratica utilizzare il seguente link:
      
      https://xpert.ggroup.cloud/#/automotive/unipolrental/dettaglio/${this.item.id}

      `

    let msg1 = {
      recipientEmail: 'chiusuracontratto@unipolrental.it', // Chiara.Bertocchi@unipolrental.it;justinsean.venturi@unipolrental.it;nicandro.faccenda@unipolrental.it;riccardo.vezzani@unipolrental.it
      senderName: 'Uxpert PRO',
      subject: 'Richesta autorizzazione riconsegna per contratto non scaduto - Targa: ' + this.item.targa,
      text: txt,
      html: html,
      attachments: [],
      pec: 2,
      bcc: '', cc: 'operations.unipolrental@general-group.it;',
      tab: 'perizie',
      idTable: this.item.id

    }


    this.db.send_mail(msg1).then(res => {
      if (res.status != 'OK') {
        alert(JSON.stringify(res))
        return
      }
    })

    this.save(false)


  }

  disableSave = false
  async confirmTemporaryAppointment(appontmentData) {

    //console.clear()

    if (this.item.dtaAppuntamento) return // già confermata
    if (!confirm("Sei sicuro di voler CONFERMARE questo appuntamento?")) return;

    this.disableSave = true
    await this.save(false, undefined, undefined, appontmentData.startDate)

  }

  /* OLD VERSION -> MOVED TO BACKEND
    async confirmTemporaryAppointment(appontmentData) {
  
      //console.clear()
  
      if (this.item.dtaAppuntamento) return // già confermata
  
      if (!confirm("Sei sicuro di voler CONFERMARE questo appuntamento?")) return;
  
      this.item.dtaAppuntamento = appontmentData.startDate
      this.item.stato = 'INCARICO ACCETTATO'
  
      let drivers: IContattoPerizia[] = this.contatti_source.filter(e => e.ruolo == 'DRIVER')
      let fleetmanagers: IContattoPerizia[] = this.contatti_source.filter(e => e.ruolo == 'FLEET MNGR')
      let centriMad: IContattoPerizia[] = this.contatti_source.filter(e => e.ruolo == 'CENTRO-MAD' || e.ruolo == 'FILIALE')
  
      let centro
      await this.db.unipolrental_get_centri(this.item.codiceCentro).then(res => {
        console.log(res)
        centro = res.item
      }).catch(err => {
        console.error(err)
      })
  
      console.log(centro)
      let ispettore = this.ispettori.find(e => e.email == this.item.assegnatoA2)
      console.log(ispettore)
      let salacontrollo = this.salacontrollo.find(e => e.email == this.item.assegnatoA1)
      console.log(ispettore)
  
  
      await this.save(false).then((res: any) => {
  
        console.log(res)
        setTimeout(() => {
          sendConfirmMail(this.item, drivers, fleetmanagers, centro, ispettore, salacontrollo, centriMad, this.db)
        }, 100);
  
      })
  
      this.loadEvents()
  
    }
  */


  async sendMailRiaddebito() {

    //console.clear()

    let drivers: IContattoPerizia[] = this.contatti_source.filter(e => e.ruolo == 'DRIVER')
    let fleetmanagers: IContattoPerizia[] = this.contatti_source.filter(e => e.ruolo == 'FLEET MNGR')

    if (confirm("Vuoi inviare l'email con le informazioni di riaddebito al cliente?"))
      sendRiaddebitoMail(this.item, drivers, fleetmanagers, this.db)
    else {
      let reminder: IPeriziaReminder = {
        idPerizia: this.item.id,
        descrizione: 'Email riaddebito non inviata per scelta utente',
        //dtaReminder: new Date(),
        categoria: 'RIA EMAIL',
        categoria1: 'SKIP'
      }

      this.db.perizie_reminder_upsert(reminder)
    }

  }

  async cancelTemporaryAppointment(appontmentData) {
    console.log(appontmentData)

    if (this.item.dtaAppuntamento) return // già confermata

    if (!confirm("Sei sicuro di voler CANCELLARE questo appuntamento?")) return;

    this.db.unipolrental_delete_temporary_appointment(appontmentData.idTmpAppointment)
      .then(res => console.log(res))
      .catch(err => console.error(err))

    await this.save(false)

    this.loadEvents()

  }


  centro

  isDisabled(date) {


    if (this.item.codiceCentro > 0) {
      return this.isDisableDate(date)
    }

    return 'open'
  }

  isBeforeContractDate(date) {
    //// modificato >= in favore di >; in data 2024/04/09 su richiesta di c.bertocchi ////

    //console.clear()
    //console.log(this.item.urScadenzaContratto)

    return this.item.urScadenzaContratto > moment(date).format('YYYY-MM-DD')
  }



  isBeforeStartDate(date) {
    let startDate = calcStartDate(new Date(), this.item.madTarga && this.item.madTarga != '')
    return date < startDate
  }

  isDisableDate(date: Date, log = false) {

    let hh = moment(date).format('HHmm')
    let wd = moment(date).weekday();

    if (log) console.log(date)

    //console.log(this.centro)

    let sts = 'closed'


    if (this.centro?.orari)
      for (const e of this.centro.orari) {

        let ex = e as IOrario

        //let hhEnd = moment(ex.hhEnd,'HHmm' ).add(-1,'second').format('HHmm')


        if (
          (!ex.weekDay || wd == ex.weekDay) &&
          (!ex.hhStart || hh >= ex.hhStart && hh < ex.hhEnd)
        ) {

          sts = ex.type
          if (log) console.log(`${hh} => ${ex.hhStart} && ${hh} < ${ex.hhEnd} -> ${sts}`)

        }


      }


    return sts

  }




  loadEvents(delay = 150) {

    if (this.newDate) { this.currentDate = this.newDate; }

    //console.log('loadEvents - start')
    setTimeout(() => {

      if (this.selectedTab_name != 'Appuntamento') {
        //console.log('loadEvents skipped: ' + this.selectedTab_name)
        return
      }

      //console.log('loadEvents')

      let appointments: Appointment[] = [];
      let events = [];

      if (this.item.dtaAppuntamento) {
        let e: any = {
          id: this.item.id,
          start: this.item.dtaAppuntamento,
          end: this.item.dtaAppuntamento,
          isAllDay: false,
          title: `*** ID: ${this.item.id} -  ${this.item.targa} ***`,
          description: `ID: ${this.item.id} - ${this.item.targa}`,
          address: this.item.citta,
          color: 'green',
          noteCarico: this.item.noteCarico,
          tmp: false
        }
        events.push(e)
      }


      if (this.item.dtaAppuntamento) {

        let d1 = this.item.dtaAppuntamento;
        let d2: Date = new Date();

        d2.setTime(d1.getTime() + 60000 * this.item.timeRequired)

        //console.log(d1)
        //console.log(d2)

        let e: any = {
          id: this.item.id,
          text: `${this.item.targa}`,
          startDate: d1,
          endDate: d2,
          allDay: false,
          address: this.item.citta,
          ispettoreDescr: this.item.ispettoreDescr,
          color: 'green',
          noteCarico: this.item.noteCarico,
          tmp: false
        }

        appointments.push(e)


      }

      let filtroIspettore = this.item.assegnatoA2 && this.item.assegnatoA2 != '' ? this.item.assegnatoA2 : this.calendarFilterData.ispettore

      //console.log('loadEvents - list_appuntamenti -> ips: ' + filtroIspettore)

      this.db.list_appuntamenti(filtroIspettore, this.item.id, this.visualizzaStorico).then(res => {

        // //console.clear()
        //console.log('list_appuntamenti', res)
        res = res.filter(e => moment(e.dtaAppuntamento) > (moment().subtract(this.visualizzaStorico ? 90 : 3, 'days')))
        //console.log('list_appuntamenti', res)

        for (let i = 0; i < res.length; i++) {

          if (res[i].id == this.item.id && +res[i].tmp == 0) continue; // skip per non aggiungere doppio appuntamento pratica corrente

          let d1 = removeTZ(new Date(res[i].dtaAppuntamento))
          let d2: Date = new Date();

          if (!res[i].timeRequired || res[i].timeRequired == 0) res[i].timeRequired = 60;

          d2.setTime(d1.getTime() + 60000 * res[i].timeRequired)


          let color = 'pastelblue'
          if (+res[i].tmp > 0 && res[i].id == this.item.id) color = '#cb99c9'
          if (+res[i].tmp > 0 && res[i].id != this.item.id) color = 'orange'



          let appointment = {
            id: res[i].id,
            startDate: (d1),
            endDate: (d2),
            address: res[i].address,
            text: `${res[i].targa}`,
            allDay: false,
            color: color,
            idTmpAppointment: res[i].idTmpAppointment,
            ispettoreDescr: res[i].ispettoreDescr,
            tmp: +res[i].tmp > 0,
            ServizioRiconsegna: res[i].ServizioRiconsegna,
            NomeCliente: res[i].NomeCliente,
            noteCarico: res[i].noteCarico,
            isMAD: res[i].isMAD,
            stato: res[i].stato,
          }
          //console.log('appointment', appointment)

          appointments.push(appointment)

        }


        //this.events = events;
        this.appointmentsData = appointments;

        //console.log('setting appointments', this.appointmentsData)

      }).catch(err => console.error(err))


    }, delay);


  }


  async checkADZ(newADZ) {


    //console.clear()
    console.log(newADZ)

    let result = []

    const zip = new JSZip();
    let extractedFiles
    try {
      extractedFiles = await zip.loadAsync(newADZ.fileContent.split(',')[1], { "base64": true });
    } catch (error) {
      extractedFiles = { files: [] }
      result.push({ code: 'adz_format', descr: 'Formato file ADZ non valido (file daneggiato)', status: 'ERROR' })
      this.adzResult = result
    }

    console.log(extractedFiles)

    let experiz
    if (extractedFiles?.files["EXPERIZ.TXT"]) //winper
      experiz = extractedFiles?.files["EXPERIZ.TXT"]
    else if (extractedFiles?.files["PERIZIE.TXT"]) //genius
      experiz = extractedFiles?.files["PERIZIE.TXT"]

    else if (extractedFiles?.files["ExPeriz.Txt"]) //altro
      experiz = extractedFiles?.files["ExPeriz.Txt"]
    else {
      result.push({ code: 'adz_format', descr: 'Formato file ADZ non valido (file EXPERIZ mancante)', status: 'ERROR' })
      this.adzResult = result
    }

    let experiz2
    if (extractedFiles?.files["EXPERIZ2.TXT"]) //winper
      experiz2 = extractedFiles?.files["EXPERIZ2.TXT"]
    else if (extractedFiles?.files["PERIZIE2.TXT"]) //genius
      experiz2 = extractedFiles?.files["PERIZIE2.TXT"]
    else if (extractedFiles?.files["ExPeriz2.Txt"]) //altro
      experiz2 = extractedFiles?.files["ExPeriz2.Txt"]
    else {
      result.push({ code: 'adz_format', descr: 'Formato file ADZ non valido (file EXPERIZ2 mancante)', status: 'ERROR' })
      this.adzResult = result
    }



    let expdanni
    if (extractedFiles?.files["EXPDANNI.TXT"]) //winper
      expdanni = extractedFiles?.files["EXPDANNI.TXT"]
    else if (extractedFiles?.files["DANNI.TXT"]) //genius
      expdanni = extractedFiles?.files["DANNI.TXT"]
    else if (extractedFiles?.files["ExpDanni.Txt"]) //altro
      expdanni = extractedFiles?.files["ExpDanni.Txt"]
    else {
      result.push({ code: 'adz_format', descr: 'Formato file ADZ non valido (file EXPDANNI mancante)', status: 'ERROR' })
      this.adzResult = result
    }

    if (result.length == 0) {
      result.push({ code: 'adz_format', descr: 'Formato file ADZ valido', status: 'OK' })
      this.adzResult = result
    }

    let experiz_data = [...adz_def.filter(e => e.NomeTXT == 'EXPERIZ')]
    try {
      let experiz_txt = new TextDecoder().decode(await zip.file(experiz.name).async("uint8array"))

      console.log(experiz_txt)

      for (const adz_field of experiz_data) {
        adz_field.val = experiz_txt.substring(adz_field.da, adz_field.da + adz_field.TOT).trim()
      }
    } catch (err) { }

    let experiz2_data = [...adz_def.filter(e => e.NomeTXT == 'EXPERIZ2')]
    try {
      let experiz2_txt = await zip.file(experiz2.name).async("string") as string
      for (const adz_field of experiz2_data) {
        adz_field.val = experiz2_txt.substring(adz_field.da, adz_field.da + adz_field.TOT).trim()
      }
    } catch (err) { }


    let exdanni_data: any[] = []

    try {
      let expdanni_txt = (await zip.file(expdanni.name).async("string") as string).split("\r\n")
      let exdanni_data_def = [...adz_def.filter(e => e.NomeTXT == 'EXPDANNI')]
      for (const expdanni_txt_line of expdanni_txt) {
        let row = JSON.parse(JSON.stringify(exdanni_data_def))
        for (const adz_field of row) {
          adz_field.val = expdanni_txt_line.substring(adz_field.da, adz_field.da + adz_field.TOT).trim()
        }
        exdanni_data.push(row)
      }
    } catch (err) { }


    console.log(experiz_data)
    console.log(experiz2_data)
    console.log(exdanni_data)

    ///////////////////////// CHECK TARGA ////////////////////
    let targa = experiz_data.find(e => e.ValorePerizia == 'targa')?.val?.toUpperCase()
    if (targa != this.item.targa?.toUpperCase().trim()) {
      result.push({ code: 'adz_targa', descr: `Targa errata: '${targa}' <> '${this.item.targa?.toUpperCase().trim()}'`, status: 'ERROR' })
    } else {
      result.push({ code: 'adz_targa', descr: 'Targa', status: 'OK' })
    }
    this.adzResult = result




    ///////////////////////// DATA 1 RILIEVO ////////////////////
    /*
    let data_1_rilievo = +experiz_data.find(e => e.ValorePerizia == 'data_1_rilievo')?.val
    if (!data_1_rilievo) {
      result.push({ code: 'adz_data_1_rilievo', descr: `Data Primo Rilievo Mancante`, status: 'ERROR' })
    } else {
      result.push({ code: 'adz_data_1_rilievo', descr: 'Data Primo Rilievo', status: 'OK' })
    }
    this.adzResult = result
    */


    ///////////////////////// CHECK COSTO RICAMBI ////////////////////
    let tot_costo_ricambi = Math.round(
      exdanni_data

        .filter(e =>
          !e.find(e => e.ValorePerizia == 'voce_di_danno').val?.startsWith('DE-SMALTIMENTO RIFIUTI')
          //&&          !e.find(e => e.ValorePerizia == 'voce_di_danno').val?.startsWith('Smalt.Rifiuti')
        )

        .map(e => e.find(e => e.ValorePerizia == 'costo_ricambi_imponibile')
          .val?.replace('00-', '-')?.replace('0-', '-'))
        .reduce((partialSum, a) => partialSum + Math.round(+a * 100) / 100, 0)
      * 100) / 100

    let tot_costo_ricambi1 = Math.round(+experiz_data.find(e => e.ValorePerizia == 'ricambi_imponibile')?.val * 100) / 100


    if (Math.abs(tot_costo_ricambi - tot_costo_ricambi1) > 0.05) { //tolleranza 0.05
      result.push({ code: 'adz_costo_ricambi', descr: `Costo ricambi non valido: '${tot_costo_ricambi}' <> '${tot_costo_ricambi1}'`, status: 'ERROR' })
    } else {
      result.push({ code: 'adz_costo_ricambi', descr: 'Costo ricambi', status: 'OK' })
    }
    this.adzResult = result


    ///////////////////////// CHECK SCONTO CUMULATIVO RICAMBI ////////////////////
    let foundRigaDanno
    for (const rigaDanno of exdanni_data) {

      if (rigaDanno.find(e => e.ValorePerizia == 'voce_di_danno').val.startsWith('*** Sconto sui ricambi') && rigaDanno.find(e => e.ValorePerizia == 'costo_ricambi_imponibile').val != '') {
        foundRigaDanno = rigaDanno
        break
      }

    }

    if (foundRigaDanno) {
      result.push({ code: 'adz_sconto_cumulativo', descr: `E' presente uno sconto cumulativo: '${foundRigaDanno.find(e => e.ValorePerizia == 'voce_di_danno').val}'`, status: 'ERROR' })
    } else {
      result.push({ code: 'adz_sconto_cumulativo', descr: 'Sconto cumulativo non presente', status: 'OK' })
    }
    this.adzResult = result


    //// diverso costo moanodopera per rentalpkus - ford
    if (this.commessa.id == 23 && this.item.marca?.toLocaleLowerCase() == 'ford') {
      this.commessa.man_carrozzeria = 32
      this.commessa.man_meccanica = 30
    }


    ///////////////////////// CHECK TARIFFA MDO MECC ////////////////////
    let costo_mdo_meccanica = +experiz_data.find(e => e.ValorePerizia == 'costo_mdo_meccanica')?.val
    if (this.commessa?.man_meccanica && this.commessa?.man_meccanica >= 0 && costo_mdo_meccanica != this.commessa?.man_meccanica) {
      result.push({ code: 'tariffa_mdo_meccanica', descr: `Tariffa mdo meccanica non valida: 'c: ${costo_mdo_meccanica}' <> adz: ${this.commessa?.man_meccanica}`, status: 'ERROR' })
    } else {
      result.push({ code: 'tariffa_mdo_meccanica', descr: 'Tariffa mdo meccanica', status: 'OK' })
    }
    this.adzResult = result
    /*
        ///////////////////////// CHECK ORE MDO MECC ////////////////////
        let tot_me_tempo = exdanni_data.map(e => e.find(e => e.ValorePerizia == 'me_tempo').val?.replace('00-', '-')?.replace('0-', '-')).reduce((partialSum, a) => partialSum + +a, 0)
        let ore_mdo_meccanica = +experiz_data.find(e => e.ValorePerizia == 'ore_mdo_meccanica')?.val
        if (tot_me_tempo != ore_mdo_meccanica) {
          result.push({ code: 'ore_mdo_meccanica', descr: `Ore mdo meccanica non valide: '${tot_me_tempo}' <> '${ore_mdo_meccanica}'`, status: 'ERROR' })
        } else {
          result.push({ code: 'ore_mdo_meccanica', descr: 'Ore mdo meccanica', status: 'OK' })
        }
        this.adzResult = result
    
    
        ///////////////////////// CHECK IMP MDO MECC ////////////////////
        let mdo_meccanica_imp = +experiz_data.find(e => e.ValorePerizia == 'mdo_meccanica_imp')?.val
        if (costo_mdo_meccanica * ore_mdo_meccanica / 1000 != mdo_meccanica_imp) {
          result.push({ code: 'imp_mdo_meccanica', descr: `Importo mdo meccanica non valido: '${costo_mdo_meccanica * ore_mdo_meccanica / 1000 }' <> ${mdo_meccanica_imp}`, status: 'ERROR' })
        } else {
          result.push({ code: 'imp_mdo_meccanica', descr: 'Importo mdo meccanica', status: 'OK' })
        }
        this.adzResult = result
    */


    ///////////////////////// CHECK TARIFFA MDO CARR ////////////////////
    let costo_mdo_carrozzeria = +experiz_data.find(e => e.ValorePerizia == 'costo_mdo_carrozzeria')?.val
    if (this.commessa?.man_carrozzeria && this.commessa?.man_carrozzeria >= 0 && costo_mdo_carrozzeria != this.commessa?.man_carrozzeria) {
      result.push({ code: 'tariffa_mdo_carrozzeria', descr: `Tariffa mdo carrozzeria non valida: c: '${costo_mdo_carrozzeria}' <> adz: ${this.commessa?.man_carrozzeria}`, status: 'ERROR' })
    } else {
      result.push({ code: 'tariffa_mdo_carrozzeria', descr: 'Tariffa mdo carrozzeria', status: 'OK' })
    }
    this.adzResult = result


    /*
        ///////////////////////// CHECK TEMPI VE  ////////////////////
        let tot_ve_tempo = exdanni_data.map(e => e.find(e => e.ValorePerizia == 've_tempo').val?.replace('00-', '-')?.replace('0-', '-')).reduce((partialSum, a) => partialSum + +a, 0)
        let tot_tempi_ve = +experiz_data.find(e => e.ValorePerizia == 'tot_tempi_ve')?.val
        if (tot_ve_tempo != tot_tempi_ve) {
          result.push({ code: 'ore_tempo_ve', descr: `Corrispondenza parziali - totali Tempo VE non valido: '${tot_ve_tempo}' <> '${tot_tempi_ve}'`, status: 'ERROR' })
        } else {
          result.push({ code: 'ore_tempo_ve', descr: 'Corrispondenza parziali - totali Tempo VE ', status: 'OK' })
        }
        this.adzResult = result
    
        ///////////////////////// CHECK TEMPI SR  ////////////////////
    
        console.log("ARRAY LE:", exdanni_data.map(e => e.find(e => e.ValorePerizia == 'sr_tempo').val?.replace('00-', '-')?.replace('0-', '-')).map(e => +e))
    
        let tot_sr_tempo = exdanni_data.map(e => e.find(e => e.ValorePerizia == 'sr_tempo').val?.replace('00-', '-')?.replace('0-', '-')).reduce((partialSum, a) => partialSum + +a, 0)
        let tot_tempi_sr = +experiz_data.find(e => e.ValorePerizia == 'tot_tempi_sr')?.val
        if (Math.round(tot_sr_tempo/10) != Math.round(tot_tempi_sr/10)) {
          result.push({ code: 'ore_tempo_ve', descr: `Corrispondenza parziali - totali Tempo SR non valido: '${tot_sr_tempo}' <> '${tot_tempi_sr}'`, status: 'ERROR' })
        } else {
          result.push({ code: 'ore_tempo_ve', descr: 'Corrispondenza parziali - totali Tempo SR ', status: 'OK' })
        }
        this.adzResult = result
    
        ///////////////////////// CHECK TEMPI LA  ////////////////////
    
        console.log("ARRAY LA:", exdanni_data.map(e => e.find(e => e.ValorePerizia == 'la_tempo').val?.replace('00-', '-')?.replace('0-', '-')).map(e => +e))
    
        let tot_la_tempo = exdanni_data.map(e => e.find(e => e.ValorePerizia == 'la_tempo').val?.replace('00-', '-')?.replace('0-', '-')).reduce((partialSum, a) => partialSum + +a, 0)
        let tot_tempi_la = +experiz_data.find(e => e.ValorePerizia == 'tot_tempi_la')?.val
        if (tot_la_tempo != tot_tempi_la) {
          result.push({ code: 'ore_tempo_la', descr: `Corrispondenza parziali - totali Tempo LA non valido: '${tot_la_tempo}' <> '${tot_tempi_la}'`, status: 'ERROR' })
        } else {
          result.push({ code: 'ore_tempo_la', descr: 'Corrispondenza parziali - totali Tempo LA ', status: 'OK' })
        }
        this.adzResult = result
    
        */


    return result


  }

  adzResult
  async attachmentSave(event) {

    console.log('attachmentSave')
    console.log(this.item.allegati)

    let newADZ = this.item.allegati.find(e => e.fileContent && e.fileContent != '' && e.fileName.toLocaleLowerCase().endsWith('.adz'))
    if (newADZ) {
      let result = await this.checkADZ(newADZ)
      console.log(result)
      this.adzResult = result
      if (result.filter(e => e.status == 'ERROR').length > 0) {
        let i = this.item.allegati.findIndex(e => e.fileContent == newADZ.fileContent)
        this.item.allegati.splice(i, 1)
        return
      }
    }
    /* sostituito da procedura dedicata
        let vdrs = this.item.allegati.filter((e: any) => e.type == "Verbale di riconsegna" && !e.sent)
        if (vdrs.length > 0) {
          this.save()
        }
    */
    if (!this.commesse_order_manager.find(e => e == this.item.commessa)) {
      this.manageStatus('', '')
    }

    if (this.loading) return;

    
    this.loading = true;

    await this.db.upsert_allegati_perizia(this.item, true).subscribe(async (event: any) => {

      console.log('upsert_allegati_perizia')
      console.log(event)

      // progress
      if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
        const percentage = 100 / event.total * event.loaded;

        this.loading_percentage = percentage;
        this.loading_loaded = event.loaded;
        this.loading_total = event.total;

        console.log(percentage);
      }

      // finished
      if (event.type === HttpEventType.Response) {

        if (event.body.status != 'OK') {
          console.error(event.body)

          console.log(JSON.parse(this.attachment_backup));
          this.item.allegati = JSON.parse(this.attachment_backup);
          this.loading = false;

          alert(JSON.stringify(event.body.error));

          return;
        } else {

          //await this.save(false)

          console.log('REFRESH!')
          this.db.get_perizia(this.id, this.type).then(res => {

            this.adzResult = undefined
            this.showAddAdz = false;
            this.loading = false;

            //this.manageStatus('', '')
            //this.save(false)

            console.log('REFRESH')
            console.log(res)
            this.data = res.item;
            this.loadAll();

            if (this.item.allegati.length > 7 && this.item.adz_data && this.commesse_order_manager.find(e => e == this.item.commessa)) {
              this.toggleOrderMode()
            }

          }).catch(err => {
            console.error(err)
            this.loading = false;
          });


        }
      }

    })



    //.catch(err => { this.loading = true; console.error(err) })


  }


  /* recover
    attachmentSave(event) {
   
   
      if (this.loading) return;
   
      this.showAddAdz = false;
   
      this.loading = true;
   
      this.db.upsert_allegati_perizia(this.item).subscribe((event: any) => {
   
        //console.log(event)
   
        // progress
        if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
          const percentage = 100 / event.total * event.loaded;
          this.loading_percentage = percentage;
          //console.log(percentage);
        }
   
        // finished
        if (event.type === HttpEventType.Response) {
          if (event.body.status != 'OK') {
            console.error(event.body)
            //console.log(JSON.parse(this.attachment_backup));
            this.item.allegati = JSON.parse(this.attachment_backup);
            this.loading = false;
            alert(JSON.stringify(event.body.error));
            return;
   
          } else {
   
            //console.log('REFRESH!')
            this.db.get_perizia(this.id, this.type).then(res => {
              this.loading = false;
              //console.log(res)
              this.data = res.item;
              this.manageStatus('', '')
   
              this.save(false)
   
              this.loadAll();
            }).catch(err => {
              console.error(err)
              this.loading = false;
            });
   
   
          }
        }
   
      })
   
      //.catch(err => { this.loading = true; console.error(err) })
   
   
    }
  */
  openDialogMsg(nextStatus: string, sendMessageTo: string,): void {

    let recipient = '';
    switch (sendMessageTo) {
      case 'ISPETTORE': recipient = this.item.assegnatoA2; break;
      case 'SALACONTROLLO': recipient = this.item.assegnatoA1; break;
      case 'CLIENTE': recipient = this.item.creatoDa; break;
    }

    const dialogRef = this.dialog.open(DialogMessage, {
      width: '250px',
      data: { recipient: recipient }
    });

    dialogRef.afterClosed().subscribe(msg => {
      //console.log(msg);



      let c: IChat = {
        ID: null,
        sender: null,
        date: new Date(),
        recipient: recipient,
        code: 'perizie-' + this.item.id,
        message: msg,
        group: false
      }

      this.db.addChat(c).then(res => {
        //console.log(res)

        this.accept_reject(nextStatus, sendMessageTo, msg);

      })

    });
  }

  isURAutorized() {
    return this.storico.find(e => e.sts == 'RICHIESTA AUTORIZZAZIONE') != undefined
  }

  checkDataAppuntamento(date): boolean {
    const momentDate = moment(date, "YYYY-MM-DD", true);
    const now = moment();

    if (momentDate.isAfter(now, 'day')) {
      alert("Operazione non riuscita. La data dell'appuntamento non è ancora passata.");
      return false;
    }

    return true;
  }


  async accept_reject(nextStatus: string, sendMessageTo: string, msg: string = null) {

    /*
        if (nextStatus == 'CONCLUSO' && this.type == 'ssu' && this.item.allegati.length > 7 && this.item.adz_data && this.commesse_order_manager.find(e => e == this.item.commessa)) {
          this.toggleOrderMode(true)
          return
        }
    */

    if (this.item.tipo == 'unipolrental' && nextStatus == 'CONCLUSO' && this.item.adz_data.adz.danni.filter(e => e.selected).length == 0) {

      alert("ATTENZIONE, stai inviando un riaddebito vuoto, se non ci sono danni riaddebitabili, aggiungi una riga con dicitura 'NESSUN DANNO ADDEBITABILE' ")
      return

    }


    // workaround per autorizzazione cliente (UR)
    if (this.item.stato == "RICHIESTA AUTORIZZAZIONE") {

      let x = prompt("Inserisci una nota per questa operazione")

      if (!x || x == '') return

      window.open(`${this.db.URL_BASE}unipolrental_auth_confirm&id=${this.item.id}&ok=${nextStatus == 'AUTORIZZO' ? 1 : 0}&msg=${x}`, '_blank')

      setTimeout(() => {
        this.init(this.item.id)
      }, 1000);

      return
    }

    // workaround per mancato appuntamento
    if (nextStatus == "MANCATO APPUNTAMENTO") {


      const isDateValid = this.checkDataAppuntamento(this.item.dtaAppuntamento);
      if (!isDateValid) return;

      let x = prompt("Inserisci una nota per questa operazione")
      if (!x || x == '') return

      this.item.ricavo_manuale = 50
      this.item.descrizione_cambio_tariffa = 'Mancato Appuntamento Oltre 24h'
      this.item.stato = 'ANNULLATO'



      await this.saveAdzRia()
      await this.db.perizie_reminder_upsert({ idPerizia: this.item.id, categoria: 'MISSED', descrizione: 'Mancato appuntamento ' + x })

      this.save(true)


      return
    }


    if (sendMessageTo && !msg) {

      //console.log('message required')
      this.openDialogMsg(nextStatus, sendMessageTo);
      return

    } else {

      //console.log(nextStatus, this.item)
      //console.log(nextStatus)

      this.saveAdzRia()

      if (this.item.cliente == 12409 && nextStatus == 'CONCLUSO') {


        for (let i = 1; i <= 4; i++)
          if (!this.item.allegati.find(e =>
            e.fileName.toLocaleLowerCase() == i + '.jpg' ||
            e.fileName.toLocaleLowerCase() == i + '.jpeg')
          ) {
            alert("Attenzione, documentazione fotografica non valida")
            return
          }


      }


      this.db.upsert_perizia_accept_reject(this.item.id, nextStatus)
        .then(res => {

          if (res.status != 'OK') {
            console.error(res);
            return
          }

          this.item.stato = nextStatus;


          if (this.item.stato == 'CONCLUSO' && this.item.tipo == 'unipolrental') {
            this.sendMailRiaddebito()
            this.saveAdzRia()
          }

          this.stato = this.db.getStato(this.item.stato);


          if (this.item.stato != this.db.getStatiPeriziaAll()[4]) this.close();

        }).catch(err => console.error(err))

    }

  }


  addPrefix(prefix) {

    for (const a of this.item.allegati) {
      a.fileName = prefix + a.fileName
    }

    this.save(false)

  }

  orderMode = false
  allegatiBk
  toggleOrderMode(tryToConclude = false) {

    //if (this.userService.getRole() == 'ADMIN' || this.userService.getRole() == 'GESTORE') {

    const dialogRef = this.dialog.open(PerizieOrderPhotoComponent, {
      width: '40%',
      data: { allegati: this.item.allegati, adz_data: this.item.adz_data, id: this.item.id }
    });

    dialogRef.afterClosed().subscribe(async (res: { allegati: IFile[], warning: boolean }) => {

      if (res) {

        let allegati = res.allegati
        let warning = res.warning

        this.item.allegati = JSON.parse(JSON.stringify(allegati))
        this.manageStatus('', '')

        if (this.type == 'ssu') {

          this.db.perizie_reminder_upsert({
            idPerizia: this.item.id,
            descrizione: 'Consegnato via controlli automatici',
            creatoDa: this.userService.getEmail(),
            dta: new Date(),
          })

          if (!warning && (this.item.stato == 'CONSEGNATO' || tryToConclude)) {
            this.item.stato = 'CONCLUSO'
          }

        }

        this.save(false)

      } else {

        if (this.userService.getRole() == 'ISPETTORE' || this.userService.getRole() == 'SALACONTROLLO')
          this.db.perizie_reminder_upsert({
            idPerizia: this.item.id,
            categoria: 'NOT_ORDERD',
            descrizione: 'Attenzione, pratica non consegnata (' + this.item.id + ')',
            creatoDa: this.userService.getEmail(),
            dta: new Date(),
            //dtaReminder: new Date(),
          })

        alert("ATTENZIONE, la pratica non è stata completata, premere il tasto 'MODALITA ORDINAMENTO' per completare la consegna")
      }

    })

    /*return
      }
      this.orderMode = !this.orderMode
      if (this.orderMode) this.allegatiBk = [...this.item.allegati]
    */
  }

  cancelOrder() {
    this.item.allegati = [...this.allegatiBk]
    this.allegatiBk = undefined
    this.orderMode = false
  }

  async saveOrder() {

    this.allegatiBk = undefined
    this.orderMode = false

    for (let i = 0; i < this.item.allegati.length; i++) {

      let tmp = this.item.allegati[i].fileName.split('.')
      let ext = tmp.splice(tmp.length - 1, 1)
      let newName = ''
      if (i < 7) {
        newName = ('' + (i + 1)) + '.' + ext
      } else {
        newName = 'F' + ('' + (i + 1)).padStart(3, '0') + '.' + ext
      }

      this.item.allegati[i].fileName = newName


    }

    await this.save(false)


  }

  public showAddAdz = false;
  toggleAddAdz() {
    console.log(this.showAddAdz)
    this.showAddAdz = !this.showAddAdz;
    console.log(this.showAddAdz)
  }

  addAdzURRow(descr, importo = undefined) {

    let field = ''
    let code = ''

    switch (descr) {
      case '^^^ Doppie chiavi ^^^':
        field = 'Doppie_chiavi'
        code = 'DC'

        /// fix per sifa
        importo = +TARIFFARIO_UR_SIFA[+this.item.ContrattoQuadroSifa].doppie_chiavi

        /// fix per clienti in deroga
        if (+this.item.urCodiceCliente == 6095894) importo = 100 //Deroga perdita chiavi: 100€
        if (+this.item.urCodiceCliente == 6105387) importo = 150 //Deroga perdita chiavi: 150€

        break;

      case '^^^ Carta di circolazione ^^^':
        field = 'Carta_di_circolazione'
        code = 'CC'
        importo = +TARIFFARIO_UR_SIFA[+this.item.ContrattoQuadroSifa].smarrimnento_documenti
        break;

      case '^^^ Ruota di scorta ^^^':
        field = 'Ruota_scorta'
        code = 'RS'
        break;
    }

    if (this.ria[field] > 0) {
      this.ria[field] = 0;
      this.item.adz_data.adz.danni = this.item.adz_data.adz.danni.filter(e => e.voce_di_danno.val != descr)

      this.saveAdzRia()

      return
    }


    if (!importo) importo = prompt("Inserisci l'importo per la voce: " + descr)
    if (!importo) return

    this.ria[field] = importo

    let row = JSON.parse(JSON.stringify(emptyAdzRow))

    row.codice_ricambio.val = code
    row.voce_di_danno.val = descr
    row.costo_ricambi_imponibile.val = importo
    row.selected = true

    this.item.adz_data.adz.danni.push(row)


    this.saveAdzRia()


  }


  async saveAdzRia() {

    if (this.type != 'unipolrental') return

    if (this.item.adz_data && this.item.adz_data.adz) {
      this.ria.Km = this.item.adz_data.adz.km.val

      let diffAccessori = +this.ria.Doppie_chiavi
        + +this.ria.Carta_di_circolazione
        + +this.ria.Ruota_scorta
      this.ria.Valore_Remarketing = +this.valRiaddebitoAdz - diffAccessori
      this.ria.Valore_perizia_riaddebito = +this.valRiaddebitoAdz

    }

    this.ria.Costo_perizia = this.item.ricavo_manuale

    await this.db.unipolrental_set_riaddebito(this.ria)
      .then(res => {

        if (res.status != 'OK') {
          console.error(res)
          alert(JSON.stringify(res))
          return
        }

        console.log(res)

      })
      .catch(err => {
        console.error(err)
        alert(JSON.stringify(err))
      })

    await this.db.perizie_update_adz(this.item)
      //.then(res => console.log(res)).catch(err => console.error(err))
      .then(res => console.log(res))
      .catch(err => {
        console.error(err)
        alert(JSON.stringify(err))
      })
  }

  livexpert() {

    let clienteDescr = ''

    try {
      clienteDescr = this.clienti.find(e => e.id == this.item.cliente).ragioneSociale
    } catch (error) { }

    /*
        let id = this.afFirestore.createId();
        let tmp = {
          id: id,
          dtaRequest: new Date(),
          plate: this.item.targa,
          client: this.item.cliente,
          clientDescr: clienteDescr,
          type: 'automotive/perizie',
          idPerizia: this.item.id
        }
    
        this.afFirestore.collection('requests').doc(id).set(tmp)
    
        this.router.navigate(['/automotive/livexpert/dettaglio/' + id])*/
  }



  reportRMK() {
    let url = this.db.URL_BASE + `unipolrental_print_report_adz&header=${this.db.getIcon()}&id=${this.item.id}&hash=&fout=${this.item.id}-${this.item.targa.replace(' ', '')}-report-rmk.pdf&images=1`;

    window.open(url, '_blank');
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onDragEnd(e) {
    console.log(e)
  }



  forecast;
  getForecast(loc, date: Date) {
    /*
        this.forecast = undefined
        this.ow.getForecast(loc).then(res => {
    
          let d = removeTZ(date);
          res = this.ow.searchForecastByDate(res, d)
    
          //console.log(res);
    
          let b = this.ow.showAlert(res);
    
          //console.log('showAlert: ' + b)
    
          if (b) {
            this.forecast = res
          }
        }).catch(err => console.error(err))
    */
  }




  async onLocationSelected(type, event) {
    //console.clear()
    console.log(event)

    switch (type) {
      case 'luogo_ispezione':
        this.item.cap = event.CAP
        this.item.citta = event.Citta
        this.item.indirizzo = event.Indirizzo
        this.item.provincia = event.Prov
        this.item.location_lat = event.location_lat
        this.item.location_lon = event.location_lon

        this.sortIspettori()

        this.resetFiliale()

        break;
      case 'luogo_sinistro':
        this.item.sinistro_cap = event.CAP
        this.item.sinistro_citta = event.Citta
        this.item.sinistro_indirizzo = event.Indirizzo
        this.item.sinistro_provincia = event.Prov
        this.item.sinistro_location_lat = event.location_lat
        this.item.sinistro_location_lon = event.location_lon
        break;
    }

  }

  isHoliday(date) {


    function isEaster(date) {

      let sd = moment(date).format('YYYY-MM-DD')
      let y = +moment(date).format('YYYY')

      return Easter(y) == sd


      function padout(number) { return (number < 10) ? '0' + number : number; }

      function Easter(Y) {
        var C = Math.floor(Y / 100);
        var N = Y - 19 * Math.floor(Y / 19);
        var K = Math.floor((C - 17) / 25);
        var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
        I = I - 30 * Math.floor((I / 30));
        I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
        var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
        J = J - 7 * Math.floor(J / 7);
        var L = I - J;
        var M = 3 + Math.floor((L + 40) / 44);
        var D = L + 28 - 31 * Math.floor(M / 4);

        return Y + '-' + padout(M) + '-' + padout(D);
      }


    }

    let holidays = [
      { date: '01/01', descr: 'Capodanno' },
      { date: '06/01', descr: 'Epifania' },
      { date: '25/04', descr: 'Liberazione' },
      { date: '01/05', descr: 'Festa del Lavoro' },
      { date: '02/06', descr: 'Festa della Repubblica' },
      { date: '15/08', descr: 'Ferragosto' },
      { date: '01/11', descr: 'Ognissanti' },
      { date: '08/12', descr: 'Immacolata' },
      { date: '25/12', descr: 'Natale' },
      { date: '26/12', descr: 'Santo Stefano' },

    ]

    let sd = moment(date).format('DD/MM')

    if (holidays.find(e => e.date == sd)) return true

    date = moment(date).add(-1, 'day').toDate()
    if (isEaster(date)) return true

    return false



  }


}


@Component({
  selector: 'dialog-overview-example-dialog',
  template: ` 

  <div class="card" style="padding:20px">
    <h1 mat-dialog-title>Qual è il motivo della richiesta a {{data.recipient}}?</h1>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-10">
          <div class="form-group">
            <input cdkFocusInitial type="text" class="form-control" placeholder="Message" [(ngModel)]="msg" (keydown.enter)="emulateSend()">
          </div>
        </div>

        <div class="col-2">
          <button id="send" #send mat-button [mat-dialog-close]="msg" >Send</button>
        </div>
      </div>
    </div>
  </div>
   
  `,
  standalone: false
})
export class DialogMessage {

  @ViewChild('myInput') myInput: ElementRef;

  msg: string = '';

  constructor(
    public dialogRef: MatDialogRef<DialogMessage>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    // this.myInput.nativeElement.

  }

  emulateSend() {
    let element: HTMLElement = document.getElementById('send');
    element.click();
  }

  send(): void {
    this.dialogRef.close();
  }

}



@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  
  <div class="card" style="padding:25px; max-height: 80vh; overflow-y:auto; ">

    <button *ngFor="let i of data" mat-raised-button type="button" class="btn btn-info" (click)="select(i)"> 
      {{i.citta}} - {{i.indirizzo}} - {{i.note}} 
    </button>
  
  </div>

  `,
  standalone: false
})
export class ListDepotDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ListDepotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ILuogo[],
  ) {

    data = data.sort((a, b) => {
      let sa = `${a.citta} ${a.indirizzo} ${a.note}`
      let sb = `${b.citta} ${b.indirizzo} ${b.note}`

      return sa > sb ? 1 : -1
    })

  }

  close() {
    this.dialogRef.close();
  }

  select(event) {
    this.dialogRef.close(event);
  }


}


export function removeTZ(d) {


  var format = 'YYYY/MM/DD HH:mm:ss';
  let sUtc = moment(d, format).format(format);

  //console.log('sUtc', sUtc);

  let dUtc = new Date(sUtc + " UTC");

  //console.log('dUtc', dUtc);

  return dUtc
}




@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  
  <div class="card" style="padding:1em; margin:auto; max-width:600px">

  
  <h3>Seleziona causale</h3>

    <mat-radio-group [(ngModel)]="selectedItem">
      <div  *ngFor="let i of list">

                    <mat-radio-button  [value]="i">
                      {{replaceDate(i.msg)}} 
                    </mat-radio-button>

                    <app-datetime *ngIf="selectedItem?.dtaAppuntamentoRequired && selectedItem == i" [(model)]="dtaAppuntamento" (onChange)="datetimeChange($event)"></app-datetime>
                    
                    <mat-form-field class="full"   *ngIf="selectedItem?.textRequired && selectedItem == i">
                      <mat-label>Testo</mat-label>
                      <input  type="text" matInput placeholder="..."
                       [(ngModel)]="text">
                    </mat-form-field>
      </div>
    </mat-radio-group>

    <button type="button" mat-raised-button class="btn btn-success" style="float:right"
    (click)="select()" >Salva</button>


  
  </div>

  `,
  standalone: false
})
export class ListInterDialogComponent {
  selectedItem
  list
  dtaAppuntamento
  text

  constructor(
    public dialogRef: MatDialogRef<ListInterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.list = data

  }

  close() {
    this.dialogRef.close();
  }

  replaceDate(s) {

    try {
      return s.replace('$DATA$', moment(this.dtaAppuntamento).format('DD/MM/YYYY')).replace('$ORARIO$', moment(this.dtaAppuntamento).format('HH:mm'))
    } catch (error) {
      return s
    }

  }

  select() {

    this.selectedItem.msg = this.selectedItem.msg
      .replace('$DATA$', moment(this.dtaAppuntamento).format('DD/MM/YYYY'))
      .replace('$ORARIO$', moment(this.dtaAppuntamento).format('HH:mm'))

    this.selectedItem.dtaAppuntamento = this.dtaAppuntamento
    if (this.text && this.text != '') this.selectedItem.msg = this.text

    this.dialogRef.close(this.selectedItem);
  }

  datetimeChange(e) {
    console.log(e)

    this.dtaAppuntamento = e
  }


}








@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
  
  <div class="card" style="padding:1em; width:100%; height:600px">

    <h3>Seleziona il destinatario</h3>

    <div class="row" *ngFor="let i of dest">
      <div class="col">{{i.name}}</div> 
      <div class="col">{{i.dest_type}}</div> 
      <div class="col">{{i.type}}</div> 
      <div class="col">{{i.ref}}</div> 

      <div class="col">
        <button mat-raised-button type="button" class="btn btn-info" (click)="select(i)"> 
        Invia
        </button>
    </div> 
    </div> 


  
  </div>

  `,
  standalone: false
})
export class ListInvitoDialogComponent {

  dest = []

  constructor(
    public dialogRef: MatDialogRef<ListInvitoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    data.forEach((element: any) => {

      if (element.ref.match('@')) {

        this.dest.push({
          name: element.name,
          dest_type: element.dest_type,
          ref: element.ref,
          type: element.ref.split('@')[1].match('pec.|.pec.') ? 'PEC' : 'EMAIL'
        })


      } else {

        this.dest.push({
          name: element.name,
          dest_type: element.dest_type,
          ref: element.ref,
          type: 'SMS'
        })

      }

    });


  }

  close() {
    this.dialogRef.close();
  }

  select(event) {
    this.dialogRef.close(event);
  }


}





@Component({
  selector: 'dialog-contatto-component',
  templateUrl: 'contatto-dialog.html',
  standalone: false
})
export class DialogContattoComponent {


  form: UntypedFormGroup;

  ruoli = [
    'CLIENTE', 'FLEET MNGR', 'DRIVER', 'CENTRO-MAD', 'FILIALE'
  ]

  item: IContattoPerizia

  constructor(
    private db: MysqlService,
    public dialogRef: MatDialogRef<DialogContattoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { item: IContattoPerizia },
    private formBuilder: UntypedFormBuilder,
  ) {

    this.form = this.formBuilder.group({
      ruolo: ['', [Validators.required]],
      inviaMail: ['', Validators.required],
      nome: ['',],
      cognome: ['',],
      email: ['', Validators.email],
      telefono: ['',],
    })

    this.item = data.item

  }

  close() {
    this.dialogRef.close();
  }

  async ok() {

    let b = await ValidateEmail(this.item.email, this.db)

    if (!b) {
      this.form.markAllAsTouched();
      alert("Attenzione, email non valida")
      return
    }

    this.dialogRef.close(this.item);
  }


}

export interface IOrario {

  type: string //'open', 'closed', 'call',
  weekDay?: number
  hhStart?: string
  hhEnd?: string
  fixedDate?: Date

}

function calcStartDate(date, isMad) {


  let startDate = moment(date)

  if (isMad)
    return startDate.toDate()

  let n = +moment().format('HH') >= 12 ? 3 : 2

  let i = 0
  while (n >= 0 && i < 100) {

    //console.log(n)

    let dw = moment().format('ddd').toUpperCase()

    if (dw != 'SAT' && dw != 'SUN') {
      n--
    }
    i++

    startDate.add(1, 'day')

    //console.log(startDate, date)

  }

  return moment(startDate.format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate()
}

export const getWeekDay = (i) => {
  switch (i) {
    case 0: return 'Domenica';
    case 1: return 'Lunedì';
    case 2: return 'Martedì';
    case 3: return 'Mercoledì';
    case 4: return 'Giovedì';
    case 5: return 'Venerdì';
    case 6: return 'Sabato';

    default: return 'Tutti i giorni'

  }
}

export const getType = (i) => {
  switch (i) {
    case 'open': return 'Aperto';
    case 'call': return 'Su prenotazione';
    case 'closed': return 'Chiuso';

  }
}

export const isCodCliUnipolRental = (codcli) => {
  const codclis = [
    '6028253',
    '6065767',
    '6036073',
    '6091326',
    '6039903',
    '6031322',
    '6076804',
    '6074865',
    '6029914',
    '6045439',
    '6026868',
    '6090206',
    '6019549',
    '6026439',
    '6060425',
    '6041343',
    '6089848',
    '6088624',
    '6049309',
    '6010376',
    '6027693',
    '6038274',
    '6036383',
    '6026442']

  return codclis.findIndex(e => e == codcli) >= 0
}


export const sendRiaddebitoMail = async (item: IPerizia, drivers: IContattoPerizia[], fleetmanagers: IContattoPerizia[], db) => {

  let recipients = []

  let emailSostitutiva = await db.unipolrental_get_destinatari_riaddebito(item.urCodiceCliente)


  if (+item.urCodiceCliente == 6010277) {
    recipients.push('manutenzioni.targati@coopservice.it')

  } else if (isCodCliUnipolRental(item.urCodiceCliente)) {
    recipients.push('parcoautoazienda@unipolsai.it')

  } else if (emailSostitutiva?.email && emailSostitutiva?.email != '') {

    recipients.push(emailSostitutiva?.email)

  } else {

    for (const fleetmanager of fleetmanagers) {
      if (fleetmanager && fleetmanager.email && fleetmanager.email != '') recipients.push(fleetmanager.email)
    }

    if (recipients.length <= 0)
      for (const driver of drivers) {
        if (driver && driver.email && driver.email != '') recipients.push(driver.email)
      }
  }

  /*
    if (fleetmanager && fleetmanager.email && fleetmanager.email != '') recipients.push(fleetmanager.email)
    else if (driver && driver.email && driver.email != '') recipients.push(driver.email)
  */


  if (recipients.length <= 0) {
    alert("Attenzione, nessun contatto email presente per il ruolo DRIVER o FLEET MANAGER")
    return
  }

  // CONCAT('',perizie.id,'/', MD5(CONCAT(perizie.id , '-GGPassword00!'))) AS Link,

  let url = `https://xpert.ggroup.cloud/#/pub/${item.id}/${MD5(item.id + '-GGPassword00!')}`

  let html = `

Gentile Cliente,<br>
<br>
in riferimento al contratto di noleggio relativo alla vettura in oggetto siamo ad inviarLe il seguente link, tramite il quale potra' accedere al materiale relativo alla perizia effettuata in sede di riconsegna. <br>
<br>
<a href="${url}">ACCEDI AL PORTALE</a><br>
<br>
Effettuate le dovute verifiche, qualora riscontrasse eventuali incongruenze, ci puo' contattare tramite l'area dedicata alla richiesta di chiarimenti all'interno della pagina.<br>
<br>
In caso di mancato riscontro entro 5 giorni lavorativi dalla data della presente, Unipol Rental provvedera' ad un addebito pari alla quantificazione dei danni rilevati e non denunciati in corso contratto.<br>
<br>
La ringraziamo per l'attenzione prestata e Le inviamo un cordiale saluto.<br>
<br>
Unipol Rental S.p.a.<br>
Via G.B. Vico, 10/c<br>
42124 Reggio Emilia<br>
http://www.unipolrental.it<br>

<br><br><br>

<p>
La presente e-mail e' stata generata automaticamente da un indirizzo di posta elettronica di solo invio; 
si chiede pertanto di non rispondere al messaggio ma scrivere a servizio.riconsegna@unipolrental.it
</p>

`


  let msg = {
    recipientEmail: recipients.join('; '),
    senderName: 'RICONSEGNE UNIPOLRENTAL',
    subject: 'Informazioni di riaddebito per il veicolo UnipolRental targato ' + item.targa,
    text: html,
    html: html,
    attachments: [],
    pec: 2,
    bcc: '', cc: ['servizio.riconsegna@unipolrental.it'].join(';'),
    tab: 'perizie',
    idTable: item.id

  }

  db.send_mail(msg).then(res => {
    if (res.status != 'OK') {
      alert(JSON.stringify(res))
      return
    }

    let reminder: IPeriziaReminder = {
      idPerizia: item.id,
      descrizione: 'Inviata email riaddebito',
      //dtaReminder: new Date(),
      categoria: 'RIA EMAIL',
      categoria1: 'SENT'
    }

    db.perizie_reminder_upsert(reminder)

  }).catch(err => console.error(err))


}


export const sendConfirmMail = async (item: IPerizia, drivers: IContattoPerizia[], fleetmanagers: IContattoPerizia[], centro, ispettore, salacontrollo, centriMad, db) => {

  let txtMAD = ''
  //console.clear()
  console.log(item)
  if (item.madTarga && item.madTarga != '') {
    //console.clear()
    console.log('MAD')
    txtMAD = `
        In concomitanza alla restituzione il veicolo nuovo da ritirare e' il seguente: 
        Modello:
        Targato: ${item.madTarga}

      `
  }


  let recipients = []

  if (isCodCliUnipolRental(item.urCodiceCliente)) {
    recipients.push('parcoautoazienda@unipolsai.it')
  } else {
    for (const driver of drivers) {
      if (driver && driver.email && driver.email != '') recipients.push(driver.email)
    }

    for (const fleetmanager of fleetmanagers) {
      if (fleetmanager && fleetmanager.email && fleetmanager.email != '') recipients.push(fleetmanager.email)
    }


  }


  for (const centroMad of centriMad) {
    if (centroMad && centroMad.email && centroMad.email != '') recipients.push(centroMad.email)
  }


  if (recipients.length <= 0) {
    alert("Attenzione, nessun contatto email presente per il ruolo DRIVER o FLEET MANAGER")
    return
  }



  let datePipe = new DatePipe("it-it")
  let d = datePipe.transform(item.dtaAppuntamento, 'dd/MM/yyyy', '+0000')
  let h = datePipe.transform(item.dtaAppuntamento, 'HH:mm', '+0000')

  let referente = ispettore?.nome ? `${ispettore?.nome} ${ispettore?.cognome}` : `${salacontrollo?.nome} ${salacontrollo?.cognome}`


  //Telefono:                    ${centro?.TELEFONO}

  let text = `
    Buongiorno,

    come da accordi telefonici di seguito il riepilogo dell'appuntamento di restituzione del veicolo di UnipolRental fissato per il giorno ${d} ${h}

    Concessionario               ${centro?.RAGIONE_SOCIALE ? centro?.RAGIONE_SOCIALE : item.noteCarico}
    Indirizzo:                   ${centro?.COMUNE ? centro?.COMUNE : item.citta} ${centro?.CAP ? centro?.CAP : item.cap} ${centro?.INDIRIZZO_RESIDENZA ? centro?.INDIRIZZO_RESIDENZA : item.indirizzo}
    Referente incaricato:        ${referente}
    Data appuntamento:           ${d}
    Orario appuntamento:         ${h}

    In concomitanza alla riconsegna del veicolo vi ricordiamo che dovranno essere restituiti le seguenti dotazioni:

    - Doppie Chiavi
    - Libretto di circolazione originale
    - Manuale di istruzione
    - Manuale di manutenzione
    
    Inoltre vi chiediamo la gentilezza di riconsegnare il veicolo pulito internamente ed esternamente.

    Ricordiamo che, nel caso in cui la persona incaricata alla riconsegna del veicolo sia differente dall'utilizzatore, e' necessario presentare una delega firmata da quest'ultimo.

    ${txtMAD}
    Grazie.
    Restituzioni UnipolRental
    Tel. 800 - 936.603  Tasto 3
    servizio.riconsegna@unipolrental.it
    


    
    La presente e-mail e' stata generata automaticamente da un indirizzo di posta elettronica di solo invio; 
    si chiede pertanto di non rispondere al messaggio ma scrivere a servizio.riconsegna@unipolrental.it
    `


  const calendarDoc = new ICal({
    id: item.id,
    start: moment(item.dtaAppuntamento).utc(false),
    end: moment(item.dtaAppuntamento).utc(false).add(item.timeRequired, 'minutes'),
    summary: "Riconsegna del veicolo targato " + item.targa,
    description: text,
    location: `${item.noteCarico}, ${item.indirizzo}, ${item.citta}`,
  });


  let cc = ['servizio.riconsegna@unipolrental.it']

  let rec1 = []
  if (centro?.INDIRIZZO_EMAIL) cc.push(centro.INDIRIZZO_EMAIL)
  if (ispettore?.email2) cc.push(ispettore.email2)
  if (ispettore?.Email2) cc.push(ispettore.Email2)
  if (ispettore?.email2) rec1.push(ispettore.email2)
  if (ispettore?.Email2) rec1.push(ispettore.Email2)

  if (salacontrollo?.email2) cc.push(salacontrollo.email2)
  if (salacontrollo?.Email2) cc.push(salacontrollo.Email2)
  if (salacontrollo?.email2) rec1.push(salacontrollo.email2)
  if (salacontrollo?.Email2) rec1.push(salacontrollo.Email2)


  let ics_text

  //console.clear()
  console.log(calendarDoc.toString())

  try {
    ics_text = window.btoa(calendarDoc.toString()
      .split('’').join("'") // fix per errore btoa
    )
  } catch (error) {
    ics_text = ''
  }

  let ics = {
    fileContent: ics_text,
    fileName: 'invite.ics',
    mime: 'text/calendar'
  }

  let vdr = {
    fileContent: (await db.unipolrental_get_vdr(item.id, true)).base64,
    fileName: 'VerbaleDiRiconsegna.pdf',
    mime: 'application/pdf'
  }

  let msg = {
    recipientEmail: recipients.join('; '),
    senderName: 'RICONSEGNE UNIPOLRENTAL',
    subject: 'Incarico di restituzione veicolo UnipolRental targato ' + item.targa + ' - ' + getFiliale(item.provincia).provFiliale + ':',
    text: text,
    html: '',
    attachments: [ics],
    pec: 2,
    bcc: 'secondolivello@unipolrental.it', cc: cc.join(';'),
    tab: 'perizie',
    idTable: item.id
  }

  db.send_mail(msg).then(res => {
    if (res.status != 'OK') {
      alert(JSON.stringify(res))
      return
    }
  }).catch(err => console.error(err))

  let text1 = `
  Si allega il verbale di riconsegna precompilato da stampare anticipatamente per lo svolgimento dell'incarico in oggetto.
  Vi ricordiamo che il verbale va restituito compilato in ogni sua parte entro 1 ora dalla fine dell'ispezione al numero whatsapp: +39 375 6557507.

  ATTENZIONE: il documento da voi inviato verrá inoltrato anche al cliente ed al driver. Occorre che la foto sia ben fatta.
  `


  let msg1 = {
    recipientEmail: rec1.join('; '),
    senderName: 'RICONSEGNE UNIPOLRENTAL',
    subject: 'Verbale di riconsegna ' + item.targa + ' ' + item.provincia,
    text: text1,
    html: '',
    attachments: [vdr],
    pec: 0,
    bcc: '', cc: 'servizio.riconsegna@unipolrental.it'
  }

  db.send_mail(msg1).then(res => {
    if (res.status != 'OK') {
      alert(JSON.stringify(res))
      return
    }
  }).catch(err => console.error(err))

}



class ICal {



  _data = {
    id: undefined,
    start: undefined,
    end: undefined,
    summary: '',
    description: '',
    location: '',
    url: ''
  }

  constructor(data: any) {

    this._data = data

  }

  toString() {

    let uid = this._data.id

    let now = moment().format('YYYYMMDD HHmmss').replace(' ', 'T')
    let dtstart = moment(this._data.start).format('YYYYMMDD HHmmssZ').replace(' ', 'T')
    let dtend = moment(this._data.end).format('YYYYMMDD HHmmssZ').replace(' ', 'T')

    let summary = this._data.summary
    let description = this._data.description
    let url = this._data.url

    let location = this._data.location

    let s = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//-/mycal-generator//EN
NAME:My Cal
X-WR-CALNAME:My Cal
BEGIN:VEVENT
UID: ${uid}
SEQUENCE:0
DTSTAMP:${now}
DTSTART:${dtstart}
DTEND:${dtend}
SUMMARY:${summary}
LOCATION:${location}
DESCRIPTION:${description}
URL;VALUE=URI:${url}
END:VEVENT
END:VCALENDAR`

    return s



  }

}


export async function ValidateEmail(email, db?): Promise<boolean> {




  //console.clear()

  if (!email || email == '') return true

  let tmp = email.split(';')

  //console.log(tmp)

  for (const e of tmp) {

    //fix per email sporche di sifa 
    if (e.split('@').length > 2) return false

    //console.log(`ValidateEmail - ${e} `)
    let b = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(e)
    //console.log(`ValidateEmail - ${e} ${b}`)

    if (!b) return false
    if (db) {
      let r = await db.perizie_contatti_check_mx(e)
      if (!r.mxcheck) {
        console.log('mx error ' + e)
        return false
      }
    }
  }


  return true
}


export const getFiliale = (prov: string) => {
  try {
    return [
      {
        "prov": "AG",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "AL",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "AN",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "AO",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "AP",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "AQ",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "AR",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "AT",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "AV",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "BA",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "BG",
        "email": "", provFiliale: 'BG'
      },
      {
        "prov": "BI",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "BL",
        "email": "", provFiliale: 'TV'
      },
      {
        "prov": "BN",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "BO",
        "email": "", provFiliale: 'BO'
      },
      {
        "prov": "BR",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "BS",
        "email": "", provFiliale: 'BS'
      },
      {
        "prov": "BT",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "BZ",
        "email": "", provFiliale: 'BZ'
      },
      {
        "prov": "CA",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "CB",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "CE",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "CH",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "CI",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "CL",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "CN",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "CO",
        "email": "", provFiliale: 'MICM'
      },
      {
        "prov": "CR",
        "email": "", provFiliale: 'MI'
      },
      {
        "prov": "CS",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "CT",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "CZ",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "EN",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "FC",
        "email": "", provFiliale: 'RA'
      },
      {
        "prov": "FE",
        "email": "", provFiliale: 'BO'
      },
      {
        "prov": "FG",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "FI",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "FM",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "FR",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "RM",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "GE",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "GO",
        "email": "", provFiliale: 'TV'
      },
      {
        "prov": "GR",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "IM",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "IS",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "KR",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "LC",
        "email": "", provFiliale: 'MICM'
      },
      {
        "prov": "LE",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "LI",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "LO",
        "email": "", provFiliale: 'MI'
      },
      {
        "prov": "LT",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "LU",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "MB",
        "email": "", provFiliale: 'MICM'
      },
      {
        "prov": "MICM",
        "email": "", provFiliale: 'MICM'
      },
      {
        "prov": "MC",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "ME",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "MI",
        "email": "", provFiliale: 'MI'
      },
      {
        "prov": "MN",
        "email": "", provFiliale: 'REG'
      },
      {
        "prov": "MO",
        "email": "", provFiliale: 'MO'
      },
      {
        "prov": "MS",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "MT",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "NA",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "NO",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "NU",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "OG",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "OR",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "OT",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "PA",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "PC",
        "email": "", provFiliale: 'PR'
      },
      {
        "prov": "PD",
        "email": "", provFiliale: 'PD'
      },
      {
        "prov": "PE",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "PG",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "PI",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "PN",
        "email": "", provFiliale: 'TV'
      },
      {
        "prov": "PO",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "PR",
        "email": "", provFiliale: 'PR'
      },
      {
        "prov": "PT",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "PU",
        "email": "", provFiliale: 'RN'
      },
      {
        "prov": "PV",
        "email": "", provFiliale: 'MI'
      },
      {
        "prov": "PZ",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "RA",
        "email": "", provFiliale: 'RA'
      },
      {
        "prov": "RC",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "RE",
        "email": "", provFiliale: 'REG'
      },
      {
        "prov": "RG",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "RI",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "RN",
        "email": "", provFiliale: 'RN'
      },
      {
        "prov": "RO",
        "email": "", provFiliale: 'PD'
      },
      {
        "prov": "Roma",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "SA",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "SI",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "SO",
        "email": "", provFiliale: 'MICM'
      },
      {
        "prov": "SP",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "SR",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "SS",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "SV",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "TA",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "TE",
        "email": "", provFiliale: 'AN'
      },
      {
        "prov": "TN",
        "email": "", provFiliale: 'TN'
      },
      {
        "prov": "TO",
        "email": "", provFiliale: 'TO'
      },
      {
        "prov": "TP",
        "email": "", provFiliale: 'BA'
      },
      {
        "prov": "TR",
        "email": "", provFiliale: 'FI'
      },
      {
        "prov": "TS",
        "email": "", provFiliale: 'TV'
      },
      {
        "prov": "TV",
        "email": "", provFiliale: 'TV'
      },
      {
        "prov": "UD",
        "email": "", provFiliale: 'TV'
      },
      {
        "prov": "VA",
        "email": "", provFiliale: 'MICM'
      },
      {
        "prov": "VB",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "VC",
        "email": "", provFiliale: 'GE'
      },
      {
        "prov": "VE",
        "email": "", provFiliale: 'TV'
      },
      {
        "prov": "VI",
        "email": "", provFiliale: 'VR'
      },
      {
        "prov": "VR",
        "email": "", provFiliale: 'VR'
      },
      {
        "prov": "VS",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "VT",
        "email": "", provFiliale: 'RM'
      },
      {
        "prov": "VV",
        "email": "", provFiliale: 'RM'
      }
    ].find(e => e.prov == prov.toUpperCase().trim())
  } catch (error) {

  }

  return {} as any



}



export const adz_def: any[] = [
  {
    "ValorePerizia": "codice_ricambio",
    "NomeTXT": "EXPDANNI",
    "da": 88,
    "TOT": 22
  },
  {
    "ValorePerizia": "voce_di_danno",
    "NomeTXT": "EXPDANNI",
    "da": 119,
    "TOT": 80
  },
  {
    "ValorePerizia": "sr_tempo",
    "NomeTXT": "EXPDANNI",
    "da": 203,
    "TOT": 5
  },
  {
    "ValorePerizia": "la_tempo",
    "NomeTXT": "EXPDANNI",
    "da": 208,
    "TOT": 5
  },
  {
    "ValorePerizia": "ve_tempo",
    "NomeTXT": "EXPDANNI",
    "da": 213,
    "TOT": 5
  },
  {
    "ValorePerizia": "me_tempo",
    "NomeTXT": "EXPDANNI",
    "da": 219,
    "TOT": 5
  },
  {
    "ValorePerizia": "costo_ricambi_imponibile",
    "NomeTXT": "EXPDANNI",
    "da": 223,
    "TOT": 9
  },
  {
    "ValorePerizia": "sr_diff",
    "NomeTXT": "EXPDANNI",
    "da": 232,
    "TOT": 1
  },
  {
    "ValorePerizia": "la_diff",
    "NomeTXT": "EXPDANNI",
    "da": 233,
    "TOT": 1
  },
  {
    "ValorePerizia": "ve_diff",
    "NomeTXT": "EXPDANNI",
    "da": 234,
    "TOT": 1
  },
  {
    "ValorePerizia": "*",
    "NomeTXT": "EXPDANNI",
    "da": 238,
    "TOT": 1
  },
  {
    "ValorePerizia": "detraz_sconto",
    "NomeTXT": "EXPDANNI",
    "da": 239,
    "TOT": 3
  },
  {
    "ValorePerizia": "lato",
    "NomeTXT": "EXPDANNI",
    "da": 247,
    "TOT": 2
  },
  {
    "ValorePerizia": "targa",
    "NomeTXT": "EXPERIZ",
    "da": 16,
    "TOT": 10
  },
  {
    "ValorePerizia": "data_sinistro",
    "NomeTXT": "EXPERIZ",
    "da": 26,
    "TOT": 8
  },
  {
    "ValorePerizia": "telaio",
    "NomeTXT": "EXPERIZ",
    "da": 34,
    "TOT": 20
  },
  {
    "ValorePerizia": "sinistro_numero",
    "NomeTXT": "EXPERIZ",
    "da": 54,
    "TOT": 20
  },
  {
    "ValorePerizia": "cod_agenzia2",
    "NomeTXT": "EXPERIZ",
    "da": 74,
    "TOT": 5
  },
  {
    "ValorePerizia": "perizia_nr",
    "NomeTXT": "EXPERIZ",
    "da": 79,
    "TOT": 8
  },
  {
    "ValorePerizia": "logo_azienda",
    "NomeTXT": "EXPERIZ",
    "da": 87,
    "TOT": 123
  },
  {
    "ValorePerizia": "relazione_di_perizia_per_la_spett",
    "NomeTXT": "EXPERIZ",
    "da": 227,
    "TOT": 43
  },
  {
    "ValorePerizia": "ramo_sinistro",
    "NomeTXT": "EXPERIZ",
    "da": 270,
    "TOT": 20
  },
  {
    "ValorePerizia": "assicurato",
    "NomeTXT": "EXPERIZ",
    "da": 290,
    "TOT": 35
  },
  {
    "ValorePerizia": "controparte",
    "NomeTXT": "EXPERIZ",
    "da": 325,
    "TOT": 35
  },
  {
    "ValorePerizia": "impresa_controparte",
    "NomeTXT": "EXPERIZ",
    "da": 360,
    "TOT": 40
  },
  {
    "ValorePerizia": "esercizio",
    "NomeTXT": "EXPERIZ",
    "da": 400,
    "TOT": 4
  },
  {
    "ValorePerizia": "cod_agenzia1",
    "NomeTXT": "EXPERIZ",
    "da": 404,
    "TOT": 50
  },
  {
    "ValorePerizia": "cod_liquidatore",
    "NomeTXT": "EXPERIZ",
    "da": 454,
    "TOT": 10
  },
  {
    "ValorePerizia": "numero_polizza",
    "NomeTXT": "EXPERIZ",
    "da": 464,
    "TOT": 30
  },
  {
    "ValorePerizia": "il_dannegg_recuperare_iva",
    "NomeTXT": "EXPERIZ",
    "da": 494,
    "TOT": 2
  },
  {
    "ValorePerizia": "data_incarico",
    "NomeTXT": "EXPERIZ",
    "da": 507,
    "TOT": 8
  },
  {
    "ValorePerizia": "data_1_rilievo",
    "NomeTXT": "EXPERIZ",
    "da": 515,
    "TOT": 8
  },
  {
    "ValorePerizia": "localita",
    "NomeTXT": "EXPERIZ",
    "da": 523,
    "TOT": 30
  },
  {
    "ValorePerizia": "c_o",
    "NomeTXT": "EXPERIZ",
    "da": 553,
    "TOT": 3
  },
  {
    "ValorePerizia": "riparazioni",
    "NomeTXT": "EXPERIZ",
    "da": 556,
    "TOT": 30
  },
  {
    "ValorePerizia": "n_fotog",
    "NomeTXT": "EXPERIZ",
    "da": 586,
    "TOT": 2
  },
  {
    "ValorePerizia": "intestatario",
    "NomeTXT": "EXPERIZ",
    "da": 588,
    "TOT": 35
  },
  {
    "ValorePerizia": "indirizzo",
    "NomeTXT": "EXPERIZ",
    "da": 623,
    "TOT": 49
  },
  {
    "ValorePerizia": "a_capo_cod_fisc",
    "NomeTXT": "EXPERIZ",
    "da": 672,
    "TOT": 17
  },
  {
    "ValorePerizia": "veicolo_marca_versione_modello",
    "NomeTXT": "EXPERIZ",
    "da": 816,
    "TOT": 130
  },
  {
    "ValorePerizia": "stato_d_uso",
    "NomeTXT": "EXPERIZ",
    "da": 946,
    "TOT": 15
  },
  {
    "ValorePerizia": "km",
    "NomeTXT": "EXPERIZ",
    "da": 961,
    "TOT": 10
  },
  {
    "ValorePerizia": "pneumatici",
    "NomeTXT": "EXPERIZ",
    "da": 968,
    "TOT": 3
  },
  {
    "ValorePerizia": "colore",
    "NomeTXT": "EXPERIZ",
    "da": 971,
    "TOT": 20
  },
  {
    "ValorePerizia": "tipo_smalto",
    "NomeTXT": "EXPERIZ",
    "da": 991,
    "TOT": 34
  },
  {
    "ValorePerizia": "allestimenti_dotazioni",
    "NomeTXT": "EXPERIZ",
    "da": 1004,
    "TOT": 21
  },
  {
    "ValorePerizia": "codice_omologazione",
    "NomeTXT": "EXPERIZ",
    "da": 1074,
    "TOT": 20
  },
  {
    "ValorePerizia": "portata",
    "NomeTXT": "EXPERIZ",
    "da": 1094,
    "TOT": 6
  },
  {
    "ValorePerizia": "tara",
    "NomeTXT": "EXPERIZ",
    "da": 1100,
    "TOT": 5
  },
  {
    "ValorePerizia": "passo",
    "NomeTXT": "EXPERIZ",
    "da": 1105,
    "TOT": 5
  },
  {
    "ValorePerizia": "posti",
    "NomeTXT": "EXPERIZ",
    "da": 1110,
    "TOT": 2
  },
  {
    "ValorePerizia": "assi",
    "NomeTXT": "EXPERIZ",
    "da": 1112,
    "TOT": 2
  },
  {
    "ValorePerizia": "valore_commerciale",
    "NomeTXT": "EXPERIZ",
    "da": 1114,
    "TOT": 10
  },
  {
    "ValorePerizia": "valore_relitto",
    "NomeTXT": "EXPERIZ",
    "da": 1124,
    "TOT": 10
  },
  {
    "ValorePerizia": "valore_per_differenza",
    "NomeTXT": "EXPERIZ",
    "da": 1134,
    "TOT": 10
  },
  {
    "ValorePerizia": "spese_accessorie",
    "NomeTXT": "EXPERIZ",
    "da": 1144,
    "TOT": 10
  },
  {
    "ValorePerizia": "gia_targa",
    "NomeTXT": "EXPERIZ",
    "da": 1192,
    "TOT": 10
  },
  {
    "ValorePerizia": "prima_immatricolazione",
    "NomeTXT": "EXPERIZ",
    "da": 1202,
    "TOT": 8
  },
  {
    "ValorePerizia": "coerenza_danno_dinamica1",
    "NomeTXT": "EXPERIZ",
    "da": 1210,
    "TOT": 13
  },
  {
    "ValorePerizia": "coerenza_danno_dinamica2",
    "NomeTXT": "EXPERIZ",
    "da": 1210,
    "TOT": 13
  },
  {
    "ValorePerizia": "A3",
    "NomeTXT": "EXPERIZ",
    "da": 1223,
    "TOT": 2
  },
  {
    "ValorePerizia": "B1",
    "NomeTXT": "EXPERIZ",
    "da": 1225,
    "TOT": 2
  },
  {
    "ValorePerizia": "B3",
    "NomeTXT": "EXPERIZ",
    "da": 1227,
    "TOT": 2
  },
  {
    "ValorePerizia": "C3",
    "NomeTXT": "EXPERIZ",
    "da": 1229,
    "TOT": 2
  },
  {
    "ValorePerizia": "A1",
    "NomeTXT": "EXPERIZ",
    "da": 1231,
    "TOT": 2
  },
  {
    "ValorePerizia": "D0",
    "NomeTXT": "EXPERIZ",
    "da": 1233,
    "TOT": 2
  },
  {
    "ValorePerizia": "F0",
    "NomeTXT": "EXPERIZ",
    "da": 1235,
    "TOT": 2
  },
  {
    "ValorePerizia": "E0",
    "NomeTXT": "EXPERIZ",
    "da": 1237,
    "TOT": 2
  },
  {
    "ValorePerizia": "C1",
    "NomeTXT": "EXPERIZ",
    "da": 1239,
    "TOT": 2
  },
  {
    "ValorePerizia": "A2",
    "NomeTXT": "EXPERIZ",
    "da": 1241,
    "TOT": 2
  },
  {
    "ValorePerizia": "B2",
    "NomeTXT": "EXPERIZ",
    "da": 1243,
    "TOT": 2
  },
  {
    "ValorePerizia": "B4",
    "NomeTXT": "EXPERIZ",
    "da": 1245,
    "TOT": 2
  },
  {
    "ValorePerizia": "C2",
    "NomeTXT": "EXPERIZ",
    "da": 1247,
    "TOT": 2
  },
  {
    "ValorePerizia": "riparatore",
    "NomeTXT": "EXPERIZ",
    "da": 1254,
    "TOT": 35
  },
  {
    "ValorePerizia": "fascia_carrozzeria",
    "NomeTXT": "EXPERIZ",
    "da": 1289,
    "TOT": 3
  },
  {
    "ValorePerizia": "tot_tempi_sr",
    "NomeTXT": "EXPERIZ",
    "da": 1294,
    "TOT": 5
  },
  {
    "ValorePerizia": "tot_tempi_la",
    "NomeTXT": "EXPERIZ",
    "da": 1299,
    "TOT": 5
  },
  {
    "ValorePerizia": "tot_tempi_ve",
    "NomeTXT": "EXPERIZ",
    "da": 1304,
    "TOT": 5
  },
  {
    "ValorePerizia": "tot_tempi_me",
    "NomeTXT": "EXPERIZ",
    "da": 1309,
    "TOT": 5
  },
  {
    "ValorePerizia": "tot_tempi_ricambi",
    "NomeTXT": "EXPERIZ",
    "da": 1314,
    "TOT": 11
  },
  {
    "ValorePerizia": "supplemento_doppio_strato",
    "NomeTXT": "EXPERIZ",
    "da": 1325,
    "TOT": 5
  },
  {
    "ValorePerizia": "supplemento_finitura",
    "NomeTXT": "EXPERIZ",
    "da": 1330,
    "TOT": 5
  },
  {
    "ValorePerizia": "tempo_agg_per_verniciatura",
    "NomeTXT": "EXPERIZ",
    "da": 1335,
    "TOT": 5
  },
  {
    "ValorePerizia": "totale_tempi_suppl",
    "NomeTXT": "EXPERIZ",
    "da": 1340,
    "TOT": 5
  },
  {
    "ValorePerizia": "totale_tempi_ve",
    "NomeTXT": "EXPERIZ",
    "da": 1345,
    "TOT": 5
  },
  {
    "ValorePerizia": "valore_assicurato",
    "NomeTXT": "EXPERIZ",
    "da": 1350,
    "TOT": 10
  },
  {
    "ValorePerizia": "valore_a_nuovo",
    "NomeTXT": "EXPERIZ",
    "da": 1360,
    "TOT": 10
  },
  {
    "ValorePerizia": "insufficienza_assicurativa",
    "NomeTXT": "EXPERIZ",
    "da": 1370,
    "TOT": 2
  },
  {
    "ValorePerizia": "coefficiente_di_riduzione",
    "NomeTXT": "EXPERIZ",
    "da": 1372,
    "TOT": 2
  },
  {
    "ValorePerizia": "danni_ard_totale_imponibile",
    "NomeTXT": "EXPERIZ",
    "da": 1374,
    "TOT": 10
  },
  {
    "ValorePerizia": "detrazione_sui_ricambi_con_asterisco",
    "NomeTXT": "EXPERIZ",
    "da": 1384,
    "TOT": 10
  },
  {
    "ValorePerizia": "tot_imponibile_sinistra",
    "NomeTXT": "EXPERIZ",
    "da": 1404,
    "TOT": 10
  },
  {
    "ValorePerizia": "tot_iva_compresa_sinistra",
    "NomeTXT": "EXPERIZ",
    "da": 1414,
    "TOT": 10
  },
  {
    "ValorePerizia": "franchigia_scoperto_con_il_max_min_del_perc",
    "NomeTXT": "EXPERIZ",
    "da": 1424,
    "TOT": 2
  },
  {
    "ValorePerizia": "franchigia_scoperto_con_il_max_min_del",
    "NomeTXT": "EXPERIZ",
    "da": 1426,
    "TOT": 10
  },
  {
    "ValorePerizia": "totale_sinistra",
    "NomeTXT": "EXPERIZ",
    "da": 1436,
    "TOT": 11
  },
  {
    "ValorePerizia": "indennizzo_contrattuale",
    "NomeTXT": "EXPERIZ",
    "da": 1447,
    "TOT": 11
  },
  {
    "ValorePerizia": "ricambi_imponibile",
    "NomeTXT": "EXPERIZ",
    "da": 1458,
    "TOT": 10
  },
  {
    "ValorePerizia": "ricambi_iva",
    "NomeTXT": "EXPERIZ",
    "da": 1468,
    "TOT": 10
  },
  {
    "ValorePerizia": "ricambi_ivato",
    "NomeTXT": "EXPERIZ",
    "da": 1478,
    "TOT": 11
  },
  {
    "ValorePerizia": "materiali_di_consumo",
    "NomeTXT": "EXPERIZ",
    "da": 1489,
    "TOT": 10
  },
  {
    "ValorePerizia": "materiali_di_consumo_iva",
    "NomeTXT": "EXPERIZ",
    "da": 1499,
    "TOT": 10
  },
  {
    "ValorePerizia": "materiali_di_consumo_ivato",
    "NomeTXT": "EXPERIZ",
    "da": 1509,
    "TOT": 11
  },
  {
    "ValorePerizia": "uso_dime_imp",
    "NomeTXT": "EXPERIZ",
    "da": 1520,
    "TOT": 10
  },
  {
    "ValorePerizia": "uso dime iva",
    "NomeTXT": "EXPERIZ",
    "da": 1530,
    "TOT": 10
  },
  {
    "ValorePerizia": "uso_dime_ivato",
    "NomeTXT": "EXPERIZ",
    "da": 1540,
    "TOT": 11
  },
  {
    "ValorePerizia": "totale_mano_d_opera_carrozzeria",
    "NomeTXT": "EXPERIZ",
    "da": 1551,
    "TOT": 10
  },
  {
    "ValorePerizia": "totale_mano_d_opera_carrozzeria_iva",
    "NomeTXT": "EXPERIZ",
    "da": 1561,
    "TOT": 10
  },
  {
    "ValorePerizia": "totale_mano_d_opera_carrozzeria_ivato",
    "NomeTXT": "EXPERIZ",
    "da": 1571,
    "TOT": 11
  },
  {
    "ValorePerizia": "mdo_meccanica_imp",
    "NomeTXT": "EXPERIZ",
    "da": 1582,
    "TOT": 10
  },
  {
    "ValorePerizia": "mdo_meccanica_iva",
    "NomeTXT": "EXPERIZ",
    "da": 1592,
    "TOT": 10
  },
  {
    "ValorePerizia": "mdo_meccanica_ivato",
    "NomeTXT": "EXPERIZ",
    "da": 1602,
    "TOT": 11
  },
  {
    "ValorePerizia": "importo_richiesto",
    "NomeTXT": "EXPERIZ",
    "da": 1613,
    "TOT": 11
  },
  {
    "ValorePerizia": "importo_concordato",
    "NomeTXT": "EXPERIZ",
    "da": 1624,
    "TOT": 14
  },
  {
    "ValorePerizia": "fermo_tecnico",
    "NomeTXT": "EXPERIZ",
    "da": 1638,
    "TOT": 3
  },
  {
    "ValorePerizia": "osservazioni",
    "NomeTXT": "EXPERIZ",
    "da": 1641,
    "TOT": 230
  },
  {
    "ValorePerizia": "data_consegna",
    "NomeTXT": "EXPERIZ",
    "da": 1871,
    "TOT": 8
  },
  {
    "ValorePerizia": "nr_isp",
    "NomeTXT": "EXPERIZ",
    "da": 1879,
    "TOT": 8
  },
  {
    "ValorePerizia": "versione_listino_ricambi",
    "NomeTXT": "EXPERIZ",
    "da": 1887,
    "TOT": 20
  },
  {
    "ValorePerizia": "listino_ricambi_aggiornato_a",
    "NomeTXT": "EXPERIZ",
    "da": 1907,
    "TOT": 8
  },
  {
    "ValorePerizia": "ore_mdo_carrozzeria",
    "NomeTXT": "EXPERIZ",
    "da": 1916,
    "TOT": 5
  },
  {
    "ValorePerizia": "costo_mdo_carrozzeria",
    "NomeTXT": "EXPERIZ",
    "da": 1921,
    "TOT": 7
  },
  {
    "ValorePerizia": "ore_mdo_meccanica",
    "NomeTXT": "EXPERIZ",
    "da": 1928,
    "TOT": 4
  },
  {
    "ValorePerizia": "costo_mdo_meccanica",
    "NomeTXT": "EXPERIZ",
    "da": 1932,
    "TOT": 5
  },
  {
    "ValorePerizia": "codice_software_house",
    "NomeTXT": "EXPERIZ",
    "da": 1941,
    "TOT": 15
  },
  {
    "ValorePerizia": "firma",
    "NomeTXT": "EXPERIZ2",
    "da": 619,
    "TOT": 40
  }
]


const TARIFFARIO_UR_SIFA = [
  { id: 0, descr: 'Contratto UR', doppie_chiavi: 300, danneggiamento_targa: 350, smarrimnento_documenti: 300, auto_non_lavata_esterno: 50, auto_non_lavata_esterno_e_interno: 120 },
  { id: 1, descr: 'Contratto Sifà: Pre 2021', doppie_chiavi: 250, danneggiamento_targa: 250, smarrimnento_documenti: 250, auto_non_lavata_esterno: 0, auto_non_lavata_esterno_e_interno: 0 },
  { id: 2, descr: 'Contratto Sifà: Post 2021', doppie_chiavi: 250, danneggiamento_targa: 250, smarrimnento_documenti: 250, auto_non_lavata_esterno: 25, auto_non_lavata_esterno_e_interno: 25 },
  { id: 3, descr: 'Contratto Sifà: Post 2021', doppie_chiavi: 250, danneggiamento_targa: 250, smarrimnento_documenti: 250, auto_non_lavata_esterno: 25, auto_non_lavata_esterno_e_interno: 25 },
  { id: 4, descr: 'Contratto Sifà: Post 2021', doppie_chiavi: 250, danneggiamento_targa: 250, smarrimnento_documenti: 250, auto_non_lavata_esterno: 25, auto_non_lavata_esterno_e_interno: 25 },
]

/*
export function createMultipleEmailValidator(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value) {
      return null;
    }

    ValidateEmail(value).then(isValid => {

      return !isValid ? { multipleEmail: true } : { multipleEmail: false };
    })

  }
}*/