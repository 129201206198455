<div #toggle class="wrapper">

    <!--
    <div class="topmenu">
        <mat-menu #appMenu="matMenu" yPosition="above">
            <button mat-menu-item>Settings</button>
            <button mat-menu-item>Help</button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu">
            File
        </button>
    </div>
    -->
    <div class="navbar-toggler" (click)="sidebarToggle()">
        <!-- <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span> -->
        <mat-icon>menu</mat-icon>
    </div>

    <div class="sidebar" *ngIf="user" data-color="danger" [ngClass]="easteregg"
        [ngStyle]="{'background': db.isTest() ? 'linear-gradient(90deg, rgba(40,1,45,0.3) 0%, rgba(207,9,9,0.3) 60%) !important' : 
        'linear-gradient(139deg, rgba(179, 179, 179, 0.3) 0%, #3f62b54a 60%) rgb(255, 255, 255)', 'background-color': db.isTest() ? '#fff !important' : '#fff' }">

        <div class="sb">
            <app-sidebar-tree></app-sidebar-tree>



            <p
                style="text-align: center; border-top:1px solid gray; border-bottom:1px solid gray; margin:30px; padding:5px">
                {{'VERSION' | translate  }}:<br>{{version}} (A{{versionAg}})



            </p>
        </div>

    </div>

    <div class="cnt"
        [ngClass]="{'main-panel': user!=undefined && user!={}, 'main-panel-nologin': user==undefined || user=={}}">

        <router-outlet></router-outlet>

        <!--        
        <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div>

        -->

    </div>
</div>