"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
class StreamMetricReport {
  constructor() {
    this.previousMetrics = {};
    this.currentMetrics = {};
  }
}
exports.default = StreamMetricReport;
