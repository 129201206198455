import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
  standalone: false
})
export class LanguageSelectorComponent {


  currentLang

  constructor(
    private translate: TranslateService
  ) {

    this.currentLang = localStorage.getItem('language') ? localStorage.getItem('language') : 'it'

    this.changeLanguage()


  }


  languages = [
    { id: 'it', descr: 'Italiano', flag: '/assets/flags/it.svg' },
    { id: 'en', descr: 'English', flag: '/assets/flags/en.svg' },
    { id: 'de', descr: 'Deutsch', flag: '/assets/flags/de.svg' },
  ]


  changeLanguage(lang = this.currentLang) {

    this.currentLang = lang

    localStorage.setItem('language', this.currentLang)

    this.translate.setDefaultLang('it');
    this.translate.use(this.currentLang);

  }

}
