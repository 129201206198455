import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import * as xlsx from 'xlsx';
@Component({
    selector: 'app-xls-button',
    templateUrl: './xls-button.component.html',
    styleUrls: ['./xls-button.component.scss'],
    standalone: false
})
export class XlsButtonComponent implements OnInit {

  constructor() { }
  @Input() Tablename;

  ngOnInit() {
  }

  export() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(document.getElementById(this.Tablename));
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, this.Tablename + '.xlsx');
  }


}
