<div class="main-content" style=" height: calc(100vh - 20px)!important; padding-top:0; margin-bottom:0; ">
    <div class="card" style="padding:1em">

        <div class="card-header card-header-info">
            <h4 class="card-title">

                AI CHECK
                <!--
        <div style="float:right"><a (click)="close()"><i class="material-icons">close</i></a>
        </div>
        -->
            </h4>
        </div><br>

        <div class="row" style="margin-left: 1rem;">
            <h4 style="color: darkgrey">Scegli la fotografia da verificare</h4>
        </div>
        <div class="row" style="margin-left: 1rem;">
            <!-- <img class="image-1 bshadow" style="max-height: 200px;" [src]="img_1" (click)="checkBase64(img_1)"/>
            <img class="image-2 bshadow ml-4" style="max-height: 200px;" [src]="img_4" (click)="checkBase64(img_4)" /> -->

            <div class="col-12 col-lg-2">
                <img class="image-1 bshadow" style="max-height: 200px;" [src]="img_2" (click)="checkBase64(img_2)" />
            </div>
            <div class="col-12 col-lg-2">
                <img class="image-2 bshadow" style="max-height: 200px;" [src]="img_3" (click)="checkBase64(img_3)"
                    [ngStyle]="{ 'margin-left': !isMobile ? '1.5rem' : '' }" />
            </div>
        </div>

        <div class="row upload-text">
            <div class="col-12 col-lg-2"></div>
            <div class="col-12 col-lg-8 text-center">
                <h3>Oppure carica la foto per verificare se è stata manipolata</h3>
            </div>
            <div class="col-12 col-lg-2"></div>
        </div>

        <div class="row" style="margin-bottom: 2rem;">
            <div class="col-12 col-lg-4"></div>
            <div class="col-12 col-lg-4 text-center">
                <button type="button" mat-raised-button class="btn btn-info" (click)="fileImg.click();">
                    <mat-icon class="upload-icon" (click)="fileImg.click();">cloud_upload</mat-icon> CARICA
                    IMMAGINE</button>
            </div>
            <div class="col-12 col-lg-4"></div>
        </div>


        <input type="file" #fileImg (change)="uploadImg($event)" style="display: none;" accept="image/*">



        <div class="row padding-row" *ngIf="img">

            <!-- <div class="image" *ngIf="isErrorMsg">
                <img class="img-fake" src="/assets/images/fake.png">
            </div> -->

            <div class="col-6">
                <img #imgx [src]="img" (click)="openViewer()"
                    style="max-width:800px;width:100%; height: auto; object-fit: cover; object-position: top center;">
            </div>

            <div class="col-6 text-center" *ngIf="loading" style="margin-top: 4rem;">
                <mat-spinner style="margin:auto"></mat-spinner>
            </div>

            <div class="col-6" *ngIf="msgs?.length>0 && !loading">
                <div class="row">

                    <div class="toast show" *ngFor="let msg of msgs" style="margin-bottom: 1rem;">

                        <div class="toast-content">
                            <i class="fa fa-exclamation-triangle error"></i>

                            <div class="message">
                                <span class="text text-1">Attenzione</span>
                                <span class="text">{{ msg }}</span>
                            </div>
                        </div>
                        <i class="fa-solid fa-x close"></i>

                    </div>

                </div>
            </div>

            <div class="col-6" *ngIf="msgs?.length<=0 && !loading">

                <div class="row">
                    <div class="toast show">

                        <div class="toast-content">
                            <i class="fa fa-check check"></i>

                            <div class="message">
                                <span class="text">CONFORME</span>
                            </div>
                        </div>
                        <i class="fa-solid fa-x close"></i>

                    </div>

                </div>
            </div>

        </div>