import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadVdrDialogComponent } from './detail/upload-vdr-dialogcomponent';
import { Router } from '@angular/router';

@Component({
    selector: 'app-upload-vdr',
    templateUrl: './upload-vdr.component.html',
    styleUrls: ['./upload-vdr.component.scss'],
    standalone: false
})
export class UploadVDRComponent implements OnInit {

  showDialog = false
  img

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  async open(asPage = true) {

    if (asPage) {

      this.router.navigate(['verbali'])

      return
    }

    let dialogRef = await this.dialog.open(UploadVdrDialogComponent)

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
      }
    })
  }

}
