"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * [[RealtimeAttendeePositionInFrame]] information about the attendee's place in the frame.
 */
class RealtimeAttendeePositionInFrame {
  constructor() {
    /**
     * Index of attendee update in the frame starting at zero
     */
    this.attendeeIndex = null;
    /**
     * Number of total attendee updates in the frame
     */
    this.attendeesInFrame = null;
  }
}
exports.default = RealtimeAttendeePositionInFrame;
