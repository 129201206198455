import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'app/services/mysql.service';
import { ActivatedRoute } from '@angular/router';
import { IPerizia, IResult } from 'app/models/perizia.interface';
import { setBlurredUrl } from 'app/components/gallery-result/gallery-result.component';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
    standalone: false
})
export class ReportComponent implements OnInit {
  
  logo;
  blur: boolean

  item: IPerizia;
  result: IResult;
  tot_danni = 0;
  dta

  constructor(private db: MysqlService, private activatedRoute: ActivatedRoute) {

    this.logo = db.getLogo();


    this.activatedRoute.queryParams.subscribe(res => {
      console.log(res)

      res.blur > 0 ? this.blur = true : this.blur = false;


      this.activatedRoute.params.subscribe(params => {
        let id = params['id'];
        let type = params['type'];
        console.log(id);



        this.loadData(id, type);

      });

    })


  }

  loadData(id, type) {

    this.db.get_perizia(id, type).then(res => {
      console.log(res)
      this.item = res.item;
    })

    this.db.get_perizia_result(id).then(async res => {
      console.log(res)
      this.dta = res.data.dta
      this.result = res.data.result;


      for (let i = 0; i < this.result.fotoVetrina.length; i++) {
        this.result.fotoVetrina[i].url = this.db.get_perizia_result_image_url(this.result.fotoVetrina[i].img)
        this.result.fotoVetrina[i].url = setBlurredUrl(this.blur, this.result.fotoVetrina[i].url);
      }

      for (let i = 0; i < this.result.altriElementi.length; i++)
        this.result.altriElementi[i].url = this.db.get_perizia_result_image_url(this.result.altriElementi[i].img)


      for (let i = 0; i < this.result.documenti.length; i++)
        this.result.documenti[i].url = this.db.get_perizia_result_image_url(this.result.documenti[i].img)


      for (let i = 0; i < this.result.damages.length; i++) {


        this.result.damages[i].img1.url = this.db.get_perizia_result_image_url(this.result.damages[i].img1.img)
        this.result.damages[i].img2.url = this.db.get_perizia_result_image_url(this.result.damages[i].img2.img)

        console.log(this.result.damages[i].part)

        console.log(this.result.vehType, this.result.damages[i].part.code)
        await this.db.get_costi_per_parte(this.result.vehType, this.result.damages[i].part.code).then(res => {

          console.log(res)

          let x = '';
          if (res.data['costo_' + this.result.damages[i].severity.toLowerCase()])
            x = res.data['costo_' + this.result.damages[i].severity.toLowerCase()]
          else
            x = res.data['costo_s']

          console.log(x)
          x = x.replace(',', '.')

          this.result.damages[i].cost = +x;

          this.tot_danni += +x

        })

        console.log(this.result.damages[i])

      }




    })

  }


  ngOnInit() {



  }

}
