<div class="cnt card"
  [ngClass]="{'cnt': true, 'cnt_left': position == 'left' , 'cnt_right': position == 'right', 'cnt_center': position == 'center'  }">

  <div class="card-header card-header-info">
    <h4 class="card-title">
      <span *ngIf="descr && descr[i] && !doubleView">{{ descr[i] }} - </span>
      {{ (_files | filterByFileType: filter)[i]?.fileName ? (_files | filterByFileType: filter)[i]?.fileName : (_files | filterByFileType: filter)[i]?.descr }} 
      - {{ (_files | filterByFileType: filter)[i]?.size }}
      <div style="float:right">
        <a (click)="close()"><i class="material-icons">close</i></a>
      </div>
    </h4>
    
    <p class="card-category"></p>
  </div>


  <div class="row" style="width:100%;margin:auto;margin-top:10px">

    <div class="col">

      <app-image [src]="safe_content ? safe_content : safe_url" [file_type]="file_type" [index]="i"></app-image>

      <embed [src]="safe_content ? safe_content : safe_url" *ngIf=" file_type == 'pdf' && !Browserbrutti "
        style="height: 65vh; display:block;width:100%; display:block; margin:auto;" class="embedSet"
        type="application/pdf">

      <div style="margin:auto; margin-top:200px" *ngIf="file_type == 'oth'  || Browserbrutti ">
        <p style="font-size: 120%; text-align: center;">
          <a [href]="safe_content ? safe_content : safe_url" target="_blank">Scarica<br><b>{{( _files |
              filterByFileType:
              filter )[i].fileName}}</b></a>
        </p>
      </div>
      <br>
    </div>

    <div class="col" *ngIf="doubleView">
      <img [src]="safe_content1 ? safe_content1 : safe_url1" *ngIf=" file_type == 'img' && i%2==0 "
        style="height: 65vh; width:100%; display:block; margin:auto; object-fit: contain;">
      <img [src]="safe_content1 ? safe_content1 : safe_url1" *ngIf=" file_type1 == 'img'  && i%2==1"
        style="height: 65vh; width:100%; display:block; margin:auto; object-fit: contain;">


      <embed [src]="safe_content1 ? safe_content1 : safe_url1" *ngIf=" file_type1 == 'pdf' && !Browserbrutti "
        style="height: 65vh; display:block;width:100%; display:block; margin:auto;" class="embedSet"
        type="application/pdf ">

      <div style="margin:auto; margin-top:200px" *ngIf="file_type1 == 'oth'  || Browserbrutti">
        <p style="font-size: 120%; text-align: center;">
          <a [href]="safe1_content? safe1_content : safe1_url" target="_blank">Scarica<br><b>{{( _files |
              filterByFileType:
              filter )[i+1].fileName}}</b></a>
        </p>
      </div>

      <br>
    </div>

  </div>



  <div class="row footer" style="width:98%">

    <div class="col-lg-3">
      <button type="button" *ngIf="!playing" class="btn btn-success" style="float:left" (click)="play()"
        mat-raised-button>
        <i class="material-icons">play_circle_filled</i>
      </button>

      <button type="button" *ngIf="playing" class="btn btn-danger" (click)="play()" mat-raised-button>
        <i class="material-icons">pause_circle_filled</i>
      </button>
    </div>

    <div class="col-lg-6">

      <div style="margin:auto">
        <button type="button" class="btn btn-primary" (click)="prev()" [disabled]="playing" mat-raised-button> &lt;
        </button>

        {{+i+1}}<span *ngIf="doubleView">,{{+i+2}}</span>/{{(_files|filterByFileType: filter).length}}
        <button type="button" class="btn btn-primary" (click)="next()" [disabled]="playing" mat-raised-button> &gt;
        </button>
      </div>
    </div>

    <div class="col-lg-3"></div>


  </div>



</div>