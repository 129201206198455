import { Component, HostListener, isDevMode } from '@angular/core';
import { MysqlService } from './services/mysql.service';


import { locale, loadMessages } from "devextreme/localization";
import { itMessages } from './devexpress-it';
import { environment } from 'environments/environment';
import { UserService } from './services/user.service';




@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent {


  favicon = '';

  constructor(private db: MysqlService, private userService: UserService,) {

    // Usa la lingua preferita (puoi personalizzare la logica)
    //const browserLang = this.translate.getBrowserLang();
    //this.translate.use(browserLang.match(/en|it/) ? browserLang : 'en');


    loadMessages(itMessages);
    locale(navigator.language);

    this.favicon = db.getIcon();

    this.checkVersion()
    setInterval(async () => {
      this.checkVersion()
    }, 60 * 1000);

    /*
        if (!isDevMode() ){
          window.console.log = function () { }; 
          window.console.warn = function () { };
        }
    
        window.console.warn = function () { };
        */
  }

  isPosponed = false
  pospone() {
    this.isPosponed = true
    this.olderVersion = false

    setTimeout(() => {
      this.checkVersion(false)
    }, 5 * 60 * 1000);
  }

  olderVersion = false
  async checkVersion(ignorePospone = true) {

    if (this.isPosponed && ignorePospone) return

    // environment.appVersion = '0.0.0'

    if (!this.userService.isLogged()) return
    if (location.href.includes('localhost')) return



    let runningVersion = environment.appVersion

    //console.log('runningVersion: ' + runningVersion)

    let latestVersion: string = (await this.db.impostazioni.get('version'))[0].valore
    //console.log('latestVersion: ' + latestVersion)

    if (versionToInt(runningVersion) > versionToInt(latestVersion)) {

      console.warn('Attenzione, la versione in uso è più recente di quella in produzione, aggiorno il valore del db')

      await this.db.impostazioni.upsert('version', runningVersion)
        .then(res => console.log(res))
        .catch(err => console.error(err))

      return
    }

    if (versionToInt(runningVersion) < versionToInt(latestVersion)) {
      this.olderVersion = true
      return
    }


  }

  refresh() {
    location.reload()
  }


}

const versionToInt = (v) => {

  let xyz = v.split('.')
  let n = +xyz[0] * 1000000 + +xyz[1] * 1000 + +xyz[2]

  return n
}


export const getIsMobile = (): boolean => {

  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  // Verifica se l'utente sta usando un dispositivo mobile
  return /android|ipad|iphone|ipod/.test(userAgent?.toLowerCase()) && !window.matchMedia('(tablet)').matches;


}