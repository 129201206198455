import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDeleteComponent, GridDuplicateComponent, GridEditComponent, GridUpDownComponent } from 'app/components/grid-edit/grid-edit.component';
import { MysqlService } from 'app/services/mysql.service';
import { VerycarQuestionEditDialogComponent } from '../verycar-defs/question-edit-dialog/question-edit-dialog.component';

@Component({
    selector: 'app-verycar-segnalazioni',
    templateUrl: './verycar-segnalazioni.component.html',
    styleUrls: ['./verycar-segnalazioni.component.scss'],
    standalone: false
})
export class VerycarSegnalazioniComponent implements OnInit {
  item

  gridApi;
  gridColumnApi;
  defaultCol = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
  };

  rowData: any[]






  columnDefs: any[] = [


    //{ field: 'Order', headerName: 'order', },
    //{ field: 'completed', headerName: '', width: '50px', resizable: false },

    { field: 'id', headerName: 'ID Uxpert', },
    
    { field: 'motivazione', headerName: 'Motivazione', },
    { field: 'user.email', headerName: 'Segnalato da', },
    { field: 'note', headerName: 'Note', },



    /*
  
      note_inspect
      hint
      Cristalli
      Kasko
      Visiva
      Guasti12mesi
      Guasti24mesi
  
    */


  ];



  users: any[];

  type: string;

  constructor(

    public dialog: MatDialog,

    private router: Router,
    private route: ActivatedRoute,
    private db: MysqlService,
  ) {


  }


  tab = 0
  onTabChanged(event) {
    //console.clear()
    console.log(event)
    this.tab = event.index

    switch (this.tab) {
      case 1:
        break;

      default:
        break;
    }
  }

  addDef() {
    this.item = {
      id: '',
      questions: [],
      ver: 0,
    } as any
  }

  loadUsers() {
    this.db.verycar.segnalazioni_list().then((res: any) => {
      console.log(res)
     
      this.rowData = res.list
    }).catch(err => {
      console.error(err)
    })
  }

  saveDef() {
    /**
    let rowData = []
    this.gridApi_def.forEachNode(node => rowData.push(node.data));

    this.item.questions = rowData

    if (this.item.id == '') this.item.id = MD5(`${new Date()}_${this.item.type}_${this.item.vehType}_${this.item.ver}`)

    this.db.verycar.defs_upsert(this.item)
      .then((res: any) => {
        if (res.status != 'OK') {
          alert(JSON.stringify(res))
          return
        }

        this.cancelDef()
        this.loadDefs()

      })
      .catch(err => alert(JSON.stringify(err)))
 */
  }

  cancelDef() {
    this.item = undefined
  }

  getUserNameByEmail(email) {
    let r;
    try {
      r = this.users.find(v => { return v.email == email });
      return r.firstname + ' ' + r.lastname;
    } catch (error) {

      return email;

    }

  }




  addQuestion() {


    let qid = Math.max(...this.item.questions.map(e => e.questionId)) + 1

    console.log(this.item.questions.map(e => e.questionId), qid)

    let max = 1
    try {
      max = Math.max(...this.item.questions.map(e => e.Order)) + 1
    } catch (error) { }


    qid = qid == -Infinity ? 1 : qid
    max = max == -Infinity ? 1 : max

    let q = {
      sectionCode: '',
      questionId: qid,
      questionName: '' + qid,

      Order: max,

    }

    //console.clear()
    console.log(q)
    this.item.questions.push(q)



    let i = this.item.questions.findIndex(e => e.questionId == qid)

    const dialogRef = this.dialog.open(VerycarQuestionEditDialogComponent, {
      data: { q: this.item.questions[i], questions: this.item.questions }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result)

      if (!result) return

      this.item.questions[i] = result

      let tmp = JSON.parse(JSON.stringify(this.item.questions))
      this.item.questions = []
      this.item.questions = tmp
    });



  }

  ngOnInit() {

    this.loadUsers()

  }


  ngOnDestroy() {
    console.log('destroy')



  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //console.log(event)
    this.autoSizeAll();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll();
  }

  sizeColumnsToFit(gridApi) {
    this.gridApi.sizeColumnsToFit()
  }


  autoSizeAll(skipHeader = false) {

      var allColumnIds = [];
      this.gridApi?.getColumns().filter(e => e.colDef.resizable).forEach((column) => {
        allColumnIds.push(column.colId);
        console.log(column)
      });

      this.gridApi?.autoSizeColumns(allColumnIds, skipHeader);

  }




}
