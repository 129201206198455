<br>
<div class="main-content" style="height: calc(100vh - 20px);; padding-top:0; margin-bottom:0" *ngIf="item">
  <div class="row" style="height: 100%;  margin-bottom:0">
    <div class=" col-md-12" style="height: 100%; margin-bottom:0">

      <form [formGroup]="form" (keydown.enter)="$event.preventDefault()" style="height: 100%; ">
        <div class="card" style="height: 100%; ">
          <div class="card-header card-header-info">
            <h4 class="card-title">Perizia - ID: {{item.id ? item.id : 'NUOVO'}}

              <div style="float:right">



              </div>
            </h4>
          </div>
          <div class="card-body" style="width: 100%!important; height: 100%" *ngIf="loading">
            <div class="row">
              <div class="col-sm-2" style="margin:auto">

                <mat-spinner *ngIf="isMSIE()"></mat-spinner>
                <mat-progress-spinner mode="indeterminate" [value]="loading_percentage" *ngIf="!isMSIE()">
                </mat-progress-spinner>

              </div>
            </div>

          </div>
          <div class="card-body" style="width: 100%!important; height: 100%;" [hidden]="loading">

            <div class="row">

              <div class="col-sm-4">

                <mat-form-field class="full">
                  <mat-label>Targa</mat-label>
                  <input type="text" matInput placeholder="Targa" formControlName="targa" [(ngModel)]="item.targa"
                    [readonly]="true">
                </mat-form-field>



              </div>


              <div class="col-sm-4">
                <mat-form-field class="full">
                  <mat-label>Telaio</mat-label>
                  <input type="text" matInput placeholder="Telaio" formControlName="telaio" [(ngModel)]="item.telaio"
                    [readonly]="true">
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field class="full">
                  <mat-label>Stato</mat-label>


                  <input type="text" matInput [(ngModel)]="item.stato" formControlName="stato" readonly>

                </mat-form-field>
              </div>

              <div class="col" *ngIf="(db.getIcon()=='gg' || db.getIcon()=='test' )  && item?.cliente==1">
                <mat-form-field class="full">
                  <mat-label>Stato Est.</mat-label>

                  <mat-select placeholder="Stato Est." [(ngModel)]="item.stato1" formControlName="stato1"
                    *ngIf="permission?.permitStatusChange">
                    <mat-option *ngFor="let i of stati1" [value]="i">
                      {{i}}
                    </mat-option>
                  </mat-select>

                  <input type="text" matInput *ngIf="!permission?.permitStatusChange" [(ngModel)]="item.stato1"
                    formControlName="stato1" readonly>

                </mat-form-field>
              </div>

            </div>






            <mat-tab-group (selectedTabChange)="onLinkClick($event)" [selectedIndex]="+tab"
              [backgroundColor]="'primary'" [color]="'danger'">

              <mat-tab label="Dati sinistro" *ngIf="type=='perizie'" [disabled]="!permission?.tabs[1]"
                style="height: 100%" [ngClass]="{'hidden': type!='perizie'}"><br>
                <div class="row">
                  <div class="col-sm-4 card">
                    <div class="card-header card-header-primary">
                      <h5 class="card-title">Sinistro</h5>
                    </div><br>



                    <mat-form-field class="full">
                      <mat-label>Numero sinistro</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Numero sinistro" (ngModelChange)="claimNrChange($event)"
                        formControlName="sinistro_numero" [(ngModel)]="item.sinistro_numero">

                      <i *ngIf="errorClaimNr" class="material-icons" style="float:right; color:red">error</i>

                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Partita di danno</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="number" matInput
                        placeholder="Partita di danno" formControlName="partita_danno" [(ngModel)]="item.partita_danno">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Mandante sinistro</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Mandante sinistro" formControlName="sinistro_mandante"
                        [(ngModel)]="item.sinistro_mandante">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Data sinistro</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="date" matInput
                        placeholder="Data sinistro" formControlName="sinistro_data" [(ngModel)]="item.sinistro_data">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Targa Assicurato</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Targa Assicurato" formControlName="sinistro_targaAssicurato"
                        [(ngModel)]="item.sinistro_targaAssicurato">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Nome Assicurato</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Nome Assicurato" formControlName="sinistro_nomeAssicurato"
                        [(ngModel)]="item.sinistro_nomeAssicurato">
                    </mat-form-field>

                  </div>

                  <!--------------------------------------------->


                  <div class="col-sm-4 card">
                    <div class="card-header card-header-info">
                      <h5 class="card-title">Danneggiato</h5>
                    </div><br>

                    <mat-form-field class="full">
                      <mat-label>Nominativo</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Nominativo" formControlName="danneggiato_nome" [(ngModel)]="item.danneggiato_nome">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Provincia</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted"
                        placeholder="Provincia" [(ngModel)]="item.danneggiato_provincia"
                        formControlName="danneggiato_provincia"
                        (ngModelChange)="selectProvinciaDanneggiatoChange($event)">
                        <mat-option *ngFor="let i of province" [value]="i.sigla">
                          {{i.sigla}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Comune</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted" placeholder="Comune"
                        [(ngModel)]="item.danneggiato_citta" formControlName="danneggiato_citta"
                        (ngModelChange)="selectComuneDanneggiatoChange($event)">
                        <mat-option *ngFor="let i of comuniDanneggiato" [value]="i.nome">
                          {{i.nome}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>CAP</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted" placeholder="CAP"
                        [(ngModel)]="item.danneggiato_cap" formControlName="danneggiato_cap">
                        <mat-option *ngFor="let i of capDanneggiato" [value]="i">
                          {{i}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Indirizzo</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Indirizzo" formControlName="danneggiato_indirizzo"
                        [(ngModel)]="item.danneggiato_indirizzo">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Telefono</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Telefono" formControlName="danneggiato_telefono"
                        [(ngModel)]="item.danneggiato_telefono">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Fax</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Fax" formControlName="danneggiato_fax" [(ngModel)]="item.danneggiato_fax">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Email</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="email" matInput
                        placeholder="Email" formControlName="danneggiato_email" [(ngModel)]="item.danneggiato_email">
                    </mat-form-field>

                  </div>

                  <!--------------------------------------------->

                  <div class="col-sm-4 card">
                    <div class="card-header card-header-success">
                      <h5 class="card-title">Patrocinatore</h5>
                    </div><br>

                    <mat-form-field class="full">
                      <mat-label>Nominativo</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Nominativo" formControlName="patrocinatore_nome"
                        [(ngModel)]="item.patrocinatore_nome">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Provincia</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted"
                        placeholder="Provincia" [(ngModel)]="item.patrocinatore_provincia"
                        formControlName="patrocinatore_provincia"
                        (ngModelChange)="selectProvinciaPatrocinatoreChange($event)">
                        <mat-option *ngFor="let i of province" [value]="i.sigla">
                          {{i.sigla}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Comune</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted" placeholder="Comune"
                        [(ngModel)]="item.patrocinatore_citta" formControlName="patrocinatore_citta"
                        (ngModelChange)="selectComunePatrocinatoreChange($event)">
                        <mat-option *ngFor="let i of comuniPatrocinatore" [value]="i.nome">
                          {{i.nome}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>CAP</mat-label>
                      <mat-select [disabled]="permission?.tabs[1] == 2 || !permission?.permitted" placeholder="CAP"
                        [(ngModel)]="item.patrocinatore_cap" formControlName="patrocinatore_cap">
                        <mat-option *ngFor="let i of capPatrocinatore" [value]="i">
                          {{i}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Indirizzo</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Indirizzo" formControlName="patrocinatore_indirizzo"
                        [(ngModel)]="item.patrocinatore_indirizzo">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Telefono</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Telefono" formControlName="patrocinatore_telefono"
                        [(ngModel)]="item.patrocinatore_telefono">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Fax</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="text" matInput
                        placeholder="Fax" formControlName="patrocinatore_fax" [(ngModel)]="item.patrocinatore_fax">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Email/PEC</mat-label>
                      <input [readonly]="permission?.tabs[1] == 2 || !permission?.permitted" type="email" matInput
                        placeholder="Email/PEC" formControlName="patrocinatore_email"
                        [(ngModel)]="item.patrocinatore_email">
                    </mat-form-field>
                  </div>
                </div>


              </mat-tab>

              <mat-tab label="Doc Perizia" *ngIf="item.stato == 'CONCLUSO' || item.stato == 'CONFERMATO'">



                <ng-template matTabLabel>
                  <span [matBadge]="item?.adz_data?.adz ? 'OK' : '-' " matBadgeOverlap="false">Doc Perizia</span>
                </ng-template>


                <!--

                <button type="button" mat-fab
                 (click)="toggleAddAdz()">+</button>
                  
                -->
                <div class="row" style="margin-left:0;">



                  <br>

                  <div class="col-12 col-sm-5" style="overflow:auto; height: 65vh;" *ngIf="!item?.adz_data?.adz">

                    <br>

                    <hr>
                    <h5 style="text-align: center">Nessun file ADZ presente</h5>
                    <hr>


                    <!--
                    <mat-slide-toggle formControlName="fotoComplete" [(ngModel)]="item.fotoComplete">Analisi
                      fotografica
                      completa</mat-slide-toggle>
                    -->
                  </div>

                  <div class="col-12 col-sm-5" style="overflow:auto; height: 65vh;" *ngIf="item?.adz_data?.adz">

                    <br>

                    <div>


                      <button class="btn" type="button" mat-raised-button (click)="openReport()" *ngIf="false"
                        [matTooltip]="''">
                        <mat-icon>archive</mat-icon>Download ADZ
                      </button>

                      <button class="btn" type="button" mat-raised-button (click)="downloadZip('all')">
                        <mat-icon>save_alt</mat-icon>Download ZIP
                      </button>

                      <button class="btn" type="button" mat-raised-button (click)="downloadZip('img')">
                        <mat-icon>camera_alt</mat-icon>Download Immagini
                      </button>

                      <button class="btn" type="button" mat-raised-button (click)="downloadZip('pdf-img')"
                        *ngIf="pdf_perizia">
                        <mat-icon>poll</mat-icon>Download PDF con immagini
                      </button>

                      <button class="btn" type="button" mat-raised-button (click)="downloadZip('pdf')"
                        *ngIf="pdf_perizia">
                        <mat-icon>poll</mat-icon>Download PDF
                      </button>

                      <button class="btn  btn-success" type="button" mat-raised-button (click)="toggleReclamo()"
                        matTooltipPosition="right">
                        <mat-icon>help</mat-icon> Richiedi chiarimento
                      </button>
                    </div>

                    <app-adz-viewer *ngIf="item?.adz_data?.adz" [setValue]="item?.adz_data" [showOnlySelected]="true"
                      [altColor]="'black'"></app-adz-viewer>
                    <!--
                    <mat-slide-toggle formControlName="fotoComplete" [(ngModel)]="item.fotoComplete">Analisi
                      fotografica
                      completa</mat-slide-toggle>
                    -->





                  </div>




                  <div class="col-12 col-sm-7 " style="min-height: 60vh">
                    <div class="card">
                      <app-gallery-result [(setSource)]="item" (onSelected)=openViewerResult($event,null)
                        [setRole]="'CLIENTE'" [(set360)]="img_360" style="height: 100%;" id="result" [disabled]="true"
                        [hideUploader]="true" [showPP]="false" *ngIf="item && selectedTab_name=='Doc Perizia'"
                        [(blur)]="blur" (onOpenViewer)="openViewerResult($event)">
                      </app-gallery-result>
                    </div>
                  </div>

                </div>


              </mat-tab>

              <mat-tab label="Privacy" *ngIf="item?.result?.privacy"><br>

                <ng-template matTabLabel>
                  <span [matBadge]="item?.result?.privacy?.length" matBadgeOverlap="false">Privacy</span>
                </ng-template>

              </mat-tab>

              <mat-tab label="Proposte"
                *ngIf="!(!(permission?.tabs[5] || ( item.stato == 'CONCLUSO' || item.stato == 'CONFERMATO' ))) && type=='perizie'">
                <br>

                <ng-template matTabLabel>
                  <span [matBadge]="item?.proposte?.length" matBadgeOverlap="false">Proposte</span>
                </ng-template>




                <div class="row">
                  <div class="col-4">

                    <mat-form-field class="full">
                      <mat-label class="highlight">Importo concordato</mat-label>
                      <input type="number" matInput placeholder="Importo concordato"
                        formControlName="importo_concordato" [(ngModel)]="item.importo_concordato">
                    </mat-form-field>

                    <mat-form-field class="full">
                      <mat-label>Email danneggiato</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="email" matInput placeholder="Email"
                        formControlName="riparatore_email" [(ngModel)]="item.danneggiato_email">
                    </mat-form-field>

                    <button type="button" mat-raised-button class="btn btn-success" style="width:100%"
                      (click)="sendConcordato('d')" [disabled]="!item?.importo_concordato>0">Invia concordato (al
                      danneggiato)</button>
                    <br>
                    <mat-form-field class="full">
                      <mat-label>Email riparatore</mat-label>
                      <input [readonly]="permission?.tabs[5] == 2" type="email" matInput placeholder="Email"
                        formControlName="riparatore_email" [(ngModel)]="item.riparatore_email">
                    </mat-form-field>

                    <button type="button" mat-raised-button class="btn btn-success" style="width:100%"
                      (click)="sendConcordato('r')" [disabled]="!item?.importo_concordato>0">Invia concordato (al
                      riaratore)</button>

                    <input type="file" #concordato_file id="concordato_file" (change)="getFileConcordato($event)"
                      style="display: none;">




                  </div>
                  <div class="col">

                    <!--  <ng2-smart-table class="grid" [settings]="concordatix_settings" [source]="concordatix_source">
                    </ng2-smart-table>-->

                  </div>
                </div>







              </mat-tab>

            </mat-tab-group>


          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-doc-viewer [index]="docviewer_i" [(files)]="docviewer_source" *ngIf="docviewer" (closed)="closeDocviewer()"
  [position]="docviewer_position" [filter]="docviewer_filter" [doubleView]="docviewer_double" [descr]="docviewer_descr">
</app-doc-viewer>


<div class="dialog animated heartBeat" *ngIf="showAddAdz">
  <a style="float:right" (click)="toggleAddAdz()"><i class="material-icons">close</i></a>
  <app-gallery [(source)]="item.allegati" [uploader]="true" id="allegati" [disabled]="!permission?.tabs[5]">
  </app-gallery>
</div>


<div class="dialog-large animated heartBeat" *ngIf="showReclamo">

  <a style="float:right" (click)="toggleReclamo()"><i class="material-icons">close</i></a>

  <app-reclami [id]="item.id"></app-reclami>

</div>