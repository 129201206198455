import { AuthorizationService,  READONLY } from './../services/authorization.service';
import { MysqlService, Appointment } from './../services/mysql.service';
import { IPerizia,  } from './../models/perizia.interface';
//import { ReadMode } from 'ngx-file-helpers';
import { Component, OnInit, EventEmitter, ViewEncapsulation,  } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OsmService } from '../services/osm.service';
import { Router, ActivatedRoute } from '@angular/router';
import {  MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../services/user.service';
import { Location,  } from '@angular/common';
import moment from 'moment-timezone';;

import { OpenWeatherService } from '../services/open-weather.service';
import { Subscription } from 'rxjs';
import { ICommessa } from '../models/commessa.interface';



declare let L;

@Component({
    selector: 'app-public-details',
    templateUrl: './public-details.component.html',
    styleUrls: ['./public-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})


export class PublicDetailsComponent implements OnInit {

  tab_mod = 1;

  s: Subscription;
  campo = ''
  nomeCampo = ''
  appointmentsData: Appointment[];
  currentDate: Date = new Date();
  vehType = '';
  attachment_backup;


  ids_list = [];
  currentIdInList;

  commesse = [];
  commessa: ICommessa | any
  //timeRequired: number;

  public READONLY = READONLY;
  public startTime = '07:00';
  public endTime = '20:00';

  storico = [];
  logs = [];
  //result;//:IResult;


  alertno: boolean = true
  id: number;

  selectedTab_name = 'Doc Perizia';
  tab = 0;

  pdf_perizia;
  img_360;
  pdf_parcel;

  blur: boolean = false;


  onLoadingUpdate: EventEmitter<number> = new EventEmitter();

  ///////////////////////////////////
  clienti = [];
  salacontrollo = [];
  ispettori = [];
  ///////////////////////////////////
  province = [];

  comuniIspezione = [];
  capIspezione = [];

  comuniDanneggiato = [];
  capDanneggiato = [];

  comuniPatrocinatore = [];
  capPatrocinatore = [];

  comuniSinistro = [];
  capSinistro = [];
  //////////////////////////////////

  marche = [];
  anni = [];
  modelli = [];
  versioni = [];

  docviewer = false;
  docviewer_i = 0;
  docviewer_source = [];
  docviewer_position = 'right';
  docviewer_filter = '';
  docviewer_double = false
  docviewer_descr


  loading = false;
  public loading_percentage: number = 0;

  public readMode = 2;

  item: IPerizia;
  form: UntypedFormGroup;

  stati = [];
  stati1 = [
    '',
    'VIDEOPERIZIA EFFETTUATA',
    'DOCUMENTAZIONE DISPONIBILE',
    'RICHIESTA DOC. INTEGRATIVA',
    'INVIATO ACCORDO',
    'RICEVUTO ACCORDO',
  ];
  stato;

  data: IPerizia;

  editMode: boolean;
  live;
  reminders;

  ninterloc

  key
  constructor(
    //public dialogRef: MatDialogRef<PerizieDialogComponent>,
    //@Inject(MAT_DIALOG_DATA) public data: IPerizia,
    private formBuilder: UntypedFormBuilder,
    private osm: OsmService,
    public authorizationService: AuthorizationService,
    private router: Router,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public listDepotDialog: MatDialog,
    public db: MysqlService,
    public userService: UserService,
    private location: Location,
    private ow: OpenWeatherService,
  ) {

    if (!localStorage.getItem('authority')) localStorage.setItem('authority', '{}')

    //this.appointmentsData = db.getAppointments();

    this.form = this.formBuilder.group({

      targa: ['', [Validators.required]],
      telaio: ['',],
      stato: ['', Validators.required],
      stato1: ['',],
      marca: ['',],
      annoImmatricolazione: ['',],
      tipoAuto: ['',],
      modello: ['',],
      deposito: ['',],
      tipo: ['',],
      nPerizia: ['',],
      km: ['',],
      provincia: ['',],
      citta: ['',],
      cap: ['',],
      indirizzo: ['',],
      location_lat: ['',],
      location_lon: ['',],
      noteCarico: ['',],

      danneggiato_nome: ['',],
      danneggiato_contatto: ['',],
      danneggiato_provincia: ['',],
      danneggiato_citta: ['',],
      danneggiato_cap: ['',],
      danneggiato_indirizzo: ['',],
      danneggiato_telefono: ['',],
      danneggiato_fax: ['',],
      danneggiato_email: ['',],
      danneggiato_pec: ['',],

      patrocinatore_nome: ['',],
      patrocinatore_contatto: ['',],
      patrocinatore_provincia: ['',],
      patrocinatore_citta: ['',],
      patrocinatore_cap: ['',],
      patrocinatore_indirizzo: ['',],
      patrocinatore_telefono: ['',],
      patrocinatore_fax: ['',],
      patrocinatore_email: ['',],
      patrocinatore_pec: ['',],

      sinistro_numero: ['',],
      partita_danno: ['',],
      sinistro_mandante: ['',],
      sinistro_data: ['',],
      sinistro_targaAssicurato: ['',],
      sinistro_nomeAssicurato: ['',],

      sinistro_provincia: ['',],
      sinistro_citta: ['',],
      sinistro_cap: ['',],
      sinistro_indirizzo: ['',],

      sinistro_location_lat: ['',],
      sinistro_location_lon: ['',],


      versione: ['',],
      quotazioneIntero: ['',],
      quotazioneVendita: ['',],
      dataImmatricolazione: ['',],


      messaggio_cliente: ['',],
      messaggio_perito: ['',],

      assegnatoA1: ['',],
      assegnatoA2: ['',],


      commessa: ['',],
      cliente: ['',],

      importo_proposta: ['',],
      importo_concordato: ['',],

      iban_proposta: ['',],




      riparatore_nome: ['',],
      riparatore_tel: ['',],
      riparatore_email: ['',],

      fotoComplete: ['',],


      noteInterne: ['',],

      documentale: ['',],

      ricavo_manuale: ['',],
      costo_manuale: ['',],
      descrizione_cambio_tariffa: ['',],


      sel_tariffa: ['',],//dummy
    });


    this.s = route.params.subscribe(val => {

      let id = +val.id

      this.key = val.hash

      //console.log('id ' + id)

      this.init(id)
    });


  }



  dati_fatt_expanded = false


  init(id) {


    //if (!this.id) 
    this.id = id;


    this.ids_list = JSON.parse(localStorage.getItem('ids_list'));
    if (!this.ids_list) this.ids_list = []

    this.currentIdInList = this.ids_list.findIndex(v => {
      let b = +v == +this.id;

      //console.log('' + +v + ' == ' + + this.id + ' => ' + b)

      return b
    })

    //console.log('currentIdInList ' + this.currentIdInList)

    let tab = +this.route.snapshot.paramMap.get('tab');




    this.db.get_perizia_pub(this.id, this.key).then(res => {

      console.log(res)

      console.log('data', res.item)
      if ((!res || res.item == undefined) && this.alertno) {
        alert("pratica non trovata o non di competenza")
        this.alertno = false
      }

      this.item = res.item

      res.item.fotoComplete = res.item.fotoComplete > 0
      this.data = res.item;
      if (!this.item.tipoVeicolo || this.item.tipoVeicolo == '') this.item.tipoVeicolo = res.item.tipo_veicolo
      this.loadAll();
    }).catch(err => console.error(err));



  }

  ngOnInit() {

    let id = +this.route.snapshot.paramMap.get('id')
    this.db.impostazioni.get('moduli').then(result => {
      //  //console.log(JSON.parse(result[0].valore))
      this.live = result[0].valore.automotive_telexpert
      this.init(id)

    })


  }







  getIconUrlFromStatus(sts: string): string {
    return './assets/img/icons/perizie/stati/' + sts.split(' ').join('_').split('/').join('_').toLowerCase() + '.png'
  }


  isEcoFabopened = false;
  ecoFabOpen() {
    this.isEcoFabopened = true;
  }
  ecoFabClose() {
    this.isEcoFabopened = false;
  }

  searchItineraryItem() {

    let query = this.item.citta.trim() + ' ' + this.item.cap.trim() + ' ' + this.item.indirizzo.trim();

    if (!query) return;

    this.db.bingmaps.search(query).then((res: any) => {
      //console.log(res);

      try {
        let x = res.data.resourceSets[0].resources[0].geocodePoints[0].coordinates;

        this.form.controls['location_lat'].setValue(x[0]);
        this.form.controls['location_lon'].setValue(x[1]);

      } catch (error) {
        alert("Impossibile geolocalizzare l'indirizzo");
        console.error(error);

      }



    }).catch(err => {
      console.error(err)
    })
  }


  sendParcel() {

    if (!this.pdf_parcel || this.pdf_parcel == '') {
      alert('Parcella non trovata');
      return;
    }

    if (!this.pdf_perizia_clean || this.pdf_perizia_clean == '') {
      alert('ADZ non trovato');
      return;
    }


    let perizia = {
      filename: this.item.targa + ".PDF",
      url: this.pdf_perizia_clean + '&images&fout=' + this.item.targa.replace(' ', '') + ".PDF"
    }

    let parcella = {
      filename: "PParcella.PDF",
      url: this.pdf_parcel
    }


    this.db.perizie_send_parcel(this.item, [perizia, parcella]).then(res => {

      if (res.status != 'OK') {
        alert(JSON.stringify(res))
        return
      }

      alert('Email inviata')


    }).catch(err => alert(JSON.stringify(err)))

  }

  downloadZip(type: string) {

    let url;

    if (type == 'pdf') {
      url = this.pdf_perizia_clean + `&fout=${this.item.id}-${this.item.targa.replace(' ', '')}-perizia.pdf&onlyselected`;
    } else if (type == 'pdf-img') {
      url = this.pdf_perizia_clean + `&images&fout=${this.item.id}-${this.item.targa.replace(' ', '')}-perizia.pdf&onlyselected`;
    } else if (type == 'noval') {
      url = this.pdf_perizia_clean + `&noval&fout=${this.item.id}-${this.item.targa.replace(' ', '')}-perizia.pdf&onlyselected`;
    } else {
      url = this.db.get_perizia_zip_url(this.id, type, this.db.getIcon(), true)
    }

    //console.log(url)
    window.open(url, '_blank');
  }

  reportRMK() {
    let url = this.db.URL_BASE + `unipolrental_print_report_adz&header=${this.db.getIcon()}&id=${this.item.id}&hash=&fout=${this.item.id}-${this.item.targa.replace(' ', '')}-report-rmk.pdf&images=1`;

    window.open(url, '_blank');
  }

  //////////////////////////////////////////

  manageStatus(source, value) {

    //alert('manageStatus')

    //console.log('here3')
    ////console.log(e)

    ////console.clear()

    let e = { source: { id: source } }

    // //console.log(`x: ${this.item.stato} y: ${this.db.getStatiPeriziaAll()[3]}`)
    // //console.log(`x: ${this.item.stato} y: ${this.db.getStatiPeriziaAll()[4]}`)

    if ((this.item.stato == this.db.getStatiPeriziaAll()[3] || this.item.stato == this.db.getStatiPeriziaAll()[4]) && (e.source.id == '' || e.source.id == 'allegati')) {

      console.log('type: 1')

      // //console.log('looking for adz')

      for (let i = 0; i < this.item.allegati.length; i++) {

        // //console.log(this.item.allegati[i].fileName + ' - ' + this.item.allegati[i].fileName.substr(-3).toLocaleLowerCase())


        if (this.item.allegati[i].fileName.substr(-3).toLocaleLowerCase() == 'adz') {

          if (!this.item.assegnatoA1 || this.item.assegnatoA1 == '')
            this.item.stato = this.db.getStatiPeriziaAll()[9];
          else
            this.item.stato = this.db.getStatiPeriziaAll()[6];
          ////console.log('new sts = ' + this.item.stato)

          break;
        }
      }
    }
    else if (this.item.stato == this.db.getStatiPeriziaAll()[0] && e.source.id == 'assegnatoA1' && this.item.assegnatoA1 != '') {
      console.log('type: 2')
      this.item.stato = this.db.getStatiPeriziaAll()[1]
    }
    else if (this.item.stato == this.db.getStatiPeriziaAll()[1] && (e.source.id == '' || e.source.id == 'allegati')) {
      console.log('type: 3')
      //alert('if')
      for (let i = 0; i < this.item.allegati.length; i++) {

        //alert('for')
        if (this.item.allegati[i].fileName.substr(-3).toLocaleLowerCase() == 'adz') {

          //alert('if2')
          this.item.stato = this.db.getStatiPeriziaAll()[9];
        }
      }


    }
    else if ((this.item.stato == this.db.getStatiPeriziaAll()[0] || this.item.stato == this.db.getStatiPeriziaAll()[1]) && e.source.id == 'assegnatoA2' && this.item.assegnatoA2 && this.item.assegnatoA2 != '') {
      console.log('type: 4')
      console.log(this.item.stato, e, this.item.assegnatoA2)

      this.item.stato = this.db.getStatiPeriziaAll()[3]
    }


  }

  loadAll() {



    console.log('loadAll')



    this.editMode = true;



    this.upsertUrlAllegati();

    for (let i = 0; i < this.data.allegatiIncarico.length; i++) {

      if (this.data.allegatiIncarico[i].type == 'da commessa')
        this.data.allegatiIncarico[i].url = this.db.commesse.get_attachment_url(this.item.commessa, this.data.allegatiIncarico[i], 'allegati')
      else
        this.data.allegatiIncarico[i].url = this.db.get_perizia_attachment_url(this.data.id, this.data.allegatiIncarico[i], 'allegatiIncarico');

    }

    if (this.data.dtaAppuntamento) this.data.dtaAppuntamento = removeTZ(new Date(this.data.dtaAppuntamento));

    this.data.documentale = +this.data.documentale > 0

    this.item = this.data;
    try {
      this.ninterloc = this.item.interlocutorie.filter(e => e.tipo == 'interlocutoria').length
    } catch (error) {

    }




    //console.log(this.item)

    let s = this.db.getStato(this.item.stato, this.item.assegnatoA1 == '');
    this.stato = s

    //console.clear()
    console.log(s)
    if (this.stato.reject == 'RICHIESTA MODIFICA/INTEGRAZIONE A SALA CONTROLLO' && (!this.item.assegnatoA1 || this.item.assegnatoA1 == ''))
      this.stato.reject = 'RICHIESTA MODIFICA/INTEGRAZIONE AD ISPETTORE'

    if (this.stato.accept == 'CONSEGNATO A SALA CONTROLLO' && (!this.item.assegnatoA1 || this.item.assegnatoA1 == ''))
      this.stato.accept = 'CONSEGNATO'
    console.log(s)


    this.loadResult();

    this.loadMarche();
    this.loadAnni();
    this.loadModelli();
    this.loadVersioni();

    this.loadProvince();
    this.loadComuniIspezione();
    this.loadComuniDanneggiato();
    this.loadComuniPatrocinatore();
    this.loadComuniSinistro();


    this.stati = this.db.getStatiPeriziaAll() //this.db.getStatiPerizia();


    this.attachment_backup = JSON.stringify(this.item.allegati);



  }

  customerFilter



  loadResultImageUrls(type: string = 'all') {

    switch (type) {
      case 'all':
        this.loadResultImageUrls('foto_vetrina');
        this.loadResultImageUrls('altri_elementi');
        this.loadResultImageUrls('danni');
        this.loadResultImageUrls('documenti');
        this.loadResultImageUrls('attachments');
        this.loadResultImageUrls('glasses');
        this.loadResultImageUrls('tires');
        this.loadResultImageUrls('danniInt');
        break;

      case 'foto_vetrina':
        if (this.item.result.fotoVetrina) for (let i = 0; i < this.item.result.fotoVetrina.length; i++) {
          this.item.result.fotoVetrina[i].url = this.db.get_perizia_result_image_url(this.item.result.fotoVetrina[i].img)
        }
        break;

      case 'altri_elementi':
        if (this.item.result.altriElementi) for (let i = 0; i < this.item.result.altriElementi.length; i++) {
          this.item.result.altriElementi[i].url = this.db.get_perizia_result_image_url(this.item.result.altriElementi[i].img)
        }
        break;

      case 'danni':
        if (this.item.result.damages) for (let i = 0; i < this.item.result.damages.length; i++) {
          this.item.result.damages[i].img1.url = this.db.get_perizia_result_image_url(this.item.result.damages[i].img1.img)
          this.item.result.damages[i].img2.url = this.db.get_perizia_result_image_url(this.item.result.damages[i].img2.img)

          for (let j = 0; this.item.result.damages[i].extra && j < this.item.result.damages[i].extra.length; j++) {

            this.item.result.damages[i].extra[j].img1.url = this.db.get_perizia_result_image_url(this.item.result.damages[i].extra[j].img1.img)
            this.item.result.damages[i].extra[j].img2.url = this.db.get_perizia_result_image_url(this.item.result.damages[i].extra[j].img2.img)

          }

        }
        break;
      case 'danniInt':
        if (this.item.result.damagesInt) for (let i = 0; i < this.item.result.damagesInt.length; i++) {
          this.item.result.damagesInt[i].img1.url = this.db.get_perizia_result_image_url(this.item.result.damagesInt[i].img1.img)
          this.item.result.damagesInt[i].img2.url = this.db.get_perizia_result_image_url(this.item.result.damagesInt[i].img2.img)


        }
        break;

      case 'glasses':
        if (this.item.result.glasses) for (let i = 0; i < this.item.result.glasses.length; i++) {
          this.item.result.glasses[i].img1.url = this.db.get_perizia_result_image_url(this.item.result.glasses[i].img1.img)
          this.item.result.glasses[i].img2.url = this.db.get_perizia_result_image_url(this.item.result.glasses[i].img2.img)



        }
        break;

      case 'documenti':
        if (this.item.result.documenti) for (let i = 0; i < this.item.result.documenti.length; i++) {
          this.item.result.documenti[i].url = this.db.get_perizia_result_image_url(this.item.result.documenti[i].img)
        }
        break;

      case 'attachments':
        if (this.item.result.attachments) for (let i = 0; i < this.item.result.attachments.length; i++) {
          this.item.result.attachments[i].url = this.db.get_perizia_result_image_url(this.item.result.attachments[i].img)
        }
        break;

      case 'tires':
        if (this.item.result.tiresData?.tires)
          for (let i = 0; i < this.item.result.tiresData.tires.length; i++) {

            for (let j = 0; j < this.item.result.tiresData.tires[i].img?.length; j++) {

              this.item.result.tiresData.tires[i].img[j].url = this.db.get_perizia_result_image_url(this.item.result.tiresData.tires[i].img[j].img)

            }

          }
        break;
    }



    //console.log(this.item.result)

  }
  onLinkClick(e) {

    //console.log(e)

    this.selectedTab_name = e.tab.textLabel;
    //if (this.selectedTab_name == 'Luogo sinistro') this.loadMap();
    if (this.selectedTab_name == 'Documenti incarico') this.docviewer_position = 'center';
    if (this.selectedTab_name == 'Doc Perizia') this.docviewer_position = 'right';
  }


  loadResult() {
    //console.clear()
    this.db.get_perizia_result_pub(this.id, this.key).then((res: any) => {
      //console.clear()
      console.log(res)

      if (!res.data) return;

      this.item.result = res.data.result;

      this.vehType = this.item.result.vehType;

      this.loadResultImageUrls();
    }).catch(err => {
      console.error(err);
    });
  }

  pdf_perizia_clean;
  upsertUrlAllegati() {
    if (this.data && this.data.allegati)
      for (let i = 0; i < this.data.allegati.length; i++) {

        if (this.data.allegati[i].fileName.toLowerCase() == 'pparcella.pdf') {
          this.data.allegati[i].url = this.db.get_perizia_attachment_url(this.data.id, this.data.allegati[i], 'allegati');
          this.pdf_parcel = this.data.allegati[i].url;

        } else if (this.data.allegati[i].fileName.startsWith('SAM_100_') || this.data.allegati[i].fileName.startsWith('360')) {
          this.data.allegati[i].url = this.db.get_perizia_attachment_url(this.data.id, this.data.allegati[i], 'allegati');
          this.img_360 = this.data.allegati[i].url;

        } else if (this.data.allegati[i].fileName.substr(-3).toLocaleLowerCase() == 'adz') {
          this.data.allegati[i].url = this.db.get_print_adz_perizie(+this.data.id, false, this.data.allegati[i].hash, this.db.getIcon() == 'tradeone' && this.item.cliente == 45 ? 'ie' : undefined);
          // tarocco header necessario per richiesta cliente BCC lease

          this.pdf_perizia = this.sanitize(this.data.allegati[i].url);
          this.pdf_perizia_clean = this.data.allegati[i].url
        } else if (this.data.allegati[i].fileName.startsWith('Parcella_')) {
          this.data.allegati[i].url = this.db.perizie_get_parcella(this.data.id, this.item.ricavo_manuale ? this.item.ricavo_manuale : this.item.ricavo);
        } else {
          this.data.allegati[i].url = this.db.get_perizia_attachment_url(this.data.id, this.data.allegati[i], 'allegati');

        }

      }

    if (this.data.adz_data) {

      this.pdf_perizia_clean = this.db.get_print_adz_perizie(+this.data.id, false, '', this.db.getIcon() == 'tradeone' && this.item.cliente == 45 ? 'ie' : undefined);

      this.pdf_perizia = this.sanitize(this.pdf_perizia);


    }

    //console.log('360: ' + this.img_360)

    //console.log('BACKUP ALLEGATI')
    //console.log(this.attachment_backup)
  }


  loadProvince() {
    this.db.list_province()
      .then(res => {
        this.province = res;
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadComuniIspezione() {
    this.db.list_comuni(this.item.provincia)
      .then(res => {
        this.comuniIspezione = res;
        this.selectComuneIspezioneChange(null);
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadComuniDanneggiato() {
    this.db.list_comuni(this.item.danneggiato_provincia)
      .then(res => {
        this.comuniDanneggiato = res;
        this.selectComuneDanneggiatoChange(null);
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadComuniPatrocinatore() {
    this.db.list_comuni(this.item.patrocinatore_provincia)
      .then(res => {
        this.comuniPatrocinatore = res;
        this.selectComunePatrocinatoreChange(null);
      })
      .catch(err => {
        console.error(err);
      })
  }


  loadComuniSinistro() {
    this.db.list_comuni(this.item.sinistro_provincia)
      .then(res => {
        this.comuniSinistro = res;
        this.selectComuneSinistroChange(null);
      })
      .catch(err => {
        console.error(err);
      })
  }




  selectProvinciaIspezioneChange(e) {
    this.loadComuniIspezione();
  }

  selectComuneIspezioneChange(e) {

    this.capIspezione = [];

    for (let i = 0; i < this.comuniIspezione.length; i++) {
      if (this.comuniIspezione[i].nome == this.item.citta) {
        this.capIspezione = this.comuniIspezione[i].cap;
        break;
      }
    }

    if (this.capIspezione.length == 1) {
      this.item.cap = this.capIspezione[0];
    }
  }


  selectProvinciaDanneggiatoChange(e) {
    this.loadComuniDanneggiato();
  }

  selectComuneDanneggiatoChange(e) {

    this.capDanneggiato = [];

    for (let i = 0; i < this.comuniDanneggiato.length; i++) {
      if (this.comuniDanneggiato[i].nome == this.item.danneggiato_citta) {
        this.capDanneggiato = this.comuniDanneggiato[i].cap;
        break;
      }
    }

    if (this.capDanneggiato.length == 1) {
      this.item.danneggiato_cap = this.capDanneggiato[0];
    }
  }

  selectProvinciaPatrocinatoreChange(e) {
    this.loadComuniPatrocinatore();
  }

  selectComunePatrocinatoreChange(e) {

    this.capPatrocinatore = [];

    for (let i = 0; i < this.comuniPatrocinatore.length; i++) {
      if (this.comuniPatrocinatore[i].nome == this.item.patrocinatore_citta) {
        this.capPatrocinatore = this.comuniPatrocinatore[i].cap;
        break;
      }
    }

    if (this.capPatrocinatore.length == 1) {
      this.item.patrocinatore_cap = this.capPatrocinatore[0];
    }
  }


  selectProvinciaSinistroChange(e) {

    //console.log('selectProvinciaSinistroChange', e)


    this.item.sinistro_citta = '';
    this.item.sinistro_cap = '';
    this.item.sinistro_indirizzo = '';

    this.loadComuniSinistro();
    //this.loadMap();
  }

  selectComuneSinistroChange(e) {

    //console.log('selectComuneSinistroChange', e)


    this.capSinistro = [];

    for (let i = 0; i < this.comuniSinistro.length; i++) {
      if (this.comuniSinistro[i].nome == this.item.sinistro_citta) {
        this.capSinistro = this.comuniSinistro[i].cap;
        break;
      }
    }

    if (this.capSinistro.length == 1) {
      this.item.sinistro_cap = this.capSinistro[0];
    }

    //this.loadMap();
  }





  loadMarche() {

    if (!this.item.tipoVeicolo) this.item.tipoVeicolo = ''
    this.db.etax.list_marche(this.item.tipoVeicolo, true)
      .then(res => {
        this.marche = res;


      })
      .catch(err => {
        console.error(err);
      })
  }

  loadAnni() {
    this.db.etax.list_anni(this.item.marca, true)
      .then(res => {
        this.anni = res;
      })
      .catch(err => {
        console.error(err);
      })
  }

  loadModelli() {
    this.db.etax.list_modelli(this.item.marca, this.item.annoImmatricolazione, this.item.tipoVeicolo, true)
      .then(res => {
        console.log(res)
        this.modelli = res;
      })
      .catch(err => {
        console.error(err);
      })
  }
  spinner = false
  loadVersioni() {
    this.spinner = true
    this.db.etax.list_versioni(this.item.marca, this.item.annoImmatricolazione, this.item.modello, this.item.tipoVeicolo, true)
      .then(res => {
        this.versioni = res;
        this.spinner = false
      })
      .catch(err => {
        console.error(err);
      })

  }
  selectTipoChange(e) {
    this.loadMarche();

    this.marche = []
    this.anni = []
    this.modelli = []
    this.versioni = []

  }


  selectMarcaChange(e) {
    this.loadAnni();
    this.modelli = []
    this.versioni = []
  }

  selectAnnoChange(e) {
    this.loadModelli();
    this.versioni = []
  }

  selectModelloChange(e) {
    this.loadVersioni();
  }

  isMSIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      let r = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      //console.log(r);
      return r;
    }
  }





  closeDocviewer() {
    this.docviewer = false;
  }

  openViewer(e, source) {
    //console.log(e)
    this.docviewer_i = 0;
    this.docviewer_source = source

    this.docviewer_double = e.double
    this.docviewer_descr = e.descr

    this.docviewer_i = e.i;
    this.docviewer_filter = e.filter;
    this.docviewer = true;

  }

  openViewerResult(e) {
    console.log(e)

    this.docviewer_i = 0;
    this.docviewer_source = e.source

    this.docviewer_double = e.double
    this.docviewer_descr = e.descr

    this.docviewer_i = e.i;
    this.docviewer = true;

  }

  map: any
  layers = L.layerGroup();






  showReclamo
  async toggleReclamo() {
    this.showReclamo = !this.showReclamo
  }
  



  public showAddAdz = false;
  toggleAddAdz() {
    //console.log(this.showAddAdz)
    this.showAddAdz = !this.showAddAdz;
    //console.log(this.showAddAdz)
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

export function removeTZ(d) {


  var format = 'YYYY/MM/DD HH:mm:ss';
  let sUtc = moment(d, format).format(format);

  //console.log('sUtc', sUtc);

  let dUtc = new Date(sUtc + " UTC");

  //console.log('dUtc', dUtc);

  return dUtc
}





