"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
const BackgroundFilterVideoFrameProcessorDelegate_1 = require("../backgroundfilter/BackgroundFilterVideoFrameProcessorDelegate");
/**
 * This class adds the functionality to allow for a set of unique observers to be added to the
 * video frame processor.
 */
/** @internal */
class BackgroundBlurVideoFrameProcessorDelegate extends BackgroundFilterVideoFrameProcessorDelegate_1.default {}
exports.default = BackgroundBlurVideoFrameProcessorDelegate;
