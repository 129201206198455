<div class="main-content" style=" height: calc(100vh - 20px)!important; padding-top:0; margin-bottom:0; 
  padding: 5%">
  <div class="card" style="padding-top:1em; padding-right: 1.5em; padding-left: 1.5em; padding-bottom: 1.5em;">

    <div class="card-header card-header-info">
      <h4 class="card-title">

        SYSTEM MONITOR

        <div style="float:right"><a (click)="close()"><i class="material-icons">close</i></a>
        </div>

      </h4>
    </div><br>



    <div class="row">
      <div class="col">
        <h1 class="title" [ngClass]="item?.status=='OK' ? 'ok' : 'error'"> STATO CLUSTER: {{item?.status}}

          <div style="float:right; padding-right:10px;">
            <a *ngIf="!loading" (click)="refresh()"> <i class="material-icons">autorenew</i></a>
            <mat-spinner *ngIf="loading" [diameter]="25" style="margin-top:20px;"></mat-spinner>
          </div>

        </h1>
      </div>
    </div>


    <div class="row">


      <div class="col" *ngFor="let e of item?.hosts">

        <h2 class="title" [ngClass]="+e?.result?.isOK ? 'ok-sub-title' : 'error'">{{e.code}}</h2>

        <p class="detail">
          Replica MySQL - Slave_IO_Running: <span
            [ngClass]="e.result?.replica?.Slave_IO_Running == 'Yes' ? 'ok-sub-title' : 'error'">{{e.result?.replica?.Slave_IO_Running}}</span>
        </p>

        <p class="detail">
          Replica MySQL - Slave_SQL_Running: <span
            [ngClass]="e.result?.replica?.Slave_SQL_Running == 'Yes' ? 'ok-sub-title' : 'error'">{{e.result?.replica?.Slave_SQL_Running}}</span>
        </p>

        <p class="detail">
          Replica MySQL - Seconds_Behind_Master: <span
            [ngClass]="+e.result?.replica?.Seconds_Behind_Master <=10 ? 'ok-sub-title' : 'error'">{{e.result?.replica?.Seconds_Behind_Master}}</span>
        </p>


        <hr>


        <p class="detail" *ngFor="let du of e.result?.diskUsage">
          DiskUsage {{du.mountPoint}}: <span [ngClass]="+du.isOK ? 'ok-sub-title' : 'error'">{{du.val}}%</span>
        </p>

        <hr>


        <p class="detail">
          Replica FS : <span
            [ngClass]="+item?.commonFiles > 10 ? 'ok-sub-title' : 'error'">{{item?.commonFiles}}%</span>
        </p>


      </div>

    </div>