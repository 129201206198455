import { Component, OnInit, ViewEncapsulation, Inject, } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MysqlService } from 'app/services/mysql.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder, } from '@angular/forms';
//import * as jsPDF from 'jspdf'

@Component({
    selector: 'app-detail-perizie',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})


export class PerizieDetailComponent implements OnInit {


  form: UntypedFormGroup;
  datetime
  note
  tipo = ''
  tipo1 = ''

  tipi = [
    'Promemoria',
    'Disponibilità centro',
    'Disponibilità perito',
    'Disponibilità cliente',
    'Comunicazioni UR',
  ]

  tipi1 = {
    'Disponibilità cliente': [
      'Cliente non risponde',
      'Cliente non vuole riconsegnare ora',
      'Cliente non ha disponibilità nei prossimi 10 gg'
    ]
  }


  constructor(
    private route: ActivatedRoute,
    private db: MysqlService,
    public dialogRef: MatDialogRef<PerizieDetailComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,

  ) {

    this.form = this.formBuilder.group({
      note: ['', [Validators.required]],
      cat: ['', [Validators.required]],
      cat1: ['',],
    })

  }

  ngOnInit() {


  }

  datetimeChange(e) {

    console.log(e)

    this.datetime = e
  }

  Close() {
    this.dialogRef.close('');

  }
  Save() {


    let data = {
      dtaReminder: this.datetime,
      descrizione: this.note,
      categoria: this.tipo,
      categoria1: this.tipo1,
    }

    console.log('click', data)
    this.dialogRef.close(data);

  }

  isButtonDisabled() {
    if (this.tipi1[this.tipo]?.length > 0 && (!this.tipo1 || this.tipo1 == '')) return true
    return !this.note || this.note == '' || this.tipo == '' || (this.tipo == 'reminder' && !this.datetime)
  }

}
