import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
    selector: 'app-adz-viewer-ft',
    templateUrl: './adz-viewer.component.html',
    styleUrls: ['./adz-viewer.component.scss'],
    standalone: false
})


export class AdzViewerFTComponent implements OnInit {

  item: any;
  pdf_perizia: string;

  @Input() //value: IResult
  set setValue(item: any) {
    //this.getContext();
    //console.log('prev value: ', this.item);
    //console.log('got name: ', item);
    this.item = item;


  }


  @Input() //value: IResult
  set setPdfPerizia(item: any) {
    this.pdf_perizia = item;
  }


  @Input() cboxEnabled: boolean = false
  @Input() showOnlySelected: boolean = false
  @Input() altColor: string = '#5555ff'


  @Input() disabled: boolean = false

  @Input() noval: boolean = false

  @Output() onCboxClicked = new EventEmitter<number>();
  constructor(
    public dialog: MatDialog,
  ) { }



  ngOnInit() {
  }


  getRows() {
    return this.item.adz.danni.filter(e => e.selected && this.showOnlySelected || !this.showOnlySelected).map(e => {


      try {
        e.costo_ricambi_imponibile.val = +e.costo_ricambi_imponibile.val.replace('00-', '-').replace('0-', '-')
      } catch (e) { }

      return e

    })

  }

  isAllChecked() {

    for (const d of this.item.adz.danni) {
      if (!d.selected) return false
    }

    return true

  }


  isCustom(s: string) {
    return s.startsWith('^^^ ') && s.endsWith(' ^^^')
  }

  cboxClickedAll(event) {

    if (this.disabled) return

    console.log('cboxClickedAll')

    //console.log(event.checked)
    for (const d of this.item.adz.danni) {
      d.selected = event.checked
    }

    setTimeout(() => {
      this.onCboxClicked.emit(+this.getTotMCA() + +this.getTotMMec())
    }, 100);

  }

  cboxClicked(event) {
    //console.log(this.item)
    setTimeout(() => {
      this.onCboxClicked.emit(+this.getTotMCA() + +this.getTotMMec())
    }, 100);
  }

  getOnlySelectedValue(field, all: boolean = false) {
    let r = 0
    for (const line of this.item.adz.danni.filter(e => (!all && e.selected) || (all && e.voce_di_danno.val.toUpperCase() != '*** RICALCOLO ***'))) {
      try {

        let val = 0
        if (line[field].val)
          val = + line[field].val

        if (Number.isNaN(val)) { // fix per aggregati adz
          val = + line[field].val.replace('00-', '-').replace('0-', '-')
        }

        r += val


        //console.log()
      } catch (error) {
        //console.error(error)
      }
    }

    //console.log(`getOnlySelectedValue ${field} ${all} ${r}`)

    return r
  }




  getSupplDoppioStrato(all: boolean = false) {

    if (!+this.item.adz.tot_tempi_ve.val) return 0
    let supplemento_doppio_strato = +this.item.adz.supplemento_doppio_strato.val / +this.item.adz.tot_tempi_ve.val
    //console.log('getSupplDoppioStrato ' + supplemento_doppio_strato)
    return Math.round(supplemento_doppio_strato * this.getOnlySelectedValue('ve_tempo', all) * 100) / 100

  }

  getSupplFinitura(all: boolean = false) {

    if (!+this.item.adz.tot_tempi_ve.val) return 0
    let supplemento_doppio_strato = +this.item.adz.supplemento_finitura.val / +this.item.adz.tot_tempi_ve.val
    //console.log('getSupplFinitura ' + supplemento_doppio_strato)
    return Math.round(supplemento_doppio_strato * this.getOnlySelectedValue('ve_tempo', all) * 100) / 100

  }

  getTotMdC(all: boolean = false) {

    let x1 = Math.round(this.getTotVE(all)) / 100

    let div = +this.item?.adz?.totale_tempi_ve.val / 100
    let x2 = 0
    if (div != 0)
      x2 = Math.round(+this.item?.adz?.materiali_di_consumo.val / div * 100) / 100

    return x1 * x2

  }

  getTAggVe(all: boolean = false) {
    let ve = +this.getOnlySelectedValue('ve_tempo', all)
    //console.log('getTAggVe ' + ve)
    return ve > 0 ? +this.item.adz.tempo_agg_per_verniciatura.val : 0
  }

  getSupplTot(all: boolean = false) {
    //console.log('getSupplTot ' + this.getSupplDoppioStrato() + ' ' + this.getSupplFinitura() + ' ' + this.getTAggVe())
    return this.getSupplDoppioStrato(all) + this.getSupplFinitura(all) + this.getTAggVe(all)
  }

  getTotVE(all: boolean = false) {
    //console.log('getTotVE ' + this.getSupplTot() + this.getOnlySelectedValue('ve_tempo'))
    return this.getSupplTot(all) + this.getOnlySelectedValue('ve_tempo', all)
  }

  getMdCPrice() {
    if (!+this.item.adz.totale_tempi_ve.val) return 0
    return +this.item.adz.materiali_di_consumo.val / +this.item.adz.totale_tempi_ve.val
  }

  getTotMCA(all: boolean = false) {

    let t =
      +this.getTotVE(all) +
      +this.getOnlySelectedValue('sr_tempo', all) +
      +this.getOnlySelectedValue('la_tempo', all)

    t /= 100

    return Math.round(t * 100) / 100
  }

  getMCAPrice() {
    return +this.item.adz.costo_mdo_carrozzeria.val
  }

  getTotMMec(all: boolean = false) {
    return Math.round(+this.getOnlySelectedValue('me_tempo', all)) / 100
  }

  getMMecPrice() {
    return +this.item.adz.costo_mdo_meccanica.val
  }


  @Output() setValoreRiaddebito = new EventEmitter<number>();


  getTotUsoDime() {
    try {
      return +this.item?.adz?.uso_dime_imp.val
    } catch (error) {

    }

    return 0
  }

  getTot(all: boolean = false) {

    let totAll = +this.getOnlySelectedValue('costo_ricambi_imponibile', true)
      + this.getTotMdC(true) //gia calcolata
      + this.getTotMCA(true) * this.getMCAPrice()
      + this.getTotMMec(true) * this.getMMecPrice()

      + this.getTotUsoDime()



    let totParz = +this.getOnlySelectedValue('costo_ricambi_imponibile', false)
      + this.getTotMdC(false) //gia calcolata
      + this.getTotMCA(false) * this.getMCAPrice()
      + this.getTotMMec(false) * this.getMMecPrice()

      + this.getTotUsoDime()


    this.item.adz.totCompleto = totAll
    this.item.adz.totParziale = totParz

    let tot = all ? totAll : totParz

    this.setValoreRiaddebito.emit(Math.round(tot * 100) / 100)

    return tot
  }


  startEdit() {




    const dialogRef = this.dialog.open(AdzEditDialogFTComponent, {
      width: '40%',
      data: { adzData: { osservazioni: ('' + this.item.adz.osservazioni.val).trim(), imponibile: this.item.adz.totImponibilePersonalizzato } }
    });

    dialogRef.afterClosed().subscribe(res => {

      if (res) {
        //this.item.adz.totImponibilePersonalizzato = res.imponibile
        this.addRicalcolo(res.imponibile)

        this.item.adz.osservazioni.val = res.osservazioni

        this.onCboxClicked.emit(+this.getTotMCA() + +this.getTotMMec())


      }

    })



  }



  addRicalcolo(importo) {

    let descr = '*** RICALCOLO ***'
    this.item.adz.danni = this.item.adz.danni.filter(e => e.voce_di_danno.val != descr)

    let tot = this.getTot(false)
    let row = JSON.parse(JSON.stringify(emptyAdzRow))

    row.voce_di_danno.val = descr
    row.costo_ricambi_imponibile.val = Math.round((+importo - tot) * 100) / 100
    row.selected = true

    this.item.adz.danni.push(row)

  }


  startEditRow(i = undefined) {



    let row: AdzEditRowDialogFTComponent
    if (i != undefined) {
      row = JSON.parse(JSON.stringify(this.item.adz.danni[i]))
      console.log(JSON.stringify(this.item.adz.danni[i]))
    } else {

      row = JSON.parse(JSON.stringify(emptyAdzRow))
    }



    const dialogRef = this.dialog.open(AdzEditRowDialogFTComponent, {
      width: '40%',
      data: { row: row }
    });

    let s = dialogRef.afterClosed().subscribe(res => {

      if (res) {
        console.log(res)

        if (i != undefined)

          if (res == 'delete') {
            this.item.adz.danni.splice(i, 1)
          } else {
            this.item.adz.danni[i] = res
          }

        else
          this.item.adz.danni.push(res)


        this.onCboxClicked.emit(+this.getTotMCA() + +this.getTotMMec())
        
        s.unsubscribe()

      }

    })
  }
}




@Component({
    selector: 'app-adz-edit-dialog-ft',
    templateUrl: 'edit-dialog.html',
    standalone: false
})
export class AdzEditDialogFTComponent implements OnInit {



  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdzEditDialogFTComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { adzData: IAdzEditData },
    private formBuilder: UntypedFormBuilder,
  ) {

    this.form = this.formBuilder.group({

      osservazioni: ['', [Validators.required]],
      imponibile: ['', Validators.required],
    })

  }

  ngOnInit(): void {

  }


  ok() {
    this.dialogRef.close(this.data.adzData);
  }

  close() {
    this.dialogRef.close();
  }

}





@Component({
    selector: 'app-adz-edit-row-dialog-ft',
    templateUrl: 'edit-row-dialog.html',
    standalone: false
})
export class AdzEditRowDialogFTComponent implements OnInit {

  row: IAdzEditDataRow

  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdzEditRowDialogFTComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { row: IAdzEditDataRow },
    private formBuilder: UntypedFormBuilder,
  ) {

    let row = { ...data.row }
    row.sr_tempo.val = +row.sr_tempo.val / 100
    row.la_tempo.val = +row.la_tempo.val / 100
    row.ve_tempo.val = +row.ve_tempo.val / 100
    row.me_tempo.val = +row.me_tempo.val / 100
    row.detraz_sconto.val = row.detraz_sconto.val
    row.costo_ricambi_imponibile.val = +row.costo_ricambi_imponibile.val

    this.row = row


    this.form = this.formBuilder.group({
      codice_ricambio: [''],
      voce_di_danno: [''],
      lato: [''],
      sr_diff: [''],
      sr_tempo: [''],
      la_diff: [''],
      la_tempo: [''],
      ve_tempo: [''],
      me_tempo: [''],
      detraz_sconto: [''],
      costo_ricambi_imponibile: [''],


    })

  }

  ngOnInit(): void {

  }


  ok() {

    this.dialogRef.close();

    let row = { ...this.row };

    let s: string = '' + row.voce_di_danno.val
    row.voce_di_danno.val = s.replace('^^^', '***')

    row.sr_tempo.val = +row.sr_tempo.val * 100
    row.la_tempo.val = +row.la_tempo.val * 100
    row.ve_tempo.val = +row.ve_tempo.val * 100
    row.me_tempo.val = +row.me_tempo.val * 100
    row.edited = true

    this.dialogRef.close(row);
  }

  close() {
    this.dialogRef.close();
  }

  delete() {
    if (confirm("Sei sicuro di voler eliminare questa riga?")) {
      this.dialogRef.close('delete');
    }
  }

}



interface IAdzEditData {
  osservazioni: string
  imponibile: number
}


export interface IAdzEditDataRow {

  "*": IADZField
  codice_ricambio: IADZField
  voce_di_danno: IADZField
  lato: IADZField
  sr_diff: IADZField
  sr_tempo: IADZField
  la_diff: IADZField
  la_tempo: IADZField
  ve_tempo: IADZField
  ve_diff: IADZField
  me_tempo: IADZField
  detraz_sconto: IADZField
  costo_ricambi_imponibile: IADZField

  selected?: boolean
  edited?: boolean
}


export interface IADZField {
  val: string | number
  layoutX?: number,
  layoutY?: number,
  type?: string
}

export const emptyAdzRow =
{
  "*": {
    "val": "",
    "layoutX": 160,
    "layoutY": 108,
    "type": "s"
  },
  "codice_ricambio": {
    "val": "",
    "layoutX": 12,
    "layoutY": 108,
    "type": "s10"
  },
  "costo_ricambi_imponibile": {
    "val": "",
    "layoutX": 178,
    "layoutY": 108,
    "type": "nr"
  },
  "detraz_sconto": {
    "val": "",
    "layoutX": 152,
    "layoutY": 108,
    "type": "s"
  },
  "lato": {
    "val": "",
    "layoutX": 90,
    "layoutY": 108,
    "type": "s"
  },
  "la_diff": {
    "val": "",
    "layoutX": 110,
    "layoutY": 108,
    "type": "s"
  },
  "la_tempo": {
    "val": "",
    "layoutX": 115,
    "layoutY": 108,
    "type": "n100"
  },
  "me_tempo": {
    "val": "",
    "layoutX": 140,
    "layoutY": 108,
    "type": "n100"
  },
  "sr_diff": {
    "val": "",
    "layoutX": 95,
    "layoutY": 108,
    "type": "s"
  },
  "sr_tempo": {
    "val": "",
    "layoutX": 100,
    "layoutY": 108,
    "type": "n100"
  },
  "ve_diff": {
    "val": "",
    "layoutX": 125,
    "layoutY": 108,
    "type": "s"
  },
  "ve_tempo": {
    "val": "",
    "layoutX": 130,
    "layoutY": 108,
    "type": "n100"
  },
  "voce_di_danno": {
    "val": "",
    "layoutX": 27,
    "layoutY": 108,
    "type": "s"
  },
  "selected": true
}