"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * [[NoOpVideoFrameProcessor]] implements [[VideoFrameProcessor]].
 * It's a no-op processor and input is passed to output directly.
 */
class NoOpVideoFrameProcessor {
  process(buffers) {
    return __awaiter(this, void 0, void 0, function* () {
      return buffers;
    });
  }
  destroy() {
    return __awaiter(this, void 0, void 0, function* () {
      return;
    });
  }
}
exports.default = NoOpVideoFrameProcessor;
