{
  "browser": {
    "fs": false,
    "path": false,
    "os": false
  },
  "name": "xpert",
  "version": "4.2.156",
  "license": "",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --open",
    "build": "ng build",
    "test": "ng test",
    "lint": "eslint './src/**/*.{ts,tsx}'",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@ag-grid-community/locale": "^32.3.3",
    "@agm/core": "1.0.0-beta.2",
    "@angular/animations": "^19.0.5",
    "@angular/cdk": "^19.0.4",
    "@angular/common": "^19.0.5",
    "@angular/compiler": "^19.0.5",
    "@angular/core": "^19.0.5",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "^19.0.5",
    "@angular/material": "^19.0.4",
    "@angular/platform-browser": "^19.0.5",
    "@angular/platform-browser-dynamic": "^19.0.5",
    "@angular/platform-server": "^19.0.5",
    "@angular/router": "^19.0.5",
    "@ckeditor/ckeditor5-angular": "^9.0.0",
    "@ckeditor/ckeditor5-build-classic": "^19.0.2",
    "@ckeditor/ckeditor5-export-pdf": "0.0.4",
    "@ecodev/fab-speed-dial": "^17.0.0",
    "@googlemaps/markerclusterer": "^2.5.3",
    "@ng-bootstrap/ng-bootstrap": "^6.1.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@opentok/client": "^2.27.3",
    "@photo-sphere-viewer/autorotate-plugin": "^5.5.2",
    "@photo-sphere-viewer/core": "^5.5.2",
    "@stripe/stripe-js": "^1.9.0",
    "360-image-viewer": "^1.0.1",
    "ag-grid-angular": "^32.3.3",
    "ag-grid-community": "^32.3.3",
    "ag-grid-enterprise": "^32.3.3",
    "amazon-chime-sdk-js": "^2.31.0",
    "bingmaps": "^2.0.3",
    "bootstrap": "^5.3.3",
    "bootstrap-material-design": "^4.1.3",
    "chart.js": "^2.8.0",
    "chartist": "0.11.0",
    "chartjs-plugin-datalabels": "^1.0.0",
    "ckeditor5": "^19.1.1",
    "core-js": "^3.39.0",
    "devextreme": "^24.1.6",
    "devextreme-angular": "^24.1.6",
    "exceljs": "^4.4.0",
    "file-saver": "^2.0.2",
    "firebase": "^11.1.0",
    "font-awesome": "^4.7.0",
    "html2canvas": "^1.4.1",
    "jszip": "^3.2.2",
    "jszip-utils": "^0.1.0",
    "leaflet": "^1.5.1",
    "leaflet.markercluster": "^1.4.1",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.28",
    "ng-multiselect-dropdown": "^1.0.0",
    "ng-pick-datetime": "^5.2.6",
    "ngx-file-drop": "^16.0.0",
    "ngx-moment": "^3.4.0",
    "ngx-stripe": "^16.4.0",
    "opentok": "^2.18.0",
    "opentok-client": "^2.10.2",
    "pdfjs-dist": "4.8",
    "perfect-scrollbar": "^1.4.0",
    "rxjs": "6.5.5",
    "three": "^0.147.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular/cli": "^19.0.6",
    "@angular/compiler-cli": "^19.0.5",
    "@angular/language-service": "^19.0.5",
    "@eslint/js": "^9.17.0",
    "@types/bootstrap": "^5.2.10",
    "@types/chart.js": "^2.9.41",
    "@types/chartist": "0.9.34",
    "@types/google.maps": "^3.58.1",
    "@types/leaflet.markercluster": "^1.4.0",
    "@types/node": "^12.11.1",
    "@types/offscreencanvas": "^2019.7.0",
    "@types/stripe-checkout": "^1.0.3",
    "@types/stripe-v3": "^3.1.20",
    "@types/supercluster": "^7.1.3",
    "@typescript-eslint/eslint-plugin": "^8.19.0",
    "@typescript-eslint/parser": "^8.19.0",
    "eslint": "^9.17.0",
    "globals": "^15.14.0",
    "sass": "^1.83.0",
    "ts-node": "5.0.1",
    "tslib": "^2.0.0",
    "typescript": "^5.6.3",
    "typescript-eslint": "^8.19.0"
  },
  "aot": "node --max-old-space-size=8192 ./node_modules/@angular/cli/bin/ng build --prod --aot  --output-hashing=all",
  "resolutions": {
    "webpack": "^5.0.0"
  }
}
