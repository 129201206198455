import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'multiline',
    standalone: false
})
export class MultilinePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    try {
      value = value.split("\n");
      return value;
    } catch (error) {
      console.error(error);
      return value;
    }
  }

}
