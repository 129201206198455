import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

//throw new Error('White screen, reveal yourself!');

platformBrowserDynamic().bootstrapModule(AppModule)
//.then((res) => console.log(res))
.catch((err) => console.error(err));

