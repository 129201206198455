import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SysMonDialogComponent } from './detail/sysmon-dialogcomponent';
import { Router } from '@angular/router';
import { MysqlService } from 'app/services/mysql.service';

@Component({
    selector: 'app-sysmon',
    templateUrl: './sysmon.component.html',
    styleUrls: ['./sysmon.component.scss'],
    standalone: false
})
export class SysMonComponent implements OnInit {


  item


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private db: MysqlService,
  ) {


  }

  async ngOnInit() {


    this.item = await this.db.sysmon.get_info()
    console.log('this.item: ', this.item);

  }

  async open(asPage = false) {

    if (asPage) {

      this.router.navigate(['sysmon'])

      return
    }

    let dialogRef = await this.dialog.open(SysMonDialogComponent, { data: this.item })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
      }
    })
  }

}
