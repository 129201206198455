"use strict";

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckCameraResolutionFeedback = void 0;
var CheckCameraResolutionFeedback;
(function (CheckCameraResolutionFeedback) {
  CheckCameraResolutionFeedback[CheckCameraResolutionFeedback["Succeeded"] = 0] = "Succeeded";
  CheckCameraResolutionFeedback[CheckCameraResolutionFeedback["Failed"] = 1] = "Failed";
  CheckCameraResolutionFeedback[CheckCameraResolutionFeedback["ResolutionNotSupported"] = 2] = "ResolutionNotSupported";
  CheckCameraResolutionFeedback[CheckCameraResolutionFeedback["PermissionDenied"] = 3] = "PermissionDenied";
})(CheckCameraResolutionFeedback = exports.CheckCameraResolutionFeedback || (exports.CheckCameraResolutionFeedback = {}));
exports.default = CheckCameraResolutionFeedback;
