<div class="card">

  <div class="card-header card-header-info">
    <h4 class="card-title">
      Inserisci/Modifica Riga
      <i style="float:right" color="danger" (click)="close()" class="material-icons">close</i>
    </h4>
  </div>

  <br>
  <br>

  <form [formGroup]="form">
    <div>
      <div class="row ml-2 mr-3">
        <div class="col">
          <mat-form-field class="full">
            <mat-label>Codice ricambio</mat-label>
            <input formControlName="codice_ricambio" type="text" matInput placeholder="Codice ricambio"
              [(ngModel)]="row.codice_ricambio.val">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full">
            <mat-label>Voce di danno</mat-label>
            <input formControlName="voce_di_danno" type="text" matInput placeholder="Voce di danno"
              [(ngModel)]="row.voce_di_danno.val">
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full">
            <mat-label>Lato</mat-label>
            <input formControlName="lato" type="text" matInput placeholder="Lato" [(ngModel)]="row.lato.val">
          </mat-form-field>
        </div>
      </div>

      <div class="row" style="width:100%">
        <div class="col-3">
          <div style="margin-left:25px; border: 2px royalblue solid; padding:15px;">
            <mat-form-field class="full">
              <mat-label>SR D.</mat-label>
              <input formControlName="sr_diff" type="text" matInput placeholder="SR D." [(ngModel)]="row.sr_diff.val"
                readonly>
            </mat-form-field>

            <mat-form-field class="full">
              <mat-label>SR Tempo</mat-label>
              <input formControlName="sr_tempo" type="number" matInput placeholder="SR Tempo"
                [(ngModel)]="row.sr_tempo.val">
            </mat-form-field>
          </div>
        </div>
        <div class="col-3">
          <div style="border: 2px green solid; padding:15px;">
            <mat-form-field class="full">
              <mat-label>LA D.</mat-label>
              <input formControlName="la_diff" type="text" matInput placeholder="LA D." [(ngModel)]="row.la_diff.val"
                readonly>
            </mat-form-field>

            <mat-form-field class="full">
              <mat-label>LA Tempo</mat-label>
              <input formControlName="la_tempo" type="number" matInput placeholder="la_tempo"
                [(ngModel)]="row.la_tempo.val">
            </mat-form-field>
          </div>
        </div>
        <div class="col-3">

          <div style="border: 2px orange solid; padding:15px;">
            <mat-form-field class="full">
              <mat-label>VE D.</mat-label>
              <input formControlName="ve_diff" type="text" matInput placeholder="VE D." [(ngModel)]="row.ve_diff.val"
                readonly>
            </mat-form-field>

            <mat-form-field class="full">
              <mat-label>VE Tempo</mat-label>
              <input formControlName="ve_tempo" type="number" matInput placeholder="VE Tempo"
                [(ngModel)]="row.ve_tempo.val">
            </mat-form-field>
          </div>

        </div>
        <div class="col-3">
          <div style="border: 2px rebeccapurple solid; padding:15px; height:100%">
            <mat-form-field class="full">
              <mat-label>ME Tempo</mat-label>
              <input formControlName="me_tempo" type="number" matInput placeholder="ME Tempo"
                [(ngModel)]="row.me_tempo.val">
            </mat-form-field>
          </div>

        </div>
      </div>
      <br>
      <div class="row ml-2 mr-3">

        <div class="col">
          <mat-form-field class="full">
            <mat-label>Sconto</mat-label>
            <input formControlName="detraz_sconto" type="text" matInput placeholder="Sconto" readonly
              [(ngModel)]="row.detraz_sconto.val">
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field class="full">
            <mat-label>Costo Ricambi</mat-label>
            <input formControlName="costo_ricambi_imponibile" type="number" matInput placeholder="Costo Ricambi"
              [(ngModel)]="row.costo_ricambi_imponibile.val">
          </mat-form-field>
        </div>
      </div>

      <div class="row" style="width:100%; padding:15px">
        <div class="col">

          <button class="btn btn-danger" type="button" mat-raised-button (click)="delete()"
            style="float:left">Elimina</button>

          <button class="btn btn-danger" type="button" mat-raised-button (click)="close()"
            style="float:right">Annulla</button>
          <button class="btn btn-success" type="button" mat-raised-button (click)="ok()"
            style="float:right; margin-right:15px">Salva</button>
        </div>
      </div>
    </div>
  </form>
</div>