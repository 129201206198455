import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { MarkerClusterer, Renderer } from '@googlemaps/markerclusterer'

declare let google: any;


@Component({
  selector: 'app-map-selector',
  templateUrl: './map-selector.component.html',
  styleUrls: ['./map-selector.component.scss'],
  standalone: false
})
export class MapSelectorComponent implements OnInit {

  search = ''

  currentItem


  geocoder = new google.maps.Geocoder();



  @ViewChild('map', { static: true }) public mapRef: ElementRef;

  loading = true

  map = null

  markers = []
  infoWindows = []
  cluster

  constructor(
    public dialogRef: MatDialogRef<MapSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { list: any[], search: string },
  ) {

    //console.clear()
    console.log(data.list)
    this.search = data.search


  }

  ngOnInit(): void {
    setTimeout(() => {
      this.initialize(true)
    }, 200);
  }


  initialize(first = false) {
    this.loading = true
    this.map = null

    let myOptions = {
      zoom: 6.75,
      center: new google.maps.LatLng(42.1095623, 13.2913728),

      mapTypeControl: false,
      mapTypeControlOptions: { style: google.maps.MapTypeControlStyle.DROPDOWN_MENU },
      navigationControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      streetViewControl: false,
      fullscreenControl: false,

      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          stylers: [{ visibility: "off" }],
        },


      ]
    }

    this.map = new google.maps.Map(this.mapRef.nativeElement, myOptions);

    this.loadMarkers(this.data.list)

    this.onSearch()

    if (first) this.loading = false


  }

  async loadMarkers(list) {

    for (const e of list) {

      let location

      if (e.lat && e.lng) {

        location = new google.maps.LatLng(e.lat, e.lng)
        let marker = new google.maps.Marker({
          title: e.title,
          position: location,
          map: this.map,
          label: { text: '' + (e.n ? e.n : 0), color: "white" },
          icon: e.icon,
          //scaledSize: new google.maps.Size(60,47)
        });

        let infoWindow = new google.maps.InfoWindow({
          content: `
            <h5 style="font-weight:bold">${e.title}</h5>
            <p>
              <span>${e.address.toUpperCase()}</span>
              <br><br>
              <span>${e.contact}</span><br><br>
              <span>N° posti auto: ${e.npa}</span><br><br>
              <span>Ispezioni pianificate nelle prossime due settimane: ${e.n ? e.n : 0}</span>
              <hr>
              <strong>Note:</strong><br>
              <span>${e.note ? e.note : ''}</span>
            </p>
          `
        });

        this.infoWindows.push(infoWindow)

        marker.addListener('click', () => {
          console.log('marker click')
          this.clearInfoWindows();

          console.log(e)

          this.currentItem = e

          infoWindow.open(this.map, marker);
        });


        this.markers.push(marker)

      }

    }


    const customRenderer: Renderer = {
      render: (cluster, stats, map) => {
        const markersInCluster = cluster.markers; // Usa 'markers' invece di 'getMarkers'
        const count = markersInCluster.length;
        let size = 48; // Dimensione base dell'icona
    
        if (count > 50) {
          size = 64; // Cambia la dimensione se ci sono più di 50 marker
        }
    
        const icon = {
          url: '/assets/img/scudo.svg', // Path dell'icona personalizzata
          size: new google.maps.Size(size, size),
          anchor: new google.maps.Point(size / 2, size / 2),
          scaledSize: new google.maps.Size(size, size),
        };
    
        // Restituisci un oggetto conforme al tipo 'Marker'
        const marker = new google.maps.Marker({
          position: cluster.bounds.getCenter(), // Ottieni la posizione del centro del cluster
          icon: icon,
          label: {
            text: count.toString(), // Numero di marker nel cluster
            //color: 'white', // Colore del testo
            fontWeight: 'bold',
            fontSize: '18px',
          },
        });
    
        return marker; // Restituisci un oggetto Marker valido
      },
    };

    this.cluster = new MarkerClusterer({
      map: this.map,
      markers: this.markers,

      renderer: customRenderer,


      /*
            styles: [{
              height: 48,
              url: "/assets/img/scudo.svg",
              width: 48,
              className: "custom-clustericon-1",
            }],
            
            gridSize: 48,
      
      */
    });

}

clearInfoWindows() {
  for (const e of this.infoWindows) {
    e.close()
  }
}

ok() {
  console.log('ok ', this.currentItem)

  if (!confirm(`Vuoi confermare la selezione del seguente punto di interesse?\n\n${this.currentItem.title}\n${this.currentItem.address}`)) return

  this.dialogRef.close(this.currentItem);

}

onSearchTimouts = []
onSearchNotFound = undefined
onSearch() {

  this.onSearchTimouts.forEach(e => clearTimeout(e));
  let t = setTimeout(() => {
    console.log('search: ', this.search)

    if (this.search == "") {

      this.map.setCenter(new google.maps.LatLng(42.1095623, 13.2913728))
      this.map.setZoom(6.75)
      this.onSearchNotFound = undefined

      return
    }

    this.geocoder.geocode({ 'address': 'Italy, IT, ' + this.search }, async (results, status) => {
      if (status == 'OK') {


        this.onSearchNotFound = false

        console.log(results[0])

        let location = results[0].geometry.location

        this.map.setCenter(location)
        this.map.setZoom(12)

      } else {

        this.onSearchNotFound = true

      }
    });

  }, 300);
  this.onSearchTimouts.push(t)
}

}
